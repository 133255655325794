// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperQuizCategoriesPage from "../component/WrapperQuizCategoriesPage";

const mapStateToProps = (state) => ({
  quizCategories: state.quizCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getQuizCategories: () => dispatch(Actions.getQuizCategories()),
  getQuizCategoryById: (...args) =>
    dispatch(Actions.getQuizCategoryById(...args)),
  createQuizCategory: (...args) =>
    dispatch(Actions.createQuizCategory(...args)),
  deleteQuizCategory: (...args) =>
    dispatch(Actions.deleteQuizCategory(...args)),
  updateQuizCategory: (...args) =>
    dispatch(Actions.updateQuizCategory(...args)),
});
class QuizCategoriesPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    quizCategories: PropTypes.array,
    getQuizCategories: PropTypes.func,
    getQuizCategoryById: PropTypes.func,
    createQuizCategory: PropTypes.func,
    deleteQuizCategory: PropTypes.func,
    updateQuizCategory: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, quizCategories } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: quizCategories.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getQuizCategories } = this.props;
    await Promise.all([getQuizCategories()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      quizCategories,
      getQuizCategoryById,
      createQuizCategory,
      deleteQuizCategory,
      updateQuizCategory,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperQuizCategoriesPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        quizCategories={quizCategories}
        getQuizCategoryById={getQuizCategoryById}
        createQuizCategory={createQuizCategory}
        deleteQuizCategory={deleteQuizCategory}
        updateQuizCategory={updateQuizCategory}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuizCategoriesPage);
