import { ACADEMIES, EVENT_TYPES, SEARCH } from "constants/routes";

export function redirectAccordingToRole(accessLevel) {
  if (
    accessLevel &&
    accessLevel.services &&
    accessLevel.services.cpfm.findIndex((r) => r === "admin") !== -1
  ) {
    return ACADEMIES;
  }

  if (
    accessLevel &&
    accessLevel.services &&
    accessLevel.services.calendy.findIndex((r) => r === "admin") !== -1
  ) {
    return EVENT_TYPES;
  }

  return SEARCH;
}
