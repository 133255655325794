import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Check";

// styles
import styles from "./styles";

class FormEventType extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    eventType: PropTypes.object,

    createEventType: PropTypes.func,
    updateEventType: PropTypes.func,
    deleteEventType: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      duration: "",
      timeGap: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.eventType);
    }
  }

  init(eventType) {
    this.setState({
      loading: false,
      name: eventType ? eventType.name : "",
      duration: eventType ? `${eventType.duration}` : "",
      timeGap: eventType ? `${eventType.timeGap}` : "",
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle:
          "If you delete this eventType, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteEventType, close, refresh, eventType } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteEventType(eventType.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "EventType has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateEventType,
      createEventType,
      eventType,
      refresh,
      close,
    } = this.props;

    const { name, duration, timeGap } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (eventType) {
      resp = await updateEventType(eventType.id, {
        name,
        duration: Number(duration),
        timeGap: Number(timeGap),
      });
    } else {
      resp = await createEventType({
        name,
        duration: Number(duration),
        timeGap: Number(timeGap),
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: eventType
          ? "EventType has been updated."
          : "EventType has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, eventType, close } = this.props;

    const { name, loading, duration, timeGap } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {eventType ? `${eventType.name}` : "Let's Create a New EventType"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {eventType
                  ? "To update the eventType, fill the form below :"
                  : "To create a new eventType, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name *"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="duration"
                    label="Duration (in minutes)"
                    className={classes.textField}
                    value={duration}
                    onChange={this.handleChange("duration")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="timeGap"
                    label="Selection Time Gap (in minutes)"
                    className={classes.textField}
                    value={timeGap}
                    onChange={this.handleChange("timeGap")}
                  />
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Amount of time between each selectable slots.
                    <br />
                    Default is 15 mn (ex: 9:00 AM, 9:15 AM, 9:30 AM ... etc)
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {eventType ? (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
              ) : (
                []
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />{" "}
                {eventType ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormEventType));
