// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperQuizTagsPage from "../component/WrapperQuizTagsPage";

const mapStateToProps = (state) => ({
  quizTags: state.quizTags,
  quizCategories: state.quizCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getQuizTags: () => dispatch(Actions.getQuizTags()),
  getQuizTagById: (...args) => dispatch(Actions.getQuizTagById(...args)),
  createQuizTag: (...args) => dispatch(Actions.createQuizTag(...args)),
  deleteQuizTag: (...args) => dispatch(Actions.deleteQuizTag(...args)),
  updateQuizTag: (...args) => dispatch(Actions.updateQuizTag(...args)),
  getQuizCategories: (...args) => dispatch(Actions.getQuizCategories(...args)),
});
class QuizTagsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    quizTags: PropTypes.array,
    getQuizTags: PropTypes.func,
    getQuizTagById: PropTypes.func,
    createQuizTag: PropTypes.func,
    deleteQuizTag: PropTypes.func,
    updateQuizTag: PropTypes.func,

    getQuizCategories: PropTypes.func,
    quizCategories: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, quizTags } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: quizTags.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getQuizTags, quizCategories, getQuizCategories } = this.props;
    const _promises = [getQuizTags()];
    if (!quizCategories.length) {
      _promises.push(getQuizCategories());
    }
    await Promise.all(_promises);

    this.setState({ loading: false });
  }

  render() {
    const {
      quizTags,
      getQuizTagById,
      createQuizTag,
      deleteQuizTag,
      updateQuizTag,
      quizCategories,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperQuizTagsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        quizTags={quizTags}
        getQuizTagById={getQuizTagById}
        createQuizTag={createQuizTag}
        deleteQuizTag={deleteQuizTag}
        updateQuizTag={updateQuizTag}
        quizCategories={quizCategories}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuizTagsPage);
