// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperStudentPage from "../component/WrapperStudentPage";

const mapStateToProps = (state) => ({
  timezones: state.timezones,
  assessments: state.assessments,
  user: state.user,
  coaches: state.coaches,
  eventTypes: state.eventTypes,
});

const mapDispatchToProps = (dispatch) => ({
  getCalendyStudents: (...args) =>
    dispatch(Actions.getCalendyStudents(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
  getSurveys: (...args) => dispatch(Actions.getSurveys(...args)),
  getEvents: (...args) => dispatch(Actions.getEvents(...args)),
  getEnrollments: (...args) => dispatch(Actions.getEnrollments(...args)),
  deleteEvent: (...args) => dispatch(Actions.deleteEvent(...args)),
  updateEvent: (...args) => dispatch(Actions.updateEvent(...args)),
  getTimeAssets: (...args) => dispatch(Actions.getTimeAssets(...args)),
  sendStudentEmail: (...args) => dispatch(Actions.sendStudentEmail(...args)),
  getTimezones: (...args) => dispatch(Actions.getTimezones(...args)),
  getAssessments: (...args) => dispatch(Actions.getAssessments(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
  getEventTypes: (...args) => dispatch(Actions.getEventTypes(...args)),
  getCoaches: (...args) => dispatch(Actions.getCoaches(...args)),
  createEvent: (...args) => dispatch(Actions.createEvent(...args)),
});

class StudentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    timezones: PropTypes.array,
    assessments: PropTypes.array,
    getTimezones: PropTypes.func,

    getCalendyStudents: PropTypes.func,
    getUsers: PropTypes.func,
    getEnrollments: PropTypes.func,
    getSurveys: PropTypes.func,
    deleteEvent: PropTypes.func,
    updateEvent: PropTypes.func,
    getTimeAssets: PropTypes.func,
    sendStudentEmail: PropTypes.func,
    createEvent: PropTypes.func,
    getAssessments: PropTypes.func,
    searchUsers: PropTypes.func,
    getEventTypes: PropTypes.func,
    coaches: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      getTimezones,
      getAssessments,
      getEventTypes,
      getCoaches,
    } = this.props;
    getTimezones();
    getAssessments();
    getEventTypes();
    getCoaches();
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      uuid: urlParams.uuid,
      loading: true,
      back: urlParams.back,
    };
    this.refresh();
  }

  async refresh() {
    const {
      getCalendyStudents,
      getUsers,
      getSurveys,
      getEnrollments,
    } = this.props;
    const { uuid } = this.state;

    const resps0 = await Promise.all([
      getCalendyStudents([
        {
          name: "students.userUUID",
          comparison: "eq",
          value: uuid,
        },
      ]),
      getUsers([
        {
          name: "uuid",
          comparison: "eq",
          value: uuid,
        },
      ]),
      getSurveys([
        {
          name: "surveys.userUUID",
          comparison: "eq",
          value: uuid,
        },
      ]),
      getEnrollments([
        {
          name: "enrollments.userUID",
          comparison: "eq",
          value: uuid,
        },
      ]),
    ]);

    const calendyStudent = resps0[0].payload[0];
    const user = resps0[1].payload[0];
    const surveys = resps0[2].payload;
    const enrollments = resps0[3].payload;

    this.setState({
      calendyStudent,
      user,
      surveys,
      enrollments,
      loading: false,
    });
  }

  // async refreshCalendy() {
  //   const { getEvents, getTimeAssets } = this.props;
  //   const { calendyStudent } = this.state;
  //   const resps1 = await Promise.all([
  //     getEvents([
  //       {
  //         name: "studentID",
  //         comparison: "eq",
  //         value: calendyStudent.id,
  //       },
  //     ]),
  //     getTimeAssets([
  //       {
  //         name: "studentID",
  //         comparison: "eq",
  //         value: calendyStudent.id,
  //       },
  //     ]),
  //   ]);

  //   const events = resps1[0].payload;
  //   const timeAssets = resps1[1].payload;

  //   this.setState({
  //     events,
  //     timeAssets,
  //   });
  // }

  render() {
    const {
      location,
      history,
      deleteEvent,
      eventTypes,
      updateEvent,
      assessments,
      sendStudentEmail,
      getSurveys,
      timezones,
      searchUsers,
      coaches,
      createEvent,
    } = this.props;

    const {
      urlParams,
      uuid,
      calendyStudent,
      loading,
      surveys,
      enrollments,
      events,
      user,
      timeAssets,
      back,
    } = this.state;

    console.log(calendyStudent?.events);
    console.log("*******");

    return (
      <WrapperStudentPage
        coaches={coaches}
        history={history}
        uuid={uuid}
        back={back}
        timeAssets={timeAssets}
        assessments={assessments}
        sendStudentEmail={sendStudentEmail}
        user={user}
        events={calendyStudent?.events}
        createEvent={createEvent}
        timeAssets={calendyStudent?.timeAssets}
        searchUsers={searchUsers}
        eventTypes={eventTypes}
        getSurveys={getSurveys}
        timezones={timezones}
        location={location}
        loading={loading}
        surveys={surveys}
        enrollments={enrollments}
        urlParams={urlParams}
        updateEvent={updateEvent}
        deleteEvent={deleteEvent}
        calendyStudent={calendyStudent}
        refreshCalendy={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentPage);
