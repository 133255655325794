import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// components
import Page from "components/Page";
import Table from "components/TablePagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperAssessmentLogsPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    harmonyChangelogs: PropTypes.array,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const { harmonyChangelogs, history, getHarmonyChangelogs } = this.props;

    return (
      <Page helmet="Assessment Logs">
        <AdvancedSearch
          history={history}
          location={window.location}
          refresh={getHarmonyChangelogs}
          keyword="changelogs"
          metaDataEndpoint="/aptitude/changelogs/searchMetadata"
        >
          <Table
            data={harmonyChangelogs || []}
            noToolbar
            meta={[
              {
                path: "userName",
                title: "User",
              },
              {
                path: "modelName",
                title: "Assessment Name",
              },
              {
                path: "modelID",
                title: "AssessmentID",
              },
              {
                path: "method",
                title: "Method",
              },
              {
                path: "createdAt",
                title: "Date",
                transform: (t) => moment(t).format("lll"),
              },
            ]}
            title={"Change logs"}
          />
        </AdvancedSearch>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperAssessmentLogsPage);
