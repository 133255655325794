import {
  GET_QUIZ_TAGS,
} from 'constants/quizTags';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_QUIZ_TAGS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
