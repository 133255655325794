import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// components
import AuthorsList from 'components/AuthorsList';
import Page from 'components/Page';
import FormAuthor from 'components/FormAuthor';

// styles
import styles from './styles';

class WrapperAuthorsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //authors
    authors: PropTypes.array,
    getAuthorById: PropTypes.func,
    createAuthor: PropTypes.func,
    deleteAuthor: PropTypes.func,
    updateAuthor: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedAuthor: undefined,
      formAuthorOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { authors } = this.props;

    switch (index) {
      case 0:
        return (
          <AuthorsList
            authors={authors}
            onSelect={(selectedAuthor) => this.setState({
              selectedAuthor,
              formAuthorOpen: true,
            })}
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createAuthor,
      deleteAuthor,
      updateAuthor,
      refresh,
    } = this.props;

    const {
      index,
      selectedAuthor,
      formAuthorOpen,
    } = this.state;

    return (
      <Page
        helmet={'Authors'}
        loadingMessage={'Loading Document'}
        loading={loading}
        fab={(
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formAuthorOpen: true })}
          >
            <AddIcon />
          </Fab>
        )}
        tabs={(
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          >
            <Tab
              label="Authors"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        )}
      >
        {
          this.getStepContent()
        }
        <FormAuthor
          open={formAuthorOpen}
          author={selectedAuthor}
          close={() => {
            this.setState({
              formAuthorOpen: false,
            });
            setTimeout(() => this.setState({
              selectedAuthor: undefined,
            }), 200);
          }
          }
          createAuthor={createAuthor}
          deleteAuthor={deleteAuthor}
          updateAuthor={updateAuthor}
          refresh={refresh}
        />
      </Page>
    )
  }
}
export default withStyles(styles)(WrapperAuthorsPage);

