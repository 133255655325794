import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCategoryPage from "../component/WrapperCategoryPage";
import Loading from "components/Loading";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  categories: state.cpfmCategories,
  careers: state.careers,
});

const mapDispatchToProps = (dispatch) => ({
  getCpfmCategoryById: (...args) =>
    dispatch(Actions.getCpfmCategoryById(...args)),
  getCpfmCategoryCareers: (...args) =>
    dispatch(Actions.getCpfmCategoryCareers(...args)),
  getFullApp: () => dispatch(Actions.getFullApp()),
  getCpfmCategories: () => dispatch(Actions.getCpfmCategories()),
});

class CategoryPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    categories: PropTypes.array,

    getCpfmCategoryById: PropTypes.func,
    getCpfmCategoryCareers: PropTypes.func,
    getFullApp: PropTypes.func,
    getCpfmCategories: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
      category: {},
      relatedCareers: [],
    };

    this.refresh();
  }

  async refresh() {
    const {
      getCpfmCategories,
      getFullApp,
      categories,
      careers,
      location,
      getCpfmCategoryById,
      getCpfmCategoryCareers,
    } = this.props;
    this.setState({ loading: true });

    const urlParams = getJsonFromUrl(location);

    if (urlParams.categoryID !== undefined) {
      getCpfmCategoryById(urlParams.categoryID).then((resp) =>
        this.setState({
          category: resp.payload,
          loading: !(
            this.props.categories.length > 0 &&
            this.props.careers.length > 0 &&
            this.state.relatedCareers.length > 0
          ),
        })
      );

      getCpfmCategoryCareers(urlParams.categoryID).then((resp) =>
        this.setState({
          relatedCareers: resp.payload,
          loading: !(
            this.props.categories.length > 0 && this.props.careers.length > 0
          ),
        })
      );

      if (categories.length === 0) {
        getCpfmCategories().then(() => {
          this.setState({
            loading: !(
              this.props.careers.length > 0 &&
              this.state.category.id !== undefined &&
              this.state.relatedCareers.length > 0
            ),
          });
        });
      }

      if (careers.length === 0) {
        getFullApp().then(() => {
          this.setState({
            loading: !(
              this.props.categories.length > 0 &&
              this.state.category.id !== undefined &&
              this.state.relatedCareers.length > 0
            ),
          });
        });
      }
    }
  }

  render() {
    const { urlParams, loading, category, relatedCareers } = this.state;

    const { categories, history, location, careers } = this.props;

    return loading ? (
      <div style={{ background: "#455a64", flex: 1 }} />
    ) : (
      <WrapperCategoryPage
        refresh={this.refresh.bind(this)}
        categories={categories}
        location={location}
        history={history}
        urlParams={urlParams}
        loading={loading}
        careers={careers}
        category={category}
        relatedCareers={relatedCareers}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
