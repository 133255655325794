import { serviceRoles } from "config/access";
import { DEGREE_REQUIREMENTS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import DegreeRequirementsPage from "./container/DegreeRequirementsPage";

export default function getRoute(userAuth) {
  const { account, cpfm } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [cpfm.name]: [cpfm.roles.admin],
  };
  return {
    path: DEGREE_REQUIREMENTS,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: DegreeRequirementsPage,
  };
}
