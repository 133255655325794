import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperMasterTagsPage from '../component/WrapperMasterTagsPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  masterTags: state.masterTags,
});

const mapDispatchToProps = dispatch => ({
  getMasterTags: () => dispatch(Actions.getMasterTags()),
  getMasterTagById: (...args) => dispatch(Actions.getMasterTagById(...args)),
  createMasterTag: (...args) => dispatch(Actions.createMasterTag(...args)),
  deleteMasterTag: (...args) => dispatch(Actions.deleteMasterTag(...args)),
  updateMasterTag: (...args) => dispatch(Actions.updateMasterTag(...args)),
});
class MasterTagsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    masterTags: PropTypes.array,
    getMasterTags: PropTypes.func,
    getMasterTagById: PropTypes.func,
    createMasterTag: PropTypes.func,
    deleteMasterTag: PropTypes.func,
    updateMasterTag: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, masterTags } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: masterTags.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getMasterTags } = this.props;
    await Promise.all([
      getMasterTags(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      masterTags,
      getMasterTagById,
      createMasterTag,
      deleteMasterTag,
      updateMasterTag,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperMasterTagsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        masterTags={masterTags}
        getMasterTagById={getMasterTagById}
        createMasterTag={createMasterTag}
        deleteMasterTag={deleteMasterTag}
        updateMasterTag={updateMasterTag}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MasterTagsPage);
