import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
// material-ui
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import Add from "@material-ui/icons/Add";
import Back from "@material-ui/icons/ArrowBack";
import Edit from "@material-ui/icons/Edit";
// custom
import FormAcademy from "components/FormAcademy";
import FormProgram from "components/FormProgram";
// reactor
import Page from "components/Page";
import ProgramEdit from "components/ProgramEdit";
import ProgramsList from "components/ProgramsList";
import QuickAcademySwitch from "components/QuickAcademySwitch";
// constants
import { ACADEMIES } from "constants/routes";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperAcademyPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    selectedProgram: PropTypes.object,
    width: PropTypes.string,
    loading: PropTypes.bool,
    contentLoading: PropTypes.bool,
    refreshKey: PropTypes.number,
    api: PropTypes.object,
    refresh: PropTypes.func,
    programs: PropTypes.array,
    academies: PropTypes.array,
    filteredPrograms: PropTypes.object,
    academy: PropTypes.object,
    programTypes: PropTypes.array,
    grades: PropTypes.array,
    ages: PropTypes.array,
    levels: PropTypes.array,
    institutions: PropTypes.array,
    divisions: PropTypes.array,
    instruments: PropTypes.array,
    musicalFamilies: PropTypes.array,
    programSubcategories: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      formAcademyOpen: false,
      formProgramOpen: false,
    };
  }

  render() {
    const {
      classes,
      history,
      location,
      loading,
      contentLoading,
      filteredPrograms,
      academy,
      academies,
      refresh,
      programs,
      api,
      width,
      ages,
      programTypes,
      grades,
      levels,
      instruments,
      musicalFamilies,
      divisions,
      institutions,
      selectedProgram,
      refreshKey,
      programSubcategories,
      masterTags,
    } = this.props;

    const { index, formAcademyOpen, formProgramOpen } = this.state;

    return (
      <React.Fragment>
        <Page
          helmet="Academy"
          loadingMessage={"Loading Academy"}
          loading={loading}
          header={
            <Grid
              container
              alignItems="center"
              justify="space-between"
              style={{
                padding: "10px 0px 0px 10px",
                width: selectedProgram ? "calc(100% - 400px)" : undefined,
              }}
            >
              <Grid>
                <Grid container>
                  <Grid item style={{ textAlign: "center" }}>
                    <Breadcrumbs
                      arial-label="Breadcrumb"
                      separator={"-"}
                      style={{
                        color: "white",
                      }}
                    >
                      <Tooltip title="Back to Academy List">
                        <Chip
                          label={"Back"}
                          className={classes.backChip}
                          onClick={() => history.push(ACADEMIES)}
                          variant="outlined"
                          avatar={
                            <Avatar className={classes.avatar}>
                              <Back className={classes.icon} />
                            </Avatar>
                          }
                        />
                      </Tooltip>
                    </Breadcrumbs>
                    <QuickAcademySwitch
                      academies={academies}
                      history={history}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      {academy ? (
                        <>
                          {academy.picture ? (
                            <Grid item style={{ paddingLeft: 16 }}>
                              <img
                                src={academy.picture}
                                style={{ height: 70, width: 70 }}
                              />
                            </Grid>
                          ) : (
                            []
                          )}
                          <Grid item style={{ paddingLeft: 16 }}>
                            <Typography display="block" variant="h6">
                              {academy.name}
                              <Tooltip title="Edit Academy">
                                <IconButton
                                  onClick={() =>
                                    this.setState({ formAcademyOpen: true })
                                  }
                                  style={{ padding: 5 }}
                                >
                                  <Edit style={{ fontSize: 18 }} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <Typography display="block" variant="caption">
                              {`${academy.address} ${academy.zipcode} ${academy.city} ${academy.state} ${academy.country}`}
                            </Typography>
                            <Typography
                              display="block"
                              variant="caption"
                              color="textSecondary"
                            >
                              {`${academy.contact}, ${academy.email}, ${academy.phone}`}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        []
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Chip
                  label="Program"
                  color="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => this.setState({ formProgramOpen: true })}
                  avatar={
                    <Avatar style={{ background: "none" }}>
                      <Add />
                    </Avatar>
                  }
                />
              </Grid>
            </Grid>
          }
          tabs={
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push(`?id=${academy && academy.id}&index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
            >
              {filteredPrograms &&
                Object.keys(filteredPrograms).map((fp) => (
                  <Tab
                    label={
                      <Badge
                        badgeContent={filteredPrograms[fp].length}
                        color="primary"
                      >
                        {fp}
                      </Badge>
                    }
                    key={fp}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                  />
                ))}
            </Tabs>
          }
        >
          <div
            style={{
              marginRight: selectedProgram ? 400 : undefined,
            }}
          >
            <ProgramsList
              programs={
                (filteredPrograms &&
                  filteredPrograms[Object.keys(filteredPrograms)[index]]) ||
                []
              } // TO_CHANGE
              selectedProgram={selectedProgram}
              onSelect={(sp) => {
                const urlParams = getJsonFromUrl(location);
                delete urlParams.programID;
                urlParams.programID = JSON.stringify(sp.id);
                const search = Object.keys(urlParams)
                  .map(
                    (k) =>
                      `${encodeURIComponent(k)}=${encodeURIComponent(
                        urlParams[k]
                      )}`
                  )
                  .join("&");
                history.push({
                  search,
                });
              }}
            />
          </div>
          <FormAcademy
            open={formAcademyOpen}
            history={history}
            academy={academy}
            close={() => {
              this.setState({
                formAcademyOpen: false,
              });
            }}
            createAcademy={api.createAcademy}
            deleteAcademy={api.deleteAcademy}
            updateAcademy={api.updateAcademy}
            refresh={refresh}
          />
          <FormProgram
            open={formProgramOpen}
            academy={academy}
            academies={academies}
            history={history}
            programTypes={programTypes}
            close={() => {
              this.setState({
                formProgramOpen: false,
              });
            }}
            createProgram={api.createProgram}
            refresh={refresh}
          />
        </Page>
        {selectedProgram || contentLoading ? (
          <Drawer
            open
            anchor="right"
            variant={"persistent"}
            classes={{
              paper: classes.drawer,
            }}
          >
            <ProgramEdit
              api={api}
              academies={academies}
              history={history}
              institutions={institutions}
              program={selectedProgram}
              ages={ages}
              programTypes={programTypes}
              grades={grades}
              levels={levels}
              instruments={instruments}
              musicalFamilies={musicalFamilies}
              divisions={divisions}
              refresh={refresh}
              refreshKey={refreshKey}
              contentLoading={contentLoading}
              masterTags={masterTags}
              programSubcategories={programSubcategories}
            />
          </Drawer>
        ) : (
          []
        )}
      </React.Fragment>
    );
  }
}
export default withWidth()(withStyles(styles)(WrapperAcademyPage));
