export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEMO = "/demo";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const COURSES = "/courses";
export const COURSE = "/course";
export const ASSESSMENTS = "/assessments";
export const ASSESSMENT = "/assessment";
export const SURVEYS = "/surveys";
export const ENROLLMENTS = "/enrollments";
export const MAP = "/map";
export const PLAN = "/plan";
export const AGE = "/ages";
export const ACADEMIES = "/academies";
export const ACADEMY = "/academy";
export const SETTINGS = "/settings";
export const PROGRAMS = "/programs";
export const PROSPECTS = "/prospects";
export const DOWNLOAD_REQUESTS = "/downloadRequests";
export const EVENT_TYPES = "/eventTypes";
export const EVENT_TYPE = "/eventType";
export const COACH = "/coach";
export const CLUB_MEMBERS = "/clubMembers";
export const STUDENT = "/student";
export const PROCESS = "/process";
export const CAREER = "/career";
export const CAREERS = "/careers";
export const CATEGORIES = "/categories";
export const CATEGORY = "/category";
export const CPFM = "/cpfm";
export const DEGREE_LEVELS = "/degreeLevels";
export const DEGREE_REQUIREMENTS = "/degreeRequirements";
export const INSTITUTIONS = "/institutions";
export const SUB_CATEGORIES = "/subCategories";
export const SEARCH = "/search";
export const TIME_ASSETS = "/timeAssets";
export const INQUIRIES = "/inquiries";
export const SKILL_TAGS = "/skillTags";
export const ASSESSMENT_LOGS = "/assessmentLogs";
export const COURSE_LOGS = "/courseLogs";
export const QUIZ_TAGS = "/quizTags";
export const QUIZ_CATEGORIES = "/quizCategories";
export const AUTHORS = '/authors';
export const MASTER_TAGS = '/masterTags';
