export default (theme) => ({
  container: {
    padding: 5,
  },
  paper: {
    padding: 20,
    borderLeft: `solid 3px ${theme.palette.primary.color[600]}`,
  },
  dangerPaper: {
    padding: 20,
    borderLeft: `solid 3px #f44336`,
  },
  paperLoading: {
    padding: 20,
    borderLeft: "solid 3px #212121",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155, 0.3)",
    borderRadius: 4,
  },
});
