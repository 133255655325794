// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCategoryPage from "../component/WrapperCategoryPage";

const mapStateToProps = (state) => ({
  categories: state.cpfmCategories,
  subCategories: state.subCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getCpfmCategories: (...args) => dispatch(Actions.getCpfmCategories(...args)),
  getCpfmCategoryById: (...args) =>
    dispatch(Actions.getCpfmCategoryById(...args)),
  createCpfmCategory: (...args) =>
    dispatch(Actions.createCpfmCategory(...args)),
  deleteCpfmCategory: (...args) =>
    dispatch(Actions.deleteCpfmCategory(...args)),
  updateCpfmCategory: (...args) =>
    dispatch(Actions.updateCpfmCategory(...args)),
  getSubCategories: (...args) => dispatch(Actions.getSubCategories(...args)),
  getCategorySubCategories: (...args) =>
    dispatch(Actions.getCategorySubCategories(...args)),
  createCategorySubCategory: (...args) =>
    dispatch(Actions.createCategorySubCategory(...args)),
  deleteCategorySubCategory: (...args) =>
    dispatch(Actions.deleteCategorySubCategory(...args)),
});
class CategoryPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    categories: PropTypes.array,
    getCpfmCategories: PropTypes.func,
    getCpfmCategoryById: PropTypes.func,
    createCpfmCategory: PropTypes.func,
    deleteCpfmCategory: PropTypes.func,
    updateCpfmCategory: PropTypes.func,

    getSubCategories: PropTypes.func,
    createCategorySubCategory: PropTypes.func,
    deleteCategorySubCategory: PropTypes.func,
    getSubCategories: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, categories } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: categories.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getCpfmCategories, getSubCategories } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getCpfmCategories(filters), getSubCategories()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      categories,
      getCpfmCategoryById,
      createCpfmCategory,
      deleteCpfmCategory,
      updateCpfmCategory,
      getCategories,
      subCategories,
      getCategorySubCategories,
      createCategorySubCategory,
      deleteCategorySubCategory,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCategoryPage
        subCategories={subCategories}
        getCategorySubCategories={getCategorySubCategories}
        createCategorySubCategory={createCategorySubCategory}
        deleteCategorySubCategory={deleteCategorySubCategory}
        refresh={this.refresh.bind(this)}
        loading={loading}
        getCategories={getCategories}
        categories={categories}
        getCpfmCategoryById={getCpfmCategoryById}
        createCpfmCategory={createCpfmCategory}
        deleteCpfmCategory={deleteCpfmCategory}
        updateCpfmCategory={updateCpfmCategory}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
