import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import arrayMove from "array-move";
// custom
import ModuleEdit from "components/ModuleEdit";
import { copyTextToClipboard } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// styles
import styles from "./styles";

const SortableItem = SortableElement(({ module, props }) => (
  <Grid item xs={12} key={module.id}>
    <ModuleEdit module={module} {...props} />
  </Grid>
));

const SortableList = SortableContainer(({ items, props }) => (
  <Grid container spacing={2}>
    {items &&
      items.map((module, index) => (
        <SortableItem
          key={module.id}
          index={index}
          module={module}
          props={props}
          disabled={!props.reorder || props.course.locked}
        />
      ))}
  </Grid>
));

class ModulesAndLectures extends Component {
  static propTypes = {
    classes: PropTypes.object,
    course: PropTypes.object,
    disabled: PropTypes.bool,
    getCourseModules: PropTypes.func,
    getModuleLectures: PropTypes.func,
    updateModule: PropTypes.func,
    updateLecture: PropTypes.func,
    getLectureMedia: PropTypes.func,
    deleteMedia: PropTypes.func,
    createLectureMedia: PropTypes.func,
    createLecture: PropTypes.func,
    deleteLecture: PropTypes.func,
    createModule: PropTypes.func,
    deleteModule: PropTypes.func,
    openFileDialog: PropTypes.func,
    app: PropTypes.object,
    reorder: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  state = {
    modules: [],
    reorder: false,
  };

  constructor(...args) {
    super(...args);
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  async upload() {
    const { course } = this.props;
    if (course.disabled) return;
    const { UploadCenter, NotificationCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          NotificationCenter.stack({
            title: "Image url copied in your clipboard",
            info: true,
            timestamp: new Date().getTime(),
          });
          copyTextToClipboard(r.payload);
          setTimeout(NotificationCenter.hide, 1000);
        });
      }
    }
  }

  componentWillMount() {
    this.refreshModules();
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { updateModule } = this.props;
    const moduleToUpdate = this.state.modules[oldIndex];
    updateModule(moduleToUpdate.id, { order: newIndex }).then(
      this.refreshModules.bind(this)
    );
    this.setState(({ modules }) => ({
      modules: arrayMove(modules, oldIndex, newIndex),
    }));
  };

  shouldCancelStart = (e) => {
    if (!(e.target.id === "grabModule")) {
      return true; // Return true to cancel sorting
    }
    return false;
  };

  async refreshModules() {
    const { course, getCourseModules } = this.props;
    const resp = await getCourseModules(course.id);
    if (resp.success) {
      this.setState({
        modules: resp.payload,
      });
    }
  }

  async createModule() {
    const { course, createModule } = this.props;
    await createModule({
      courseID: course.id,
      name: "My New Module",
      active: false,
      activeDate: "",
    });
    this.refreshModules();
  }

  render() {
    const {
      classes,
      getModuleLectures,
      getLectureMedia,
      updateLecture,
      updateModule,
      deleteMedia,
      createLectureMedia,
      createLecture,
      deleteLecture,
      deleteModule,
      openFileDialog,
      app,
      reorder,
      disabled,
    } = this.props;
    const { modules } = this.state;

    return (
      <div>
        <Grid
          container
          spacing={2}
          justify="space-between"
          style={{
            position: "sticky",
            top: 0,
          }}
        >
          <Grid item>
            <Typography display="block" variant="h6">
              Modules
            </Typography>
            {reorder ? (
              <Typography
                display="block"
                color="textSecondary"
                variant="caption"
              >
                Drag and Drop items using the handle.
              </Typography>
            ) : (
              <div />
            )}
          </Grid>
        </Grid>
        <SortableList
          axis="y"
          items={modules || []}
          props={{
            app,
            reorder,
            getLectureMedia,
            updateModule,
            updateLecture,
            getModuleLectures,
            deleteMedia,
            createLectureMedia,
            createLecture,
            deleteLecture,
            deleteModule,
            openFileDialog,
            disabled,
            refresh: this.refreshModules.bind(this),
          }}
          onSortEnd={this.onSortEnd}
          shouldCancelStart={this.shouldCancelStart}
          key="0"
        />
        <Fab
          color="primary"
          aria-label="Add"
          disabled={disabled}
          className={classes.floatingActionButton}
          onClick={this.createModule.bind(this)}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(ModulesAndLectures);
