import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const degreeLevels = `${REACT_APP_API_PREFIX}/${service}/degreeLevels`;

export function getDegreeLevels(filters) {
  const url = `${degreeLevels}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getDegreeLevelById(id) {
  const url = `${degreeLevels}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateDegreeLevel(id, body) {
  const url = `${degreeLevels}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteDegreeLevel(id) {
  const url = `${degreeLevels}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createDegreeLevel(body) {
  const url = `${degreeLevels}`;
  return {
    method: POST,
    url,
    body,
  };
}
