import * as Api from "api";
import {
  CREATE_PROGRAM_SUBCATEGORY,
  DELETE_PROGRAM_SUBCATEGORY,
  GET_PROGRAM_SUBCATEGORIES,
  GET_PROGRAM_SUBCATEGORY_BY_ID,
  UPDATE_PROGRAM_SUBCATEGORY,
} from "constants/programSubcategories";
import { networkAction } from "helpers/network/networkAction";

export const getProgramSubcategories = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAM_SUBCATEGORIES,
    Api.getProgramSubcategories,
    []
  );

export const getProgramSubcategoryById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAM_SUBCATEGORY_BY_ID,
    Api.getProgramSubcategoryById,
    [id]
  );

export const deleteProgramSubcategory = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_PROGRAM_SUBCATEGORY,
    Api.deleteProgramSubcategory,
    [id]
  );

export const createProgramSubcategory = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_PROGRAM_SUBCATEGORY,
    Api.createProgramSubcategory,
    [body]
  );

export const updateProgramSubcategory = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_PROGRAM_SUBCATEGORY,
    Api.updateProgramSubcategory,
    [id, body]
  );
