export default {
  academyID: "",
  ageFromID: "",
  ageToID: "",
  gradeFromID: "",
  gradeToID: "",
  dateFrom: "",
  dateTo: "",
  instruments: [],
  levels: [],
  minorities: false,
  musicalFamilies: [],
  programTypeID: "",
  website: "",
  name: "",
};
