import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/icons/CheckBox";
import Code from "@material-ui/icons/Code";
import FormatBold from "@material-ui/icons/FormatBold";
import FormatItalic from "@material-ui/icons/FormatItalic";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import FormatQuote from "@material-ui/icons/FormatQuote";
import Link from "@material-ui/icons/Link";
import TableChart from "@material-ui/icons/TableChart";
import "codemirror/mode/markdown/markdown";
import "codemirror/theme/monokai.css";
// components
import Markdown from "components/Markdown";
import PropTypes from "prop-types";
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import "./codemirror.css";
// styles
import styles from "./styles";

class MarkdownEditor extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    value: PropTypes.string,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      mode: 0,
      editor: undefined,
    };

    this.codeMirror = React.createRef();
  }

  toolbarAction(action) {
    const editor = this.codeMirror.current.getCodeMirror();

    const moveCursor = (line, val) => {
      const cursor = editor.getCursor();
      cursor.line += line;
      cursor.ch += val;
      editor.setCursor(cursor);
    };

    const selection = editor.getSelection();
    switch (action) {
      case "bold":
        editor.replaceSelection(`**${selection}**`);
        if (selection.length === 0) moveCursor(0, -2);
        break;
      case "italic":
        editor.replaceSelection(`*${selection}*`);
        if (selection.length === 0) moveCursor(0, -2);
        break;
      case "quote":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}> ${selection}`
        );
        break;
      case "code":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}\`${selection}\``
        );
        if (selection.length === 0) moveCursor(0, -1);
        break;
      case "link":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}[${selection}](url)`
        );
        break;
      case "bullet":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}* ${selection}`
        );
        break;
      case "numbered":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}1. ${selection}`
        );
        break;
      case "checkbox":
        editor.replaceSelection(
          `${selection.length ? "" : "\n"}* [ ] ${selection}`
        );
        break;
      case "table":
        editor.replaceSelection(`${
          selection.length ? "" : "\n"
        }| header | header |
| ------ | ------ |
| cell | cell |
| cell | cell |`);
        break;
      default:
        break;
    }
    editor.focus();
  }

  render() {
    const {
      classes,
      onBlur,
      onKeyPress,
      onChange,
      value,
      theme,
      disabled,
    } = this.props;

    const { mode } = this.state;

    const options = {
      lineNumbers: true,
      lineWrapping: true,
      autofocus: true,
      mode: "markdown",
      theme: theme && theme.palette.type === "dark" ? "monokai" : "default",
    };

    return (
      <div //eslint-disable-line
        className={classes.container}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      >
        <Grid
          container
          className={classes.toolbar}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Tabs
              value={mode}
              style={{ padding: 0 }}
              indicatorColor="primary"
              textColor="primary"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              onChange={(e, v) => this.setState({ mode: v })}
            >
              <Tab
                label="Preview"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                disabled={disabled}
                label="Write"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </Grid>
          {mode === 1 ? (
            <div>
              <Grid container>
                <Grid item>
                  <Tooltip title="Add bold text">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("bold")}
                    >
                      <FormatBold className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Add italic text">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("italic")}
                    >
                      <FormatItalic className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Insert a quote">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("quote")}
                    >
                      <FormatQuote className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Insert a code">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("code")}
                    >
                      <Code className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Insert a link">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("link")}
                    >
                      <Link className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Add a bullet list">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("bullet")}
                    >
                      <FormatListBulleted className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Add a numbered list">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("numbered")}
                    >
                      <FormatListNumbered className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Add a task list">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("checkbox")}
                    >
                      <Checkbox className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Add a table">
                    <IconButton
                      className={classes.toolbarButton}
                      onClick={() => this.toolbarAction("table")}
                    >
                      <TableChart className={classes.toolbarIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          ) : (
            []
          )}
        </Grid>
        {mode === 0 ? (
          <div style={{ padding: 10 }}>
            <Markdown source={value} />
          </div>
        ) : (
          <div style={{ width: "100%", position: "relative" }}>
            <CodeMirror
              value={value}
              onChange={(v) => onChange({ target: { value: v } })}
              className="CustomCodemirror"
              options={options}
              ref={this.codeMirror}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MarkdownEditor);
