import { Chip, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { REACT_APP_API } from "config";
import AsyncAutocomplete from "hooks/AsyncAutocomplete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormEnrollment extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    organizationID: PropTypes.object,
    user: PropTypes.object,

    close: PropTypes.func,
    open: PropTypes.bool,
    users: PropTypes.array,

    enrollment: PropTypes.object,

    courses: PropTypes.array,
    createEnrollment: PropTypes.func,
    updateEnrollment: PropTypes.func,
    deleteEnrollment: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      courseID: "",
      users: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.enrollment);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(enrollment) {
    this.setState({
      loading: false,
      courseID: enrollment ? enrollment.courseID : "",
      userUUID: enrollment ? enrollment.userUUID : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this enrollment, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteEnrollment, close, refresh, enrollment } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteEnrollment(enrollment.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Enrollment has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateEnrollment,
      createEnrollment,
      enrollment,
      refresh,
      close,
    } = this.props;

    const { courseID, users } = this.state;

    const { NotificationCenter } = this.context;

    const usersUUID = [];
    for (const key in users) {
      if (users.hasOwnProperty(key)) {
        const user = users[key];
        if (user.id === 0) {
          usersUUID.push(user.tempUUID);
        } else {
          usersUUID.push(user.uuid);
        }
      }
    }

    let resp;
    this.setState({ loading: true });
    resp = await createEnrollment({
      courseID: Number(courseID),
      usersUUID,
    });

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: enrollment
          ? "Enrollment has been updated."
          : "Enrollment has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      enrollment,
      courses,
      close,
      organizationID,
      user,
    } = this.props;

    const { courseID, userUUID, loading, users } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        classes={{
          paperScrollPaper: classes.paper,
        }}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ minWidth: 400 }}>
            <DialogTitle id="responsive-dialog-title">
              {enrollment ? `${enrollment.name}` : "New Enrollment"}
            </DialogTitle>
            <DialogContent className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="group-simple">
                      Select the course
                    </InputLabel>
                    <Select
                      variant="outlined"
                      fullWidth
                      value={courseID}
                      onChange={this.handleChange("courseID")}
                    >
                      {courses.map((c) => (
                        <MenuItem key={`c_${c.id}`} value={c.id}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {users &&
                  users.map((u, i) => (
                    <Grid item>
                      <Chip
                        label={`${u.email}`}
                        onDelete={() => {
                          const _u = [...this.state.users];
                          _u.splice(Number(i), 1);
                          this.setState({
                            users: _u,
                          });
                        }}
                      />
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <AsyncAutocomplete
                    label={"Select the User"}
                    searchEndpoint={`${REACT_APP_API}/account/organizations/${organizationID}/users/search`}
                    bodyTransform={(searchText) => ({ text: searchText })}
                    selectedLabelText={(o) => {
                      if (o) {
                        return `${o.firstName} ${o.lastName}`;
                      }

                      return "";
                    }}
                    onSelect={(o) => {
                      if (o) {
                        const _u = [...this.state.users];
                        _u.push(o);
                        this.setState({
                          users: _u,
                        });
                      }
                    }}
                  />
                  <Typography variant="caption">
                    Show only students from{" "}
                    <b>
                      {
                        user.organizations.find((o) => o.id === organizationID)
                          .name
                      }
                    </b>
                  </Typography>
                  {/* <AutoComplete
                    placeHolder={"Choose the User"}
                    onSelect={(m) => {
                      this.setState({
                        userUID: m.value ? m.value.uuid : "",
                      });
                    }}
                    suggestions={
                      users &&
                      users.map((u) => ({
                        value: u,
                        label: `${u.firstName} ${u.lastName} - ${u.email}`,
                      }))
                    }
                  /> */}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {enrollment && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button
                onClick={close}
                disabled={users.length === 0 || !this.state.courseID}
              >
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {enrollment ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormEnrollment));
