// helpers
// actions
import * as Actions from "actions";
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperAssessmentLogsPage from "../component/WrapperAssessmentLogsPage";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getHarmonyChangelogs: (...args) =>
    dispatch(Actions.getHarmonyChangelogs(...args)),
});
class AssessmentLogsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    harmonyChangelogs: PropTypes.array,
    getHarmonyChangelogs: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      harmonyChangelogs: [],
      loading: false,
    };
    this.refresh();
  }

  async refresh() {
    const { getHarmonyChangelogs } = this.props;
    const { urlParams } = this.state;
    const r = await getHarmonyChangelogs(urlParams.filters);
    this.setState({
      harmonyChangelogs: r.payload,
    });
  }

  render() {
    const { location, history } = this.props;

    const { urlParams, loading, harmonyChangelogs } = this.state;

    return (
      <WrapperAssessmentLogsPage
        history={history}
        location={location}
        harmonyChangelogs={harmonyChangelogs}
        urlParams={urlParams}
        refresh={this.refresh.bind(this)}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentLogsPage);
