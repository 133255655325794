import { fade } from "@material-ui/core";

export function hexToRgbA(hex, opacity) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = "0x" + c.join(""); // eslint-disable-line
    return `rgba(${(c >> 16) & 255}, ${(c >> 8) & 255}, ${
      c & 255
    }, ${opacity})`;
  }

  throw new Error("Bad Hex");
}

export function getGradient(categories, opacity = 1) {
  if (categories?.length === 1) return categories[0].color;
  var res = `linear-gradient(to left, `;

  for (const key in categories) {
    if (Object.hasOwnProperty.call(categories, key)) {
      const category = categories[key];
      res += `${fade(category.color, opacity)} ${Math.floor(
        (Number(key) * 100) / categories.length
      )}%${Number(key) !== categories.length - 1 ? ", " : ""}`;
    }
  }

  res += `)`;

  return res;
}
