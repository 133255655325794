import { serviceRoles } from "config/access";
import { CLUB_MEMBERS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import ClubMembersPage from "./container/ClubMembersPage";

export default function getRoute(userAuth) {
  const { account, papyrus } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [papyrus.name]: [account.roles.admin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: CLUB_MEMBERS,
    withSidebar: true,
    withAppBar: true,
    component: ClubMembersPage,
  };
}
