import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";

// styles
import styles from "./styles";

class EventTypeEdit extends Component {
  static propTypes = {
    classes: PropTypes.object,
    eventType: PropTypes.object,
    updateEventType: PropTypes.func,
    refresh: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { eventType } = this.props;
    this.state = {
      ...eventType,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { refresh, eventType, updateEventType } = this.props;

    if (eventType[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateEventType(eventType.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const { classes } = this.props;
    const { loading, name, duration, timeGap } = this.state;

    return (
      <Paper className={loading ? classes.paperLoading : classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  fullWidth
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("name");
                  }}
                  onBlur={() => this.save("name")}
                  disabled={loading}
                  className={classes.inputBase}
                  defaultValue={name}
                  onChange={this.handleChange("name")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Duration:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  In minutes
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  fullWidth
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("duration", true);
                  }}
                  onBlur={() => this.save("duration", true)}
                  disabled={loading}
                  className={classes.inputBase}
                  defaultValue={duration}
                  onChange={this.handleChange("duration")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Time Gap:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Amount of time between each selectable slots. Default is 15 mn
                  (ex: 9:00 AM, 9:15 AM, 9:30 AM ... etc)
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  fullWidth
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("timeGap", true);
                  }}
                  onBlur={() => this.save("timeGap", true)}
                  disabled={loading}
                  className={classes.inputBase}
                  defaultValue={timeGap}
                  onChange={this.handleChange("timeGap")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(EventTypeEdit);
