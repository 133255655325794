import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import { MASTER_TAGS } from 'constants/routes';
import MasterTagsPage from './container/MasterTagsPage';

export default function getSettingsRoute(requireAuthFunctions) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
  };
  return {
    path: MASTER_TAGS,
    onEnter: () => checkAccess(requireAuth, requireAuthFunctions),
    withSidebar: true,
    withAppBar: true,
    component: MasterTagsPage,
  };
}

