// material-ui
import { withStyles } from "@material-ui/core/styles";
import arrayMove from "array-move";
// components
import Availability from "components/Availability";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// styles
import styles from "./styles";




const SortableItem = SortableElement(({ availability, props }) => (
  <Availability key={availability.id} availability={availability} {...props} />
));

const SortableList = SortableContainer(({ items, props }) => (
  <div>
    {items &&
      items.map((a, index) => (
        <SortableItem key={a.id} availability={a} index={index} props={props} />
      ))}
  </div>
));

class Availabilities extends Component {
  static propTypes = {
    classes: PropTypes.object,
    availabilities: PropTypes.object,
    coach: PropTypes.object,
    updateAvailability: PropTypes.func,
    deleteAvailability: PropTypes.func,
    createAvailability: PropTypes.func,
    refresh: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { availabilities } = this.props;

    this.state = {
      availabilities,
    };
  }

  shouldCancelStart = (e) => {
    if (
      !(
        e.target.id === "grab" ||
        (e.target.nearestViewportElement &&
          e.target.nearestViewportElement.id === "grab")
      )
    ) {
      return true; // Return true to cancel sorting
    }
  };

  onSortEnd = async ({ oldIndex, newIndex }) => {
    // const { updateModule } = this.props;
    const availabilityToUpdate = this.state.availabilities[oldIndex];
    // updateModule(moduleToUpdate.id, { order: newIndex }).then(this.refreshModules.bind(this));
    this.setState(({ availabilities }) => ({
      availabilities: arrayMove(availabilities, oldIndex, newIndex),
    }));
  };

  render() {
    const {
      classes,
      updateAvailability,
      deleteAvailability,
      createAvailability,
      refresh,
      coach,
    } = this.props;
    const { availabilities } = this.state;

    return (
      <SortableList
        axis="y"
        shouldCancelStart={this.shouldCancelStart}
        items={availabilities || []}
        props={{
          classes,
          coach,
          updateAvailability,
          deleteAvailability,
          createAvailability,
          refresh,
        }}
        onSortEnd={this.onSortEnd}
        key="0"
      />
    );
  }
}

export default withStyles(styles)(Availabilities);
