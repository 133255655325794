import { GET, PUT, DELETE, POST, DATA } from "constants/methods";

const service = "aptitude";
const lectures = `/${service}/lectures`;

export function getLectures() {
  const url = `${lectures}`;
  return {
    method: GET,
    url,
  };
}

export function createLectureMedia(lectureID, file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${lectures}/${lectureID}/media`;

  return {
    method: DATA,
    url,
    body: data,
  };
}

export function getLectureById(id) {
  const url = `${lectures}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getLectureMedia(id) {
  const url = `${lectures}/${id}/media`;
  return {
    method: GET,
    url,
  };
}

export function updateLecture(id, body) {
  const url = `${lectures}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteLecture(id) {
  const url = `${lectures}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createLecture(body) {
  const url = `${lectures}`;
  return {
    method: POST,
    url,
    body,
  };
}
