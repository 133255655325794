import * as Api from "api";
import {
  CREATE_QUIZ_CATEGORY,
  DELETE_QUIZ_CATEGORY,
  GET_QUIZ_CATEGORIES,
  GET_QUIZ_CATEGORY_BY_ID,
  UPDATE_QUIZ_CATEGORY,
} from "constants/quizCategories";
import { networkAction } from "helpers/network/networkAction";

export const getQuizCategories = () => async (dispatch) =>
  networkAction(dispatch, GET_QUIZ_CATEGORIES, Api.getQuizCategories, []);

export const getQuizCategoryById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_QUIZ_CATEGORY_BY_ID, Api.getQuizCategoryById, [
    id,
  ]);

export const deleteQuizCategory = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_QUIZ_CATEGORY, Api.deleteQuizCategory, [id]);

export const createQuizCategory = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_QUIZ_CATEGORY, Api.createQuizCategory, [body]);

export const updateQuizCategory = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_QUIZ_CATEGORY, Api.updateQuizCategory, [
    id,
    body,
  ]);
