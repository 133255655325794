import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const quizTags = `${REACT_APP_API_PREFIX}/${service}/quizTags`;

export function getQuizTags(filters) {
  const url = `${quizTags}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getQuizTagById(id) {
  const url = `${quizTags}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateQuizTag(id, body) {
  const url = `${quizTags}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteQuizTag(id) {
  const url = `${quizTags}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createQuizTag(body) {
  const url = `${quizTags}`;
  return {
    method: POST,
    url,
    body,
  };
}
