import { serviceRoles } from "config/access";
import { INSTITUTIONS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import InstitutionsPage from "./container/InstitutionsPage";

export default function getRoute(userAuth) {
  const { account, cpfm } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [cpfm.name]: [cpfm.roles.admin],
  };
  return {
    path: INSTITUTIONS,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: InstitutionsPage,
  };
}
