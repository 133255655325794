import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperDegreeRequirementsPage from '../component/WrapperDegreeRequirementsPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  degreeRequirements: state.degreeRequirements,
});

const mapDispatchToProps = dispatch => ({
  getDegreeRequirements: () => dispatch(Actions.getDegreeRequirements()),
  getDegreeRequirementById: (...args) => dispatch(Actions.getDegreeRequirementById(...args)),
  createDegreeRequirement: (...args) => dispatch(Actions.createDegreeRequirement(...args)),
  deleteDegreeRequirement: (...args) => dispatch(Actions.deleteDegreeRequirement(...args)),
  updateDegreeRequirement: (...args) => dispatch(Actions.updateDegreeRequirement(...args)),
});
class DegreeRequirementsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    degreeRequirements: PropTypes.array,
    getDegreeRequirements: PropTypes.func,
    getDegreeRequirementById: PropTypes.func,
    createDegreeRequirement: PropTypes.func,
    deleteDegreeRequirement: PropTypes.func,
    updateDegreeRequirement: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, degreeRequirements } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: degreeRequirements.length === 0,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.refresh();
  }

  async refresh() {
    const { getDegreeRequirements } = this.props;
    await Promise.all([
      getDegreeRequirements(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      degreeRequirements,
      getDegreeRequirementById,
      createDegreeRequirement,
      deleteDegreeRequirement,
      updateDegreeRequirement,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperDegreeRequirementsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        degreeRequirements={degreeRequirements}
        getDegreeRequirementById={getDegreeRequirementById}
        createDegreeRequirement={createDegreeRequirement}
        deleteDegreeRequirement={deleteDegreeRequirement}
        updateDegreeRequirement={updateDegreeRequirement}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DegreeRequirementsPage);
