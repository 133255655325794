// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class ProgramsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    programs: PropTypes.array,
    getPrograms: PropTypes.func,
    onSelect: PropTypes.func,
    onDoubleSelect: PropTypes.func,
    dataLoading: PropTypes.bool,
    selectedProgram: PropTypes.object,
    history: PropTypes.object,
    advancedSearch: PropTypes.bool,
  };

  static defaultProps = {
    advancedSearch: true,
  };

  onSelect(selectedProgram, index) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedProgram, index);
    }
  }

  onDoubleSelect(selectedProgram) {
    const { onDoubleSelect } = this.props;
    if (onDoubleSelect) {
      onDoubleSelect(selectedProgram);
    }
  }

  render() {
    const { programs, selectedProgram, history, getPrograms, advancedSearch } =
      this.props;

    const table = (
      <Table
        data={programs}
        rowStyle={(d) => ({
          background:
            selectedProgram && selectedProgram.id === d.id
              ? "rgba(155,155,155,0.1)"
              : undefined,
        })}
        meta={[
          {
            path: "name",
            title: "Name",
          },
          {
            path: "academyName",
            title: "Academy",
          },
          {
            path: "updatedAt",
            title: "Updated At",
            transform: (r) => (r !== "0001-01-01T00:00:00Z" ? r : ""),
          },
        ]}
        onRowSelect={this.onSelect.bind(this)}
        onRowDoubleSelect={this.onDoubleSelect.bind(this)}
      />
    );

    if (!advancedSearch) {
      return table;
    }

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getPrograms}
        keyword="programs"
        metaDataEndpoint="/cpfm/programs/searchMetadata"
      >
        {table}
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(ProgramsList);
