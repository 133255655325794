import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const programInstitutions = `${REACT_APP_API_PREFIX}/${service}/programInstitutions`;

export function getProgramInstitutions(filters) {
  const url = `${programInstitutions}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getProgramInstitutionById(id) {
  const url = `${programInstitutions}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateProgramInstitution(id, body) {
  const url = `${programInstitutions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProgramInstitution(id) {
  const url = `${programInstitutions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProgramInstitution(body) {
  const url = `${programInstitutions}`;
  return {
    method: POST,
    url,
    body,
  };
}
