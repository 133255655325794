import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperCoachPage from "../component/WrapperCoachPage";

const mapStateToProps = (state) => ({
  timezones: state.timezones,
  coaches: state.coaches,
});

const mapDispatchToProps = (dispatch) => ({
  getCoachById: (...args) => dispatch(Actions.getCoachById(...args)),
  updateCoach: (...args) => dispatch(Actions.updateCoach(...args)),
  deleteCoach: (...args) => dispatch(Actions.deleteCoach(...args)),
  getTimezones: (...args) => dispatch(Actions.getTimezones(...args)),
  updateAvailability: (...args) =>
    dispatch(Actions.updateAvailability(...args)),
  getDailyAvailabilities: (...args) =>
    dispatch(Actions.getDailyAvailabilities(...args)),
  deleteAvailability: (...args) =>
    dispatch(Actions.deleteAvailability(...args)),
  createAvailability: (...args) =>
    dispatch(Actions.createAvailability(...args)),
  getAvailabilities: (...args) => dispatch(Actions.getAvailabilities(...args)),
  getCoaches: (...args) => dispatch(Actions.getCoaches(...args)),
});
class CoachPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    updateCoach: PropTypes.func,
    getCoachById: PropTypes.func,
    deleteCoach: PropTypes.func,
    getTimezones: PropTypes.func,
    timezones: PropTypes.array,

    getCoaches: PropTypes.func,
    getAvailabilities: PropTypes.func,
    createAvailability: PropTypes.func,
    deleteAvailability: PropTypes.func,
    updateAvailability: PropTypes.func,
    getDailyAvailabilities: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getTimezones, getCoaches } = this.props;
    getTimezones();
    getCoaches();
    const urlParams = getJsonFromUrl(location);

    this.state = {
      urlParams,
      loading: true,
      coachID: urlParams.id,
    };

    this.refresh();
    this.refreshAvailabilities();
  }

  async refresh() {
    const { coachID } = this.state;
    const { getCoachById } = this.props;
    const resp = await getCoachById(coachID);

    this.setState({
      coach: resp.payload,
      loading: false,
    });
  }

  async refreshAvailabilities() {
    const { getAvailabilities } = this.props;
    const resp = await getAvailabilities();

    this.setState({
      availabilities: resp.payload,
      refreshKey: new Date().getTime(),
    });
  }

  render() {
    const {
      location,
      history,
      updateCoach,
      deleteCoach,
      timezones,
      createAvailability,
      deleteAvailability,
      updateAvailability,
      getDailyAvailabilities,
      coaches,
    } = this.props;

    const {
      urlParams,
      coach,
      loading,
      refreshKey,
      availabilities,
    } = this.state;

    return (
      <WrapperCoachPage
        history={history}
        location={location}
        coach={coach}
        updateCoach={updateCoach}
        deleteCoach={deleteCoach}
        urlParams={urlParams}
        refresh={this.refresh.bind(this)}
        refreshAvailabilities={this.refreshAvailabilities.bind(this)}
        availabilities={availabilities}
        refreshKey={refreshKey}
        timezones={timezones}
        loading={loading}
        coaches={coaches}
        createAvailability={createAvailability}
        deleteAvailability={deleteAvailability}
        updateAvailability={updateAvailability}
        getDailyAvailabilities={getDailyAvailabilities}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CoachPage);
