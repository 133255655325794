import * as Api from "api";
import {
  CREATE_CATEGORY_SUB_CATEGORY,
  DELETE_CATEGORY_SUB_CATEGORY,
  GET_CATEGORY_SUB_CATEGORIES,
  GET_CATEGORY_SUB_CATEGORY_BY_ID,
  UPDATE_CATEGORY_SUB_CATEGORY,
} from "constants/categorySubCategories";
import { networkAction } from "helpers/network/networkAction";

export const getCategorySubCategories = (filters) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CATEGORY_SUB_CATEGORIES,
    Api.getCategorySubCategories,
    [filters]
  );

export const getCategorySubCategoryById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CATEGORY_SUB_CATEGORY_BY_ID,
    Api.getCategorySubCategoryById,
    [id]
  );

export const deleteCategorySubCategory = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_CATEGORY_SUB_CATEGORY,
    Api.deleteCategorySubCategory,
    [id]
  );

export const createCategorySubCategory = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_CATEGORY_SUB_CATEGORY,
    Api.createCategorySubCategory,
    [body]
  );

export const updateCategorySubCategory = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_CATEGORY_SUB_CATEGORY,
    Api.updateCategorySubCategory,
    [id, body]
  );
