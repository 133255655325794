import { networkAction } from "helpers/network/networkAction";

import {
  GET_DOWNLOAD_REQUESTS,
  GET_DOWNLOAD_REQUEST_BY_ID,
  DELETE_DOWNLOAD_REQUEST,
  UPDATE_DOWNLOAD_REQUEST,
  CREATE_DOWNLOAD_REQUEST,
} from "constants/downloadRequests";

import * as Api from "api";

export const getDownloadRequests = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_DOWNLOAD_REQUESTS, Api.getDownloadRequests, [
    filters,
  ]);

export const getDownloadRequestById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_DOWNLOAD_REQUEST_BY_ID,
    Api.getDownloadRequestById,
    [id]
  );

export const deleteDownloadRequest = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_DOWNLOAD_REQUEST, Api.deleteDownloadRequest, [
    id,
  ]);

export const createDownloadRequest = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_DOWNLOAD_REQUEST, Api.createDownloadRequest, [
    body,
  ]);

export const updateDownloadRequest = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_DOWNLOAD_REQUEST, Api.updateDownloadRequest, [
    id,
    body,
  ]);
