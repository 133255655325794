import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import Bookmark from "@material-ui/icons/BookmarkTwoTone";
import Delete from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grain from "@material-ui/icons/Grain";
import arrayMove from "array-move";
// custom
import LectureEdit from "components/LectureEdit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// styles
import styles from "./styles";

const SortableItem = SortableElement(({ lecture, props }) => (
  <Grid item key={lecture.id} xs={12}>
    <LectureEdit lecture={lecture} {...props} />
  </Grid>
));

const SortableList = SortableContainer(({ items, props }) => (
  <Grid container spacing={2}>
    {items &&
      items.map((lecture, index) => (
        <SortableItem
          key={module.id}
          index={index}
          lecture={lecture}
          props={props}
          disabled={!props.reorder}
        />
      ))}
  </Grid>
));

class ModulesAndLectures extends Component {
  static propTypes = {
    classes: PropTypes.object,
    module: PropTypes.object,
    disabled: PropTypes.bool,
    refresh: PropTypes.func,
    getModuleLectures: PropTypes.func,
    getLectureMedia: PropTypes.func,
    updateModule: PropTypes.func,
    updateLecture: PropTypes.func,
    deleteMedia: PropTypes.func,
    createLectureMedia: PropTypes.func,
    createLecture: PropTypes.func,
    deleteLecture: PropTypes.func,
    deleteModule: PropTypes.func,
    app: PropTypes.object,
    reorder: PropTypes.bool,
    openFileDialog: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { module } = this.props;
    this.state = {
      ...module,
      loading: false,
      lectures: [],
      expanded: false,
    };
    this.getModuleLectures();
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { updateLecture, refresh } = this.props;
    const lectureToUpdate = this.state.lectures[oldIndex];
    updateLecture(lectureToUpdate.id, { order: newIndex }).then(refresh);
    this.setState(({ lectures }) => ({
      lectures: arrayMove(lectures, oldIndex, newIndex),
    }));
  };

  async getModuleLectures() {
    const { getModuleLectures, module } = this.props;
    console.log("--------- getModuleLectures");
    const resp = await getModuleLectures(module.id);
    if (resp.success) {
      this.setState({ lectures: resp.payload });
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updateModule, module, refresh } = this.props;

    if (module[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateModule(module.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async newLecture() {
    const { createLecture, module } = this.props;
    await createLecture({
      moduleID: module.id,
      name: "New Lecture",
    });
    this.getModuleLectures();
  }

  async deleteModule() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete the module all related lectures will also be erased. You won't be able to recover them.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteModule, module, refresh } = this.props;
    await deleteModule(module.id);
    refresh();
  }

  shouldCancelStart = (e) => {
    console.log(e.target.id);
    if (!(e.target.id === "grabLecture")) {
      return true; // Return true to cancel sorting
    }
    return false;
  };

  render() {
    const {
      classes,
      updateLecture,
      getLectureMedia,
      deleteMedia,
      deleteLecture,
      app,
      reorder,
      openFileDialog,
      disabled,
    } = this.props;

    const { lectures, loading, name, active, expanded, order } = this.state;

    return (
      <ExpansionPanel
        onChange={(e, b) => this.setState({ expanded: b })}
        className={loading ? classes.paperLoading : classes.paper}
      >
        <ExpansionPanelSummary
          className={classes.test}
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container spacing={2} alignItems="center">
            {reorder ? (
              <Grid item id="grabModule">
                <Grain className={classes.grip} id="grabModule" />
              </Grid>
            ) : (
              []
            )}
            <Grid item>
              <Badge
                classes={{
                  badge: !active ? classes.notActive : undefined,
                }}
                badgeContent={lectures.length}
                color="primary"
              >
                <Bookmark className={classes.darModeFix} />
              </Badge>
            </Grid>
            <Grid
              item
              style={{
                width: reorder ? "calc(100% - 102px)" : "calc(100% - 56px)",
              }}
            >
              <InputBase
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("name");
                }}
                disabled={loading || disabled}
                style={{ width: "100%" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onBlur={() => this.save("name")}
                className={classes.inputBase}
                value={name}
                onChange={this.handleChange("name")}
              />
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        {expanded ? (
          <ExpansionPanelDetails className={classes.expanded}>
            <div style={{ width: "100%" }}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    display="block"
                    variant="h6"
                    style={{ color: "white", paddingBottom: 10 }}
                  >
                    Lectures:
                  </Typography>
                </Grid>
                <Grid item style={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={active}
                        disabled={disabled}
                        onChange={() => {
                          this.setState({ active: !active }, () =>
                            this.save("active")
                          );
                        }}
                        value="active"
                        style={{ color: "white" }}
                      />
                    }
                    label={
                      <Typography display="block" style={{ color: "white" }}>
                        Active
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <SortableList
                axis="y"
                items={lectures || []}
                props={{
                  app,
                  deleteMedia,
                  deleteLecture,
                  updateLecture,
                  getLectureMedia,
                  reorder,
                  disabled,
                  openFileDialog,
                  refresh: this.getModuleLectures.bind(this),
                }}
                onSortEnd={this.onSortEnd}
                shouldCancelStart={this.shouldCancelStart.bind(this)}
                key="0"
              />
              <Grid
                container
                justify="space-between"
                style={{ marginTop: 32 }}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography
                    display="block"
                    variant="body2"
                    style={{ color: "white" }}
                  >
                    Module Order:
                  </Typography>
                  <InputBase
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("order", true);
                    }}
                    disabled={loading || disabled}
                    onBlur={() => this.save("order", true)}
                    className={classes.inputBase}
                    value={order}
                    onChange={this.handleChange("order")}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    style={{
                      color: "white",
                      borderColor: "white",
                    }}
                    disabled={disabled}
                    variant="outlined"
                    label="New Lecture"
                    avatar={
                      <Avatar style={{ background: "white" }}>
                        <Add color="primary" />
                      </Avatar>
                    }
                    onClick={this.newLecture.bind(this)}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    style={{
                      color: "white",
                      borderColor: "white",
                    }}
                    disabled={disabled}
                    variant="outlined"
                    label="Delete Module"
                    avatar={
                      <Avatar style={{ background: "white" }}>
                        <Delete color="primary" />
                      </Avatar>
                    }
                    onClick={this.deleteModule.bind(this)}
                  />
                </Grid>
              </Grid>
            </div>
          </ExpansionPanelDetails>
        ) : (
          <div />
        )}
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(ModulesAndLectures);
