import red from "@material-ui/core/colors/red";

export default (theme) => ({
  container: {
    padding: 5,
  },
  delete: {
    color: red[500],
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  userImage: {
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  textField: {
    width: "100%",
  },
  progressContainer: {
    textAlign: "center",
    padding: 50,
  },
  dialogActions: {
    justifyContent: "space-between",
  },
  inputBase: {
    background: theme.palette.type === "dark" ? "#616161" : "white",
    borderRadius: 4,
    fontFamily: "monospace",
    color: theme.palette.type === "dark" ? "white" : "black",
    border: `solid 1px ${
      theme.palette.type === "dark" ? "#757575" : "#CCCCCC"
    }`,
    padding: "4px 8px",
  },
});
