import * as Api from "api";
import {
  CREATE_AGE,
  DELETE_AGE,
  GET_AGES,
  GET_AGE_BY_ID,
  GET_PROGRAMS_BY_AGE,
  UPDATE_AGE,
} from "constants/ages";
import { networkAction } from "helpers/network/networkAction";

export const getAges = () => async (dispatch) =>
  networkAction(dispatch, GET_AGES, Api.getAges, []);

export const getProgramsByAge = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAMS_BY_AGE, Api.getProgramsByAge, [id]);

export const getAgeById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_AGE_BY_ID, Api.getAgeById, [id]);

export const deleteAge = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_AGE, Api.deleteAge, [id]);

export const createAge = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_AGE, Api.createAge, [body]);

export const updateAge = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_AGE, Api.updateAge, [id, body]);
