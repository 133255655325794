import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteForEver from "@material-ui/icons/DeleteTwoTone";
import Duo from "@material-ui/icons/Duo";
import Email from "@material-ui/icons/EmailTwoTone";
import EventBusyIcon from "@material-ui/icons/EventBusyTwoTone";
import EventIcon from "@material-ui/icons/EventTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Person from "@material-ui/icons/PersonTwoTone";
// custom
import TimezoneSelect from "components/TimezoneSelect";
// routes
import { STUDENT } from "constants/routes";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { Component } from "react";
import fileDownload from "react-file-download";
// styles
import styles from "./styles";

class Event extends Component {
  static propTypes = {
    coaches: PropTypes.object,
    classes: PropTypes.object,
    event: PropTypes.object,
    updateEvent: PropTypes.func,
    sendStudentEmail: PropTypes.func,
    history: PropTypes.func,
    timezones: PropTypes.array,
    deleteEvent: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { event } = this.props;
    this.state = {
      ...event,
      loading: false,
    };
  }

  handleDateChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;
    const { tzName } = this.state;

    this.setState({
      [name]: moment.tz(value, tzName).utc().format(),
    });
  };

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  addToCalendar() {
    const { event } = this.props;
    const ics = require("ics");

    const e = {
      start: moment(event.dateFromUTC).format("YYYY-M-D-H-m").split("-"),
      end: moment(event.dateToUTC).format("YYYY-M-D-H-m").split("-"),
      title: `Private Session with ${event.student.fullName} (${event.student.email})`,
      description: ``,
      url: window.location.href,
      categories: ["Winning on Stage"],
    };

    ics.createEvent(e, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }
      fileDownload(
        value,
        `Private Session with ${event.student.fullName} (${event.student.email}).ics`
      );
    });
  }

  async save(name, numeric) {
    const { refresh, event, updateEvent } = this.props;

    if (event[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateEvent(event.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async confirmDelete() {
    const { deleteEvent, event, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteEvent(event.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Event has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      event,
      history,
      sendStudentEmail,
      timezones,
      coaches,
      selection,
    } = this.props;
    const { NotificationCenter } = this.context;
    const {
      tzName,
      loading,
      notes,
      memo,
      dateFromUTC,
      dateToUTC,
      billableDuration,
    } = this.state;
    const dateFrom = moment(event.dateFromUTC);
    const dateTo = moment(event.dateToUTC);

    const coach = coaches?.find((c) => c.id === event.coachID);

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container justify="space-between">
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Avatar src={coach?.avatar} />
                </Grid>
                {event.teacherID ? (
                  <Grid item>
                    <Avatar src={event.teacher.avatar} />
                  </Grid>
                ) : (
                  []
                )}
                <Grid item>
                  <div className={classes.time}>
                    {`${dateFrom.format("hh:mm a")} - ${dateTo.format(
                      "hh:mm a"
                    )}`}
                  </div>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {dateFrom.fromNow()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography display="block" variant="body2">
                    <b>{event.student && event.student.fullName}</b>
                  </Typography>
                  <Typography display="block" variant="caption">
                    {event.eventType && event.eventType.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography display="block" variant="body2">
                    Coach: <b>{coach && coach.fullName}</b>
                  </Typography>
                </Grid>
                {event.teacherID ? (
                  <Grid item>
                    <Typography display="block" variant="body2">
                      Teacher: <b>{event.teacher && event.teacher.fullName}</b>
                    </Typography>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {billableDuration ? `Lasted ${billableDuration} minutes` : ""}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item style={{ paddingTop: 20 }}>
              <Button
                variant="outlined"
                style={{
                  color: "#4caf50",
                  borderColor: "#4caf50",
                }}
              >
                <Duo /> Join Meeting
              </Button>
              {window.location.pathname !== STUDENT ? (
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  style={{ paddingTop: 8 }}
                  onClick={() => {
                    history.push(
                      `${STUDENT}?uuid=${
                        event.student.userUUID
                      }&back=${encodeURIComponent(
                        `${window.location.pathname}${window.location.search}`
                      )}`
                    );
                  }}
                >
                  <Grid item>
                    <Person style={{ fontSize: 16 }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      display="block"
                      style={{ cursor: "pointer" }}
                      color="textSecondary"
                    >
                      View Student
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                []
              )}
              <Grid
                container
                alignItems="center"
                spacing={1}
                style={{ paddingTop: 8 }}
                onClick={this.addToCalendar.bind(this)}
              >
                <Grid item>
                  <EventIcon style={{ fontSize: 16 }} />
                </Grid>
                <Grid item>
                  <Typography
                    display="block"
                    style={{ cursor: "pointer" }}
                    color="textSecondary"
                  >
                    Add to Calendar
                  </Typography>
                </Grid>
              </Grid>
              {selection === "upcoming" ? (
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  onClick={() =>
                    sendStudentEmail(event.id).then((resp) => {
                      if (resp.success) {
                        NotificationCenter.sweetAlert({
                          title: "Email sent to student",
                          success: true,
                          timestamp: new Date().getTime(),
                        });
                        setTimeout(NotificationCenter.hide, 1500);
                      } else {
                        NotificationCenter.sweetAlert({
                          title: "Something went wrong...",
                          error: true,
                          timestamp: new Date().getTime(),
                        });
                        setTimeout(NotificationCenter.hide, 1500);
                      }
                    })
                  }
                  style={{ paddingTop: 8 }}
                >
                  <Grid item>
                    <Email style={{ fontSize: 16 }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      display="block"
                      style={{ cursor: "pointer" }}
                      color="textSecondary"
                    >
                      Send Event Email
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                []
              )}
              {selection === "upcoming" ? (
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item>
                    <EventBusyIcon style={{ fontSize: 16 }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      display="block"
                      style={{ cursor: "pointer" }}
                      color="textSecondary"
                    >
                      Cancel
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                []
              )}

              <Grid
                container
                alignItems="center"
                spacing={1}
                style={{ paddingTop: 8 }}
                onClick={() => {
                  NotificationCenter.sweetAlert(
                    {
                      title: "Are you sure ?",
                      subtitle:
                        "You are about to delete this event. Student won't be notified of your action.",
                      timestamp: new Date().getTime(),
                      error: true,
                    },
                    {
                      cancel: {
                        label: "Cancel",
                        musicalFamily: "default",
                      },
                      confirm: {
                        label: "I am sure",
                        musicalFamily: "error",
                        callback: this.confirmDelete.bind(this),
                      },
                    }
                  );
                }}
              >
                <Grid item>
                  <DeleteForEver style={{ fontSize: 16 }} />
                </Grid>
                <Grid item>
                  <Typography
                    display="block"
                    style={{ cursor: "pointer" }}
                    color="textSecondary"
                  >
                    Delete
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 20, flexGrow: 1 }}>
              <Typography display="block" variant="caption">
                Billable duration (in minutes)
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                How long did the session last ? The value will decrement the
                student's credit.
              </Typography>
              <InputBase
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("billableDuration", true);
                }}
                onBlur={() => this.save("billableDuration", true)}
                disabled={loading}
                className={classes.inputBase}
                defaultValue={billableDuration}
                onChange={this.handleChange("billableDuration")}
              />
              <Typography display="block" variant="caption">
                From
              </Typography>
              <InputBase
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("dateFromUTC");
                }}
                onBlur={() => this.save("dateFromUTC")}
                type="datetime-local"
                disabled={loading}
                className={classes.inputBase}
                value={moment
                  .tz(dateFromUTC, tzName)
                  .format("YYYY-MM-DDTHH:mm")}
                onChange={this.handleDateChange("dateFromUTC")}
              />
              <Typography display="block" variant="caption">
                To
              </Typography>
              <InputBase
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("dateToUTC");
                }}
                type="datetime-local"
                onBlur={() => this.save("dateToUTC")}
                disabled={loading}
                className={classes.inputBase}
                value={moment.tz(dateToUTC, tzName).format("YYYY-MM-DDTHH:mm")}
                onChange={this.handleDateChange("dateToUTC")}
              />
              <Typography display="block" variant="caption">
                Timezone
              </Typography>
              <TimezoneSelect
                timezones={timezones}
                tzName={tzName}
                onChange={(t) =>
                  this.setState({ tzName: t }, () => this.save("tzName"))
                }
              />
              {/* <InputBase
                fullWidth
                onKeyPress={(e) => { if (e.key === 'Enter') this.save('tzName'); }}
                onBlur={() => this.save('tzName')}
                disabled={loading}
                className={classes.inputBase}
                defaultValue={tzName}
                onChange={this.handleChange('tzName')}
              /> */}
              <Typography display="block" variant="caption">
                Notes (This is private for you)
              </Typography>
              <InputBase
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("notes");
                }}
                onBlur={() => this.save("notes")}
                disabled={loading}
                className={classes.inputBase}
                defaultValue={notes}
                onChange={this.handleChange("notes")}
              />
              <Typography display="block" variant="caption">
                Memo (Public with the student)
              </Typography>
              <InputBase
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("memo");
                }}
                onBlur={() => this.save("memo")}
                disabled={loading}
                className={classes.inputBase}
                defaultValue={memo}
                onChange={this.handleChange("memo")}
              />
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Student Full Name
              </Typography>
              <Typography display="block" variant="body2">
                {event.student.fullName}
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Student Email
              </Typography>
              <Typography display="block" variant="body2">
                {event.student.email}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(Event);
