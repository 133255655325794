import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormQuizTag from "components/FormQuizTag";
import Page from "components/Page";
// components
import QuizTagsList from "components/QuizTagsList";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperQuizTagsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //quizTags
    quizTags: PropTypes.array,
    getQuizTagById: PropTypes.func,
    createQuizTag: PropTypes.func,
    deleteQuizTag: PropTypes.func,
    updateQuizTag: PropTypes.func,

    quizCategories: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedQuizTag: undefined,
      formQuizTagOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { quizTags, quizCategories } = this.props;

    switch (index) {
      case 0:
        return (
          <QuizTagsList
            quizTags={quizTags}
            quizCategories={quizCategories}
            onSelect={(selectedQuizTag) =>
              this.setState({
                selectedQuizTag,
                formQuizTagOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createQuizTag,
      deleteQuizTag,
      updateQuizTag,
      quizCategories,
      refresh,
    } = this.props;

    const { index, selectedQuizTag, formQuizTagOpen } = this.state;

    return (
      <Page
        helmet={"QuizTags"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formQuizTagOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="QuizTags"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormQuizTag
          open={formQuizTagOpen}
          quizTag={selectedQuizTag}
          quizCategories={quizCategories}
          close={() => {
            this.setState({
              formQuizTagOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedQuizTag: undefined,
                }),
              200
            );
          }}
          createQuizTag={createQuizTag}
          deleteQuizTag={deleteQuizTag}
          updateQuizTag={updateQuizTag}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperQuizTagsPage);
