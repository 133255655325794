import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperSkillTagsPage from '../component/WrapperSkillTagsPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  skillTags: state.skillTags,
});

const mapDispatchToProps = dispatch => ({
  getSkillTags: () => dispatch(Actions.getSkillTags()),
  getSkillTagById: (...args) => dispatch(Actions.getSkillTagById(...args)),
  createSkillTag: (...args) => dispatch(Actions.createSkillTag(...args)),
  deleteSkillTag: (...args) => dispatch(Actions.deleteSkillTag(...args)),
  updateSkillTag: (...args) => dispatch(Actions.updateSkillTag(...args)),
});
class SkillTagsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    skillTags: PropTypes.array,
    getSkillTags: PropTypes.func,
    getSkillTagById: PropTypes.func,
    createSkillTag: PropTypes.func,
    deleteSkillTag: PropTypes.func,
    updateSkillTag: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, skillTags } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: skillTags.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getSkillTags } = this.props;
    await Promise.all([
      getSkillTags(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      skillTags,
      getSkillTagById,
      createSkillTag,
      deleteSkillTag,
      updateSkillTag,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperSkillTagsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        skillTags={skillTags}
        getSkillTagById={getSkillTagById}
        createSkillTag={createSkillTag}
        deleteSkillTag={deleteSkillTag}
        updateSkillTag={updateSkillTag}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SkillTagsPage);
