import * as Api from "api";
import {
  CREATE_CAREER_DEGREE_REQUIREMENT,
  DELETE_CAREER_DEGREE_REQUIREMENT,
  GET_CAREER_DEGREE_REQUIREMENTS,
  GET_CAREER_DEGREE_REQUIREMENT_BY_ID,
  UPDATE_CAREER_DEGREE_REQUIREMENT,
} from "constants/careerDegreeRequirements";
import { networkAction } from "helpers/network/networkAction";

export const getCareerDegreeRequirements = (filters) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CAREER_DEGREE_REQUIREMENTS,
    Api.getCareerDegreeRequirements,
    [filters]
  );

export const getCareerDegreeRequirementById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CAREER_DEGREE_REQUIREMENT_BY_ID,
    Api.getCareerDegreeRequirementById,
    [id]
  );

export const deleteCareerDegreeRequirement = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_CAREER_DEGREE_REQUIREMENT,
    Api.deleteCareerDegreeRequirement,
    [id]
  );

export const createCareerDegreeRequirement = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_CAREER_DEGREE_REQUIREMENT,
    Api.createCareerDegreeRequirement,
    [body]
  );

export const updateCareerDegreeRequirement = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_CAREER_DEGREE_REQUIREMENT,
    Api.updateCareerDegreeRequirement,
    [id, body]
  );
