// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCategoryPage from "../component/WrapperCategoryPage";

const mapStateToProps = (state) => ({
  subCategories: state.subCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getSubCategories: (...args) => dispatch(Actions.getSubCategories(...args)),
  getSubCategoryById: (...args) =>
    dispatch(Actions.getSubCategoryById(...args)),
  createSubCategory: (...args) => dispatch(Actions.createSubCategory(...args)),
  deleteSubCategory: (...args) => dispatch(Actions.deleteSubCategory(...args)),
  updateSubCategory: (...args) => dispatch(Actions.updateSubCategory(...args)),
});
class CategoryPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    subCategories: PropTypes.array,
    getSubCategories: PropTypes.func,
    getSubCategoryById: PropTypes.func,
    createSubCategory: PropTypes.func,
    deleteSubCategory: PropTypes.func,
    updateSubCategory: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, subCategories } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: subCategories.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getSubCategories } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getSubCategories(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      subCategories,
      getSubCategoryById,
      createSubCategory,
      deleteSubCategory,
      updateSubCategory,
      getCategories,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCategoryPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        getCategories={getCategories}
        subCategories={subCategories}
        getSubCategoryById={getSubCategoryById}
        createSubCategory={createSubCategory}
        deleteSubCategory={deleteSubCategory}
        updateSubCategory={updateSubCategory}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
