import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROGRAMS,
  GET_PROGRAM_BY_ID,
  DELETE_PROGRAM,
  UPDATE_PROGRAM,
  CREATE_PROGRAM,
} from "constants/programs";

import * as Api from "api";

export const getPrograms = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAMS, Api.getPrograms, [filters]);

export const getProgramById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAM_BY_ID, Api.getProgramById, [id]);

export const deleteProgram = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PROGRAM, Api.deleteProgram, [id]);

export const createProgram = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PROGRAM, Api.createProgram, [body]);

export const updateProgram = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROGRAM, Api.updateProgram, [id, body]);
