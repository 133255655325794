export default (theme) => ({
  container: {
    padding: 20,
  },
  expanded: {
    padding: 24,
    background:
      theme.palette.type === "dark"
        ? "#212121"
        : theme.palette.primary.color[600],
  },
  paper: {
    borderLeft: `solid 3px ${theme.palette.primary.color[600]}`,
  },
  paperLoading: {
    borderLeft: "solid 3px #212121",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155, 0.3)",
    borderRadius: 4,
  },
  notActive: {
    background: "black",
  },
  darModeFix: {
    color: theme.palette.type === "dark" ? "white" : "black",
  },
  grip: {
    color: "#9e9e9e",
    fontSize: 14,
    cursor: "grab",
  },
});
