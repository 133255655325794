import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// custom
import Event from "components/Event";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class EventsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    events: PropTypes.array,
    eventTypes: PropTypes.array,
    onSelect: PropTypes.func,
    updateEvent: PropTypes.func,
    createEvent: PropTypes.func,
    deleteEvent: PropTypes.func,
    history: PropTypes.object,
    coaches: PropTypes.array,
    timezones: PropTypes.array,
    sendStudentEmail: PropTypes.func,
    calendyStudent: PropTypes.object,
    refresh: PropTypes.func,
    createEvent: PropTypes.func,
  };

  state = {
    selection: "upcoming",
  };

  addPast() {
    const { createEvent, calendyStudent, refresh } = this.props;
    createEvent({
      coachID: 1,
      eventTypeID: 1,
      dateFromUTC: moment().utc().format(),
      dateToUTC: moment().utc().format(),
      studentID: calendyStudent.id,
      tzName: "America/Los_Angeles",
    }).then(refresh);
  }

  render() {
    const {
      events,
      classes,
      onSelect,
      updateEvent,
      history,
      timezones,
      deleteEvent,
      sendStudentEmail,
      refresh,
      coaches,
      calendyStudent,
    } = this.props;

    const { selection } = this.state;

    const es = events
      .filter((e) => {
        const today = moment();
        const dateFrom = moment(e.dateFromUTC);
        if (selection === "upcoming") {
          return today.diff(dateFrom) <= 0;
        }

        return today.diff(dateFrom) > 0;
      })
      .sort((e) => {
        const today = moment();
        const dateFrom = moment(e.dateFromUTC);
        return today.diff(dateFrom);
      });

    let dateCursor;
    return (
      <div
        style={{
          width: "100%",
          maxWidth: 1000,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid container spacing={1} style={{ paddingBottom: 8 }}>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <div>
                  <Chip
                    variant={selection === "upcoming" ? "filled" : "outlined"}
                    label={"Upcoming"}
                    color={selection === "upcoming" ? "primary" : undefined}
                    onClick={() => this.setState({ selection: "upcoming" })}
                  />
                  <Chip
                    variant={selection === "past" ? "filled" : "outlined"}
                    label={"Past"}
                    color={selection === "past" ? "primary" : undefined}
                    onClick={() => this.setState({ selection: "past" })}
                  />
                </div>
              </Grid>
              {calendyStudent ? (
                <Grid item>
                  <Chip
                    label="Add Past Event"
                    onClick={this.addPast.bind(this)}
                  />
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Grid>
        {es.map((e) => {
          const date = moment(e.dateFromUTC);
          const JSX = [];
          if (
            dateCursor === undefined ||
            date.format("YYYY/MM/DD") !== dateCursor.format("YYYY/MM/DD")
          ) {
            dateCursor = date.clone();
            JSX.push(
              <div className={classes.listHeader}>
                <Typography display="block" color="textSecondary">
                  {date.format("ll")}
                </Typography>
              </div>
            );
          }
          JSX.push(
            <Event
              key={e.id}
              event={e}
              history={history}
              coaches={coaches}
              sendStudentEmail={sendStudentEmail}
              timezones={timezones}
              updateEvent={updateEvent}
              deleteEvent={deleteEvent}
              selection={selection}
              refresh={refresh}
            />
          );
          return JSX;
        })}
      </div>
    );
  }
}

export default withStyles(styles)(EventsList);
