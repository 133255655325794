import * as Api from "api";
import {
  CREATE_MUSICAL_FAMILY,
  DELETE_MUSICAL_FAMILY,
  GET_MUSICAL_FAMILIES,
  GET_MUSICAL_FAMILY_BY_ID,
  GET_PROGRAMS_BY_MUSICAL_FAMILY,
  UPDATE_MUSICAL_FAMILY,
} from "constants/musicalFamilies";
import { networkAction } from "helpers/network/networkAction";

export const getMusicalFamilies = () => async (dispatch) =>
  networkAction(dispatch, GET_MUSICAL_FAMILIES, Api.getMusicalFamilies, []);

export const getProgramsByMusicalFamily = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAMS_BY_MUSICAL_FAMILY,
    Api.getProgramsByMusicalFamily,
    [id]
  );

export const getMusicalFamilyById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MUSICAL_FAMILY_BY_ID, Api.getMusicalFamilyById, [
    id,
  ]);

export const deleteMusicalFamily = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MUSICAL_FAMILY, Api.deleteMusicalFamily, [id]);

export const createMusicalFamily = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MUSICAL_FAMILY, Api.createMusicalFamily, [
    body,
  ]);

export const updateMusicalFamily = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MUSICAL_FAMILY, Api.updateMusicalFamily, [
    id,
    body,
  ]);
