import { GET_CALENDY_STUDENTS } from "constants/calendyStudents";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_CALENDY_STUDENTS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
