import React, { Component } from "react";
import PropTypes from "prop-types";
import { Textfit } from "react-textfit";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Play from "@material-ui/icons/PlayCircleOutline";

// styles
import styles from "./styles";

// constant
import { CAREER } from "constants/routes";

class Bubble extends Component {
  static propTypes = {
    comingSoon: PropTypes.bool,
    classes: PropTypes.object,
    size: PropTypes.number,
    career: PropTypes.object,
    history: PropTypes.object,
    onPlayVideo: PropTypes.func,
    onHover: PropTypes.func,
    disabled: PropTypes.bool,
    noAnimation: PropTypes.bool,
  };

  state = {
    hover: false,
    clicked: false,
  };

  handleClick() {
    const { history, career, comingSoon } = this.props;
    if (comingSoon) return;
    this.setState({
      clicked: true,
    });
    setTimeout(() => history.push(`${CAREER}?careerID=${career.id}`), 300);
  }

  getFill(career, index) {
    if (career.category && index === 0) {
      return career.category.color;
    }

    if (career.categories && career.categories[index]) {
      return career.categories[index].color;
    }

    return "#FFFFFF";
  }

  render() {
    const {
      size,
      career,
      onPlayVideo,
      onHover,
      comingSoon,
      noAnimation,
    } = this.props;
    const { hover, clicked } = this.state;

    let _size = size;
    let _margin = 0;

    if (hover) {
      _size = size + 20;
      _margin = -10;
    }

    if (clicked) {
      _size = size + 500;
      _margin = -250;
    }

    let opacity = 1;

    if (clicked) {
      opacity = 0;
    }

    return (
      <div>
        <div
          onClick={
            onPlayVideo && comingSoon !== true
              ? onPlayVideo
              : this.handleClick.bind(this)
          }
          style={{
            width: size,
            height: size,
            position: "relative",
            cursor: "pointer",
            margin: "auto",
          }}
          onMouseEnter={() => {
            if (noAnimation !== true) this.setState({ hover: true });
            if (onHover) onHover(career);
          }}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          {/* <img src={Shape} /> */}
          <SvgIcon
            viewBox="0 0 150 150"
            style={{
              transition: `
                box-shadow 0.2s cubic-bezier(.52,.09,.43,2),
                width ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                height ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                margin-top ${
                  clicked ? "0.4s" : "0.2s"
                } cubic-bezier(.52,.09,.43,2),
                margin-left ${
                  clicked ? "0.4s" : "0.2s"
                } cubic-bezier(.52,.09,.43,2),
                top ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                left ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                opacity 0.4s cubic-bezier(.52,.09,.43,2),
                transform 0.8s cubic-bezier(.52,.09,.43,2)`,
              borderRadius: size,
              boxShadow: hover ? "0px 0px 5px rgba(0,0,0,0.5)" : undefined,
              pointerEvents: "none",
              width: _size,
              height: _size,
              marginTop: _margin,
              marginLeft: _margin,
              opacity: opacity,
              position: "absolute",
              zIndex: 99,
              transform: hover ? "rotate(360deg)" : "rotate(0deg)",
            }}
          >
            <g id="XMLID_1_">
              <path
                id="XMLID_377_"
                fill={this.getFill(career, 0)}
                d="M146.6,58.9c-1.7,4.6-6.7,7-11.4,5.4c-3-1.1-5.1-3.6-5.7-6.5c-3.4-10.7-9.9-20.6-19.5-28
            		c-7.5-5.8-16.1-9.5-24.8-11.1c0.3-0.3,0.6-0.7,0.9-1.1c2.3-3,3.3-6.7,2.9-10.5c-0.3-2.3-1.1-4.4-2.3-6.2
            		c12.1,1.9,23.9,6.8,34.2,14.8c12.5,9.7,21.1,22.6,25.5,36.7c0.2,0.6,0.3,1.1,0.5,1.7c0,0.1,0.1,0.3,0.1,0.4
            		C147.3,56,147.1,57.5,146.6,58.9z"
              />
              <path
                id="XMLID_376_"
                fill={this.getFill(career, 1)}
                d="M134.3,121.5c-4.2,5.5-9.1,10.2-14.4,14.2c-1.7,1.8-4.1,2.9-6.7,2.8c-4.9-0.1-8.8-4.3-8.6-9.2
            		c0.1-3.6,2.3-6.5,5.3-7.9c0-0.1-0.1-0.2-0.1-0.3c3.9-3,7.4-6.5,10.6-10.5c9.5-12.3,13.2-27.3,11.6-41.7c1.9,1,4.1,1.5,6.2,1.5
            		c0.6,0,1.2,0,1.8-0.1c3.7-0.5,7-2.3,9.3-5.3C152,84.4,147.2,104.8,134.3,121.5z"
              />
              <path
                id="XMLID_375_"
                fill={this.getFill(career, 3)}
                d="M34,115.6c-3.4,0.6-6.4,2.5-8.5,5.2c-1.9,2.4-2.9,5.3-3,8.3C2.2,109.3-4.7,79.8,3.2,53.5
            		l0.1,0c0.5-2,1.7-3.9,3.6-5.2c4.1-2.8,9.6-1.7,12.4,2.4c1.9,2.7,2,6.2,0.6,8.9C14.4,79.1,19.5,100.6,34,115.6z"
              />
              <path
                id="XMLID_374_"
                fill={this.getFill(career, 4)}
                d="M82,14.4c-1.9,2.5-4.9,3.7-7.9,3.4c-16.8,0.3-33.4,7.9-44.5,22.3c-2.1,2.8-4,5.7-5.5,8.7
            		c-0.9-1.5-2.1-3-3.6-4.1c-3-2.3-6.7-3.4-10.5-2.9c-0.9,0.1-1.7,0.3-2.5,0.6c2.2-4.6,4.9-9,8.1-13.2C30.3,10.3,52.1,0.3,74.3,0
            		c2.1-0.2,4.3,0.4,6.2,1.8C84.3,4.9,85,10.5,82,14.4z"
              />
              <path
                id="XMLID_373_"
                fill={this.getFill(career, 2)}
                d="M50.5,145.9c-6.5-2.3-12.6-5.4-18-9.1c-2.2-1-4-3-4.8-5.5c-1.4-4.7,1.2-9.7,5.9-11.1
            		c3.4-1,6.9,0.1,9.1,2.5c0.1-0.1,0.2-0.1,0.2-0.2c4,2.7,8.5,5,13.3,6.7c14.7,5.1,30,3.8,43.1-2.3c-0.3,2.1-0.1,4.3,0.6,6.4
            		c0.2,0.6,0.4,1.1,0.7,1.7c1.6,3.4,4.4,5.9,7.9,7.2C91.2,150.8,70.4,152.8,50.5,145.9z"
              />
            </g>
          </SvgIcon>
          <div
            style={{
              transition: `
                box-shadow 0.2s cubic-bezier(.52,.09,.43,2),
                width ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                height ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                margin-top ${
                  clicked ? "0.4s" : "0.2s"
                } cubic-bezier(.52,.09,.43,2),
                margin-left ${
                  clicked ? "0.4s" : "0.2s"
                } cubic-bezier(.52,.09,.43,2),
                top ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                left ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                opacity 0.4s cubic-bezier(.52,.09,.43,2)`,
              position: "absolute",
              backgroundImage: `url(${career.cover})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: _size - _size * 0.32,
              height: _size - _size * 0.32,
              top: (_size * 0.32) / 2,
              left: (_size * 0.32) / 2,
              marginTop: _margin,
              marginLeft: _margin,
              borderRadius: size,
              opacity: opacity,
            }}
          ></div>
          <div
            style={{
              transition: `
                box-shadow 0.2s cubic-bezier(.52,.09,.43,2),
                width ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                height ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                margin-top ${
                  clicked ? "0.4s" : "0.2s"
                } cubic-bezier(.52,.09,.43,2),
                margin-left ${
                  clicked ? "0.4s" : "0.2s"
                } cubic-bezier(.52,.09,.43,2),
                top ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                left ${clicked ? "0.4s" : "0.2s"} cubic-bezier(.52,.09,.43,2),
                opacity 0.4s cubic-bezier(.52,.09,.43,2)`,
              position: "absolute",
              background: "rgba(0,0,0,0.2)",
              width: _size - _size * 0.32,
              height: _size - _size * 0.32,
              top: (_size * 0.32) / 2,
              left: (_size * 0.32) / 2,
              marginTop: _margin,
              marginLeft: _margin,
              borderRadius: size,
              color: "white",
              fontWeight: 900,
              textShadow: "1px 1px 2px black",
              textAlign: "center",
              opacity: opacity,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                transition: `
                  width ${
                    clicked ? "0.4s" : "0.2s"
                  } cubic-bezier(.52,.09,.43,2),
                  height ${
                    clicked ? "0.4s" : "0.2s"
                  } cubic-bezier(.52,.09,.43,2)`,
                width: _size - _size * 0.32,
                height: _size - _size * 0.32,
                fontSize: 30,
                position: "relative",
              }}
            >
              <div
                ref="titleContainer"
                style={{
                  width: "calc(100% - 16px)",
                  maxHeight: "calc(100% - 16px)",
                  padding: 8,
                  textAlign: "center",
                  margin: "auto",
                  textTransform: "capitalize",
                  position: "relative",
                }}
              >
                <Textfit mode="multi">
                  {comingSoon !== true && onPlayVideo && hover ? (
                    <Play
                      style={{
                        height: "60%",
                        width: "60%",
                      }}
                    />
                  ) : (
                    career.title.toLowerCase()
                  )}
                </Textfit>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Bubble);
