import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Grain from "@material-ui/icons/Grain";
import Tooltip from "@material-ui/core/Tooltip";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "material-ui-pickers";

// styles
import styles from "./styles";

class Availability extends Component {
  static propTypes = {
    classes: PropTypes.object,
    availability: PropTypes.object,
    coach: PropTypes.object,
    refresh: PropTypes.func,
    updateAvailability: PropTypes.func,
    deleteAvailability: PropTypes.func,
    createAvailability: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { availability } = this.props;

    this.state = {
      ...availability,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked }, () => this.save(name));
  };

  onChange = (name) => (value) => {
    const { coach } = this.props;
    const v = value.format().substring(0, 19);
    this.setState(
      {
        [name]: moment.tz(v, coach.tzName).utc().format(),
      },
      () => this.save(name)
    );
  };

  async save(name, numeric) {
    const { refresh, availability, updateAvailability } = this.props;
    if (availability[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateAvailability(availability.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      coach,
      refresh,
      availability,
      deleteAvailability,
    } = this.props;
    const {
      dateFromUTC,
      dateToUTC,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      available,
      loading,
    } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div
          className={classes.availability}
          style={{
            border: available
              ? "solid 1px rgba(76, 175, 80, 0.3)"
              : "solid 1px rgba(244, 67, 54, 0.3)",
            background: available
              ? "rgba(76,175,80,0.1)"
              : "rgba(244, 67, 54,0.1)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 9999,
            }}
          >
            <Grid container alignItems="center">
              <Grid item>
                <Tooltip title="Drag & Drop to change priority. Items on top overwrite the others below.">
                  <Grain className={classes.grip} id="grab" />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={1}>
            <Grid item>
              <Typography display="block" color="textSecondary">
                Date Range
              </Typography>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography display="block" variant="body2">
                    From
                  </Typography>
                </Grid>
                <Grid item>
                  <DatePicker
                    disabled={loading}
                    error={false}
                    invalidDateMessage=""
                    invalidLabel="- - / - - / - - - -"
                    className={classes.inputBase}
                    format={"MM/DD/YYYY"}
                    value={moment(dateFromUTC)
                      .tz(coach.tzName)
                      .format()
                      .substring(0, 19)}
                    onChange={this.onChange("dateFromUTC")}
                    autoOk
                    TextFieldComponent={InputBase}
                    style={{ width: 80 }}
                  />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="body2">
                    To
                  </Typography>
                </Grid>
                <Grid item>
                  <DatePicker
                    error={false}
                    disabled={loading}
                    invalidDateMessage=""
                    invalidLabel="- - / - - / - - - -"
                    className={classes.inputBase}
                    format={"MM/DD/YYYY"}
                    value={moment(dateToUTC)
                      .tz(coach.tzName)
                      .format()
                      .substring(0, 19)}
                    onChange={this.onChange("dateToUTC")}
                    autoOk
                    TextFieldComponent={InputBase}
                    style={{ width: 80 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography display="block" color="textSecondary">
                Time Range
              </Typography>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography display="block" variant="body2">
                    From
                  </Typography>
                </Grid>
                <Grid item>
                  <TimePicker
                    error={false}
                    disabled={loading}
                    invalidDateMessage=""
                    invalidLabel="- - : - -"
                    className={classes.inputBase}
                    format={"h:mm a"}
                    value={moment(dateFromUTC)
                      .tz(coach.tzName)
                      .format()
                      .substring(0, 19)}
                    onChange={this.onChange("dateFromUTC")}
                    autoOk
                    TextFieldComponent={InputBase}
                    style={{ width: 60 }}
                  />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="body2">
                    To
                  </Typography>
                </Grid>
                <Grid item>
                  <TimePicker
                    error={false}
                    disabled={loading}
                    invalidDateMessage=""
                    invalidLabel="- - : - -"
                    className={classes.inputBase}
                    format={"h:mm a"}
                    value={moment(dateToUTC)
                      .tz(coach.tzName)
                      .format()
                      .substring(0, 19)}
                    onChange={this.onChange("dateToUTC")}
                    autoOk
                    TextFieldComponent={InputBase}
                    style={{ width: 60 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography display="block" color="textSecondary">
            Days
          </Typography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={monday}
                    onChange={this.handleCheckboxChange("monday")}
                  />
                }
                label="M"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tuesday}
                    onChange={this.handleCheckboxChange("tuesday")}
                  />
                }
                label="T"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wednesday}
                    onChange={this.handleCheckboxChange("wednesday")}
                  />
                }
                label="W"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={thursday}
                    onChange={this.handleCheckboxChange("thursday")}
                  />
                }
                label="T"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={friday}
                    onChange={this.handleCheckboxChange("friday")}
                  />
                }
                label="F"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saturday}
                    onChange={this.handleCheckboxChange("saturday")}
                  />
                }
                label="S"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sunday}
                    onChange={this.handleCheckboxChange("sunday")}
                  />
                }
                label="s"
              />
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Switch
                checked={available}
                onChange={this.handleCheckboxChange("available")}
              />
            }
            label="Available"
          />
          <Button
            style={{
              textTransform: "none",
            }}
            variant="outlined"
            onClick={() => {
              deleteAvailability(availability.id).then(refresh);
            }}
          >
            Delete
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(Availability);
