import { GET_ACADEMIES } from "constants/academies";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_ACADEMIES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
