import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "cpfm";
const programLevels = `/${service}/programLevels`;

export function getProgramLevels() {
  const url = `${programLevels}`;
  return {
    method: GET,
    url,
  };
}

export function getProgramLevelById(id) {
  const url = `${programLevels}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProgramLevel(id, body) {
  const url = `${programLevels}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProgramLevel(id) {
  const url = `${programLevels}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProgramLevel(body) {
  const url = `${programLevels}`;
  return {
    method: POST,
    url,
    body,
  };
}
