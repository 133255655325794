// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import ProgramCount from "hooks/ProgramCount";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class AgesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    ages: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedAge, index) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedAge, index);
    }
  }

  render() {
    const { ages } = this.props;

    return (
      <Table
        data={ages}
        meta={[
          {
            path: "value",
            title: "Value",
          },
          {
            path: "programCount",
            title: "Programs#",
            render: (t, d) => (
              <ProgramCount
                value={t}
                id={d.id}
                title={`${d.value} years old related programs`}
                type="ages"
              />
            ),
          },
        ]}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(AgesList);
