import MomentUtils from "@date-io/moment";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// custom
import EnrollmentsList from "components/EnrollmentsList";
import FormEnrollment from "components/FormEnrollment";
// reactor
import Page from "components/Page";
import { REACT_APP_STUDENT } from "config";
import { isExpired } from "helpers";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

function EnrollmentDetails({
  open,
  onClose,
  enrollment,
  updateEnrollment,
  refresh,
}) {
  const [expirationDate, setExpirationDate] = React.useState(
    enrollment?.expirationDate
  );

  const handleDateChange = (date) => {
    setExpirationDate(date);
    updateEnrollment(enrollment?.id, {
      expirationDate: date.utc().format(),
    }).then(refresh);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={enrollment?.courseBackground}
            alt="background"
            style={{ height: 50 }}
          />
          <div style={{ paddingLeft: 20 }}>
            <Typography variant="h6">{enrollment?.userFullName}</Typography>
            <Typography variant="subtitle1">
              {enrollment?.courseName} Enrollment
            </Typography>
          </div>
        </div>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="body2">Enrollment ID</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body2">{enrollment?.id}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Status</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body2">
              {isExpired(moment(expirationDate).format()) ? (
                <span style={{ color: "#f44336" }}>Expired</span>
              ) : (
                <span style={{ color: "#4caf50" }}>Valid</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Expiration Date</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body2">
              {moment(expirationDate).format("lll")}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {moment(expirationDate).fromNow()}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Start Date</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body2">
              {moment(enrollment?.startDate).format("lll")}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {moment(enrollment?.startDate).fromNow()}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Percentage Completed</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body2">{enrollment?.percentage}%</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Current Module</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body2">
              {enrollment?.moduleName || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Current Lecture</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body2">
              {enrollment?.lectureName || "-"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              background: "rgba(33,150,243,0.1)",
              border: "solid 1px rgba(33,150,243,0.3)",
            }}
          >
            <Typography style={{ color: "#2196f3" }} variant="body2">
              Change Expiration Date:
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                showTodayButton
                autoOk
                variant="dialog"
                format="MM/DD/yyyy"
                margin="normal"
                fullWidth
                value={expirationDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() =>
            window.open(
              `${REACT_APP_STUDENT}/enrollment?id=${enrollment?.id}`,
              "_blank"
            )
          }
        >
          Open Course{" "}
          <i style={{ marginLeft: 8 }} class="fas fa-external-link-alt"></i>
        </Button>
        <Button onClick={onClose} color="primary" variant="contained">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function EnrollmentBatchEdit({
  open,
  onClose,
  updateEnrollment,
  selectedEnrollments,
  refresh,
}) {
  const [expirationDate, setExpirationDate] = React.useState();
  const [backdrop, setBackdrop] = React.useState(false);

  const handleDateChange = (date) => {
    setExpirationDate(date);
  };

  const done = async () => {
    const proms = [];
    onClose();
    setBackdrop(true);
    for (const key in selectedEnrollments) {
      if (Object.hasOwnProperty.call(selectedEnrollments, key)) {
        const enrollment = selectedEnrollments[key];
        proms.push(
          updateEnrollment(enrollment.id, {
            expirationDate: expirationDate.utc().format(),
          })
        );
      }
    }
    await Promise.all(proms);
    refresh();
    setBackdrop(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change the Expiration Date to {selectedEnrollments.length} Enrollment
          {selectedEnrollments.length > 1 ? "s" : ""}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                background: "rgba(33,150,243,0.1)",
                border: "solid 1px rgba(33,150,243,0.3)",
              }}
            >
              <Typography style={{ color: "#2196f3" }} variant="body2">
                Change Expiration Date:
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  showTodayButton
                  variant="dialog"
                  format="MM/DD/yyyy"
                  margin="normal"
                  fullWidth
                  autoOk
                  value={expirationDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={done} color="primary" variant="contained">
            Change
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        style={{
          zIndex: 99999,
          color: "white",
        }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

class WrapperEnrollmentPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    createEnrollment: PropTypes.func,
    courses: PropTypes.array,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    enrollments: PropTypes.array,
    getEnrollments: PropTypes.func,
    users: PropTypes.array,
    user: PropTypes.object,
    filters: PropTypes.array,
    location: PropTypes.object,
    dataLoading: PropTypes.bool,
    searchUsers: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      newEnrollmentOpen: false,
      index: urlParams.index ? Number(urlParams.index) : 0,
      groupSelectEnabled: false,
      selectedEnrollments: [],
    };
  }

  getStepContent() {
    const { index, groupSelectEnabled } = this.state;
    const {
      enrollments,
      history,
      filters,
      location,
      dataLoading,
      getEnrollments,
      user,
      searchUsers,
      courses,
      refreshKey,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <EnrollmentsList
            courses={courses}
            user={user}
            key={refreshKey}
            searchUsers={searchUsers}
            location={location}
            getEnrollments={getEnrollments}
            onGroupSelect={(e) => this.setState({ selectedEnrollments: e })}
            history={history}
            groupSelectEnabled={groupSelectEnabled}
            enrollments={enrollments}
            onSelect={(se) =>
              this.setState({
                selectedEnrollment: se,
                enrollmentDetailsOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      createEnrollment,
      loading,
      courses,
      refresh,
      users,
      user,
      updateEnrollment,
    } = this.props;

    const { index, newEnrollmentOpen, selectedEnrollments } = this.state;

    return (
      <Page
        helmet="Enrollments"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ newEnrollmentOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Enrollments"}
        tabs={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={classes.tabs}
          >
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                });
                history.push(`?index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
            <div style={{ paddingRight: 20 }}>
              {this.state.groupSelectEnabled ? (
                <>
                  {selectedEnrollments.length ? (
                    <Typography
                      variant="caption"
                      color="primary"
                      style={{ marginRight: 12 }}
                    >
                      {selectedEnrollments.length} Enrollment
                      {selectedEnrollments.length > 1 ? "s" : ""} selected
                    </Typography>
                  ) : (
                    []
                  )}
                  <Button
                    disabled={selectedEnrollments.length === 0}
                    onClick={() => this.setState({ batchEditOpen: true })}
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                </>
              ) : (
                []
              )}
              <Button
                size="small"
                variant="outlined"
                style={{ marginLeft: 12 }}
                onClick={() =>
                  this.setState((prevState) => ({
                    groupSelectEnabled: !prevState.groupSelectEnabled,
                    selectedEnrollments: [],
                  }))
                }
              >
                {!this.state.groupSelectEnabled ? "Batch Edit" : "Done"}
              </Button>
            </div>
          </div>
        }
      >
        {this.getStepContent()}
        <FormEnrollment
          courses={courses}
          users={users}
          refresh={refresh}
          organizationID={user.organizationID}
          user={user}
          open={newEnrollmentOpen}
          close={() => this.setState({ newEnrollmentOpen: false })}
          createEnrollment={createEnrollment}
        />
        {this.state.selectedEnrollment ? (
          <EnrollmentDetails
            open={this.state.enrollmentDetailsOpen}
            onClose={() => this.setState({ enrollmentDetailsOpen: false })}
            enrollment={this.state.selectedEnrollment}
            updateEnrollment={updateEnrollment}
            refresh={refresh}
          />
        ) : (
          []
        )}
        <EnrollmentBatchEdit
          selectedEnrollments={selectedEnrollments}
          open={this.state.batchEditOpen}
          onClose={() => this.setState({ batchEditOpen: false })}
          updateEnrollment={updateEnrollment}
          selectedEnrollments={this.state.selectedEnrollments}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEnrollmentPage);
