import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class ClubMembersList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    clubMembers: PropTypes.array,
    history: PropTypes.object,
    getClubMembers: PropTypes.func,
    onSelect: PropTypes.func,
    searchUsers: PropTypes.func,
  };

  onSelect(selectedDownloadRequest) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedDownloadRequest);
    }
  }

  render() {
    const { clubMembers, getClubMembers, history, searchUsers } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getClubMembers}
        keyword="clubMembers"
        metaDataEndpoint="/papyrus/clubMembers/searchMetadata"
        exportEndpoint="/papyrus/clubMembers/csv"
        metaDataOveride={{
          userUUID: {
            type: "autoFill",
            label: "User",
            getData: (search) =>
              searchUsers({
                text: search,
              }),
            valueKey: "uuid",
            labelTransform: (t) => `${t.firstName} ${t.lastName} (${t.email})`,
          },
        }}
      >
        <Table
          data={clubMembers}
          meta={[
            {
              path: "email",
              title: "Email",
              numeric: false,
            },
            {
              path: "userFullName",
              title: "Full Name",
              numeric: false,
            },
            {
              path: "startDate",
              title: "Start Date",
              numeric: false,
              render: (t) => (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography display="block" variant="body2">
                      {moment(t).format("lll")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {moment(t).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              ),
            },
            {
              path: "expirationDate",
              title: "Expiration Date",
              numeric: false,
              render: (t) => (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography display="block" variant="body2">
                      {moment(t).format("lll")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {moment(t).fromNow()}
                    </Typography>
                  </Grid>
                </Grid>
              ),
            },
            {
              path: "renewalDate",
              title: "Renewal Date",
              numeric: false,
              render: (t) =>
                t ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography display="block" variant="body2">
                        {moment(t).format("lll")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        {moment(t).fromNow()}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography display="block" variant="body2">
                    Not Renewed
                  </Typography>
                ),
            },
          ]}
          title={"Book Club Members"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(ClubMembersList);
