import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Icon,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import EnrollmentsList from "components/EnrollmentsList";
import EventList from "components/EventList";
// custom
import Page from "components/Page";
import SurveysList from "components/SurveysList";
import TimeAssetList from "components/TimeAssetList";
import { REACT_APP_API } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCoachPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    urlParams: PropTypes.object,
    assessments: PropTypes.array,
    surveys: PropTypes.array,
    enrollments: PropTypes.array,
    timezones: PropTypes.array,
    timeAssets: PropTypes.array,
    getSurveys: PropTypes.func,
    events: PropTypes.array,
    sendStudentEmail: PropTypes.func,
    user: PropTypes.object,
    calendyStudent: PropTypes.object,
    eventTypes: PropTypes.array,
    updateEvent: PropTypes.func,
    deleteEvent: PropTypes.func,
    refreshCalendy: PropTypes.func,
    createEvent: PropTypes.func,
    coaches: PropTypes.array,
    uuid: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    const { urlParams, eventTypes, coaches } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      coachID: coaches[0]?.id,
      eventTypeID: eventTypes[0]?.id,
    };
  }

  getStepContent() {
    const {
      refreshCalendy,
      events,
      updateEvent,
      deleteEvent,
      eventTypes,
      timezones,
      surveys,
      enrollments,
      coaches,
      sendStudentEmail,
      calendyStudent,
      createEvent,
      assessments,
      getSurveys,
      timeAssets,
      user,
      searchUsers,
    } = this.props;

    const { index } = this.state;

    console.log(events);
    console.log("----");

    switch (index) {
      case 0:
        return (
          <Grid
            container
            justify="center"
            style={{ maxWidth: 1000, marginLeft: "auto", marginRight: "auto" }}
            spacing={2}
          >
            <Grid item xs={12}>
              <TimeAssetList
                timeAssets={timeAssets}
                events={events}
                eventTypes={eventTypes}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography display="block" variant="h6">
                Events
              </Typography>
              <EventList
                eventTypes={eventTypes}
                calendyStudent={calendyStudent}
                events={events || []}
                createEvent={createEvent}
                coaches={coaches}
                updateEvent={updateEvent}
                sendStudentEmail={sendStudentEmail}
                deleteEvent={deleteEvent}
                refresh={refreshCalendy}
                timezones={timezones}
                // onSelect={selectedEventType => history.push(`${EVENT_TYPE}?id=${selectedEventType.id}`)}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <SurveysList
            user={user}
            searchUsers={searchUsers}
            surveys={surveys || []}
            assessments={assessments}
            getSurveys={getSurveys}
            onSelect={(s) =>
              window.open(
                `${REACT_APP_API}/harmony/surveys/${s.id}/profile`,
                "_blank"
              )
            }
          />
        );
      case 2:
        return <EnrollmentsList enrollments={enrollments || []} />;
      default:
        return (
          <Typography display="block" variant="body2">
            Comming Soom
          </Typography>
        );
    }
  }

  render() {
    const {
      // classes,
      loading,
      classes,
      history,
      uuid,
      surveys,
      enrollments,
      calendyStudent,
      back,
      eventTypes,
      coaches,
      refreshCalendy,
    } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet={"Student"}
        loading={loading}
        fab={
          <Tooltip title="Add Session">
            <Fab
              onClick={() => this.setState({ scheduleOpen: true })}
              color="primary"
              aria-label="Add"
            >
              <Icon className="fad fa-calendar-plus" />
            </Fab>
          </Tooltip>
        }
        header={
          <Grid
            container
            alignItems="center"
            spacing={1}
            style={{
              padding: "12px 0px 0px 16px",
              width: "calc(100% - 16px)",
            }}
          >
            {back ? (
              <Grid item>
                <Chip label={"← Back"} onClick={() => history.push(back)} />
              </Grid>
            ) : (
              []
            )}
            <Grid item>
              <Typography
                display="block"
                variant="h5"
                style={{
                  fontWeight: 800,
                  fontSize: 32,
                }}
              >
                {calendyStudent && calendyStudent.fullName}
              </Typography>
            </Grid>
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?uuid=${uuid}&index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Private Sessions"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label={`Profiles ${
                surveys && surveys.length ? `(${surveys.length})` : []
              }`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label={`Courses ${
                enrollments && enrollments.length
                  ? `(${enrollments.length})`
                  : []
              }`}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <Dialog
          fullWidth
          fullScreen
          open={this.state.scheduleOpen}
          onClose={() => this.setState({ scheduleOpen: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            style={{ textAlign: "center" }}
          >
            Schedule a Session for {calendyStudent?.fullName}
          </DialogTitle>
          <DialogContent
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container alignItems="center" justify="center" spacing={1}>
              <Grid item xs={3}>
                <TextField
                  select
                  fullWidth
                  label="Coach"
                  size="small"
                  variant="outlined"
                  value={this.state.coachID}
                  onChange={(e) => this.setState({ coachID: e.target.value })}
                >
                  {coaches.map((c) => (
                    <MenuItem value={c.id} key={c.id}>
                      {c.fullName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  fullWidth
                  label="Event Type"
                  size="small"
                  variant="outlined"
                  value={this.state.eventTypeID}
                  onChange={(e) =>
                    this.setState({ eventTypeID: e.target.value })
                  }
                >
                  {eventTypes.map((c) => (
                    <MenuItem value={c.id} key={c.id}>
                      {c.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {this.state.coachID && this.state.eventTypeID ? (
              <iframe
                frameBorder="0"
                style={{ flexGrow: 1 }}
                height={"100%"}
                width={"100%"}
                src={`https://calendy.beta.winningonstage.com/schedule?coachID=${this.state.coachID}&eventTypeID=${this.state.eventTypeID}`}
              />
            ) : (
              []
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              autoFocus
              variant="contained"
              onClick={() => {
                this.setState({ scheduleOpen: false });
                refreshCalendy();
              }}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCoachPage);
