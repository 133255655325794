// material-ui
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class QuizTagsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    quizTags: PropTypes.array,
    quizCategories: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedQuizTag) {
    const { onSelect } = this.props;
    this.setState({ selectedQuizTag });
    if (onSelect) {
      onSelect(selectedQuizTag);
    }
  }

  render() {
    const { quizTags, quizCategories } = this.props;

    return (
      <Table
        data={quizTags}
        meta={[
          {
            path: "color",
            title: "",
            numeric: false,
            render: (t, d) => {
              return (
                <div
                  style={{
                    background: t,
                    width: 20,
                    height: 20,
                    borderRadius: 30,
                    border: "solid 2px white",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
                  }}
                />
              );
            },
            width: 50,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "categoryID",
            title: "Category",
            numeric: false,
            render: (t, d) => {
              const cat = quizCategories.find((b) => `${b.id}` === t);
              if (cat) {
                return <Typography variant="body2">{cat.title}</Typography>;
              }
              return <div />;
            },
          },
        ]}
        title={"QuizTags"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(QuizTagsList);
