// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { durationFormatter } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CalendyStudentsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    calendyStudents: PropTypes.array,
    history: PropTypes.object,
    onSelect: PropTypes.func,
    getCalendyStudents: PropTypes.func,
    searchUsers: PropTypes.func,
  };

  onSelect(selectedDownloadRequest) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedDownloadRequest);
    }
  }

  render() {
    const {
      calendyStudents,
      getCalendyStudents,
      history,
      searchUsers,
    } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getCalendyStudents}
        keyword="students"
        metaDataEndpoint="/calendy/students/searchMetadata"
        metaDataOveride={{
          userUUID: {
            label: "User",
            type: "autoFill",
            getData: (search) =>
              searchUsers({
                text: search,
              }),
            valueKey: "uuid",
            labelTransform: (t) => `${t.firstName} ${t.lastName} (${t.email})`,
          },
        }}
      >
        <Table
          data={calendyStudents}
          meta={[
            {
              path: "fullName",
              title: "Student Name",
              numeric: false,
            },
            {
              path: "email",
              title: "Email",
              numeric: false,
            },
            {
              path: "totalMinutes",
              title: "Total Time",
              numeric: false,
              render: (t) => <div>{durationFormatter(t)}</div>,
            },
            {
              path: "notes",
              title: "Notes",
              numeric: false,
              render: (t) => <div>{t}</div>,
            },
          ]}
          title={"Calendy Students"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(CalendyStudentsList);
