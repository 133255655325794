import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormSurvey from "components/FormSurvey";
// reactor
import Page from "components/Page";
// custom
import SurveysList from "components/SurveysList";
import { REACT_APP_STUDENT } from "config";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperSurveyPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    surveys: PropTypes.array,
    createSurvey: PropTypes.func,
    deleteSurvey: PropTypes.func,
    updateSurvey: PropTypes.func,
    getSurveys: PropTypes.func,
    filters: PropTypes.array,
    assessments: PropTypes.array,
    users: PropTypes.array,
    location: PropTypes.object,
    dataLoading: PropTypes.bool,
    user: PropTypes.object,
    searchUsers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSurvey: undefined,
      formSurveyOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      surveys,
      history,
      filters,
      getSurveys,
      location,
      dataLoading,
      assessments,
      user,
      searchUsers,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <SurveysList
            user={user}
            searchUsers={searchUsers}
            filters={filters}
            location={location}
            history={history}
            assessments={assessments}
            getSurveys={getSurveys}
            surveys={surveys}
            onSelect={(selectedSurvey) =>
              window.open(
                `${REACT_APP_STUDENT}/survey?id=${selectedSurvey.id}`,
                "_blank"
              )
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      users,
      assessments,
      createSurvey,
      deleteSurvey,
      updateSurvey,
      refresh,
      user,
    } = this.props;

    const { index, selectedSurvey, formSurveyOpen } = this.state;

    return (
      <Page
        helmet="Surveys"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formSurveyOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Surveys"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormSurvey
          open={formSurveyOpen}
          organizationID={user.organizationID}
          user={user}
          assessments={assessments}
          users={users}
          survey={selectedSurvey}
          close={() => {
            this.setState({
              formSurveyOpen: false,
            });
          }}
          createSurvey={createSurvey}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSurveyPage);
