import { Chip, Grid, Grow, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SearchIcon from "@material-ui/icons/Search";
import Visibility from "@material-ui/icons/VisibilityTwoTone";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// components
import Page from "components/Page";
import Table from "components/TablePagination";
import { IMPERSONATE_REDIRECTION, REACT_APP_API } from "config";
import { setCookie, updateURLParameter } from "helpers";
import AsyncAutocomplete from "hooks/AsyncAutocomplete";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class WrapperSearchPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    searchUsers: PropTypes.func,
    history: PropTypes.object,
    cookies: PropTypes.object,
    products: PropTypes.array,
    getProducts: PropTypes.func,
    impersonate: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      fetched: false,
    };
  }

  async search() {
    const { getProducts, history } = this.props;
    const { selectedUser } = this.state;
    const filters = [
      {
        name: "userUUID",
        comparison: "eq",
        value: selectedUser.uuid,
      },
    ];
    history.push({
      search: updateURLParameter("filters", JSON.stringify(filters)),
    });
    const r = await getProducts(filters);
    this.setState({
      fetched: true,
      products: r.payload,
    });
  }

  render() {
    const {
      classes,
      history,
      user,
      cookies,
      impersonate,
      getProducts,
      searchUsers,
    } = this.props;

    const { index, selectedUser, fetched, products } = this.state;

    return (
      <Page helmet="Search" noPadding>
        <div
          style={{
            flex: 1,
            background: "#3A484E",
            justifyContent: !fetched && "center",
            display: "flex",
            color: "white",
            position: "relative",
          }}
        >
          {!fetched ? (
            <Grow in>
              <div
                style={{ textAlign: "center", width: "100%", marginTop: 50 }}
              >
                <Typography variant="h4" style={{ fontWeight: 800 }}>
                  <SearchIcon /> Search for a Consumer
                </Typography>
                <Typography
                  variant="subhead"
                  style={{ color: "rgba(255, 255, 255, 0.7)" }}
                >
                  Across all organizations. Show student's related products.
                </Typography>
                <br />
                <br />
                <Paper
                  style={{
                    marginTop: 20,
                    padding: 10,
                    width: "100%",
                    maxWidth: 600,
                    margin: "auto",
                  }}
                >
                  <AsyncAutocomplete
                    label={"Full Name or Email"}
                    searchEndpoint={`${REACT_APP_API}/account/users/search`}
                    bodyTransform={(searchText) => ({ text: searchText })}
                    selectedLabelText={(o) => {
                      if (o) {
                        return `${o.firstName} ${o.lastName} (${o.email})`;
                      }

                      return "";
                    }}
                    onSelect={(o) =>
                      this.setState({ selectedUser: o }, this.search.bind(this))
                    }
                  />
                </Paper>
              </div>
            </Grow>
          ) : (
            <Grow in>
              <div style={{ flex: 1, padding: 20 }}>
                <Grid container justify="space-between" spacing={2}>
                  <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <Chip
                      icon={<SearchIcon style={{ color: "white" }} />}
                      label={"New Search"}
                      variant="outlined"
                      style={{
                        borderColor: "white",
                        color: "white",
                      }}
                      onClick={() =>
                        this.setState({
                          fetched: false,
                          products: [],
                          selectedUser: undefined,
                        })
                      }
                    />
                    <div style={{ color: "white", paddingLeft: 8 }}>
                      <Typography>
                        {`${selectedUser.firstName} ${selectedUser.lastName} (${selectedUser.email})`}
                      </Typography>
                      <Typography variant="caption" style={{ opacity: 0.6 }}>
                        {selectedUser.uuid}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item>
                    <Chip
                      label={`Impersonate`}
                      onClick={async () => {
                        setCookie(
                          cookies,
                          "redirectAfterStopImpersonate",
                          window.location.href
                        );
                        await impersonate(selectedUser);
                        window.location.replace(IMPERSONATE_REDIRECTION);
                      }}
                      icon={<Visibility style={{ color: "white" }} />}
                      style={{ color: "white", background: "#f44336" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AdvancedSearch
                      history={history}
                      location={window.location}
                      refresh={getProducts}
                      keyword="products"
                      exportEndpoint="/pmp/products/csv"
                      metaDataEndpoint="/pmp/products/searchMetadata"
                      hideMetaData={["userUUID"]}
                      metaDataOveride={{
                        userUUID: {
                          type: "autoFill",
                          getData: (search) =>
                            searchUsers({
                              text: search,
                            }),
                          valueKey: "uuid",
                          labelTransform: (t) =>
                            `${t.firstName} ${t.lastName} (${t.email})`,
                        },
                        productType: {
                          type: "autocomplete",
                          data: [{ name: "survey" }, { name: "enrollment" }],
                          valueKey: "name",
                          labelKey: "name",
                        },
                      }}
                    >
                      <Table
                        data={products}
                        noToolbar
                        meta={[
                          {
                            path: "studentName",
                            title: "Student",
                          },
                          {
                            path: "creationDate",
                            title: "Date",
                            transform: (t) => moment(t).format("lll"),
                          },
                          {
                            path: "productType",
                            title: "Product Type",
                          },
                          {
                            path: "productName",
                            title: "Product Name",
                          },
                          {
                            path: "id",
                            title: "",
                            render: () => (
                              <div style={{ textAlign: "right" }}>
                                <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                              </div>
                            ),
                          },
                        ]}
                        title={"Products"}
                        onRowSelect={(d) => {
                          window.open(
                            `${IMPERSONATE_REDIRECTION}${d.productType}?id=${d.productID}`,
                            "_blank"
                          );
                        }}
                        // onRowDoubleSelect={this.onDoubleSelect.bind(this)}
                      />
                    </AdvancedSearch>
                  </Grid>
                </Grid>
              </div>
            </Grow>
          )}
        </div>
      </Page>
    );
  }
}
export default withCookies(withStyles(styles)(WrapperSearchPage));
