export default (theme) => ({
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
  back: {
    color: "rgba(127,127,127,0.5)",
  },
  fore: {
    position: "absolute",
    left: 0,
  },
  circularContainer: {
    position: "relative",
    width: 40,
  },
  value: {
    position: "absolute",
    top: 10,
    textAlign: "center",
    width: "100%",
  },
  darkModeFix: {
    color: theme.palette.type === "dark" ? "white" : "black",
  },
});
