import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "cpfm";
const instruments = `/${service}/programInstruments`;

export function getProgramInstruments() {
  const url = `${instruments}`;
  return {
    method: GET,
    url,
  };
}

export function getProgramInstrumentById(id) {
  const url = `${instruments}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProgramInstrument(id, body) {
  const url = `${instruments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProgramInstrument(id) {
  const url = `${instruments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProgramInstrument(body) {
  const url = `${instruments}`;
  return {
    method: POST,
    url,
    body,
  };
}
