export default (theme) => ({
  container: {
    padding: 5,
  },
  availability: {
    padding: 8,
    borderRadius: 4,
    marginTop: 16,
    position: "relative",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.paper,
    padding: 2,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
  },
  grip: {
    color: "#9e9e9e",
    fontSize: 14,
    cursor: "grab",
  },
});
