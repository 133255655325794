// routes
import { Icon } from "@material-ui/core";
import {
  ACADEMIES,
  ASSESSMENTS,
  ASSESSMENT_LOGS,
  AUTHORS,
  CAREERS,
  CATEGORIES,
  CLUB_MEMBERS,
  COURSES,
  COURSE_LOGS,
  DEGREE_LEVELS,
  DEGREE_REQUIREMENTS,
  DOWNLOAD_REQUESTS,
  ENROLLMENTS,
  EVENT_TYPES,
  INQUIRIES,
  PROGRAMS,
  QUIZ_CATEGORIES,
  QUIZ_TAGS,
  SEARCH,
  SETTINGS,
  SKILL_TAGS,
  SUB_CATEGORIES,
  SURVEYS,
} from "constants/routes";
import React from "react";

export const menu = [
  {
    type: "header",
    label: "Consumer Products",
  },
  {
    icon: <Icon className="icon fas fa-search" />,
    label: "Search",
    path: SEARCH,
    divider: true,
    type: "route",
  },
  {
    icon: <Icon className="icon fas fa-user-graduate" />,
    label: "Course Enrollments",
    path: ENROLLMENTS,
    divider: true,
    type: "route",
  },
  {
    icon: <Icon className="icon far fa-id-badge" />,
    label: "Student Profiles",
    path: SURVEYS,
    divider: true,
    type: "route",
  },
  {
    icon: <Icon className="icon fas fa-book" />,
    label: "Book Club Members",
    path: CLUB_MEMBERS,
    divider: true,
    type: "route",
  },
  {
    icon: <Icon className="icon fas fa-video" />,
    label: "Private Sessions",
    path: EVENT_TYPES,
    divider: true,
    type: "route",
  },
  {
    icon: <Icon className="icon fas fa-bullseye" />,
    label: "Website Prospects",
    path: DOWNLOAD_REQUESTS,
    divider: true,
    type: "route",
  },
  {
    type: "header",
    label: "Content Management",
  },
  {
    icon: <Icon className="icon fas fa-chalkboard-teacher" />,
    label: "Courses",
    path: COURSES,
    divider: true,
    type: "route",
    nested: [
      {
        icon: <Icon className="icon fas fa-chalkboard" />,
        label: "Courses",
        path: COURSES,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fas fa-terminal" />,
        label: "Logs",
        path: COURSE_LOGS,
        divider: true,
        type: "route",
      },
    ],
  },
  {
    icon: <Icon className="icon fas fa-poll-h" />,
    label: "Assessments",
    path: ASSESSMENTS,
    divider: true,
    type: "route",
    nested: [
      {
        icon: <Icon className="icon fas fa-question" />,
        label: "Assessments",
        path: ASSESSMENTS,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fas fa-user-check" />,
        label: "Inquiries",
        path: INQUIRIES,
        divider: true,
        type: "route",
      },

      {
        icon: <Icon className="icon fas fa-terminal" />,
        label: "Logs",
        path: ASSESSMENT_LOGS,
        divider: true,
        type: "route",
      },
    ],
  },
  {
    icon: <Icon className="icon fas fa-map-marked-alt" />,
    label: "CPFM Map",
    path: ACADEMIES,
    divider: true,
    type: "route",
    nested: [
      {
        icon: <Icon className="icon fas fa-school" />,
        label: "Academies",
        path: ACADEMIES,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fas fa-guitar" />,
        label: "Programs",
        path: PROGRAMS,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fas fa-toolbox" />,
        label: "Settings",
        path: SETTINGS,
        divider: true,
        type: "route",
      },
    ],
  },
  {
    icon: <Icon className="icon fas fa-icons" />,
    label: "CPFM Careers",
    path: CAREERS,
    divider: true,
    type: "route",
    nested: [
      {
        icon: <Icon className="icon fas fa-drum" />,
        label: "Careers",
        path: CAREERS,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fas fa-square" />,
        label: "Categories",
        path: CATEGORIES,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fas fa-th-large" />,
        label: "SubCategories",
        path: SUB_CATEGORIES,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fas fa-toolbox" />,
        label: "Degree Levels",
        path: DEGREE_LEVELS,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fad fa-school" />,
        label: "Education Requirements",
        path: DEGREE_REQUIREMENTS,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fas fa-tag" />,
        label: "Skill Tags",
        path: SKILL_TAGS,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fad fa-layer-group" />,
        label: "Quiz Categories",
        path: QUIZ_CATEGORIES,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fad fa-ball-pile" />,
        label: "Quiz Tags",
        path: QUIZ_TAGS,
        divider: true,
        type: "route",
      },
      {
        icon: <Icon className="icon fad fa-feather" />,
        label: "Authors",
        path: AUTHORS,
        divider: true,
        type: "route",
      },
    ],
  },
];
