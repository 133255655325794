import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// components
import InstitutionsList from 'components/InstitutionsList';
import Page from 'components/Page';
import FormInstitution from 'components/FormInstitution';

// styles
import styles from './styles';

class WrapperInstitutionsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //institutions
    institutions: PropTypes.array,
    getInstitutionById: PropTypes.func,
    createInstitution: PropTypes.func,
    deleteInstitution: PropTypes.func,
    updateInstitution: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedInstitution: undefined,
      formInstitutionOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { institutions } = this.props;

    switch (index) {
      case 0:
        return (
          <InstitutionsList
            institutions={institutions}
            onSelect={(selectedInstitution) => this.setState({
              selectedInstitution,
              formInstitutionOpen: true,
            })}
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createInstitution,
      deleteInstitution,
      updateInstitution,
      refresh,
    } = this.props;

    const {
      index,
      selectedInstitution,
      formInstitutionOpen,
    } = this.state;

    return (
      <Page
        helmet={'Institutions'}
        loadingMessage={'Loading Document'}
        loading={loading}
        fab={(
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formInstitutionOpen: true })}
          >
            <AddIcon />
          </Fab>
        )}
        tabs={(
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          >
            <Tab
              label="Institutions"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        )}
      >
        {
          this.getStepContent()
        }
        <FormInstitution
          open={formInstitutionOpen}
          institution={selectedInstitution}
          close={() => {
            this.setState({
              formInstitutionOpen: false,
            });
            setTimeout(() => this.setState({
              selectedInstitution: undefined,
            }), 200);
          }
          }
          createInstitution={createInstitution}
          deleteInstitution={deleteInstitution}
          updateInstitution={updateInstitution}
          refresh={refresh}
        />
      </Page>
    )
  }
}
export default withStyles(styles)(WrapperInstitutionsPage);

