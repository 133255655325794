import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const programTags = `${REACT_APP_API_PREFIX}/${service}/programTags`;

export function getProgramTags(filters) {
  const url = `${programTags}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getProgramTagById(id) {
  const url = `${programTags}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateProgramTag(id, body) {
  const url = `${programTags}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProgramTag(id) {
  const url = `${programTags}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProgramTag(body) {
  const url = `${programTags}`;
  return {
    method: POST,
    url,
    body,
  };
}
