import { networkAction } from "helpers/network/networkAction";

import {
  GET_LECTURES,
  GET_LECTURE_BY_ID,
  GET_LECTURE_MEDIA,
  DELETE_LECTURE,
  UPDATE_LECTURE,
  CREATE_LECTURE,
  CREATE_LECTURE_MEDIA,
} from "constants/lectures";

import * as Api from "api";

export const getLectures = () => async (dispatch) =>
  networkAction(dispatch, GET_LECTURES, Api.getLectures, []);

export const getLectureById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_LECTURE_BY_ID, Api.getLectureById, [id]);

export const createLectureMedia = (lectureId, file) => async (dispatch) =>
  networkAction(dispatch, CREATE_LECTURE_MEDIA, Api.createLectureMedia, [
    lectureId,
    file,
  ]);

export const getLectureMedia = (id) => async (dispatch) =>
  networkAction(dispatch, GET_LECTURE_MEDIA, Api.getLectureMedia, [id]);

export const deleteLecture = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_LECTURE, Api.deleteLecture, [id]);

export const createLecture = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_LECTURE, Api.createLecture, [body]);

export const updateLecture = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_LECTURE, Api.updateLecture, [id, body]);
