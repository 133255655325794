// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import ProgramCount from "hooks/ProgramCount";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class LevelsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    levels: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedLevel, index) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedLevel, index);
    }
  }

  render() {
    const { levels } = this.props;

    return (
      <Table
        data={levels}
        meta={[
          {
            path: "name",
            title: "Name",
          },
          {
            path: "programCount",
            title: "Programs#",
            render: (t, d) => (
              <ProgramCount
                value={t}
                id={d.id}
                title={`${d.name} related programs`}
                type="levels"
              />
            ),
          },
        ]}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(LevelsList);
