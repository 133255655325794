import { Checkbox, Chip, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import BackupIcon from "@material-ui/icons/Backup";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Map from "@material-ui/icons/MapTwoTone";
import Place from "@material-ui/icons/Place";
// constants
import { ACADEMIES } from "constants/routes";
import GoogleMapReact from "google-map-react";
import ImageCrop from "hooks/ImageCrop";
import ImageResize from "image-resize";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const google = window.google;

class FormAcademy extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    history: PropTypes.object,

    close: PropTypes.func,
    open: PropTypes.bool,

    academy: PropTypes.object,

    createAcademy: PropTypes.func,
    updateAcademy: PropTypes.func,
    deleteAcademy: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { UploadCenter } = this.context;
    this.state = {
      loading: false,
      name: "",
      contact: "",
      website: "",
      phone: "",
      email: "",
      address: "",
      city: "",
      picture: "",
      state: "",
      zipcode: "",
      country: "",
      lat: "",
      lng: "",
      spiderWeb: false,
    };

    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.geocoder = new google.maps.Geocoder();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.academy);
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  async upload() {
    const { UploadCenter, NotificationCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          this.setState({ picture: r.payload });
        });
      }
    }
  }

  getLatLgn() {
    const { zipcode, city, state } = this.state;

    const address = `${this.state.address} ${zipcode} ${city} ${state}`;

    this.geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results.length) {
        const result = results[0];
        this.setState({
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        });
      }
    });
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (
          name === "state" ||
          name === "city" ||
          name === "zipcode" ||
          name === "address"
        ) {
          this.getLatLgn();
        }
      }
    );
  };

  init(academy) {
    this.setState(
      {
        loading: false,
        name: academy ? academy.name : "",
        website: academy ? academy.website : "",
        contact: academy ? academy.contact : "",
        phone: academy ? academy.phone : "",
        email: academy ? academy.email : "",
        address: academy ? academy.address : "",
        city: academy ? academy.city : "",
        state: academy ? academy.state : "",
        zipcode: academy ? academy.zipcode : "",
        country: academy ? academy.country : "",
        picture: academy ? academy.picture : "",
        spiderWeb: academy ? academy.spiderWeb : false,
        lat: "",
        lng: "",
      },
      this.getLatLgn.bind(this)
    );
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this academy, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteAcademy, close, refresh, academy, history } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteAcademy(academy.id);

    history.push(ACADEMIES);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Academy has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateAcademy, createAcademy, academy, refresh, close } =
      this.props;

    const {
      name,
      contact,
      phone,
      email,
      address,
      city,
      state,
      zipcode,
      picture,
      country,
      website,
      lat,
      lng,
      spiderWeb,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (academy) {
      resp = await updateAcademy(academy.id, {
        name,
        contact,
        phone,
        email,
        website,
        address,
        city,
        picture,
        state,
        zipcode,
        country,
        spiderWeb,
        latitude: `${lat}`,
        longitude: `${lng}`,
      });
    } else {
      resp = await createAcademy({
        name,
        contact,
        phone,
        email,
        website,
        address,
        city,
        state,
        picture,
        zipcode,
        country,
        spiderWeb,
        latitude: `${lat}`,
        longitude: `${lng}`,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: academy
          ? "Academy has been updated."
          : "Academy has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, academy, close } = this.props;

    const {
      name,
      contact,
      phone,
      email,
      website,
      address,
      city,
      state,
      zipcode,
      country,
      loading,
      lat,
      lng,
      picture,
      spiderWeb,
    } = this.state;

    const { UploadCenter } = this.context;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"md"}
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {academy ? `${academy.name}` : "Let's Create a New Academy"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {academy
                  ? "To update the academy, fill the form below :"
                  : "To create a new academy, fill the form below :"}
              </DialogContentText>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        className={classes.textField}
                        value={name}
                        onChange={this.handleChange("name")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="website"
                        label="Website"
                        className={classes.textField}
                        value={website}
                        onChange={this.handleChange("website")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Chip
                        label="Upload Logo"
                        size="small"
                        icon={<BackupIcon />}
                        onClick={UploadCenter.openFileDialog}
                      />
                    </Grid>
                    {picture ? (
                      <Grid item xs={12}>
                        <img src={picture} />
                        <br />
                        <Chip
                          label="Remove Logo"
                          size="small"
                          icon={<CloseIcon />}
                          onClick={() => {
                            this.setState({ picture: "" }, () =>
                              this.save("picture")
                            );
                          }}
                        />
                        <ImageCrop
                          value={picture}
                          onCrop={(url) => {
                            var imageResize = new ImageResize({
                              format: "png",
                              width: 100,
                              height: 100,
                            });
                            imageResize.play(url).then(async (resized) => {
                              fetch(resized)
                                .then((res) => res.blob())
                                .then(async (blob) => {
                                  const resp = await UploadCenter.upload(blob);
                                  if (resp.success) {
                                    this.setState(
                                      { picture: resp.payload },
                                      () => this.save("picture")
                                    );
                                  }
                                });
                            });
                          }}
                        />
                        <TextField
                          id="picture"
                          className={classes.textField}
                          value={picture}
                          onChange={this.handleChange("picture")}
                        />
                      </Grid>
                    ) : (
                      []
                    )}
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={spiderWeb}
                            onChange={this.handleCheckboxChange("spiderWeb")}
                          />
                        }
                        label="Show program as a Spider Web"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="contact"
                        label="Contact"
                        className={classes.textField}
                        value={contact}
                        onChange={this.handleChange("contact")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="email"
                        label="Email"
                        className={classes.textField}
                        value={email}
                        onChange={this.handleChange("email")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="phone"
                        label="Phone"
                        className={classes.textField}
                        value={phone}
                        onChange={this.handleChange("phone")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="address"
                        label="Address"
                        className={classes.textField}
                        value={address}
                        onChange={this.handleChange("address")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="zipcode"
                        label="Zipcode"
                        className={classes.textField}
                        value={zipcode}
                        onChange={this.handleChange("zipcode")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="city"
                        label="City"
                        className={classes.textField}
                        value={city}
                        onChange={this.handleChange("city")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="state"
                        label="State"
                        className={classes.textField}
                        value={state}
                        onChange={this.handleChange("state")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="country"
                        label="Country"
                        className={classes.textField}
                        value={country}
                        onChange={this.handleChange("country")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  {lat && lng ? (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyD67kHvhZC_mBkppkJIMAkZDu4c279J_As",
                      }}
                      center={{
                        lat,
                        lng,
                      }}
                      defaultZoom={14}
                    >
                      <Place
                        lat={lat}
                        lng={lng}
                        text={"Here"}
                        style={{
                          color: "#f44336",
                          marginTop: -45,
                          marginLeft: -17,
                        }}
                        fontSize="large"
                      />
                    </GoogleMapReact>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <Map
                        style={{ color: "rgba(155,155,155)", fontSize: 40 }}
                      />
                      <Typography
                        display="block"
                        color="textSecondary"
                        variant="caption"
                      >
                        Map will appear after the address is set.
                      </Typography>
                    </div>
                  )}
                  {lat}, {lng}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {academy && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {academy ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormAcademy));
