import { Avatar } from "@material-ui/core";
// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class AuthorsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    authors: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedAuthor) {
    const { onSelect } = this.props;
    this.setState({ selectedAuthor });
    if (onSelect) {
      onSelect(selectedAuthor);
    }
  }

  render() {
    const { authors } = this.props;

    return (
      <Table
        data={authors}
        meta={[
          {
            path: "picture",
            title: "",
            numeric: false,
            width: 50,
            render: (t) => <Avatar src={t} />,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
        ]}
        title={"Authors"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(AuthorsList);
