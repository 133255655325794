import * as Api from "api";
import {
  CREATE_MASTER_TAG,
  DELETE_MASTER_TAG,
  GET_MASTER_TAGS,
  GET_MASTER_TAG_BY_ID,
  GET_PROGRAMS_BY_MASTER_TAG,
  UPDATE_MASTER_TAG,
} from "constants/masterTags";
import { networkAction } from "helpers/network/networkAction";

export const getMasterTags = () => async (dispatch) =>
  networkAction(dispatch, GET_MASTER_TAGS, Api.getMasterTags, []);

export const getProgramsByMasterTag = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAMS_BY_MASTER_TAG,
    Api.getProgramsByMasterTag,
    [id]
  );

export const getMasterTagById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MASTER_TAG_BY_ID, Api.getMasterTagById, [id]);

export const deleteMasterTag = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MASTER_TAG, Api.deleteMasterTag, [id]);

export const createMasterTag = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MASTER_TAG, Api.createMasterTag, [body]);

export const updateMasterTag = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MASTER_TAG, Api.updateMasterTag, [id, body]);
