import { Grid } from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CIMFooterGradientOnly from "assets/img/CIMFooterGradientOnly.jpg";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Footer({ gradient = false }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{
        padding: "64px 0px",
        // background: "red",
        backgroundImage: gradient && `url(${CIMFooterGradientOnly})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography style={{ marginRight: 16 }} variant="h6">
          Brought to you by
        </Typography>
        <img
          src="https://storage.googleapis.com/college101-logos/cpfm/logo_main.png"
          height="60px"
          alt="cpfmLogo"
        />
      </Grid>
      <Grid item style={{ textAlign: "center" }}>
        <Typography variant="body2">www.collegeprepformusicians.com</Typography>
        <Typography variant="body2">@collegeprepformusicians</Typography>
      </Grid>
    </Grid>
  );
}
