// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
import Table from "components/TablePagination";
// custom
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class DownloadRequestsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    downloadRequests: PropTypes.array,
    getDownloadRequests: PropTypes.func,
    history: PropTypes.func,
    onSelect: PropTypes.func,
    books: PropTypes.array,
  };

  onSelect(selectedDownloadRequest) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedDownloadRequest);
    }
  }

  render() {
    const {
      downloadRequests,
      getDownloadRequests,
      history,
      books,
    } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getDownloadRequests}
        keyword="downloadRequests"
        metaDataEndpoint="/papyrus/downloadRequests/searchMetadata"
        exportEndpoint="/papyrus/downloadRequests/csv"
        metaDataOveride={{
          bookID: {
            type: "autocomplete",
            data: books,
            valueKey: "id",
            labelKey: "title",
          },
        }}
      >
        <Table
          data={downloadRequests}
          meta={[
            {
              path: "email",
              title: "Email",
              numeric: false,
            },
            {
              path: "inquiryDate",
              title: "Date",
              numeric: false,
              transform: (t) => moment(t).format("lll"),
            },
            {
              path: "bookID",
              title: "Document#",
              numeric: false,
              transform: (t) =>
                books && books.length
                  ? books.find((b) => b.id === t)?.title
                  : t,
            },
          ]}
          title={"Website Prospects"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(DownloadRequestsList);
