// material-ui
import { Fab } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormInquiry from "components/FormInquiry";
import InquiriesList from "components/InquiriesList";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperInquiriesPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    getInquiries: PropTypes.func,
    searchUsers: PropTypes.func,
    inquiries: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { inquiries, history, api, searchUsers } = this.props;

    switch (index) {
      case 0:
        return (
          <InquiriesList
            getInquiries={api.getInquiries}
            history={history}
            searchUsers={searchUsers}
            inquiries={inquiries}
            onSelect={(selectedInquiry) =>
              this.setState({ selectedInquiry, formInquiryOpen: true })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const { classes, history, loading, api, inquiries } = this.props;

    const { index, selectedInquiry } = this.state;

    return (
      <Page
        helmet="Inquiries"
        loading={loading}
        loadingMessage={"Fetching all Inquiries"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formInquiryOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormInquiry
          open={this.state.formInquiryOpen}
          close={() =>
            this.setState({
              formInquiryOpen: false,
              selectedInquiry: undefined,
            })
          }
          inquiry={selectedInquiry}
          age={this.state.selectedInquiry}
          createInquiry={api.createInquiry}
          updateInquiry={api.updateInquiry}
          deleteInquiry={api.deleteInquiry}
          refresh={api.refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperInquiriesPage);
