import { networkAction } from "helpers/network/networkAction";

import {
  GET_ASSESSMENTS,
  GET_ASSESSMENT_QUESTIONS,
  GET_ASSESSMENT_BY_ID,
  DELETE_ASSESSMENT,
  UPDATE_ASSESSMENT,
  CREATE_ASSESSMENT,
} from "constants/assessments";

import * as Api from "api";

export const getAssessments = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_ASSESSMENTS, Api.getAssessments, [filters]);

export const getAssessmentById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ASSESSMENT_BY_ID, Api.getAssessmentById, [id]);

export const getAssessmentQuestions = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_ASSESSMENT_QUESTIONS,
    Api.getAssessmentQuestions,
    [id]
  );

export const deleteAssessment = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ASSESSMENT, Api.deleteAssessment, [id]);

export const createAssessment = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ASSESSMENT, Api.createAssessment, [body]);

export const updateAssessment = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ASSESSMENT, Api.updateAssessment, [id, body]);
