export default (theme) => ({
  container: {
    padding: 5,
  },
  floatingActionButton: {
    position: "fixed",
    right: 20,
    bottom: 20,
  },
});
