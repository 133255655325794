import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "pmp";
const products = `${REACT_APP_API_PREFIX}/${service}/products`;

export function getProducts(filters) {
  const url = `${products}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getProductById(id) {
  const url = `${products}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateProduct(id, body) {
  const url = `${products}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProduct(id) {
  const url = `${products}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProduct(body) {
  const url = `${products}`;
  return {
    method: POST,
    url,
    body,
  };
}
