import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const assessments = `/${service}/assessments`;

export function getAssessments(filters) {
  const url = `${assessments}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getAssessmentById(id) {
  const url = `${assessments}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function getAssessmentQuestions(id) {
  const url = `${assessments}/${id}/questions`;
  return {
    method: GET,
    url,
  };
}

export function updateAssessment(id, body) {
  const url = `${assessments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAssessment(id) {
  const url = `${assessments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAssessment(body) {
  const url = `${assessments}`;
  return {
    method: POST,
    url,
    body,
  };
}
