import * as Api from "api";
import {
  CREATE_CAREER_AUTHOR,
  DELETE_CAREER_AUTHOR,
  GET_CAREER_AUTHORS,
  GET_CAREER_AUTHOR_BY_ID,
  UPDATE_CAREER_AUTHOR,
} from "constants/careerAuthors";
import { networkAction } from "helpers/network/networkAction";

export const getCareerAuthors = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_AUTHORS, Api.getCareerAuthors, [filters]);

export const getCareerAuthorById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_AUTHOR_BY_ID, Api.getCareerAuthorById, [
    id,
  ]);

export const deleteCareerAuthor = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CAREER_AUTHOR, Api.deleteCareerAuthor, [id]);

export const createCareerAuthor = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CAREER_AUTHOR, Api.createCareerAuthor, [body]);

export const updateCareerAuthor = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CAREER_AUTHOR, Api.updateCareerAuthor, [
    id,
    body,
  ]);
