import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  makeStyles,
  Slider,
  Typography,
} from "@material-ui/core";
import CropIcon from "@material-ui/icons/Crop";
import React from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ImageCrop({ value, onCrop, aspect = 1 }) {
  const [open, setOpen] = React.useState(false);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [rotation, setRotation] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [croppedImage, setCroppedImage] = React.useState(null);

  const classes = useStyles(styles);

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = React.useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        value,
        croppedAreaPixels,
        rotation
      );
      console.log("done", { croppedImage });
      setCroppedImage(croppedImage);
      onCrop(croppedImage);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = React.useCallback(() => {
    setCroppedImage(null);
    setOpen(false);
  }, []);

  return (
    <>
      <Chip
        label="Crop"
        icon={<CropIcon />}
        size="small"
        onClick={() => setOpen(true)}
      />
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
              width: "100%",
            }}
          >
            <Typography variant="h6">Crop the logo</Typography>
            <Button onClick={showCroppedImage} variant="outlined">
              Crop
            </Button>
          </div>
        </DialogTitle>
        <div style={{ width: 600, height: 600, position: "relative" }}>
          <Cropper
            image={value}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          {croppedImage}
        </div>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            classes={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
      </Dialog>
    </>
  );
}
