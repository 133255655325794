import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperProcessPage from "../component/WrapperProcessPage";

const mapStateToProps = (state) => ({
  programs: state.programs,
  academies: state.academies,
});

const mapDispatchToProps = (dispatch) => ({
  getPrograms: (...args) => dispatch(Actions.getPrograms(...args)),
  updateProgram: (...args) => dispatch(Actions.updateProgram(...args)),
  getAcademies: (...args) => dispatch(Actions.getAcademies(...args)),
  updateAcademy: (...args) => dispatch(Actions.updateAcademy(...args)),
});
class ProcessPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    getAcademies: PropTypes.func,
    getPrograms: PropTypes.func,
    updateProgram: PropTypes.func,
    updateAcademy: PropTypes.func,

    academies: PropTypes.array,
    programs: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: true,
    };

    this.refresh();
  }

  async refresh() {
    const { getPrograms, getAcademies } = this.props;
    await Promise.all([getAcademies(), getPrograms()]);

    this.setState({
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      academies,
      programs,
      updateProgram,
      updateAcademy,
    } = this.props;

    const { loading } = this.state;

    return (
      <WrapperProcessPage
        history={history}
        location={location}
        loading={loading}
        academies={academies.reverse()}
        updateProgram={updateProgram}
        updateAcademy={updateAcademy}
        programs={programs}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProcessPage);
