import {
  GET_AUTHORS,
} from 'constants/authors';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_AUTHORS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
