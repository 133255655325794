import {
  AppBar,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DeleteForEver from "@material-ui/icons/DeleteForever";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { copyTextToClipboard } from "helpers";
import Career from "hooks/Career";
import CareerTitle from "hooks/CareerTitle";
import CheckboxAutocomplete from "hooks/CheckboxAutocomplete";
// custom
import RichText from "hooks/RichText";
import PropTypes from "prop-types";
import React from "react";
import { Bar, Container, Section } from "react-simple-resizer";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const CareerSliderItem = ({ slideItem, api, getCareerSliderItems }) => {
  const [type, setType] = React.useState(slideItem.type || "image");
  const [src, setScr] = React.useState(slideItem.src || "");
  const [order, setOrder] = React.useState(slideItem.order || "");

  const update = () => {
    api
      .updateCareerSliderItem(slideItem.id, {
        type,
        src,
        order: Number(order),
      })
      .then((r) => {
        getCareerSliderItems({ id: slideItem.careerID });
      });
  };

  return (
    <div
      style={{
        background: "rgba(155,155,155,0.1)",
        border: "solid 1px rgba(155,155,155,0.3)",
        padding: 10,
        borderRadius: 4,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            size="small"
            onBlur={update}
            label="Type"
            select
            fullWidth
            variant="outlined"
            style={{ background: "white" }}
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="image">Image</MenuItem>
            <MenuItem value="video">Video</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            onBlur={update}
            label="URL"
            fullWidth
            variant="outlined"
            style={{ background: "white" }}
            value={src}
            multiline
            rowsMax={4}
            onChange={(e) => setScr(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Order"
            onBlur={update}
            fullWidth
            variant="outlined"
            style={{ background: "white" }}
            value={order}
            onChange={(e) => setOrder(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Chip
            icon={<DeleteForEver />}
            label="Delete Media"
            size="small"
            variant="outlined"
            onClick={() => {
              api.deleteCareerSliderItem(slideItem.id).then((r) => {
                getCareerSliderItems({ id: slideItem.careerID });
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const CareerMedium = ({ careerMedium, api, getCareerMedia }) => {
  const [title, setTitle] = React.useState(careerMedium.title || "");
  const [mediaURL, setMediaURL] = React.useState(careerMedium.mediaURL || "");
  const [description, setDescription] = React.useState(
    careerMedium.description || ""
  );

  const update = () => {
    api
      .updateCareerMedium(careerMedium.id, {
        title,
        mediaURL,
        description,
      })
      .then((r) => {
        getCareerMedia({ id: careerMedium.careerID });
      });
  };

  return (
    <div
      style={{
        background: "rgba(155,155,155,0.1)",
        border: "solid 1px rgba(155,155,155,0.3)",
        padding: 10,
        borderRadius: 4,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            size="small"
            onBlur={update}
            label="Title"
            fullWidth
            variant="outlined"
            style={{ background: "white" }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            onBlur={update}
            label="Description"
            fullWidth
            variant="outlined"
            style={{ background: "white" }}
            value={description}
            multiline
            rowsMax={4}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Media URL"
            onBlur={update}
            fullWidth
            variant="outlined"
            style={{ background: "white" }}
            value={mediaURL}
            onChange={(e) => setMediaURL(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Chip
            icon={<DeleteForEver />}
            label="Delete Media"
            size="small"
            variant="outlined"
            onClick={() => {
              api.deleteCareerMedium(careerMedium.id).then((r) => {
                getCareerMedia({ id: careerMedium.careerID });
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

class FormCareer extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    history: PropTypes.object,
    close: PropTypes.func,
    open: PropTypes.bool,
    career: PropTypes.object,
    quizTags: PropTypes.array,
    quizCategories: PropTypes.array,
    api: PropTypes.object,
    refresh: PropTypes.func,
    skillTags: PropTypes.array,
    degreeLevels: PropTypes.array,
    degreeRequirements: PropTypes.array,
    subCategories: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  async addCareerSubCategory(v) {
    const { career, api } = this.props;
    const r = await api.createCareerSubCategory({
      careerID: career.id,
      subCategoryID: v.id,
    });
    if (r.success) {
      this.getCareerSubCategories(career);
    }
  }

  async deleteCareerSubCategory(csc) {
    const { api, career } = this.props;
    const r = await api.deleteCareerSubCategory(csc.id);
    if (r.success) {
      this.getCareerSubCategories(career);
    }
  }

  async addCareerDegreeLevel(cdl) {
    const { career, api } = this.props;
    const r = await api.createCareerDegreeLevel({
      careerID: career.id,
      degreeLevelID: cdl.id,
    });
    if (r.success) {
      this.getCareerDegreeLevels(career);
    }
  }

  async deleteCareerDegreeLevel(cdl) {
    const { api, career } = this.props;
    const r = await api.deleteCareerDegreeLevel(cdl.id);
    if (r.success) {
      this.getCareerDegreeLevels(career);
    }
  }

  async getCareerDegreeLevels(career) {
    if (!career) return;
    const { api } = this.props;
    const r = await api.getCareerDegreeLevels([
      {
        name: "career_degree_levels.careerID",
        comparison: "eq",
        value: career.id,
      },
    ]);
    if (r.payload) {
      const careerDegreeLevels = r.payload;
      this.setState({
        careerDegreeLevels,
      });
    }
  }

  async addCareerDegreeRequirement(cdr) {
    const { career, api } = this.props;
    const r = await api.createCareerDegreeRequirement({
      careerID: career.id,
      degreeRequirementID: cdr.id,
    });
    if (r.success) {
      this.getCareerDegreeRequirements(career);
    }
  }

  async deleteCareerDegreeRequirement(cdr) {
    const { api, career } = this.props;
    const r = await api.deleteCareerDegreeRequirement(cdr.id);
    if (r.success) {
      this.getCareerDegreeRequirements(career);
    }
  }

  async getCareerDegreeRequirements(career) {
    if (!career) return;
    const { api } = this.props;
    const r = await api.getCareerDegreeRequirements([
      {
        name: "career_degree_requirements.careerID",
        comparison: "eq",
        value: career.id,
      },
    ]);
    if (r.payload) {
      const careerDegreeRequirements = r.payload;
      this.setState({
        careerDegreeRequirements,
      });
    }
  }

  async getCareerSubCategories(career) {
    if (!career) return;
    const { api, subCategories } = this.props;
    const r = await api.getCareerSubCategories([
      {
        name: "careerID",
        comparison: "eq",
        value: career.id,
      },
    ]);
    if (r.payload) {
      const careerSubCategories = r.payload;

      for (const key in careerSubCategories) {
        if (careerSubCategories.hasOwnProperty(key)) {
          const element = careerSubCategories[key];
          element.subCategory = subCategories.find(
            (sc) => sc.id === element.subCategoryID
          );
        }
      }
      this.setState({
        careerSubCategories,
      });
    }
  }

  async getCareerMedia(career) {
    if (!career) return;
    const { api } = this.props;
    const r = await api.getCareerMedia([
      {
        name: "careerID",
        comparison: "eq",
        value: career.id,
      },
    ]);
    if (r.success) {
      this.setState({
        careerMedia: r.payload,
      });
    }
  }

  async getCareerSliderItems(career) {
    if (!career) return;
    const { api } = this.props;
    const r = await api.getCareerSliderItems([
      {
        name: "career_slider_items.careerID",
        comparison: "eq",
        value: career.id,
      },
    ]);
    if (r.success) {
      this.setState({
        careerSliderItems: r.payload,
      });
    }
  }

  async getCareerTitles(career) {
    if (!career) return;
    const { api } = this.props;
    const r = await api.getCareerTitles([
      {
        name: "career_titles.careerID",
        comparison: "eq",
        value: career.id,
      },
    ]);
    if (r.success) {
      this.setState({
        titles: r.payload,
      });
    }
  }

  async getCareerQuizTags(career) {
    if (!career) return;
    const { api } = this.props;
    const r = await api.getCareerQuizTags([
      {
        name: "career_quiz_tags.careerID",
        comparison: "eq",
        value: career.id,
      },
    ]);
    if (r.success) {
      this.setState({
        careerQuizTags: r.payload,
      });
    }
  }

  async getCareerCategories(career) {
    if (!career) return;
    const { api } = this.props;
    const r = await api.getCareerCategories(career.id);
    if (r.success) {
      this.setState({
        careerCategories: r.payload,
      });
    }
  }

  async getCareerAuthors(career) {
    if (!career) return;
    const { api } = this.props;
    const r = await api.getCareerAuthors([
      {
        name: "career_authors.careerID",
        comparison: "eq",
        value: career.id,
      },
    ]);
    if (r.success) {
      this.setState({
        careerAuthors: r.payload,
      });
    }
  }

  async addCareerQuizTag(v) {
    const { career, api } = this.props;
    const r = await api.createCareerQuizTag({
      careerID: career.id,
      quizTagID: v.id,
    });
    if (r.success) {
      this.getCareerQuizTags(career);
    }
  }

  async deleteCareerQuizTag(cqc) {
    const { api, career } = this.props;
    const r = await api.deleteCareerQuizTag(cqc.id);
    if (r.success) {
      this.getCareerQuizTags(career);
    }
  }

  async deleteCareerAuthor(cqc) {
    const { api, career } = this.props;
    const r = await api.deleteCareerAuthor(cqc.id);
    if (r.success) {
      this.getCareerAuthors(career);
    }
  }

  async addCareerAuthor(v) {
    const { career, api } = this.props;
    const r = await api.createCareerAuthor({
      careerID: career.id,
      authorID: v.id,
    });
    if (r.success) {
      this.getCareerAuthors(career);
    }
  }

  constructor(...args) {
    super(...args);
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.state = {
      loading: false,
      categoriesLoading: false,
      quizTagsLoading: false,
      skillTagsLoading: false,
      apprenticeship: false,
      intership: false,
      partTime: false,
      fullTime: false,
      title: "",
      titles: [],
      cover: "",
      description: "",
      additionalInfo: "",
      videoURL: "",
      public: "",
      icon: "",
      comingSoon: "",
      degreeLevel: "",
      degreeRequirement: "",
      order: "",
      careerCategories: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps);
      if (nextProps.career) setTimeout(this.getCareerSkillTags.bind(this), 500);
    }
  }

  async upload() {
    const { UploadCenter, NotificationCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          NotificationCenter.stack({
            title: "Image url copied in your clipboard",
            info: true,
            timestamp: new Date().getTime(),
          });
          copyTextToClipboard(r.payload);
          setTimeout(NotificationCenter.hide, 1000);
        });
      }
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  init(nextProps) {
    const { career } = nextProps;

    this.setState({
      loading: false,
      title: career ? career.title : "",
      titles: career ? career.titles : [],
      cover: career ? career.cover : "",
      partTime: career ? career.partTime : false,
      fullTime: career ? career.fullTime : false,
      apprenticeship: career ? career.apprenticeship : false,
      intership: career ? career.intership : false,
      description: career ? career.description : "",
      additionalInfo: career ? career.additionalInfo : "",
      videoURL: career ? career.videoURL : "",
      comingSoon: career ? career.comingSoon : false,
      public: career ? career.public : false,
      order: career ? career.order : "",
      careerCategories: career ? career.categories : [],
      careerSkillTags: career ? career.skills : [],
      degreeLevel: career ? career.degreeLevel : "",
      degreeRequirement: career ? career.degreeRequirement : "",
      oneSentenceDescription: career ? career.oneSentenceDescription : "",
      icon: career ? career.icon : "",
      careerMedia: [],
      careerSliderItems: [],
    });

    this.getCareerSubCategories(career);
    this.getCareerMedia(career);
    this.getCareerSliderItems(career);
    this.getCareerQuizTags(career);
    this.getCareerCategories(career);
    this.getCareerAuthors(career);
    this.getCareerDegreeLevels(career);
    this.getCareerDegreeRequirements(career);
  }

  handleTextChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSwitchChange = (name) => (event) => {
    const value = event.target.checked;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: "If you delete this career, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api, close, refresh, career } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await api.deleteCareer(career.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Career has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async addCategory(category) {
    const { career, api, refresh } = this.props;
    await api.addCareerCategory({
      categoryID: category.id,
      careerID: career.id,
    });
    this.getCareerCategories(career);
    refresh();
  }

  async deleteCategory(category) {
    const { api, career, refresh } = this.props;
    await api.deleteCareerCategory(category.relationID);
    this.getCareerCategories(career);
    refresh();
  }

  async addCareerSkill(skillTag) {
    const { career, api, refresh } = this.props;
    await api.createCareerSkill({
      careerID: career.id,
      skillTagID: skillTag.id,
    });
    this.getCareerSkillTags();
    refresh();
  }

  async deleteCareerSkill(skillTag) {
    const { api, career, refresh } = this.props;
    await api.deleteCareerSkill(skillTag.id);
    this.getCareerSkillTags();
    refresh();
  }

  async getCareerSkillTags() {
    const { api, career, skillTags } = this.props;
    const { getCareerSkills } = api;

    const r = await getCareerSkills([
      { name: "careerID", comparison: "eq", value: career.id },
    ]);
    let careerSkills = [];

    for (const key in r.payload) {
      if (r.payload.hasOwnProperty(key)) {
        const element = r.payload[key];
        element.skillTag = skillTags.find((s) => s.id === element.skillTagID);
        careerSkills.push(element);
      }
    }

    this.setState({ careerSkills });
  }

  async save() {
    const { createCareer, career, refresh, close, api, quizTags } = this.props;

    const {
      title,
      description,
      videoURL,
      comingSoon,
      order,
      cover,
      partTime,
      fullTime,
      degreeLevel,
      degreeRequirement,
      apprenticeship,
      additionalInfo,
      intership,
      icon,
      oneSentenceDescription,
      careerSubCategories,
      careerDegreeLevels,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (career) {
      resp = await api.updateCareer(career.id, {
        title,
        order: order !== "" ? Number(order) : undefined,
        description,
        cover,
        public: this.state.public,
        videoURL,
        partTime,
        oneSentenceDescription,
        fullTime,
        comingSoon,
        degreeLevel: degreeLevel ? degreeLevel : undefined,
        degreeRequirement: degreeRequirement ? degreeRequirement : undefined,
        apprenticeship,
        additionalInfo,
        intership,
        icon,
      });
    } else {
      resp = await api.createCareer({
        title,
        description,
        cover:
          "https://storage.googleapis.com/college101-images/caeerCover.jpg",
        public: this.state.public,
        videoURL,
        partTime,
        oneSentenceDescription,
        fullTime,
        icon,
        comingSoon,
        degreeLevel: degreeLevel ? degreeLevel : undefined,
        degreeRequirement: degreeRequirement ? degreeRequirement : undefined,
        apprenticeship,
        additionalInfo,
        intership,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: career ? "Career has been updated." : "Career has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async addCareerMedium() {
    const { api, career } = this.props;
    await api.createCareerMedium({
      careerID: career.id,
    });
    this.getCareerMedia(career);
  }

  async addCareerSliderItem() {
    const { api, career } = this.props;
    await api.createCareerSliderItem({
      careerID: career.id,
    });
    this.getCareerSliderItems(career);
  }

  async addCareerTitle() {
    const { api, career } = this.props;
    await api.createCareerTitle({
      careerID: career.id,
      title: "",
    });
    this.getCareerTitles(career);
  }

  render() {
    const {
      categories,
      classes,
      fullScreen,
      open,
      career,
      close,
      skillTags,
      degreeLevels,
      degreeRequirements,
      api,
      subCategories,
      quizTags,
      quizCategories,
      authors,
    } = this.props;

    const {
      title,
      titles,
      careerAuthors,
      description,
      loading,
      categoriesLoading,
      quizTagsLoading,
      careerCategories,
      skillTagsLoading,
      careerSkills,
      partTime,
      fullTime,
      degreeLevel,
      degreeRequirement,
      apprenticeship,
      intership,
      additionalInfo,
      careerSubCategories,
      careerMedia,
      careerSliderItems,
      careerQuizTags,
      icon,
      oneSentenceDescription,
      careerDegreeLevels,
      careerDegreeRequirements,
    } = this.state;

    const options = [...skillTags.filter((st) => st.value)];

    if (this.state.skillTagText) {
      options.push({
        value: `+ ${this.state.skillTagText}`,
      });
    }

    const sortedQuizTags = quizTags.sort((a, b) => {
      if (b.categoryID < a.categoryID) return 1;
      if (b.categoryID > a.categoryID) return -1;
      return 0;
    });

    sortedQuizTags.forEach((e) => {
      e.categoryName = quizCategories.find(
        (qc) => qc.id === e.categoryID
      )?.title;
      e.careerQuizTag = careerQuizTags?.find((cqt) => cqt.quizTagID === e.id);
    });

    const sortedAuthors = authors.sort((a, b) => {
      if (b.name < a.name) return 1;
      if (b.name > a.name) return -1;
      return 0;
    });

    sortedAuthors.forEach((e) => {
      e.careerAuthor = careerAuthors?.find((cqt) => cqt.authorID === e.id);
    });

    const sortedSubCategories = subCategories.sort((a, b) => {
      if (b.title < a.title) return 1;
      if (b.title > a.title) return -1;
      return 0;
    });

    sortedSubCategories.forEach((e) => {
      e.careerSubCategory = careerSubCategories?.find(
        (sc) => sc.subCategoryID === e.id
      );
    });

    const sortedCategories = categories.sort((a, b) => {
      if (b.title < a.title) return 1;
      if (b.title > a.title) return -1;
      return 0;
    });

    sortedCategories.forEach((e) => {
      e.careerCategory = careerCategories?.find((c) => c.id === e.id);
    });

    const sortedDegreeLevels = degreeLevels.sort((a, b) => {
      if (b.pos < a.pos) return 1;
      if (b.pos > a.pos) return -1;
      return 0;
    });

    sortedDegreeLevels.forEach((e) => {
      e.careerDegreeLevel = careerDegreeLevels?.find(
        (c) => c.degreeLevelID === e.id
      );
    });

    const sortedDegreeRequirements = degreeRequirements.sort((a, b) => {
      if (b.value < a.value) return 1;
      if (b.value > a.value) return -1;
      return 0;
    });

    sortedDegreeRequirements.forEach((e) => {
      e.careerDegreeRequirement = careerDegreeRequirements?.find(
        (c) => c.degreeRequirementID === e.id
      );
    });

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        fullScreen
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <AppBar position="absolute" style={{ background: "#424242" }}>
              <Toolbar
                style={{ display: "flex", justifyContent: "space-between" }}
                variant="dense"
              >
                <div>
                  <Button onClick={close} color="inherit" variant="outlined">
                    Cancel
                  </Button>
                  {career && career.id ? (
                    <Button
                      onClick={this.delete.bind(this)}
                      color="inherit"
                      variant="outlined"
                      style={{ color: "#f44336", marginLeft: 8 }}
                    >
                      Delete Career
                    </Button>
                  ) : (
                    []
                  )}
                </div>
                <div />
                <Button
                  autoFocus
                  onClick={this.save.bind(this)}
                  color="inherit"
                  variant="contained"
                  style={{ background: "white", color: "#ff9800" }}
                >
                  Done
                </Button>
              </Toolbar>
            </AppBar>
            <Container
              style={{
                marginTop: 48,
                height: "calc(100vh - 48px)",
                position: "relative",
              }}
            >
              <Section style={{ height: "100%", overflow: "scroll" }}>
                <Career
                  {...this.state}
                  titles={titles}
                  careerQuizTags={sortedQuizTags.filter(
                    (f) => f.careerQuizTag != undefined
                  )}
                  careerDegreeRequirements={sortedDegreeRequirements.filter(
                    (f) => f.careerDegreeRequirement != undefined
                  )}
                  careerDegreeLevels={sortedDegreeLevels.filter(
                    (f) => f.careerDegreeLevel != undefined
                  )}
                />
              </Section>
              <Bar
                size={8}
                style={{
                  background: "rgba(155,155,155,0.3)",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DragHandleIcon
                  style={{
                    color: "#9e9e9e",
                    position: "absolute",
                    fontSize: 16,
                    marginLeft: -4,
                    transform: "rotate(90deg)",
                  }}
                />
              </Bar>
              <Section
                style={{
                  overflow: "scroll",
                  background: "rgba(155,155,155,0.1)",
                }}
              >
                <Grid container style={{ padding: 16 }}>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      style={{ cursor: "pointer " }}
                      onClick={() =>
                        this.setState((prevState) => ({
                          section1: !prevState.section1,
                        }))
                      }
                    >
                      Title & Description{" "}
                      <IconButton color="primary" size="small">
                        {this.state.section1 ? (
                          <Icon
                            class="fas fa-chevron-down"
                            fontSize="inherit"
                          ></Icon>
                        ) : (
                          <Icon
                            class="fas fa-chevron-up"
                            fontSize="inherit"
                          ></Icon>
                        )}
                      </IconButton>
                    </Typography>
                    <Collapse
                      in={this.state.section1}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            size="small"
                            id="title"
                            label="Title"
                            className={classes.textField}
                            value={title}
                            onChange={this.handleTextChange("title")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            size="small"
                            id="icon"
                            label="Icon"
                            placeholder="Drag and drop an image and paste the URL here"
                            className={classes.textField}
                            value={icon}
                            onChange={this.handleTextChange("icon")}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Alternative Titles:
                          </Typography>
                          <Chip
                            label="Add Alternative Title"
                            variant="outlined"
                            size="small"
                            onClick={this.addCareerTitle.bind(this)}
                          />
                          <Grid container spacing={1}>
                            {titles &&
                              titles.map((t) => (
                                <Grid item key={t.id}>
                                  <CareerTitle
                                    title={t.title}
                                    api={api}
                                    id={t.id}
                                    getCareerTitles={() =>
                                      this.getCareerTitles(career)
                                    }
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            size="small"
                            id="icon"
                            label="One Sentence Description"
                            placeholder="One Sentence Description"
                            className={classes.textField}
                            value={oneSentenceDescription}
                            onChange={this.handleTextChange(
                              "oneSentenceDescription"
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="caption" color="textSecondary">
                            Description
                          </Typography>
                          <RichText
                            value={description}
                            onSave={(v) => this.setState({ description: v })}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="caption" color="textSecondary">
                            Additional Info
                          </Typography>
                          <RichText
                            value={additionalInfo}
                            onSave={(v) => this.setState({ additionalInfo: v })}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      style={{ cursor: "pointer " }}
                      onClick={() =>
                        this.setState((prevState) => ({
                          section5: !prevState.section5,
                        }))
                      }
                    >
                      Slider{" "}
                      <IconButton color="primary" size="small">
                        {this.state.section5 ? (
                          <Icon
                            class="fas fa-chevron-down"
                            fontSize="inherit"
                          ></Icon>
                        ) : (
                          <Icon
                            class="fas fa-chevron-up"
                            fontSize="inherit"
                          ></Icon>
                        )}
                      </IconButton>
                    </Typography>
                    <Collapse
                      in={this.state.section5}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container spacing={2}>
                        {careerSliderItems &&
                          careerSliderItems.map((csi) => (
                            <Grid item xs={12}>
                              <CareerSliderItem
                                slideItem={csi}
                                api={api}
                                getCareerSliderItems={this.getCareerSliderItems.bind(
                                  this
                                )}
                              />
                            </Grid>
                          ))}
                        <Grid item>
                          <Chip
                            label="Add Slide Item"
                            variant="outlined"
                            size="small"
                            onClick={this.addCareerSliderItem.bind(this)}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      style={{ cursor: "pointer " }}
                      onClick={() =>
                        this.setState((prevState) => ({
                          section2: !prevState.section2,
                        }))
                      }
                    >
                      Media{" "}
                      <IconButton color="primary" size="small">
                        {this.state.section2 ? (
                          <Icon
                            class="fas fa-chevron-down"
                            fontSize="inherit"
                          ></Icon>
                        ) : (
                          <Icon
                            class="fas fa-chevron-up"
                            fontSize="inherit"
                          ></Icon>
                        )}
                      </IconButton>
                    </Typography>
                    <Collapse
                      in={this.state.section2}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container spacing={2}>
                        {careerMedia &&
                          careerMedia.map((cm) => (
                            <Grid item xs={12}>
                              <CareerMedium
                                careerMedium={cm}
                                api={api}
                                getCareerMedia={this.getCareerMedia.bind(this)}
                              />
                            </Grid>
                          ))}
                        <Grid item>
                          <Chip
                            label="Add Media"
                            variant="outlined"
                            size="small"
                            onClick={this.addCareerMedium.bind(this)}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      style={{ cursor: "pointer " }}
                      onClick={() =>
                        this.setState((prevState) => ({
                          section3: !prevState.section3,
                        }))
                      }
                    >
                      Categories & Tags{" "}
                      <IconButton color="primary" size="small">
                        {this.state.section3 ? (
                          <Icon
                            class="fas fa-chevron-down"
                            fontSize="inherit"
                          ></Icon>
                        ) : (
                          <Icon
                            class="fas fa-chevron-up"
                            fontSize="inherit"
                          ></Icon>
                        )}
                      </IconButton>
                    </Typography>
                    <Collapse
                      in={this.state.section3}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container spacing={2}>
                        {career ? (
                          <Grid item xs={12}>
                            <CheckboxAutocomplete
                              options={sortedCategories}
                              value={sortedCategories.filter(
                                (f) => f.careerCategory != undefined
                              )}
                              label="Categories"
                              optionKey="title"
                              onDelete={(e) => {
                                this.deleteCategory(e.careerCategory);
                              }}
                              onAdd={(e) => {
                                this.addCategory(e);
                              }}
                            />
                          </Grid>
                        ) : (
                          []
                        )}
                        {career ? (
                          <Grid item xs={12}>
                            <CheckboxAutocomplete
                              options={sortedSubCategories}
                              value={sortedSubCategories.filter(
                                (f) => f.careerSubCategory != undefined
                              )}
                              label="Sub Categories"
                              optionKey="title"
                              onDelete={(e) => {
                                this.deleteCareerSubCategory(
                                  e.careerSubCategory
                                );
                              }}
                              onAdd={(e) => {
                                this.addCareerSubCategory(e);
                              }}
                            />
                          </Grid>
                        ) : (
                          []
                        )}
                        {career ? (
                          <Grid item xs={12}>
                            <CheckboxAutocomplete
                              options={sortedQuizTags}
                              value={sortedQuizTags.filter(
                                (f) => f.careerQuizTag != undefined
                              )}
                              label="Quiz Tags"
                              optionKey="name"
                              groupBy={(option) => option.categoryName}
                              onDelete={(e) => {
                                this.deleteCareerQuizTag(e.careerQuizTag);
                              }}
                              onAdd={(e) => {
                                this.addCareerQuizTag(e);
                              }}
                            />
                          </Grid>
                        ) : (
                          []
                        )}
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      style={{ cursor: "pointer " }}
                      onClick={() =>
                        this.setState((prevState) => ({
                          section4: !prevState.section4,
                        }))
                      }
                    >
                      Education & Requirements{" "}
                      <IconButton color="primary" size="small">
                        {this.state.section4 ? (
                          <Icon
                            class="fas fa-chevron-down"
                            fontSize="inherit"
                          ></Icon>
                        ) : (
                          <Icon
                            class="fas fa-chevron-up"
                            fontSize="inherit"
                          ></Icon>
                        )}
                      </IconButton>
                    </Typography>
                    <Collapse
                      in={this.state.section4}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CheckboxAutocomplete
                            options={sortedDegreeLevels}
                            value={sortedDegreeLevels.filter(
                              (f) => f.careerDegreeLevel != undefined
                            )}
                            label="Degree Levels"
                            optionKey="value"
                            onDelete={(e) => {
                              this.deleteCareerDegreeLevel(e.careerDegreeLevel);
                            }}
                            onAdd={(e) => {
                              this.addCareerDegreeLevel(e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CheckboxAutocomplete
                            options={sortedDegreeRequirements}
                            value={sortedDegreeRequirements.filter(
                              (f) => f.careerDegreeRequirement != undefined
                            )}
                            label="Education Requirements"
                            optionKey="value"
                            onDelete={(e) => {
                              this.deleteCareerDegreeRequirement(
                                e.careerDegreeRequirement
                              );
                            }}
                            onAdd={(e) => {
                              this.addCareerDegreeRequirement(e);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={apprenticeship}
                                onChange={this.handleSwitchChange(
                                  "apprenticeship"
                                )}
                                name="apprenticeship"
                                color="primary"
                              />
                            }
                            label="Apprenticeships Recommended"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={intership}
                                onChange={this.handleSwitchChange("intership")}
                                name="intership"
                                color="primary"
                              />
                            }
                            label="Internships Recommended"
                          />
                        </Grid>
                        {/* <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={partTime}
                                onChange={this.handleSwitchChange("partTime")}
                                name="partTime"
                                color="primary"
                              />
                            }
                            label="Part Time"
                          />
                        </Grid> */}
                        {/* <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={fullTime}
                                onChange={this.handleSwitchChange("fullTime")}
                                name="fullTime"
                                color="primary"
                              />
                            }
                            label="Full Time"
                          />
                        </Grid> */}
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      style={{ cursor: "pointer " }}
                      onClick={() =>
                        this.setState((prevState) => ({
                          section6: !prevState.section6,
                        }))
                      }
                    >
                      Authors
                      <IconButton color="primary" size="small">
                        {this.state.section6 ? (
                          <Icon
                            class="fas fa-chevron-down"
                            fontSize="inherit"
                          ></Icon>
                        ) : (
                          <Icon
                            class="fas fa-chevron-up"
                            fontSize="inherit"
                          ></Icon>
                        )}
                      </IconButton>
                    </Typography>
                    <Collapse
                      in={this.state.section6}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CheckboxAutocomplete
                            options={sortedAuthors}
                            value={sortedAuthors.filter(
                              (f) => f.careerAuthor != undefined
                            )}
                            label="Authors"
                            optionKey="name"
                            onDelete={(e) => {
                              this.deleteCareerAuthor(e.careerAuthor);
                            }}
                            onAdd={(e) => {
                              this.addCareerAuthor(e);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </Section>
            </Container>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormCareer));
