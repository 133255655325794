import { networkAction } from "helpers/network/networkAction";

import {
  GET_ACADEMIES,
  GET_ACADEMY_BY_ID,
  DELETE_ACADEMY,
  UPDATE_ACADEMY,
  CREATE_ACADEMY,
  GET_ACADEMY_PROGRAMS,
} from "constants/academies";

import * as Api from "api";

export const getAcademies = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_ACADEMIES, Api.getAcademies, [filters]);

export const getAcademyById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ACADEMY_BY_ID, Api.getAcademyById, [id]);

export const getAcademyPrograms = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ACADEMY_PROGRAMS, Api.getAcademyPrograms, [id]);

export const deleteAcademy = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ACADEMY, Api.deleteAcademy, [id]);

export const createAcademy = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ACADEMY, Api.createAcademy, [body]);

export const updateAcademy = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ACADEMY, Api.updateAcademy, [id, body]);
