import { CPFM } from "constants/routes";
import CpfmPage from "./container/CpfmPage";
import { checkAccess } from "helpers/auth";

export default function getPublicRoute(userAuth) {
  const requireAuth = {};
  return {
    path: CPFM,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: false,
    withAppBar: false,
    component: CpfmPage,
  };
}
