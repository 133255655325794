import moment from "moment";

export function durationFormatter(minutes) {
  if (minutes <= 60) {
    return `${minutes}mn`;
  }

  let mod = minutes % 60;

  return `${Math.floor(minutes / 60)}h ${mod ? `${mod}mn` : ""}`;
}

export function isExpired(date) {
  let expired = false;
  if (date && moment().diff(moment(date), "days") >= 0) {
    expired = true;
  }
  return expired;
}
