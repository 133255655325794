// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperEventTypePage from "../component/WrapperEventTypePage";

const mapStateToProps = (state) => ({
  eventTypes: state.eventTypes,
  events: state.events,
  coaches: state.coaches,
  timezones: state.timezones,
  calendyStudents: state.calendyStudents,
  timeAssets: state.timeAssets,
});

const mapDispatchToProps = (dispatch) => ({
  getEventTypes: () => dispatch(Actions.getEventTypes()),
  getEventTypeById: (...args) => dispatch(Actions.getEventTypeById(...args)),
  createEventType: (...args) => dispatch(Actions.createEventType(...args)),
  deleteEventType: (...args) => dispatch(Actions.deleteEventType(...args)),
  updateEventType: (...args) => dispatch(Actions.updateEventType(...args)),
  getEvents: (...args) => dispatch(Actions.getEvents(...args)),
  updateEvent: (...args) => dispatch(Actions.updateEvent(...args)),
  deleteEvent: (...args) => dispatch(Actions.deleteEvent(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
  createCoach: (...args) => dispatch(Actions.createCoach(...args)),
  getCoaches: (...args) => dispatch(Actions.getCoaches(...args)),
  getTimeAssets: (...args) => dispatch(Actions.getTimeAssets(...args)),
  getTimezones: (...args) => dispatch(Actions.getTimezones(...args)),
  sendStudentEmail: (...args) => dispatch(Actions.sendStudentEmail(...args)),
  getCalendyStudents: (...args) =>
    dispatch(Actions.getCalendyStudents(...args)),
});
class EventTypePage extends Component {
  static propTypes = {
    // session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    getTimeAssets: PropTypes.func,
    timeAssets: PropTypes.array,

    eventTypes: PropTypes.array,
    getEventTypes: PropTypes.func,
    getEventTypeById: PropTypes.func,
    createEventType: PropTypes.func,
    deleteEventType: PropTypes.func,
    updateEventType: PropTypes.func,

    getEvents: PropTypes.func,
    sendStudentEmail: PropTypes.func,
    getTimezones: PropTypes.func,
    timezones: PropTypes.array,
    updateEvent: PropTypes.func,
    deleteEvent: PropTypes.func,
    events: PropTypes.array,

    getCoaches: PropTypes.func,
    createCoach: PropTypes.func,
    coaches: PropTypes.array,
    calendyStudents: PropTypes.array,

    getCalendyStudents: PropTypes.func,
    searchUsers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, eventTypes, getTimezones } = this.props;
    getTimezones();
    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: eventTypes.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const {
      getEventTypes,
      getEvents,
      getCoaches,
      getCalendyStudents,
      getTimeAssets,
    } = this.props;
    await Promise.all([
      getEventTypes(),
      getEvents(),
      getCoaches(),
      getCalendyStudents(),
      getTimeAssets(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      eventTypes,
      updateEvent,
      deleteEvent,
      getEventTypeById,
      createEventType,
      deleteEventType,
      updateEventType,
      createCoach,
      events,
      coaches,
      history,
      calendyStudents,
      sendStudentEmail,
      timezones,
      getTimeAssets,
      timeAssets,
      searchUsers,
      getCalendyStudents,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperEventTypePage
        refresh={this.refresh.bind(this)}
        loading={loading}
        eventTypes={eventTypes}
        getCalendyStudents={getCalendyStudents}
        updateEvent={updateEvent}
        searchUsers={searchUsers}
        deleteEvent={deleteEvent}
        history={history}
        timeAssets={timeAssets}
        getEventTypeById={getEventTypeById}
        createEventType={createEventType}
        deleteEventType={deleteEventType}
        updateEventType={updateEventType}
        sendStudentEmail={sendStudentEmail}
        timezones={timezones}
        createCoach={createCoach}
        getTimeAssets={getTimeAssets}
        events={events}
        coaches={coaches}
        urlParams={urlParams}
        calendyStudents={calendyStudents}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventTypePage);
