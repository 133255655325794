export default (theme) => ({
  container: {
    padding: 5,
    width: "100%",
  },
  paper: {
    borderLeft: `solid 3px #607d8b`,
  },
  paperLoading: {
    borderLeft: "solid 3px #212121",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155, 0.3)",
    borderRadius: 4,
  },
  darkModeFix: {
    color: theme.palette.type === "dark" ? "white" : "black",
  },
  grip: {
    color: "#9e9e9e",
    fontSize: 14,
    cursor: "grab",
  },
});
