// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// custom
import DownloadRequestsList from "components/DownloadRequestsList";
import FormDownloadRequest from "components/FormDownloadRequest";
// reactor
import Page from "components/Page";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperDownloadRequestPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    downloadRequests: PropTypes.array,
    createDownloadRequest: PropTypes.func,
    deleteDownloadRequest: PropTypes.func,
    updateDownloadRequest: PropTypes.func,
    getDownloadRequests: PropTypes.func,
    books: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedDownloadRequest: undefined,
      formDownloadRequestOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      downloadRequests,
      history,
      getDownloadRequests,
      books,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <DownloadRequestsList
            downloadRequests={downloadRequests}
            history={history}
            books={books}
            getDownloadRequests={getDownloadRequests}
            // onSelect={(selectedDownloadRequest) =>
            //   this.setState({
            //     selectedDownloadRequest,
            //     formDownloadRequestOpen: true,
            //   })
            // }
          />
        );
      default:
    }

    return [];
  }

  export() {
    const { downloadRequests } = this.props;

    const data = [];
    for (const key in downloadRequests) {
      if (downloadRequests.hasOwnProperty(key)) {
        const e = downloadRequests[key];
        data.push({
          email: e.email,
          bookID: e.bookID,
          inquiryDate: moment(e.inquiryDate)
            .parseZone()
            .format("MM/DD/YYYY HH:mm:ss"),
        });
      }
    }

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Prospect Export",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  render() {
    const {
      classes,
      history,
      loading,
      createDownloadRequest,
      deleteDownloadRequest,
      updateDownloadRequest,
      refresh,
    } = this.props;

    const {
      index,
      selectedDownloadRequest,
      formDownloadRequestOpen,
    } = this.state;

    return (
      <Page
        helmet="Website Prospects"
        loading={loading}
        loadingMessage={"Fetching all Website Prospects"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormDownloadRequest
          open={formDownloadRequestOpen}
          downloadRequest={selectedDownloadRequest}
          close={() => {
            this.setState({
              formDownloadRequestOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedDownloadRequest: undefined,
                }),
              200
            );
          }}
          createDownloadRequest={createDownloadRequest}
          deleteDownloadRequest={deleteDownloadRequest}
          updateDownloadRequest={updateDownloadRequest}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperDownloadRequestPage);
