import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class MiniLayout extends Component {
  static propTypes = {
    classes: PropTypes.object,
    selectedCareer: PropTypes.object,
    interactionDisabled: PropTypes.number,
    onClick: PropTypes.func,
    onScrollRatioChange: PropTypes.func,
    smallBubbleSize: PropTypes.number,
    width: PropTypes.number,
    careers: PropTypes.array,
    maxPitch: PropTypes.number,
    col: PropTypes.number,
    spacing: PropTypes.number,
    scrollRatio: PropTypes.number,
    selectedCategory: PropTypes.object,
    colors: PropTypes.array,
    windowWidth: PropTypes.number,
    layoutWidth: PropTypes.number,
  };

  static contextTypes = {
    KeyboardEventCenter: PropTypes.object,
  };

  componentWillMount() {
    const { KeyboardEventCenter } = this.context;

    KeyboardEventCenter.listen("left", this.keyboardEvent.bind(this));
    KeyboardEventCenter.listen("right", this.keyboardEvent.bind(this));

    this.setState({
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      left: 0,
      mouseDown: false,
      hover: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ scrollRatio: nextProps.scrollRatio });
  }

  keyboardEvent(handler) {
    const { onScrollRatioChange } = this.props;

    const { scrollRatio } = this.state;

    switch (handler.key) {
      case "left":
        onScrollRatioChange(scrollRatio - 10);
        break;
      case "right":
        onScrollRatioChange(scrollRatio + 10);
        break;
      default:
    }
  }

  genLayout() {
    const {
      careers,
      maxPitch,
      spacing,
      smallBubbleSize,
      selectedCategory,
      selectedCareer,
    } = this.props;

    const JSX = [];
    let pitch = maxPitch;
    let nextOp = -1;
    let i = 0;
    let col = [];
    let stacked = 0;
    while (i < careers.length) {
      let color = careers[i].categories
        ? careers[i].categories[0].color
        : "#FFFFFF";
      if (selectedCategory) {
        if (
          careers[i].categories.find((cc) => cc.id === selectedCategory.id) ===
          undefined
        ) {
          color = undefined;
        } else {
          color = selectedCategory.color;
        }
      }

      if (selectedCareer) {
        if (selectedCareer.id !== careers[i].id) {
          color = undefined;
        }
      }

      col.push(
        <Grid item key={`item_${i}`}>
          <div
            style={{
              background: color,
              opacity: color === undefined ? 0.5 : 1,
              borderRadius: smallBubbleSize,
              height: smallBubbleSize - 2,
              width: smallBubbleSize - 2,
              border: "solid 1px white",
              margin: spacing / 2,
            }}
          ></div>
        </Grid>
      );
      stacked += 1;
      if (stacked === pitch) {
        JSX.push(
          <Grid item key={`grid_${i}`}>
            <Grid container direction="column" spacing={0}>
              {col}
            </Grid>
          </Grid>
        );
        stacked = 0;
        col = [];
        pitch += nextOp;
        if (pitch === 1) {
          nextOp = 1;
        }

        if (pitch === maxPitch) {
          nextOp = -1;
        }
      }

      if (i === careers.length - 1 && col.length) {
        JSX.push(
          <Grid item key={`grid_${i + 1}`}>
            <Grid container direction="column" spacing={0}>
              {col}
            </Grid>
          </Grid>
        );
      }

      i++;
    }

    return (
      <Grid container spacing={0} alignItems="center">
        {JSX}
      </Grid>
    );
  }

  render() {
    const {
      classes,
      windowWidth,
      layoutWidth,
      width,
      interactionDisabled,
      onClick,
      onScrollRatioChange,
    } = this.props;

    const { hover, scrollRatio } = this.state;

    return (
      <div
        className={classes.container}
        style={{
          width,
          margin: "auto",
          cursor: onClick ? "pointer" : undefined,
          background: hover && onClick ? "rgba(0,0,0,0.1)" : undefined,
          borderRadius: 10,
        }}
        ref="container"
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onMouseMove={
          interactionDisabled !== true
            ? (e) => {
                const boundingClientRect = this.refs.container.getBoundingClientRect();
                const mini = this.refs.miniScreen.clientWidth;
                let scroll = e.clientX - boundingClientRect.left - mini / 2;

                if (scroll < 0) {
                  scroll = 0;
                }

                let ratio = ((scroll + mini) / width) * 100;

                if (ratio > 100) {
                  ratio = 100;
                }
                this.setState({ scrollRatio: ratio });

                onScrollRatioChange(ratio);
              }
            : undefined
        }
      >
        {this.genLayout()}
        {windowWidth && layoutWidth && interactionDisabled !== true ? (
          <div
            ref="miniScreen"
            style={{
              background: "rgba(255,255,255,0.3)",
              height: "calc(100% + 20px)",
              borderRadius: 8,
              position: "absolute",
              top: -10,
              left: `calc(${scrollRatio}% - ${
                (width * windowWidth) / layoutWidth
              }px)`,
              zIndex: 9999,
              width: `${(windowWidth / layoutWidth) * 100}%`,
              cursor: "grab",
            }}
          ></div>
        ) : (
          []
        )}
        {onClick ? (
          <Typography
            display="block"
            variant="caption"
            style={{ color: "white", textAlign: "center" }}
          >
            Back to the Wall
          </Typography>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(MiniLayout);
