import { networkAction } from 'helpers/network/networkAction';

import {
  GET_INSTITUTIONS,
  GET_INSTITUTION_BY_ID,
  DELETE_INSTITUTION,
  UPDATE_INSTITUTION,
  CREATE_INSTITUTION,
} from 'constants/institutions';

import * as Api from 'api';

export const getInstitutions = () => async dispatch => networkAction(
  dispatch,
  GET_INSTITUTIONS,
  Api.getInstitutions,
  []
);

export const getInstitutionById = (id) => async dispatch => networkAction(
  dispatch,
  GET_INSTITUTION_BY_ID,
  Api.getInstitutionById,
  [id]
);

export const deleteInstitution = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_INSTITUTION,
  Api.deleteInstitution,
  [id]
);

export const createInstitution = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_INSTITUTION,
  Api.createInstitution,
  [body]
);

export const updateInstitution = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_INSTITUTION,
  Api.updateInstitution,
  [id, body]
);
