import { networkAction } from "helpers/network/networkAction";

import {
  GET_EVENT_TYPE_COACHES,
  GET_EVENT_TYPE_COACH_BY_ID,
  DELETE_EVENT_TYPE_COACH,
  UPDATE_EVENT_TYPE_COACH,
  CREATE_EVENT_TYPE_COACH,
} from "constants/eventTypeCoaches";

import * as Api from "api";

export const getEventTypeCoaches = () => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_TYPE_COACHES, Api.getEventTypeCoaches, []);

export const getEventTypeCoachById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_EVENT_TYPE_COACH_BY_ID,
    Api.getEventTypeCoachById,
    [id]
  );

export const deleteEventTypeCoach = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_EVENT_TYPE_COACH, Api.deleteEventTypeCoach, [
    id,
  ]);

export const createEventTypeCoach = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_EVENT_TYPE_COACH, Api.createEventTypeCoach, [
    body,
  ]);

export const updateEventTypeCoach = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_EVENT_TYPE_COACH, Api.updateEventTypeCoach, [
    id,
    body,
  ]);
