// import PropTypes from 'prop-types';
// material-ui
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import AutoComplete from "@material-ui/lab/Autocomplete";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CheckboxAutocomplete({
  options,
  value,
  optionKey,
  groupBy,
  onAdd,
  onDelete,
  label,
}) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  const emptyCheckIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <AutoComplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      disableClearable
      groupBy={groupBy}
      value={value}
      ChipProps={{ size: "small" }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      renderOption={(option, { selected }) => {
        return (
          <React.Fragment>
            <Checkbox
              icon={emptyCheckIcon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option[optionKey]}`}
          </React.Fragment>
        );
      }}
      onChange={(e, v, r) => {
        switch (r) {
          case "remove-option":
            onDelete(value.filter((x) => !v.includes(x))[0]);
            break;
          case "select-option":
            onAdd(v.filter((x) => !value.includes(x))[0]);
            break;
        }
      }}
      getOptionLabel={(option) => {
        return `${option[optionKey]}`;
      }}
    />
  );
}
