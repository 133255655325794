import { GET, PUT, DELETE, POST } from "constants/methods";
const eventTypes = "/calendy/eventTypes";

export function getEventTypes() {
  const url = `${eventTypes}`;
  return {
    method: GET,
    url,
  };
}

export function getEventTypeById(id) {
  const url = `${eventTypes}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEventType(id, body) {
  const url = `${eventTypes}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEventType(id) {
  const url = `${eventTypes}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEventType(body) {
  const url = `${eventTypes}`;
  return {
    method: POST,
    url,
    body,
  };
}
