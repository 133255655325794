import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperEventTypePage from "../component/WrapperEventTypePage";

const mapStateToProps = (state) => ({
  coaches: state.coaches,
});

const mapDispatchToProps = (dispatch) => ({
  getEventTypeById: (...args) => dispatch(Actions.getEventTypeById(...args)),
  getAvailabilities: (...args) => dispatch(Actions.getAvailabilities(...args)),
  updateAvailability: (...args) =>
    dispatch(Actions.updateAvailability(...args)),
  deleteAvailability: (...args) =>
    dispatch(Actions.deleteAvailability(...args)),
  createAvailability: (...args) =>
    dispatch(Actions.createAvailability(...args)),
  getDailyAvailabilities: (...args) =>
    dispatch(Actions.getDailyAvailabilities(...args)),
  getEventTypeCoaches: (...args) =>
    dispatch(Actions.getEventTypeCoaches(...args)),
  createEventTypeCoach: (...args) =>
    dispatch(Actions.createEventTypeCoach(...args)),
  deleteEventTypeCoach: (...args) =>
    dispatch(Actions.deleteEventTypeCoach(...args)),
  updateEventType: (...args) => dispatch(Actions.updateEventType(...args)),
  getCoaches: (...args) => dispatch(Actions.getCoaches(...args)),
});
class EventTypePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getAvailabilities: PropTypes.func,
    updateAvailability: PropTypes.func,
    deleteAvailability: PropTypes.func,
    createAvailability: PropTypes.func,
    getDailyAvailabilities: PropTypes.func,
    getEventTypeCoaches: PropTypes.func,
    createEventTypeCoach: PropTypes.func,
    deleteEventTypeCoach: PropTypes.func,
    updateEventType: PropTypes.func,
    getCoaches: PropTypes.func,
    coaches: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);

    this.state = {
      urlParams,
      loading: true,
      eventTypeID: urlParams.id,
    };

    this.refresh();
  }

  async refresh() {
    const { eventTypeID } = this.state;
    const {
      getEventTypeById,
      getAvailabilities,
      getEventTypeCoaches,
      getCoaches,
    } = this.props;
    const resps = await Promise.all([
      getEventTypeById(eventTypeID),
      getAvailabilities(),
      getEventTypeCoaches(eventTypeID),
      getCoaches(),
    ]);

    this.setState({
      eventType: resps[0].payload,
      availabilities: resps[1].payload,
      eventTypeCoaches: resps[2].payload,
      refreshKey: new Date().getTime(),
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      updateAvailability,
      deleteAvailability,
      createAvailability,
      getDailyAvailabilities,
      updateEventType,
      createEventTypeCoach,
      deleteEventTypeCoach,
      coaches,
    } = this.props;

    const {
      urlParams,
      eventType,
      loading,
      refreshKey,
      availabilities,
      eventTypeCoaches,
    } = this.state;

    return (
      <WrapperEventTypePage
        history={history}
        location={location}
        eventType={eventType}
        urlParams={urlParams}
        availabilities={availabilities}
        getDailyAvailabilities={getDailyAvailabilities}
        eventTypeCoaches={eventTypeCoaches}
        createEventTypeCoach={createEventTypeCoach}
        deleteEventTypeCoach={deleteEventTypeCoach}
        allCoaches={coaches}
        loading={loading}
        refreshKey={refreshKey}
        updateAvailability={updateAvailability}
        deleteAvailability={deleteAvailability}
        createAvailability={createAvailability}
        updateEventType={updateEventType}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventTypePage);
