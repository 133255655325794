import * as Api from "api";
import {
  CREATE_CAREER_SUB_CATEGORY,
  DELETE_CAREER_SUB_CATEGORY,
  GET_CAREER_SUB_CATEGORIES,
  GET_CAREER_SUB_CATEGORY_BY_ID,
  UPDATE_CAREER_SUB_CATEGORY,
} from "constants/careerSubCategories";
import { networkAction } from "helpers/network/networkAction";

export const getCareerSubCategories = (filters) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CAREER_SUB_CATEGORIES,
    Api.getCareerSubCategories,
    [filters]
  );

export const getCareerSubCategoryById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CAREER_SUB_CATEGORY_BY_ID,
    Api.getCareerSubCategoryById,
    [id]
  );

export const deleteCareerSubCategory = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_CAREER_SUB_CATEGORY,
    Api.deleteCareerSubCategory,
    [id]
  );

export const createCareerSubCategory = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_CAREER_SUB_CATEGORY,
    Api.createCareerSubCategory,
    [body]
  );

export const updateCareerSubCategory = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_CAREER_SUB_CATEGORY,
    Api.updateCareerSubCategory,
    [id, body]
  );
