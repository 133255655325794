export default (theme) => ({
  container: {
    padding: 5,
  },
  paper: {
    padding: 20,
    borderLeft: `solid 3px ${theme.palette.primary.color[600]}`,
  },
  paperLoading: {
    padding: 20,
    borderLeft: "solid 3px #212121",
  },
  info: {
    padding: 20,
    borderLeft: "solid 3px #2196f3",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: 4,
  },
  lock: {
    height: 15,
  },
  h5: {
    fontWeight: 600,
  },
});
