import React, { Component } from "react";
import PropTypes from "prop-types";
import DragScroll from "react-dragscroll";
import ReactDOM from "react-dom";

// constants
import { CATEGORY } from "constants/routes";

// material-ui
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";

// Custom
import MiniLayout from "components/MiniLayout";
import Layout from "components/Layout";

// styles
import styles from "./styles";

let maxPitch = 4;
let spacing = 16;
let smallSpacing = 2;
let bubbleSize = 110;
let smallBubbleSize = 7;

class WrapperCpfmPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    careers: PropTypes.array,
    categories: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { careers } = this.props;
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.state = {
      col: this.getCol(),
      index: 0,
      init: false,
      careerCount: careers.length,
      scrollRatio: 0,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.prevent) {
      return false;
    }

    return true;
  }

  updateStateCol() {
    this.setState({ col: this.getCol() });
  }

  getCol() {
    const { careers } = this.props;
    let careerCount = careers.length;
    let pitch = maxPitch;
    let nextOp = -1;
    let i = 0;
    let col = 0;
    let stacked = 0;
    while (i < careerCount) {
      stacked += 1;
      if (stacked === pitch) {
        col += 1;
        stacked = 0;
        pitch += nextOp;
        if (pitch === 1) {
          nextOp = 1;
        }

        if (pitch === maxPitch) {
          nextOp = -1;
        }
      }

      if (i === careerCount - 1 && stacked) {
        col += 1;
      }

      i++;
    }

    return col;
  }

  componentDidMount() {
    const { init } = this.state;
    ReactDOM.findDOMNode(this.refs.scrollContainer).addEventListener(
      "scroll",
      this.scrollEvent.bind(this)
    );

    if (!init) {
      this.updateDimensions();
    }
  }

  updateDimensions() {
    const { col } = this.state;
    const layoutWidth = col * (bubbleSize + spacing) - spacing;
    const scrollContainer = ReactDOM.findDOMNode(this.refs.scrollContainer);

    if (scrollContainer) {
      const scrollLeft = scrollContainer.scrollLeft;
      const windowWidth = scrollContainer.clientWidth;
      const windowHeight = scrollContainer.clientHeight;

      if (windowHeight < 450) {
        maxPitch = 2;
        this.updateStateCol();
      }

      if (windowHeight >= 450 && windowHeight < 800) {
        maxPitch = 3;
        this.updateStateCol();
      }

      if (windowHeight > 800) {
        maxPitch = 4;
        this.updateStateCol();
      }

      bubbleSize = 100 + windowWidth / 65;

      const scrollRatio = ((scrollLeft + windowWidth) / layoutWidth) * 100;

      this.setState({
        scrollLeft,
        windowWidth,
        layoutWidth,
        scrollRatio: scrollRatio,
        prevent: false,
        init: true,
      });
    }
  }

  scrollEvent(e, v, t) {
    const { col } = this.state;

    const scrollLeft = ReactDOM.findDOMNode(this.refs.scrollContainer)
      .scrollLeft;
    const layoutWidth = col * (bubbleSize + spacing) - spacing;
    const windowWidth = ReactDOM.findDOMNode(this.refs.scrollContainer)
      .clientWidth;
    const scrollRatio = ((scrollLeft + windowWidth) / layoutWidth) * 100;

    this.setState({
      scrollLeft,
      windowWidth,
      layoutWidth,
      scrollRatio: scrollRatio,
    });
  }

  render() {
    const { classes, history, categories, careers } = this.props;

    const {
      col,
      scrollRatio,
      scrollLeft,
      windowWidth,
      layoutWidth,
      index,
      careerCount,
      stagedCareer,
    } = this.state;

    const width = "md";

    return (
      <div
        style={{
          height: "100%",
          position: "relative",
          background: "#455a64",
        }}
      >
        <DragScroll
          ref="scrollContainer"
          onScroll={this.scrollEvent.bind(this)}
          className={
            width !== "xs" && width !== "sm"
              ? classes.container
              : classes.mobileContainer
          }
        >
          <div
            ref="layout"
            style={{
              // background: 'red',
              width: col * (bubbleSize + spacing),
              height: "100%",
            }}
            className={classes.layout}
          >
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid item xs={12}>
                <Layout
                  spacing={spacing}
                  history={history}
                  careerCount={careerCount}
                  bubbleSize={bubbleSize}
                  careerOrder={careers}
                  maxPitch={maxPitch}
                  onHover={(stagedCareer) => {
                    this.setState({ stagedCareer });
                    setTimeout(() => {
                      if (
                        this.state.stagedCareer &&
                        this.state.stagedCareer.title === stagedCareer.title
                      ) {
                        this.setState({ stagedCareer: undefined });
                      }
                    }, 3000);
                  }}
                />
                {width !== "xs" && width !== "sm" ? (
                  <Grid item xs={12} style={{ height: 50 }}>
                    {stagedCareer ? (
                      <Fade in key={stagedCareer.title}>
                        <Typography
                          display="block"
                          variant="display2"
                          className={classes.helpTitle}
                        >
                          {stagedCareer.title.toLowerCase()}
                        </Typography>
                      </Fade>
                    ) : (
                      []
                    )}
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          </div>
        </DragScroll>
        <div
          style={{
            width: "100vw",
            position: "fixed",
            bottom: 0,
          }}
        >
          {width !== "xs" && width !== "sm" ? (
            <div
              style={{
                margin: "auto",
                marginBottom: 30,
              }}
              onMouseLeave={() => this.setState({ prevent: false })}
            >
              <MiniLayout
                ref="miniLayout"
                careers={careers}
                smallBubbleSize={smallBubbleSize}
                width={col * (smallBubbleSize + smallSpacing)}
                items={careerCount}
                maxPitch={maxPitch}
                col={col}
                scrollRatio={scrollRatio}
                spacing={smallSpacing}
                scrollLeft={scrollLeft}
                windowWidth={windowWidth}
                layoutWidth={layoutWidth}
                onScrollRatioChange={(sr) => {
                  this.setState({
                    scrollRatio: sr,
                    prevent: true,
                  });
                  ReactDOM.findDOMNode(this.refs.scrollContainer).scrollLeft =
                    (layoutWidth * sr) / 100 - windowWidth + 8;
                }}
              />
            </div>
          ) : (
            []
          )}
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={index}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              {categories.map((c) => (
                <Tab
                  onClick={() => history.push(`${CATEGORY}?categoryID=${c.id}`)}
                  key={c.title}
                  label={<span style={{ color: c.color }}>{c.title}</span>}
                />
              ))}
            </Tabs>
          </AppBar>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperCpfmPage);
