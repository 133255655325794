import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperInstitutionsPage from '../component/WrapperInstitutionsPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  institutions: state.institutions,
});

const mapDispatchToProps = dispatch => ({
  getInstitutions: () => dispatch(Actions.getInstitutions()),
  getInstitutionById: (...args) => dispatch(Actions.getInstitutionById(...args)),
  createInstitution: (...args) => dispatch(Actions.createInstitution(...args)),
  deleteInstitution: (...args) => dispatch(Actions.deleteInstitution(...args)),
  updateInstitution: (...args) => dispatch(Actions.updateInstitution(...args)),
});
class InstitutionsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    institutions: PropTypes.array,
    getInstitutions: PropTypes.func,
    getInstitutionById: PropTypes.func,
    createInstitution: PropTypes.func,
    deleteInstitution: PropTypes.func,
    updateInstitution: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, institutions } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: institutions.length === 0,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.refresh();
  }

  async refresh() {
    const { getInstitutions } = this.props;
    await Promise.all([
      getInstitutions(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      institutions,
      getInstitutionById,
      createInstitution,
      deleteInstitution,
      updateInstitution,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperInstitutionsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        institutions={institutions}
        getInstitutionById={getInstitutionById}
        createInstitution={createInstitution}
        deleteInstitution={deleteInstitution}
        updateInstitution={updateInstitution}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InstitutionsPage);
