import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const careerAuthors = `${REACT_APP_API_PREFIX}/${service}/careerAuthors`;

export function getCareerAuthors(filters) {
  const url = `${careerAuthors}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCareerAuthorById(id) {
  const url = `${careerAuthors}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateCareerAuthor(id, body) {
  const url = `${careerAuthors}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCareerAuthor(id) {
  const url = `${careerAuthors}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCareerAuthor(body) {
  const url = `${careerAuthors}`;
  return {
    method: POST,
    url,
    body,
  };
}
