// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ClubMembersList from "components/ClubMembersList";
// reactor
import Page from "components/Page";
import { ExportToCsv } from "export-to-csv";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperClubMembersPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    getClubMembers: PropTypes.func,
    searchUsers: PropTypes.func,
    clubMembers: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { clubMembers, history, getClubMembers, searchUsers } = this.props;

    switch (index) {
      case 0:
        return (
          <ClubMembersList
            getClubMembers={getClubMembers}
            history={history}
            searchUsers={searchUsers}
            clubMembers={clubMembers}
            onSelect={(selectedClubMembers) => console.log(selectedClubMembers)}
          />
        );
      default:
    }

    return [];
  }

  export() {
    const { clubMembers } = this.props;

    const data = [];
    for (const key in clubMembers) {
      if (clubMembers.hasOwnProperty(key)) {
        const e = clubMembers[key];
        data.push({
          email: e.email,
          fullName: e.userFullName,
          userUUID: e.userUUID,
          startDate: e.startDate,
          expirationDate: e.expirationDate,
          renewalDate: e.renewalDate,
          paymentID: e.paymentID,
          id: e.id,
        });
      }
    }

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Prospect Export",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="ClubMembers"
        loading={loading}
        loadingMessage={"Fetching all ClubMembers"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperClubMembersPage);
