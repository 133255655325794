// import PropTypes from 'prop-types';
// material-ui
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as Actions from "actions";
import { ProgramEdit } from "components";
import ProgramsList from "components/ProgramsList";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ProgramCount({ value, type, title, id }) {
  const [open, setOpen] = React.useState(false);
  const [selectedProgram, setSelectedProgram] = React.useState();
  const [programById, setProgramById] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [programs, setPrograms] = React.useState([]);
  const classes = useStyles(styles);

  const academies = useSelector((state) => state.academies);
  const institutions = useSelector((state) => state.institutions);
  const ages = useSelector((state) => state.ages);
  const programTypes = useSelector((state) => state.programTypes);
  const grades = useSelector((state) => state.grades);
  const levels = useSelector((state) => state.levels);
  const instruments = useSelector((state) => state.instruments);
  const musicalFamilies = useSelector((state) => state.musicalFamilies);
  const divisions = useSelector((state) => state.divisions);
  const masterTags = useSelector((state) => state.masterTags);
  const programSubcategories = useSelector(
    (state) => state.programSubcategories
  );

  const api = {
    updateProgram: (...args) => dispatch(Actions.updateProgram(...args)),
    createProgramInstrument: (...args) =>
      dispatch(Actions.createProgramInstrument(...args)),
    deleteProgramInstrument: (...args) =>
      dispatch(Actions.deleteProgramInstrument(...args)),
    createProgramMusicalFamily: (...args) =>
      dispatch(Actions.createProgramMusicalFamily(...args)),
    deleteProgramMusicalFamily: (...args) =>
      dispatch(Actions.deleteProgramMusicalFamily(...args)),
    createProgramLevel: (...args) =>
      dispatch(Actions.createProgramLevel(...args)),
    deleteProgramLevel: (...args) =>
      dispatch(Actions.deleteProgramLevel(...args)),
    createProgramDivision: (...args) =>
      dispatch(Actions.createProgramDivision(...args)),
    createProgramRelateSubcategory: (...args) =>
      dispatch(Actions.createProgramRelateSubcategory(...args)),
    createProgramTag: (...args) => dispatch(Actions.createProgramTag(...args)),
    deleteProgramDivision: (...args) =>
      dispatch(Actions.deleteProgramDivision(...args)),
    deleteProgramRelateSubcategory: (...args) =>
      dispatch(Actions.deleteProgramRelateSubcategory(...args)),
    deleteProgramTag: (...args) => dispatch(Actions.deleteProgramTag(...args)),
    deleteProgramInstitution: (...args) =>
      dispatch(Actions.deleteProgramInstitution(...args)),
    deleteProgram: (...args) => dispatch(Actions.deleteProgram(...args)),
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (open) {
      refresh();
    }
  }, [open]);

  React.useEffect(() => {
    if (selectedProgram && selectedProgram.id) {
      dispatch(Actions.getProgramById(selectedProgram.id)).then((r) => {
        if (r.success) {
          setProgramById(r.payload);
        }
      });
    }
  }, [selectedProgram]);

  const closeDialog = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  const openDialog = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const refresh = async () => {
    setLoading(true);
    var r;
    switch (type) {
      case "ages":
        r = await dispatch(Actions.getProgramsByAge(id));
        break;
      case "levels":
        r = await dispatch(Actions.getProgramsByLevel(id));
        break;
      case "grades":
        r = await dispatch(Actions.getProgramsByGrade(id));
        break;
      case "instruments":
        r = await dispatch(Actions.getProgramsByInstrument(id));
        break;
      case "musicalFamilies":
        r = await dispatch(Actions.getProgramsByMusicalFamily(id));
        break;
      case "masterTags":
        r = await dispatch(Actions.getProgramsByMasterTag(id));
        break;
      case "programSubcategories":
        r = await dispatch(Actions.getProgramsByProgramSubcategories(id));
        break;
      case "divisions":
        r = await dispatch(Actions.getProgramsByDivision(id));
        break;
      default:
        break;
    }

    if (r.success) {
      setPrograms(r.payload);
      setLoading(false);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Box
          sx={{
            background: "rgba(155,155,155,0.1)",
            border: `solid 1px rgba(155,155,155,0.3)`,
            borderRadius: 4,
            padding: "2px 4px",
            "&:hover": {
              borderColor: "#2196f3",
              background: "rgba(33,150,243, 0.1)",
              color: "#2196f3",
            },
          }}
          onClick={openDialog}
        >
          <Typography variant="body2">
            {value} programs <i class="fa-solid fa-chevron-right p-left"></i>
          </Typography>
        </Box>
      </Box>
      <Dialog
        style={{ margin: 16 }}
        open={open}
        onClose={closeDialog}
        fullScreen
      >
        <IconButton
          onClick={closeDialog}
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            width: 30,
            height: 30,
          }}
        >
          <Icon class="fa-solid fa-xmark" />
        </IconButton>
        <DialogTitle>
          <Typography variant="h4" style={{ fontWeight: 800 }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ display: "flex" }}>
          {loading ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ flex: 1, overflow: "scroll", display: "flex" }}>
              <div
                style={{
                  flexGrow: 1,
                  maxHeight: `calc(100vh - 140px)`,
                  overflow: "scroll",
                }}
              >
                <ProgramsList
                  programs={programs}
                  advancedSearch={false}
                  onSelect={(e) => {
                    setProgramById();
                    setSelectedProgram(e);
                  }}
                />
              </div>
              {programById ? (
                <div
                  style={{
                    width: 350,
                    maxHeight: `calc(100vh - 140px)`,
                    overflow: "scroll",
                  }}
                >
                  <ProgramEdit
                    api={api}
                    academies={academies}
                    // history={history}
                    institutions={institutions}
                    program={programById}
                    ages={ages}
                    programTypes={programTypes}
                    grades={grades}
                    levels={levels}
                    instruments={instruments}
                    musicalFamilies={musicalFamilies}
                    divisions={divisions}
                    refresh={refresh}
                    refreshKey={programById && programById.id}
                    contentLoading={programById === null}
                    masterTags={masterTags}
                    programSubcategories={programSubcategories}
                  />
                </div>
              ) : (
                []
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
