import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { COURSE } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CourseCard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    course: PropTypes.object,
    history: PropTypes.object,
  };

  render() {
    const { classes, course, history } = this.props;

    return (
      <Paper className={classes.container}>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Grid container justify="space-between">
              <Grid item>
                <Typography display="block" variant="body2">
                  {course.name}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {course.author}
                </Typography>
              </Grid>
              {/* <Grid item>
                <div className={classes.circularContainer}>
                  <CircularProgress
                    className={classes.back}
                    variant="static"
                    value={100}
                  />
                  <CircularProgress
                    className={classes.fore}
                    variant="static"
                    value={course.percentage}
                  />
                  <Typography display="block" color="primary" className={classes.value}>
                    {course.percentage}<span style={{ fontSize: 10 }}>%</span>
                  </Typography>
                </div>
              </Grid> */}
              <Grid item xs={12}>
                <div
                  style={{
                    backgroundImage: `url(${course.background})`,
                    height: 150,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    marginLeft: -10,
                    width: "calc(100% + 20px)",
                  }}
                />
              </Grid>
              <Grid item>
                <Grid container>
                  {course.percentage !== 0 ? (
                    <Grid item xs={12}>
                      <Typography display="block" variant="body2">
                        {course.subtitle}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Typography display="block" color="textSecondary">
                        You have not started the course yet.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography display="block" color="textSecondary" variant="caption">
              {course.locked ? (
                <>
                  <i class="fad fa-lock-alt"></i> Locked
                </>
              ) : (
                []
              )}
            </Typography>
            <Chip
              label={"Edit Course"}
              color="primary"
              onClick={() => {
                history.push(`${COURSE}?id=${course.id}`);
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(CourseCard);
