// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCoursePage from "../component/WrapperCoursePage";

const mapStateToProps = (state) => ({
  app: state.app,
  courses: state.courses,
  enrollments: state.enrollments,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getCourseById: (...args) => dispatch(Actions.getCourseById(...args)),
  getEnrollments: (...args) => dispatch(Actions.getEnrollments(...args)),
  updateCourse: (...args) => dispatch(Actions.updateCourse(...args)),
  updateModule: (...args) => dispatch(Actions.updateModule(...args)),
  updateLecture: (...args) => dispatch(Actions.updateLecture(...args)),
  getCourseModules: (...args) => dispatch(Actions.getCourseModules(...args)),
  getModuleLectures: (...args) => dispatch(Actions.getModuleLectures(...args)),
  getLectureMedia: (...args) => dispatch(Actions.getLectureMedia(...args)),
  deleteMedia: (...args) => dispatch(Actions.deleteMedia(...args)),
  createLectureMedia: (...args) =>
    dispatch(Actions.createLectureMedia(...args)),
  createLecture: (...args) => dispatch(Actions.createLecture(...args)),
  deleteLecture: (...args) => dispatch(Actions.deleteLecture(...args)),
  createModule: (...args) => dispatch(Actions.createModule(...args)),
  deleteModule: (...args) => dispatch(Actions.deleteModule(...args)),
  deleteCourse: (...args) => dispatch(Actions.deleteCourse(...args)),
  getCourses: (...args) => dispatch(Actions.getCourses(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
});
class CoursePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,

    getCourseById: PropTypes.func,
    updateCourse: PropTypes.func,
    updateModule: PropTypes.func,
    updateLecture: PropTypes.func,
    getCourseModules: PropTypes.func,
    getModuleLectures: PropTypes.func,
    getLectureMedia: PropTypes.func,
    deleteMedia: PropTypes.func,
    createLectureMedia: PropTypes.func,
    createLecture: PropTypes.func,
    deleteLecture: PropTypes.func,
    createModule: PropTypes.func,
    deleteModule: PropTypes.func,
    deleteCourse: PropTypes.func,
    getCourses: PropTypes.func,

    getEnrollments: PropTypes.func,
    enrollments: PropTypes.array,
    courses: PropTypes.array,
    user: PropTypes.object,
    searchUsers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getEnrollments, getCourses } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      courseID: urlParams.id,
      course: {},
      loading: true,
    };
    getCourses();
    getEnrollments([
      { name: "enrollments.courseID", comparison: "eq", value: urlParams.id },
    ]);
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getCourseById } = this.props;
    const { courseID } = this.state;

    const resp = await getCourseById(courseID);

    if (resp.success) {
      const course = resp.payload;
      this.setState({
        loading: false,
        course,
        refreshKey: Date.now(),
      });
    }
  }

  render() {
    const {
      location,
      history,
      app,
      updateCourse,
      updateModule,
      updateLecture,
      getCourseModules,
      getModuleLectures,
      getLectureMedia,
      deleteMedia,
      createLectureMedia,
      createLecture,
      deleteLecture,
      createModule,
      deleteModule,
      deleteCourse,
      getEnrollments,
      enrollments,
      courses,
      user,
      searchUsers,
    } = this.props;

    const { urlParams, course, loading, refreshKey } = this.state;

    return (
      <WrapperCoursePage
        key={course?.locked}
        course={course}
        app={app}
        loading={loading}
        courses={courses}
        user={user}
        searchUsers={searchUsers}
        history={history}
        location={location}
        urlParams={urlParams}
        updateCourse={updateCourse}
        updateModule={updateModule}
        getEnrollments={getEnrollments}
        updateLecture={updateLecture}
        refreshKey={refreshKey}
        enrollments={enrollments}
        getCourseModules={getCourseModules}
        getModuleLectures={getModuleLectures}
        getLectureMedia={getLectureMedia}
        deleteMedia={deleteMedia}
        createLectureMedia={createLectureMedia}
        createLecture={createLecture}
        deleteLecture={deleteLecture}
        createModule={createModule}
        deleteModule={deleteModule}
        deleteCourse={deleteCourse}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CoursePage);
