import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// custom
import Page from "components/Page";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const google = window.google;

class WrapperProcessPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    loading: PropTypes.bool,
    academies: PropTypes.array,
    updateAcademy: PropTypes.func,
    updateProgram: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.geocoder = new google.maps.Geocoder();
  }

  state = {
    processing: false,
    stop: false,
  };

  getLatLgn(address) {
    return new Promise((resolve) => {
      this.geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results && results.length) {
          const result = results[0];
          resolve({
            success: true,
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
            address,
            results,
          });
        } else {
          resolve({
            success: false,
            lat: "MISSING",
            lng: "MISSING",
            address,
            results,
          });
        }
      });
    });
  }

  async geocodePrograms() {
    const { programs, updateProgram } = this.props;
    this.state.issues = [];
    this.setState({
      processing: true,
      index: 0,
      title: "",
      address: "",
      gps: "",
      count: programs?.length,
      issues: [],
      stop: false,
    });

    for (const key in programs) {
      if (programs.hasOwnProperty(key)) {
        const program = programs[key];
        if (program.address) {
          if (
            program.latitude === undefined ||
            program.latitude === null ||
            program.latitude?.length === 0 ||
            program.latitude?.length === 1 ||
            program.latitude?.length === "MISSING" ||
            program.latitude?.length === "MISSING"
          ) {
            if (!this.state.stop) {
              this.setState({
                index: Number(key),
                title: program.name,
                address: `${program.address} ${program.zipcode} ${program.city} ${program.state}`,
              });
              const r = await this.getLatLgn(
                `${program.address} ${program.zipcode} ${program.city} ${program.state}`
              );
              this.setState({ gps: `latitude: ${r.lat}, longitude: ${r.lng}` });
              if (r.success) {
                await updateProgram(program.id, {
                  latitude: `${r.lat}`,
                  longitude: `${r.lng}`,
                });
                const _issues = this.state.issues;
                _issues.unshift(
                  `✅ PROGRAM #${program.id} SUCCESSFULLY LOCATED ${
                    r.address
                  }, ${(r.lat, r.lng)}`
                );
                this.setState({ issues: _issues });
              } else {
                const _issues = this.state.issues;
                _issues.unshift(
                  `🧨 CANNOT LOCATE PROGRAM #${program.id} (${program.address} ${program.zipcode} ${program.city} ${program.state})`
                );
                this.setState({ issues: _issues });
                console.log(r, program);
              }
            }
          } else {
            const _issues = this.state.issues;
            _issues.unshift(
              `👍 PROGRAM #${program.id} ALREADY HAS GPS COORDINATES (${program.latitude}, ${program.longitude})`
            );
            this.setState({ issues: _issues });
          }
        }
      }
    }
  }

  async geocodeAcademies() {
    const { academies, updateAcademy } = this.props;
    this.state.issues = [];
    this.setState({
      processing: true,
      index: 0,
      title: "",
      address: "",
      gps: "",
      count: academies?.length,
      issues: [],
      stop: false,
    });

    for (const key in academies) {
      if (academies.hasOwnProperty(key)) {
        const academy = academies[key];
        if (
          academy.latitude === undefined ||
          academy.latitude === null ||
          academy.latitude?.length === 0 ||
          academy.latitude?.length === 1 ||
          academy.latitude?.length === "MISSING" ||
          academy.latitude?.length === "MISSING"
        ) {
          if (!this.state.stop) {
            this.setState({
              index: Number(key),
              title: academy.name,
              address: `${academy.address} ${academy.zipcode} ${academy.city} ${academy.state}`,
            });
            const r = await this.getLatLgn(
              `${academy.address} ${academy.zipcode} ${academy.city} ${academy.state}`
            );
            this.setState({ gps: `latitude: ${r.lat}, longitude: ${r.lng}` });
            if (r.success) {
              await updateAcademy(academy.id, {
                latitude: `${r.lat}`,
                longitude: `${r.lng}`,
              });
              const _issues = this.state.issues;
              _issues.unshift(
                `✅ ACADEMY #${academy.id} SUCCESSFULLY LOCATED ${r.address}, ${
                  (r.lat, r.lng)
                }`
              );
              this.setState({ issues: _issues });
            } else {
              const _issues = this.state.issues;
              _issues.unshift(
                `🧨 CANNOT LOCATE ACADEMY #${academy.id} (${academy.address} ${academy.zipcode} ${academy.city} ${academy.state})`
              );
              this.setState({ issues: _issues });
              console.log(r, academy);
            }
          }
        } else {
          const _issues = this.state.issues;
          _issues.unshift(
            `👍 ACADEMY #${academy.id} ALREADY HAS GPS COORDINATES (${academy.latitude}, ${academy.longitude})`
          );
          this.setState({ issues: _issues });
        }
      }
    }
  }

  timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  render() {
    const { loading, academies, programs } = this.props;
    const { processing, title, address, index, count, stop, issues, gps } =
      this.state;

    return (
      <Page loading={loading}>
        <Grid container spacing={1} justify="center">
          <Grid item>
            <Button variant="raised" onClick={this.geocodeAcademies.bind(this)}>
              Geocode Academies ({academies && academies?.length})
            </Button>
          </Grid>
          <Grid item>
            <Button variant="raised" onClick={this.geocodePrograms.bind(this)}>
              Geocode Programs ({programs && programs?.length})
            </Button>
          </Grid>
          {processing ? (
            <Grid item xs={12} style={{ textAlign: "center", marginTop: 100 }}>
              <Typography display="block" variant="display3">
                {title}
              </Typography>
              <Typography display="block" variant="body2">
                {address}
              </Typography>
              <Typography display="block" variant="body2">
                {gps}
              </Typography>
              <Typography display="block" variant="caption">
                Progress: {index}/{count} - {Math.round((index / count) * 100)}{" "}
                %
              </Typography>
              <Button
                style={{ background: "red", color: "white" }}
                onClick={() => this.setState({ stop: true })}
              >
                STOP
              </Button>
              <hr />
              {issues &&
                issues.map((i) => (
                  <Typography display="block" variant="caption">
                    {i}
                  </Typography>
                ))}
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperProcessPage);
