import { networkAction } from 'helpers/network/networkAction';

import {
  GET_DEGREE_LEVELS,
  GET_DEGREE_LEVEL_BY_ID,
  DELETE_DEGREE_LEVEL,
  UPDATE_DEGREE_LEVEL,
  CREATE_DEGREE_LEVEL,
} from 'constants/degreeLevels';

import * as Api from 'api';

export const getDegreeLevels = () => async dispatch => networkAction(
  dispatch,
  GET_DEGREE_LEVELS,
  Api.getDegreeLevels,
  []
);

export const getDegreeLevelById = (id) => async dispatch => networkAction(
  dispatch,
  GET_DEGREE_LEVEL_BY_ID,
  Api.getDegreeLevelById,
  [id]
);

export const deleteDegreeLevel = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_DEGREE_LEVEL,
  Api.deleteDegreeLevel,
  [id]
);

export const createDegreeLevel = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_DEGREE_LEVEL,
  Api.createDegreeLevel,
  [body]
);

export const updateDegreeLevel = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_DEGREE_LEVEL,
  Api.updateDegreeLevel,
  [id, body]
);
