import {
  GET_DEGREE_REQUIREMENTS,
} from 'constants/degreeRequirements';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_DEGREE_REQUIREMENTS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
