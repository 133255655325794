import { networkAction } from "helpers/network/networkAction";

import {
  GET_CALENDY_STUDENTS,
  GET_CALENDY_STUDENT_BY_ID,
  DELETE_CALENDY_STUDENT,
  UPDATE_CALENDY_STUDENT,
  CREATE_CALENDY_STUDENT,
} from "constants/calendyStudents";

import * as Api from "api";

export const getCalendyStudents = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CALENDY_STUDENTS, Api.getCalendyStudents, [
    filters,
  ]);

export const getCalendyStudentById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CALENDY_STUDENT_BY_ID,
    Api.getCalendyStudentById,
    [id]
  );

export const deleteCalendyStudent = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CALENDY_STUDENT, Api.deleteCalendyStudent, [
    id,
  ]);

export const createCalendyStudent = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CALENDY_STUDENT, Api.createCalendyStudent, [
    body,
  ]);

export const updateCalendyStudent = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CALENDY_STUDENT, Api.updateCalendyStudent, [
    id,
    body,
  ]);
