import { networkAction } from 'helpers/network/networkAction';

import {
  GET_PROGRAM_INSTITUTIONS,
  GET_PROGRAM_INSTITUTION_BY_ID,
  DELETE_PROGRAM_INSTITUTION,
  UPDATE_PROGRAM_INSTITUTION,
  CREATE_PROGRAM_INSTITUTION,
} from 'constants/programInstitutions';

import * as Api from 'api';

export const getProgramInstitutions = () => async dispatch => networkAction(
  dispatch,
  GET_PROGRAM_INSTITUTIONS,
  Api.getProgramInstitutions,
  []
);

export const getProgramInstitutionById = (id) => async dispatch => networkAction(
  dispatch,
  GET_PROGRAM_INSTITUTION_BY_ID,
  Api.getProgramInstitutionById,
  [id]
);

export const deleteProgramInstitution = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_PROGRAM_INSTITUTION,
  Api.deleteProgramInstitution,
  [id]
);

export const createProgramInstitution = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_PROGRAM_INSTITUTION,
  Api.createProgramInstitution,
  [body]
);

export const updateProgramInstitution = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_PROGRAM_INSTITUTION,
  Api.updateProgramInstitution,
  [id, body]
);
