import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import Typography from "@material-ui/core/Typography";
import Delete from "@material-ui/icons/Delete";
import More from "@material-ui/icons/MoreVert";
import OpenInNew from "@material-ui/icons/OpenInNew";
import PropTypes from "prop-types";
import React, { Component } from "react";
// images
import ai from "./ai.png";
import avi from "./avi.png";
import css from "./css.png";
import csv from "./csv.png";
import doc from "./doc.png";
import html from "./html.png";
import javascript from "./javascript.png";
import jpg from "./jpg.png";
import json from "./json.png";
import mp3 from "./mp3.png";
import mp4 from "./mp4.png";
import pdf from "./pdf.png";
import png from "./png.png";
import ppt from "./ppt.png";
import psd from "./psd.png";
import txt from "./txt.png";
import { default as xls, default as xml } from "./xls.png";

class File extends Component {
  static propTypes = {
    file: PropTypes.object,
    deleteFile: PropTypes.func,
    refresh: PropTypes.func,
  };

  state = {
    hover: false,
    anchorEl: null,
  };

  handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getFileIconBasedOnMime() {
    const { file } = this.props;
    const { type } = file;
    const _type = type || file.type;
    switch (_type) {
      case "application/postscript":
        return ai;
      case "application/x-troff-msvideo":
      case "video/avi":
      case "video/msvideo":
      case "video/x-msvideo":
        return avi;
      case "text/css":
      case "application/x-pointplus":
        return css;
      case "text/csv":
        return csv;
      case "application/msword":
        return doc;
      case "text/html":
        return html;
      case "application/x-javascript":
      case "application/javascript":
      case "text/javascript":
        return javascript;
      case "image/jpeg":
      case "image/pjpeg":
        return jpg;
      case "audio/mpeg3":
      case "audio/x-mpeg-3":
      case "video/mpeg":
      case "video/x-mpeg":
        return mp3;
      case "application/json":
        return json;
      case "video/mp4":
        return mp4;
      case "application/pdf":
        return pdf;
      case "image/png":
        return png;
      case "application/mspowerpoint":
      case "application/powerpoint":
      case "application/vnd.ms-powerpoint":
      case "application/x-mspowerpoint":
        return ppt;
      case "application/octet-stream":
        return psd;
      case "text/plain":
        return txt;
      case "application/excel":
      case "application/vnd.ms-excel":
      case "application/x-excel":
      case "application/x-msexcel":
        return xls;
      case "application/xml":
      case "text/xml":
        return xml;
      default:
        return undefined;
    }
  }

  getFileIcon() {
    const { file } = this.props;
    const icon = this.getFileIconBasedOnMime();

    if (icon) {
      return icon;
    }
    const extension = file.name.split(".")[1];
    switch (extension) {
      case "ai":
        return ai;
      case "avi":
        return avi;
      case "css":
        return css;
      case "csv":
        return csv;
      case "doc":
      case "docx":
        return doc;
      case "html":
        return html;
      case "javascript":
        return javascript;
      case "jpg":
        return jpg;
      case "mp3":
        return mp3;
      case "json":
        return json;
      case "mp4":
        return mp4;
      case "pdf":
        return pdf;
      case "png":
        return png;
      case "ppt":
      case "pptx":
        return ppt;
      case "psd":
        return psd;
      case "txt":
        return txt;
      case "xls":
      case "xlsx":
        return xls;
      case "xml":
        return xml;
      default:
        return txt;
    }
  }

  render() {
    const { file, refresh, deleteFile } = this.props;
    const { hover, anchorEl } = this.state;

    console.log(hover, deleteFile);

    return (
      <div
        style={{
          float: "left",
          margin: 20,
          textAlign: "center",
          cursor: "pointer",
          position: "relative",
          paddingTop: 25,
          minWidth: 100,
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {hover && deleteFile ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <IconButton
              aria-label="More"
              onClick={this.handleClick}
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
            >
              <More fontSize="small" />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.handleClose();
                  window.open(file.url, "_blank");
                }}
              >
                <ListItemIcon>
                  <OpenInNew />
                </ListItemIcon>
                <ListItemText primary="Open" />
              </MenuItem>
              <MenuItem
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  await deleteFile(file.id);
                  if (refresh) refresh();
                }}
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText primary="Delete" />
              </MenuItem>
            </Menu>
          </div>
        ) : (
          []
        )}
        <img src={this.getFileIcon()} alt="file" />
        <br />
        <Typography display="block" variant="caption" color="textSecondary">
          {file.name.length > 20
            ? `${file.name.substring(0, 20)}...`
            : file.name}
        </Typography>
      </div>
    );
  }
}

export default File;
