import * as Api from "api";
import {
  CREATE_GRADE,
  DELETE_GRADE,
  GET_GRADES,
  GET_GRADE_BY_ID,
  GET_PROGRAMS_BY_GRADE,
  UPDATE_GRADE,
} from "constants/grades";
import { networkAction } from "helpers/network/networkAction";

export const getGrades = () => async (dispatch) =>
  networkAction(dispatch, GET_GRADES, Api.getGrades, []);

export const getProgramsByGrade = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAMS_BY_GRADE, Api.getProgramsByGrade, [id]);

export const getGradeById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GRADE_BY_ID, Api.getGradeById, [id]);

export const deleteGrade = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_GRADE, Api.deleteGrade, [id]);

export const createGrade = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GRADE, Api.createGrade, [body]);

export const updateGrade = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_GRADE, Api.updateGrade, [id, body]);
