export default (theme) => ({
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
  darkMode: {
    background:
      theme.palette.type === "dark" ? "rgba(255,255,255,1)" : undefined,
    borderRadius: 4,
    padding: 4,
  },
});
