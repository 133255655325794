import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "aptitude";
const medias = `/${service}/media`;

export function getMedias() {
  const url = `${medias}`;
  return {
    method: GET,
    url,
  };
}

export function getMediaById(id) {
  const url = `${medias}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateMedia(id, body) {
  const url = `${medias}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMedia(id) {
  const url = `${medias}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMedia(body) {
  const url = `${medias}`;
  return {
    method: POST,
    url,
    body,
  };
}
