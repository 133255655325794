// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperAcademyPage from "../component/WrapperAcademiesPage";

const mapStateToProps = (state) => ({
  academies: state.academies,
});

const mapDispatchToProps = (dispatch) => ({
  getAcademies: (...args) => dispatch(Actions.getAcademies(...args)),
  getAcademyById: (...args) => dispatch(Actions.getAcademyById(...args)),
  createAcademy: (...args) => dispatch(Actions.createAcademy(...args)),
  deleteAcademy: (...args) => dispatch(Actions.deleteAcademy(...args)),
  updateAcademy: (...args) => dispatch(Actions.updateAcademy(...args)),
});
class AcademyPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    academies: PropTypes.array,
    getAcademies: PropTypes.func,
    getAcademyById: PropTypes.func,
    createAcademy: PropTypes.func,
    deleteAcademy: PropTypes.func,
    updateAcademy: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, academies } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
      loading: true,
      dataLoading: false,
    };

    this.refresh();
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.location.search !== this.props.location.search) {
  //     const urlParams = getJsonFromUrl(nextProps.location);
  //     console.log(urlParams);
  //     this.setState(
  //       {
  //         filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
  //       },
  //       this.refresh.bind(this)
  //     );
  //   }
  // }

  async refresh() {
    console.log("refresh");
    const { getAcademies } = this.props;
    const { urlParams } = this.state;

    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    this.setState({ dataLoading: true });
    await Promise.all([getAcademies(filters)]);

    this.setState({ loading: false, dataLoading: false });
  }

  render() {
    const {
      history,
      location,
      academies,
      getAcademyById,
      createAcademy,
      deleteAcademy,
      updateAcademy,
      getAcademies,
    } = this.props;

    const { urlParams, loading, dataLoading, filters } = this.state;

    return (
      <WrapperAcademyPage
        history={history}
        location={location}
        filters={filters}
        dataLoading={dataLoading}
        refresh={this.refresh.bind(this)}
        loading={loading}
        getAcademies={getAcademies}
        academies={academies}
        getAcademyById={getAcademyById}
        createAcademy={createAcademy}
        deleteAcademy={deleteAcademy}
        updateAcademy={updateAcademy}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AcademyPage);
