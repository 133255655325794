import React, { Component } from "react";
import PropTypes from "prop-types";
import AvatarEditor from "react-avatar-editor";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class AvatarScale extends Component {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    close: PropTypes.func,
    onSave: PropTypes.func,
    image: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    this.editor = React.createRef();
    this.state = {
      scale: 1,
      rotate: 50,
      imageURL: undefined,
    };
  }

  onClick() {
    const { onSave, close } = this.props;
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = this.editor.getImageScaledToCanvas();
      const url = canvas.toDataURL();
      // let imageURL;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          onSave(blob);
          close();
        });
    }
  }

  handleScaleChange = (event, value) => {
    this.setState({ scale: value });
  };

  render() {
    const { image, open, close } = this.props;

    const { scale, rotate, imageURL } = this.state;

    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit your Avatar</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} justify="center">
            <Grid item>
              <AvatarEditor
                ref={(r) => (this.editor = r)}
                image={image}
                crossOrigin="anonymous"
                width={250}
                height={250}
                border={0}
                borderRadius={125}
                scale={scale / 6 + 1}
                rotate={rotate * 3.6 - 180}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" style={{ paddingBottom: 10 }}>
                Zoom
              </Typography>
              <Slider
                value={scale}
                aria-labelledby="label"
                onChange={(e, v) => this.setState({ scale: v })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" style={{ paddingBottom: 10 }}>
                Rotate
              </Typography>
              <Slider
                value={rotate}
                aria-labelledby="label"
                onChange={(e, v) => this.setState({ rotate: v })}
              />
            </Grid>
            {imageURL ? (
              <Grid item>
                {imageURL}
                <img src={imageURL} />
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.handleClose}
            color="primary"
            variant="contained"
            autoFocus
            onClick={this.onClick.bind(this)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AvatarScale);
