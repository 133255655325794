import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const careerTitles = `${REACT_APP_API_PREFIX}/${service}/careerTitles`;

export function getCareerTitles(filters) {
  const url = `${careerTitles}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCareerTitleById(id) {
  const url = `${careerTitles}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCareerTitle(id, body) {
  const url = `${careerTitles}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCareerTitle(id) {
  const url = `${careerTitles}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCareerTitle(body) {
  const url = `${careerTitles}`;
  return {
    method: POST,
    url,
    body,
  };
}
