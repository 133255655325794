import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  InputBase,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Deletable from "components/AdvancedSearchRefactor/Deletable";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TwitterPicker } from "react-color";
// styles
import styles from "./styles";

class FormProgramType extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    programType: PropTypes.object,

    createProgramType: PropTypes.func,
    updateProgramType: PropTypes.func,
    deleteProgramType: PropTypes.func,

    createProgramSubcategory: PropTypes.func,
    updateProgramSubcategory: PropTypes.func,
    deleteProgramSubcategory: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      visible: false,
      name: "",
      icon: "",
      color: "",
      subcategories: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.programType);
    }
  }

  handleChange = (v) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [v]: value,
    });
  };

  init(programType) {
    this.setState({
      loading: false,
      name: programType ? programType.name : "",
      icon: programType ? programType.icon : "",
      color: programType ? programType.color : "",
      visible: programType ? programType.visible : false,
      subcategories: programType ? programType.subcategories : [],
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this programType, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          programType: "default",
        },
        confirm: {
          label: "I am sure",
          programType: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteProgramType, programType, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteProgramType(programType.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "ProgramType has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateProgramType,
      createProgramType,
      close,
      programType,
      refresh,
    } = this.props;

    const { name, color, icon, visible } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (programType) {
      resp = await updateProgramType(programType.id, {
        name,
        color,
        icon,
        visible,
      });
    } else {
      resp = await createProgramType({
        name,
        color,
        icon,
        visible,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      refresh();
      close();
      NotificationCenter.sweetAlert({
        title: programType
          ? "ProgramType has been updated."
          : "ProgramType has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  handleColorChange = (name) => (color) => {
    this.setState({ [name]: color.hex });
  };

  async updateSubcategory(s) {
    const { updateProgramSubcategory } = this.props;
    this.setState({ programSubcategoryLoading: s.id });
    await updateProgramSubcategory(s.id, s);
    this.setState({ programSubcategoryLoading: 0 });
  }

  deleteSubcategory = (s) => async () => {
    const { subcategories } = this.state;
    const { deleteProgramSubcategory } = this.props;
    const r = await deleteProgramSubcategory(s.id);

    if (r.success) {
      const _subcategories = [...subcategories];
      _subcategories.splice(subcategories.findIndex((c) => c.id === s.id));
      this.setState((s) => ({
        subcategories: _subcategories,
      }));
    }
  };

  async addSubcategory() {
    const { createProgramSubcategory, programType } = this.props;
    const r = await createProgramSubcategory({
      programTypeID: programType.id,
    });

    if (r.success)
      this.setState((s) => ({
        subcategories: [...s.subcategories, r.payload],
      }));
  }

  render() {
    const { classes, fullScreen, open, programType, close } = this.props;

    const {
      name,
      loading,
      icon,
      color,
      visible,
      subcategories,
      programSubcategoryLoading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {programType
                ? `${programType.name}`
                : "Let's Create a New ProgramType"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {programType
                  ? "To update the programType, fill the form below :"
                  : "To create a new programType, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="icon"
                    label="Icon URL"
                    className={classes.textField}
                    value={icon}
                    onChange={this.handleChange("icon")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={visible}
                        onChange={(e) =>
                          this.setState({ visible: e.target.checked })
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Visible on the map"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TwitterPicker
                    color={color || ""}
                    disableAlpha
                    onChange={this.handleColorChange("color")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <Typography variant="subtitle2">Subcategories</Typography>
                </Grid>
                {subcategories.map((s, i) => (
                  <Grid item key={s.id}>
                    <Deletable
                      onDelete={this.deleteSubcategory(s)}
                      loading={programSubcategoryLoading === s.id}
                    >
                      <InputBase
                        value={s.name}
                        autoFocus
                        placeholder={"Name"}
                        onChange={(e) => {
                          const _subcategories = [...subcategories];
                          _subcategories[i].name = e.target.value;
                          this.setState({
                            subcategories: _subcategories,
                          });
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            this.updateSubcategory(s);
                          }
                        }}
                        onBlur={() => this.updateSubcategory(s)}
                        className={classes.inputBase}
                      />
                    </Deletable>
                  </Grid>
                ))}
                <Grid item>
                  <Chip
                    onClick={this.addSubcategory.bind(this)}
                    label="+ Subcategory"
                    variant="outline"
                    size="small"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {programType && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {programType ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormProgramType));
