import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// components
import Timezone from "components/Timezone";

// styles
import styles from "./styles";
import { ClickAwayListener, Popper, Paper } from "@material-ui/core";

class TimezoneSelect extends Component {
  static propTypes = {
    classes: PropTypes.object,
    timezones: PropTypes.array,
    tzName: PropTypes.string,
    onChange: PropTypes.func,
  };

  state = {
    hover: false,
    anchorEl: null,
  };

  getData() {
    const { timezones } = this.props;
    const data = [];

    for (const key in timezones) {
      if (timezones.hasOwnProperty(key)) {
        const timezone = timezones[key];
        data.push({
          label: `(GMT${timezone.utc}) ${timezone.display}`,
          value: timezone.name,
        });
      }
    }

    return data;
  }

  render() {
    const { tzName, onChange } = this.props;

    const data = this.getData();
    const find = data.find((d) => d.value === tzName);

    return (
      <div>
        <Select
          className="basic-single"
          placeholder="Select the correct timezone"
          classNamePrefix="select"
          value={find}
          isClearable
          isSearchable
          name="tzName"
          onChange={(i) => {
            onChange(i.value);
            this.setState({ anchorEl: null });
          }}
          options={this.getData()}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TimezoneSelect);
