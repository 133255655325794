import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';

// styles
import styles from './styles';

class InstitutionsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    institutions: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedInstitution) {
    const { onSelect } = this.props;
    this.setState({ selectedInstitution });
    if (onSelect) {
      onSelect(selectedInstitution);
    }
  }

  render() {
    const { institutions } = this.props;

    return (
        <Table
          data={institutions}
          meta={[
            {
              path: 'name',
              title: 'Name',
              numeric: false,
              hidden: { mdDown: true },
            },
          ]}
          title={'Institutions'}
          onRowSelect={this.onSelect.bind(this)}
        />
    );
  }
}

export default withStyles(styles)(InstitutionsList);
