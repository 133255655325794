import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const CareerMedia = `${REACT_APP_API_PREFIX}/${service}/careerMedia`;

export function getCareerMedia(filters) {
  const url = `${CareerMedia}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCareerMediumById(id) {
  const url = `${CareerMedia}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateCareerMedium(id, body) {
  const url = `${CareerMedia}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCareerMedium(id) {
  const url = `${CareerMedia}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCareerMedium(body) {
  const url = `${CareerMedia}`;
  return {
    method: POST,
    url,
    body,
  };
}
