import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import SaveIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// styles
import styles from './styles';



class FormMasterTag extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    masterTag: PropTypes.object,

    createMasterTag: PropTypes.func,
    updateMasterTag: PropTypes.func,
    deleteMasterTag: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.masterTag);
    }
  }

  init(masterTag) {
    this.setState({
      loading: false,
      name: masterTag ? masterTag.name : '',
    });
  }

  handleChange = name => event => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: 'If you delete this masterTag, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
          callback: () => console.log('Callback'),
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const {
      deleteMasterTag,
      close,
      refresh,
      masterTag,
    } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteMasterTag(masterTag.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'MasterTag has been deleted.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateMasterTag,
      createMasterTag,
      masterTag,
      refresh,
      close,
    } = this.props;

    const {
      name,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (masterTag) {
      resp = await updateMasterTag(
        masterTag.id,
        {
          name,
        }
      );
    } else {
      resp = await createMasterTag({
        name,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: masterTag ? 'MasterTag has been updated.' : 'MasterTag has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      masterTag,
      close,
    } = this.props;

    const {
      name,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading ?
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">
              Loading
            </DialogTitle>
            <CircularProgress />
          </div>
          :
          <div>
            <DialogTitle id="responsive-dialog-title">
              {
                masterTag ?
                `${masterTag.name}`
                :
                'Let\'s Create a New MasterTag'
              }
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {
                  masterTag ?
                  'To update the masterTag, fill the form below :'
                  :
                  'To create a new masterTag, fill the form below :'
                }
              </DialogContentText>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name *"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange('name')}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              className={classes.dialogActions}
            >
              {
                masterTag ?
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
                :
                []
              }
              <Button
                onClick={close}
              >
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button
                color="primary"
                autoFocus
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} /> {
                  masterTag ?
                  'Save'
                  :
                  'Create'
                }
              </Button>
            </DialogActions>
          </div>
        }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormMasterTag));
