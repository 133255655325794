import { networkAction } from "helpers/network/networkAction";

import {
  GET_AVAILABILITIES,
  GET_AVAILABILITY_BY_ID,
  DELETE_AVAILABILITY,
  UPDATE_AVAILABILITY,
  CREATE_AVAILABILITY,
} from "constants/availabilities";

import * as Api from "api";

export const getAvailabilities = () => async (dispatch) =>
  networkAction(dispatch, GET_AVAILABILITIES, Api.getAvailabilities, []);

export const getAvailabilityById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_AVAILABILITY_BY_ID, Api.getAvailabilityById, [
    id,
  ]);

export const deleteAvailability = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_AVAILABILITY, Api.deleteAvailability, [id]);

export const createAvailability = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_AVAILABILITY, Api.createAvailability, [body]);

export const updateAvailability = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_AVAILABILITY, Api.updateAvailability, [
    id,
    body,
  ]);
