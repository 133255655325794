import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const careers = `/${service}/careers`;
const careerCategories = `/${service}/careerCategories`;

export function getCareerCategories(id) {
  const url = `${careers}/${id}/categories`;
  return {
    method: GET,
    url,
  };
}

export function deleteCareerCategory(relationID) {
  const url = `${careerCategories}/${relationID}`;
  return {
    method: DELETE,
    url,
  };
}

export function addCareerCategory(body) {
  const url = `${careerCategories}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function getFullApp(filters) {
  const url = `${careers}/full${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCareers(filters) {
  const url = `${careers}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCareerById(id) {
  const url = `${careers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCareer(id, body) {
  const url = `${careers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCareer(id) {
  const url = `${careers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCareer(body) {
  const url = `${careers}`;
  return {
    method: POST,
    url,
    body,
  };
}
