import { networkAction } from 'helpers/network/networkAction';

import {
  GET_AUTHORS,
  GET_AUTHOR_BY_ID,
  DELETE_AUTHOR,
  UPDATE_AUTHOR,
  CREATE_AUTHOR,
} from 'constants/authors';

import * as Api from 'api';

export const getAuthors = () => async dispatch => networkAction(
  dispatch,
  GET_AUTHORS,
  Api.getAuthors,
  []
);

export const getAuthorById = (id) => async dispatch => networkAction(
  dispatch,
  GET_AUTHOR_BY_ID,
  Api.getAuthorById,
  [id]
);

export const deleteAuthor = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_AUTHOR,
  Api.deleteAuthor,
  [id]
);

export const createAuthor = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_AUTHOR,
  Api.createAuthor,
  [body]
);

export const updateAuthor = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_AUTHOR,
  Api.updateAuthor,
  [id, body]
);
