import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormSkillTag from "components/FormSkillTag";
import Page from "components/Page";
// components
import SkillTagsList from "components/SkillTagsList";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperSkillTagsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //skillTags
    skillTags: PropTypes.array,
    getSkillTagById: PropTypes.func,
    createSkillTag: PropTypes.func,
    deleteSkillTag: PropTypes.func,
    updateSkillTag: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSkillTag: undefined,
      formSkillTagOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { skillTags } = this.props;

    switch (index) {
      case 0:
        return (
          <SkillTagsList
            skillTags={skillTags}
            onSelect={(selectedSkillTag) =>
              this.setState({
                selectedSkillTag,
                formSkillTagOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createSkillTag,
      deleteSkillTag,
      updateSkillTag,
      refresh,
    } = this.props;

    const { index, selectedSkillTag, formSkillTagOpen } = this.state;

    return (
      <Page
        helmet={"SkillTags"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formSkillTagOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="SkillTags"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormSkillTag
          open={formSkillTagOpen}
          skillTag={selectedSkillTag}
          close={() => {
            this.setState({
              formSkillTagOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedSkillTag: undefined,
                }),
              200
            );
          }}
          createSkillTag={createSkillTag}
          deleteSkillTag={deleteSkillTag}
          updateSkillTag={updateSkillTag}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSkillTagsPage);
