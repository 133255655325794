// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class InquiriesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    inquiries: PropTypes.array,
    history: PropTypes.object,
    getInquiries: PropTypes.func,
    onSelect: PropTypes.func,
    searchUsers: PropTypes.func,
  };

  onSelect(selectedDownloadRequest) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedDownloadRequest);
    }
  }

  render() {
    const { inquiries, getInquiries, history, searchUsers } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getInquiries}
        keyword="inquiries"
        metaDataEndpoint="/harmony/inquiries/searchMetadata"
      >
        <Table
          data={inquiries}
          meta={[
            {
              path: "name",
              title: "Name",
            },
            {
              path: "description",
              title: "Description",
            },
            {
              path: "kind",
              title: "Kind",
            },
            {
              path: "question",
              title: "Question",
            },
          ]}
          title={"Inquiries Questions"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(InquiriesList);
