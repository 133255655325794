import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import Add from "@material-ui/icons/Add";
// custom
import FormProgram from "components/FormProgram";
// reactor
import Page from "components/Page";
import ProgramEdit from "components/ProgramEdit";
import ProgramsList from "components/ProgramsList";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperAcademyPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    selectedProgram: PropTypes.object,
    width: PropTypes.string,
    loading: PropTypes.bool,
    contentLoading: PropTypes.bool,
    refreshKey: PropTypes.number,
    api: PropTypes.object,
    refresh: PropTypes.func,
    programs: PropTypes.array,
    academies: PropTypes.array,
    filteredPrograms: PropTypes.object,
    academy: PropTypes.object,
    programTypes: PropTypes.array,
    institutions: PropTypes.array,
    grades: PropTypes.array,
    ages: PropTypes.array,
    levels: PropTypes.array,
    divisions: PropTypes.array,
    instruments: PropTypes.array,
    musicalFamilies: PropTypes.array,
    programSubcategories: PropTypes.array,
    masterTags: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      formAcademyOpen: false,
      formProgramOpen: false,
    };
  }

  // test

  render() {
    const {
      classes,
      history,
      location,
      loading,
      contentLoading,
      filteredPrograms,
      academies,
      refresh,
      api,
      ages,
      programTypes,
      grades,
      levels,
      instruments,
      musicalFamilies,
      divisions,
      selectedProgram,
      refreshKey,
      getPrograms,
      institutions,
      programSubcategories,
      masterTags,
    } = this.props;

    const { index, formProgramOpen } = this.state;

    return (
      <React.Fragment>
        <Page
          helmet="Programs"
          loadingMessage={"Loading Programs"}
          loading={loading}
          header={
            <Grid
              container
              alignItems="center"
              justify="space-between"
              style={{
                padding: "10px 0px 0px 10px",
                width: selectedProgram ? "calc(100% - 400px)" : undefined,
              }}
            >
              <Grid item>
                <Typography display="block" variant="h6">
                  All Programs
                </Typography>
              </Grid>
              <Grid item>
                <Chip
                  label="Program"
                  color="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => this.setState({ formProgramOpen: true })}
                  avatar={
                    <Avatar style={{ background: "none" }}>
                      <Add />
                    </Avatar>
                  }
                />
              </Grid>
            </Grid>
          }
          tabs={
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push(
                  `?index=${v}${
                    selectedProgram?.id
                      ? `?programID=${selectedProgram.id}`
                      : ""
                  }`
                );
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
            >
              {filteredPrograms &&
                Object.keys(filteredPrograms).map((fp) => (
                  <Tab
                    label={
                      <Badge
                        badgeContent={filteredPrograms[fp].length}
                        color="primary"
                      >
                        {fp}
                      </Badge>
                    }
                    key={fp}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                  />
                ))}
            </Tabs>
          }
        >
          <div
            style={{
              marginRight: selectedProgram ? 400 : undefined,
            }}
          >
            <ProgramsList
              programs={
                (filteredPrograms &&
                  filteredPrograms[Object.keys(filteredPrograms)[index]]) ||
                []
              } // TO_CHANGE
              selectedProgram={selectedProgram}
              getPrograms={api.getPrograms}
              history={history}
              onSelect={(sp) => {
                const urlParams = getJsonFromUrl(location);
                delete urlParams.programID;
                urlParams.programID = JSON.stringify(sp.id);
                const search = Object.keys(urlParams)
                  .map(
                    (k) =>
                      `${encodeURIComponent(k)}=${encodeURIComponent(
                        urlParams[k]
                      )}`
                  )
                  .join("&");
                history.push({
                  search,
                });
              }}
            />
          </div>
          <FormProgram
            open={formProgramOpen}
            academies={academies}
            history={history}
            programTypes={programTypes}
            close={() => {
              this.setState({
                formProgramOpen: false,
              });
            }}
            createProgram={api.createProgram}
            refresh={refresh}
          />
        </Page>
        {selectedProgram || contentLoading ? (
          <Drawer
            open
            anchor="right"
            variant={"persistent"}
            classes={{
              paper: classes.drawer,
            }}
          >
            <ProgramEdit
              api={api}
              academies={academies}
              history={history}
              program={selectedProgram}
              programSubcategories={programSubcategories}
              ages={ages}
              programTypes={programTypes}
              grades={grades}
              levels={levels}
              instruments={instruments}
              musicalFamilies={musicalFamilies}
              divisions={divisions}
              refresh={refresh}
              refreshKey={refreshKey}
              contentLoading={contentLoading}
              institutions={institutions}
              masterTags={masterTags}
            />
          </Drawer>
        ) : (
          []
        )}
      </React.Fragment>
    );
  }
}
export default withWidth()(withStyles(styles)(WrapperAcademyPage));
