import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVert from "@material-ui/icons/MoreVert";
import Search from "@material-ui/icons/Search";
// constants
import { ACADEMY } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class QuickAcademySwitch extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    academies: PropTypes.array,
  };

  state = {
    anchorEl: null,
    search: "",
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { academies, history } = this.props;
    const { anchorEl, search } = this.state;

    return (
      <React.Fragment>
        <Tooltip title="Quick Academy Change">
          <IconButton
            style={{ padding: 5 }}
            onClick={this.handleClick.bind(this)}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem>
            <FormControl>
              <InputLabel htmlFor="adornment-password">Search</InputLabel>
              <Input
                id="adornment-password"
                value={search}
                fullWidth
                onChange={(e) => this.setState({ search: e.target.value })}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          </MenuItem>
          {academies &&
            academies
              .filter((a) => a.name.toLowerCase().indexOf(search) !== -1)
              .map((a) => (
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    history.push(`${ACADEMY}?id=${a.id}`);
                  }}
                  key={a.id}
                >
                  {a.name}
                </MenuItem>
              ))}
        </Menu>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(QuickAcademySwitch);
