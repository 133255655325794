import * as Api from "api";
import {
  ADD_CAREER_CATEGORY,
  CREATE_CAREER,
  DELETE_CAREER,
  DELETE_CAREER_CATEGORY,
  GET_CAREERS,
  GET_CAREER_BY_ID,
  GET_CAREER_CATEGORIES,
  GET_FULL_APP,
  UPDATE_CAREER,
} from "constants/careers";
import { networkAction } from "helpers/network/networkAction";

export const getFullApp = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_FULL_APP, Api.getFullApp, [filters]);

export const getCareers = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CAREERS, Api.getCareers, [filters]);

export const addCareerCategory = (body) => async (dispatch) =>
  networkAction(dispatch, ADD_CAREER_CATEGORY, Api.addCareerCategory, [body]);

export const getCareerCategories = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_CATEGORIES, Api.getCareerCategories, [id]);

export const deleteCareerCategory = (relationID) => async (dispatch) =>
  networkAction(dispatch, DELETE_CAREER_CATEGORY, Api.deleteCareerCategory, [
    relationID,
  ]);

export const getCareerById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_BY_ID, Api.getCareerById, [id]);

export const deleteCareer = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CAREER, Api.deleteCareer, [id]);

export const createCareer = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CAREER, Api.createCareer, [body]);

export const updateCareer = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CAREER, Api.updateCareer, [id, body]);
