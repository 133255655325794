import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "papyrus";
const downloadRequests = `/${service}/downloadRequests`;

export function getDownloadRequests(filters) {
  const url = `${downloadRequests}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getDownloadRequestById(id) {
  const url = `${downloadRequests}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateDownloadRequest(id, body) {
  const url = `${downloadRequests}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteDownloadRequest(id) {
  const url = `${downloadRequests}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createDownloadRequest(body) {
  const url = `${downloadRequests}`;
  return {
    method: POST,
    url,
    body,
  };
}
