import * as Api from "api";
import {
  CREATE_CAREER_DEGREE_LEVEL,
  DELETE_CAREER_DEGREE_LEVEL,
  GET_CAREER_DEGREE_LEVELS,
  GET_CAREER_DEGREE_LEVEL_BY_ID,
  UPDATE_CAREER_DEGREE_LEVEL,
} from "constants/careerDegreeLevels";
import { networkAction } from "helpers/network/networkAction";

export const getCareerDegreeLevels = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_DEGREE_LEVELS, Api.getCareerDegreeLevels, [
    filters,
  ]);

export const getCareerDegreeLevelById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CAREER_DEGREE_LEVEL_BY_ID,
    Api.getCareerDegreeLevelById,
    [id]
  );

export const deleteCareerDegreeLevel = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_CAREER_DEGREE_LEVEL,
    Api.deleteCareerDegreeLevel,
    [id]
  );

export const createCareerDegreeLevel = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_CAREER_DEGREE_LEVEL,
    Api.createCareerDegreeLevel,
    [body]
  );

export const updateCareerDegreeLevel = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_CAREER_DEGREE_LEVEL,
    Api.updateCareerDegreeLevel,
    [id, body]
  );
