import masterTags from './masterTags';
import { combineReducers } from "redux"; //eslint-disable-line
import academies from "./academies";
import ages from "./ages";
import app from "./app";
import assessments from "./assessments";
import authors from "./authors";
import books from "./books";
import calendyStudents from "./calendyStudents";
import careers from "./careers";
import clubMembers from "./clubMembers";
import coaches from "./coaches";
import courses from "./courses";
import cpfmCategories from "./cpfmCategories";
import degreeLevels from "./degreeLevels";
import degreeRequirements from "./degreeRequirements";
import divisions from "./divisions";
import downloadRequests from "./downloadRequests";
import enrollments from "./enrollments";
import errors from "./errors";
import events from "./events";
import eventTypes from "./eventTypes";
import grades from "./grades";
import inquiries from "./inquiries";
import institutions from "./institutions";
import instruments from "./instruments";
import levels from "./levels";
import musicalFamilies from "./musicalFamilies";
import products from "./products";
import profiles from "./profiles";
import programs from "./programs";
import programSubcategories from "./programSubcategories";
import programTypes from "./programTypes";
import quizCategories from "./quizCategories";
import quizTags from "./quizTags";
import searchPresets from "./searchPresets";
import skillTags from "./skillTags";
import subCategories from "./subCategories";
import surveys from "./surveys";
import timeAssets from "./timeAssets";
import timezones from "./timezones";
import user from "./user";
import userApps from "./userApps";
import users from "./users";
export default combineReducers({
  errors,
  skillTags,
  careers,
  subCategories,
  searchPresets,
  cpfmCategories,
  programTypes,
  user,
  levels,
  grades,
  divisions,
  instruments,
  musicalFamilies,
  academies,
  ages,
  app,
  users,
  userApps,
  courses,
  assessments,
  surveys,
  enrollments,
  profiles,
  downloadRequests,
  eventTypes,
  events,
  coaches,
  clubMembers,
  calendyStudents,
  timezones,
  programs,
  degreeLevels,
  degreeRequirements,
  institutions,
  products,
  books,
  timeAssets,
  inquiries,
  quizTags,
  quizCategories,
  authors,
  programSubcategories,
  masterTags,
});
