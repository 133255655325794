import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TwitterPicker } from "react-color";
// styles
import styles from "./styles";

class FormQuizTag extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    quizTag: PropTypes.object,

    createQuizTag: PropTypes.func,
    updateQuizTag: PropTypes.func,
    deleteQuizTag: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      color: "",
      categoryID: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.quizTag);
    }
  }

  init(quizTag) {
    this.setState({
      loading: false,
      name: quizTag ? quizTag.name : "",
      color: quizTag ? quizTag.color : "",
      categoryID: quizTag ? quizTag.categoryID : "",
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle:
          "If you delete this quizTag, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteQuizTag, close, refresh, quizTag } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteQuizTag(quizTag.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "QuizTag has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateQuizTag,
      createQuizTag,
      quizTag,
      refresh,
      close,
    } = this.props;

    const { name, color, categoryID } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (quizTag) {
      resp = await updateQuizTag(quizTag.id, {
        name,
        color,
        categoryID,
      });
    } else {
      resp = await createQuizTag({
        name,
        color,
        categoryID,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: quizTag
          ? "QuizTag has been updated."
          : "QuizTag has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      quizTag,
      close,
      quizCategories,
    } = this.props;

    const { name, color, loading, categoryID } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {quizTag ? `${quizTag.name}` : "Let's Create a New QuizTag"}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name *"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Category"
                    className={classes.textField}
                    value={categoryID}
                    select
                    onChange={this.handleChange("categoryID")}
                  >
                    {quizCategories.map((t) => (
                      <MenuItem key={t.id} value={t.id}>
                        {t.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={12}>
                  <div style={{ marginLeft: 10 }}>
                    <TwitterPicker
                      color={color ?? "#ffffff"}
                      triangle="hide"
                      onChangeComplete={(c) => {
                        if (c && c.hex) this.setState({ color: c.hex });
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {quizTag ? (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
              ) : (
                []
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />{" "}
                {quizTag ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormQuizTag));
