import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// custom
import CourseCard from "components/CourseCard";

// styles
import styles from "./styles";

class CoursesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    history: PropTypes.object,
    courses: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedCourse) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCourse);
    }
  }

  render() {
    const { courses, history } = this.props;

    return (
      <div>
        <Grid container spacing={2} alignItems="stretch">
          {courses &&
            courses.map((e) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CourseCard key={e.id} course={e} history={history} />
              </Grid>
            ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CoursesList);
