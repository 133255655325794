import { serviceRoles } from "config/access";
import { QUIZ_TAGS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import QuizTagsPage from "./container/QuizTagsPage";

export default function getSettingsRoute(requireAuthFunctions) {
  const { account, cpfm } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [cpfm.name]: [cpfm.roles.admin],
  };
  return {
    path: QUIZ_TAGS,
    onEnter: () => checkAccess(requireAuth, requireAuthFunctions),
    withSidebar: true,
    withAppBar: true,
    component: QuizTagsPage,
  };
}
