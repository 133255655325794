import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// components
import CoursesList from "components/CoursesList";
import FormCourse from "components/FormCourse";
// reactor
import Page from "components/Page";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperCoursePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    courses: PropTypes.array,
    createCourse: PropTypes.func,
    deleteCourse: PropTypes.func,
    updateCourse: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCourse: undefined,
      formCourseOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { courses, history } = this.props;

    switch (index) {
      case 0:
        return (
          <CoursesList
            history={history}
            courses={courses}
            onSelect={(selectedCourse) =>
              this.setState({
                selectedCourse,
                formCourseOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createCourse,
      deleteCourse,
      updateCourse,
      refresh,
    } = this.props;

    const { index, selectedCourse, formCourseOpen } = this.state;

    return (
      <Page
        helmet="Courses"
        loadingMessage={"Loading Courses"}
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formCourseOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormCourse
          open={formCourseOpen}
          course={selectedCourse}
          close={() => {
            this.setState({
              formCourseOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCourse: undefined,
                }),
              200
            );
          }}
          createCourse={createCourse}
          deleteCourse={deleteCourse}
          updateCourse={updateCourse}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCoursePage);
