import * as Api from "api";
import {
  CREATE_TIME_ASSET,
  DELETE_TIME_ASSET,
  GET_TIME_ASSETS,
  GET_TIME_ASSET_BY_ID,
  UPDATE_TIME_ASSET,
} from "constants/timeAssets";
import { networkAction } from "helpers/network/networkAction";

export const getTimeAssets = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_TIME_ASSETS, Api.getTimeAssets, [filters]);

export const getTimeAssetById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TIME_ASSET_BY_ID, Api.getTimeAssetById, [id]);

export const deleteTimeAsset = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TIME_ASSET, Api.deleteTimeAsset, [id]);

export const createTimeAsset = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TIME_ASSET, Api.createTimeAsset, [body]);

export const updateTimeAsset = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TIME_ASSET, Api.updateTimeAsset, [id, body]);
