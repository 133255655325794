import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Close from "@material-ui/icons/Close";

// custom
import Page from "components/Page";
import EventTypeEdit from "components/EventTypeEdit";

// constants
import { EVENT_TYPES } from "constants/routes";

// styles
import styles from "./styles";
import {
  createEventTypeCoach,
  deleteEventTypeCoach,
} from "../../../api/calendy/eventTypeCoaches";

class WrapperEventTypePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    eventType: PropTypes.object,
    availabilities: PropTypes.array,
    updateAvailability: PropTypes.func,
    deleteAvailability: PropTypes.func,
    createAvailability: PropTypes.func,
    updateEventType: PropTypes.func,
    getDailyAvailabilities: PropTypes.func,
    deleteEventTypeCoach: PropTypes.func,
    eventTypeCoaches: PropTypes.array,
    createEventTypeCoach: PropTypes.func,
    refresh: PropTypes.func,
    coaches: PropTypes.array,
    allCoaches: PropTypes.array,
    refreshKey: PropTypes.number,
    loading: PropTypes.bool,
  };

  state = {
    anchorEl: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.eventType && this.state === undefined) {
      this.state = {
        ...nextProps.eventType,
      };
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      history,
      coaches,
      loading,
      eventType,
      refresh,
      updateEventType,
      eventTypeCoaches,
      createEventTypeCoach,
      deleteEventTypeCoach,
      allCoaches,
    } = this.props;

    const { anchorEl } = this.state;
    const coachToAdd = allCoaches.filter(
      (coach) =>
        eventTypeCoaches &&
        eventTypeCoaches.find((etc) => etc.coachID === coach.id) === undefined
    );

    console.log(coachToAdd);
    return (
      <Page helmet={"Event Type"} loading={loading}>
        {eventType ? (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.goBack()}
              >
                {"< Back"}
              </Button>
            </Grid>
            <Grid item>
              <Typography display="block" variant="h5">
                {eventType.name}
              </Typography>
            </Grid>
            <Grid item />
            <Grid item xs={12}>
              <Typography display="block" variant="title">
                General
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <EventTypeEdit
                eventType={eventType}
                updateEventType={updateEventType}
                refresh={refresh}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" variant="title">
                Coaches
              </Typography>
            </Grid>
            {eventTypeCoaches &&
              eventTypeCoaches.map((c) => {
                const coach = allCoaches.find((ac) => ac.id === c.coachID);
                if (coach) {
                  return (
                    <Grid
                      item
                      key={coach.id}
                      style={{ textAlign: "center", margin: 8 }}
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Avatar
                            src={coach.avatar}
                            style={{ width: 80, height: 80 }}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            label={coach.fullName}
                            onDelete={() =>
                              deleteEventTypeCoach(c.id).then(refresh)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
                return [];
              })}
            {coachToAdd && coachToAdd.length ? (
              <Grid item xs={12}>
                <Chip
                  label="+ Add Coach"
                  variant="outlined"
                  onClick={(event) =>
                    this.setState({ anchorEl: event.currentTarget })
                  }
                />
              </Grid>
            ) : (
              []
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose.bind(this)}
            >
              {coachToAdd &&
                coachToAdd.map((c) => (
                  <MenuItem
                    onClick={() =>
                      createEventTypeCoach({
                        eventTypeID: eventType.id,
                        coachID: c.id,
                      }).then(() => {
                        refresh();
                        this.setState({ anchorEl: null });
                      })
                    }
                  >
                    <ListItemIcon className={classes.icon}>
                      <Avatar src={c.avatar} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.primary }}
                      inset
                      primary={c.fullName}
                    />
                  </MenuItem>
                ))}
            </Menu>
          </Grid>
        ) : (
          []
        )}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEventTypePage);
