import CircularProgress from "@material-ui/core/CircularProgress";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class SurveysList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    surveys: PropTypes.array,
    onSelect: PropTypes.func,
    getSurveys: PropTypes.func,
    advancedSearch: PropTypes.bool,
    filters: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    assessments: PropTypes.array,
    dataLoading: PropTypes.bool,
    searchUsers: PropTypes.func,
    user: PropTypes.object,
  };

  onSelect(selectedSurvey) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedSurvey);
    }
  }

  render() {
    const {
      surveys,
      getSurveys,
      history,
      classes,
      assessments,
      user,
      searchUsers,
    } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        exportEndpoint="/harmony/surveys/csv"
        refresh={getSurveys}
        keyword="surveys"
        metaDataEndpoint="/harmony/surveys/searchMetadata"
        metaDataOveride={{
          assessmentID: {
            type: "autocomplete",
            data: assessments,
            valueKey: "id",
            labelKey: "name",
          },
          userUUID: {
            label: "User",
            type: "autoFill",
            getData: (search) =>
              searchUsers({
                text: search,
              }),
            valueKey: "uuid",
            labelTransform: (t) => `${t.firstName} ${t.lastName} (${t.email})`,
          },
        }}
      >
        <Table
          data={surveys}
          noToolbar
          rowStyle={(d) => ({
            borderLeft: `solid 3px ${d.assessmentColor}`,
          })}
          meta={[
            {
              path: "assessmentIcon",
              render: (t) => (
                <img
                  className={classes.darkMode}
                  src={t}
                  alt="assessment"
                  style={{ height: 40 }}
                />
              ),
              width: 30,
            },
            {
              path: "assessmentName",
              title: "Assessment",
              numeric: false,
            },
            {
              path: "userFullName",
              title: "User",
              render: (t, d) => (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    {t !== "null" ? t : "Unknown User"}
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {d.userUUID}
                  </Typography>
                </React.Fragment>
              ),
            },
            {
              path: "dateTaken",
              title: "Date",
              transform: (t) => moment(t).parseZone().fromNow(),
            },
            {
              path: "percentage",
              title: "Percentage",
              render: (t, d) => (
                <div className={classes.circularContainer}>
                  <CircularProgress
                    className={classes.back}
                    variant="static"
                    value={100}
                  />
                  <CircularProgress
                    className={classes.fore}
                    variant="static"
                    value={(d.answersIdx / d.questionsCount) * 100}
                  />
                  <Typography
                    display="block"
                    color="primary"
                    className={classes.value}
                  >
                    {Math.round((d.answersIdx / d.questionsCount) * 100)}
                    <span style={{ fontSize: 10 }}>%</span>
                  </Typography>
                </div>
              ),
            },
          ]}
          title={"Surveys"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(SurveysList);
