import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const degreeRequirements = `${REACT_APP_API_PREFIX}/${service}/degreeRequirements`;

export function getDegreeRequirements(filters) {
  const url = `${degreeRequirements}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getDegreeRequirementById(id) {
  const url = `${degreeRequirements}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateDegreeRequirement(id, body) {
  const url = `${degreeRequirements}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteDegreeRequirement(id) {
  const url = `${degreeRequirements}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createDegreeRequirement(body) {
  const url = `${degreeRequirements}`;
  return {
    method: POST,
    url,
    body,
  };
}
