import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Delete from "@material-ui/icons/Delete";
// custom
import MarkdownEditor from "components/MarkdownEditor";
// routes
import { COURSES } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CourseEdit extends Component {
  static propTypes = {
    classes: PropTypes.object,
    course: PropTypes.object,
    updateCourse: PropTypes.func,
    deleteCourse: PropTypes.func,
    history: PropTypes.object,
    refresh: PropTypes.func,
    app: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { course } = this.props;

    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.state = {
      loading: false,
      ...course,
    };
  }

  componentWillUnmount() {
    const { UploadCenter } = this.context;
    UploadCenter.disregard();
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async upload() {
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          this.setState({
            background: r.payload,
          });
          this.save("background");
        });
      }
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  async save(name, numeric) {
    const { updateCourse, refresh, course } = this.props;

    if (course[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateCourse(course.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteCourse, refresh, course, history } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await deleteCourse(course.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Course has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      history.push(COURSES);
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
      name,
      author,
      subtitle,
      salePage,
      description,
      background,
      locked,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography display="block" variant="h6">
              Course Information
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Name:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("name");
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("name")}
                  className={classes.inputBase}
                  value={name}
                  onChange={this.handleChange("name")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Author:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("author");
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("author")}
                  className={classes.inputBase}
                  value={author}
                  onChange={this.handleChange("author")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Subtitle:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("subtitle");
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("subtitle")}
                  className={classes.inputBase}
                  value={subtitle}
                  multiline
                  onChange={this.handleChange("subtitle")}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography display="block" variant="h6">
              Marketing
            </Typography>
            {background ? (
              <img
                src={background}
                alt="course-background"
                style={{
                  height: 75,
                  background: "#FFFFFF",
                  borderRadius: 4,
                  padding: 2,
                }}
              />
            ) : (
              []
            )}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Background URL:
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Drag and drop to upload a background.
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("background");
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("background")}
                  className={classes.inputBase}
                  value={background}
                  onChange={this.handleChange("background")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Sale Page Link:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("salePage");
                  }}
                  disabled={loading || locked}
                  fullWidth
                  onBlur={() => this.save("salePage")}
                  className={classes.inputBase}
                  value={salePage}
                  onChange={this.handleChange("salePage")}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={loading ? classes.paperLoading : classes.paper}>
            <Typography display="block" variant="h6">
              Description
            </Typography>
            <MarkdownEditor
              value={description}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("description");
              }}
              onBlur={() => this.save("description")}
              disabled={loading || locked}
              onChange={this.handleChange("description")}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            className={loading ? classes.paperLoading : classes.dangerPaper}
          >
            <Typography display="block" variant="h6">
              Danger Zone
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Delete the Course.
            </Typography>
            <Button
              onClick={this.delete.bind(this)}
              style={{ color: "white", background: "#f44336" }}
              variant="raised"
              disabled={locked}
            >
              <Delete style={{ marginRight: 15 }} /> Delete
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CourseEdit);
