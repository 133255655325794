import { networkAction } from 'helpers/network/networkAction';

import {
  GET_PROGRAM_TAGS,
  GET_PROGRAM_TAG_BY_ID,
  DELETE_PROGRAM_TAG,
  UPDATE_PROGRAM_TAG,
  CREATE_PROGRAM_TAG,
} from 'constants/programTags';

import * as Api from 'api';

export const getProgramTags = () => async dispatch => networkAction(
  dispatch,
  GET_PROGRAM_TAGS,
  Api.getProgramTags,
  []
);

export const getProgramTagById = (id) => async dispatch => networkAction(
  dispatch,
  GET_PROGRAM_TAG_BY_ID,
  Api.getProgramTagById,
  [id]
);

export const deleteProgramTag = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_PROGRAM_TAG,
  Api.deleteProgramTag,
  [id]
);

export const createProgramTag = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_PROGRAM_TAG,
  Api.createProgramTag,
  [body]
);

export const updateProgramTag = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_PROGRAM_TAG,
  Api.updateProgramTag,
  [id, body]
);
