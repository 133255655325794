import { GET, PUT, DELETE, POST } from "constants/methods";
const eventTypeCoaches = "/calendy/eventTypeCoaches";

export function getEventTypeCoaches() {
  const url = `${eventTypeCoaches}`;
  return {
    method: GET,
    url,
  };
}

export function getEventTypeCoachById(id) {
  const url = `${eventTypeCoaches}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEventTypeCoach(id, body) {
  const url = `${eventTypeCoaches}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEventTypeCoach(id) {
  const url = `${eventTypeCoaches}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEventTypeCoach(body) {
  const url = `${eventTypeCoaches}`;
  return {
    method: POST,
    url,
    body,
  };
}
