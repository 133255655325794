import { serviceRoles } from "config/access";
import { INQUIRIES } from "constants/routes";
import { checkAccess } from "helpers/auth";
import Page from "./container/InquiriesPage";

export default function getRoute(userAuth) {
  const { account, harmony } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [harmony.name]: [harmony.roles.admin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: INQUIRIES,
    withSidebar: true,
    withAppBar: true,
    component: Page,
  };
}
