import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "papyrus";
const clubMembers = `/${service}/clubMembers`;

export function getClubMembers(filters) {
  const url = `${clubMembers}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getClubMemberById(id) {
  const url = `${clubMembers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateClubMember(id, body) {
  const url = `${clubMembers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteClubMember(id) {
  const url = `${clubMembers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createClubMember(body) {
  const url = `${clubMembers}`;
  return {
    method: POST,
    url,
    body,
  };
}
