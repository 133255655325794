import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class EventTypesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    eventTypes: PropTypes.array,
    onSelect: PropTypes.func,
  };

  render() {
    const { eventTypes, classes, onSelect } = this.props;

    return (
      <Grid container spacing={2}>
        {eventTypes.map((e) => (
          <Grid item key={e.id} className={classes.item}>
            <Paper className={classes.paper} onClick={() => onSelect(e)}>
              <Typography display="block" variant="title">
                {e.name}
              </Typography>
              <Typography display="block" variant="body2" color="textSecondary">
                {`${e.duration} minutes`}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(EventTypesList);
