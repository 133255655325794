// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import ProgramCount from "hooks/ProgramCount";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class MasterTagsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    masterTags: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedMasterTag) {
    const { onSelect } = this.props;
    this.setState({ selectedMasterTag });
    if (onSelect) {
      onSelect(selectedMasterTag);
    }
  }

  render() {
    const { masterTags } = this.props;

    return (
      <Table
        data={masterTags}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
            hidden: { mdDown: true },
          },
          {
            path: "programCount",
            title: "Programs#",
            render: (t, d) => (
              <ProgramCount
                value={t}
                id={d.id}
                title={`${d.name} related programs`}
                type="masterTags"
              />
            ),
          },
        ]}
        title={"MasterTags"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(MasterTagsList);
