// helpers
import * as Actions from "actions";
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperSearchPage from "../component/WrapperSearchPage";

const mapStateToProps = (state) => ({
  user: state.user,
  products: state.products,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (...args) => dispatch(Actions.getProducts(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
  impersonate: (...args) => dispatch(Actions.impersonate(...args)),
});
class SearchPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
    getProducts: PropTypes.func,
    searchUsers: PropTypes.func,
    impersonate: PropTypes.func,
    products: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: false,
    };
  }

  // componentDidMount() {
  //   this.refresh();
  // }

  async refresh() {
    // const { getElementById } = this.props;
    // const { id } = this.state;
    // const r = await getElementById(id);
    // if (r.success) {
    //   this.setState({
    //     refresh: false,
    //     element: r.payload,
    //   });
    // }
  }

  render() {
    const {
      location,
      history,
      user,
      products,
      getProducts,
      impersonate,
      searchUsers,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperSearchPage
        history={history}
        impersonate={impersonate}
        products={products}
        getProducts={getProducts}
        user={user}
        location={location}
        searchUsers={searchUsers}
        urlParams={urlParams}
        refresh={this.refresh.bind(this)}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
