import * as Api from "api";
import {
  CREATE_CHANGELOG,
  DELETE_CHANGELOG,
  GET_CHANGELOGS,
  GET_CHANGELOG_BY_ID,
  UPDATE_CHANGELOG,
} from "constants/changelogs";
import { networkAction } from "helpers/network/networkAction";

export const getAptitudeChangelogs = () => async (dispatch) =>
  networkAction(dispatch, GET_CHANGELOGS, Api.getAptitudeChangelogs, []);

export const getAptitudeChangelogById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CHANGELOG_BY_ID, Api.getAptitudeChangelogById, [
    id,
  ]);

export const deleteAptitudeChangelog = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CHANGELOG, Api.deleteAptitudeChangelog, [id]);

export const createAptitudeChangelog = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CHANGELOG, Api.createAptitudeChangelog, [
    body,
  ]);

export const updateAptitudeChangelog = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CHANGELOG, Api.updateAptitudeChangelog, [
    id,
    body,
  ]);

export const getHarmonyChangelogs = () => async (dispatch) =>
  networkAction(dispatch, GET_CHANGELOGS, Api.getHarmonyChangelogs, []);

export const getHarmonyChangelogById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CHANGELOG_BY_ID, Api.getHarmonyChangelogById, [
    id,
  ]);

export const deleteHarmonyChangelog = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CHANGELOG, Api.deleteHarmonyChangelog, [id]);

export const createHarmonyChangelog = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CHANGELOG, Api.createHarmonyChangelog, [body]);

export const updateHarmonyChangelog = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CHANGELOG, Api.updateHarmonyChangelog, [
    id,
    body,
  ]);
