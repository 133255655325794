import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// Custom
import Bubble from "components/Bubble";

// styles
import styles from "./styles";

class Layout extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    careerCount: PropTypes.number,
    bubbleSize: PropTypes.number,
    spacing: PropTypes.number,
    careerOrder: PropTypes.array,
    maxPitch: PropTypes.number,
    onHover: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      init: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.maxPitch !== this.props.maxPitch) {
      return true;
    }

    if (nextProps.bubbleSize !== this.props.bubbleSize) {
      return true;
    }

    return false;
  }

  render() {
    const {
      history,
      careerCount,
      careerOrder,
      bubbleSize,
      maxPitch,
      spacing,
      onHover,
    } = this.props;

    const JSX = [];
    let pitch = maxPitch;
    let nextOp = -1;
    let i = 0;
    let col = [];
    let stacked = 0;

    while (i < careerOrder.length) {
      col.push(
        <Grid item key={`item_${i}`}>
          <Bubble
            career={careerOrder[i]}
            size={bubbleSize}
            history={history}
            onHover={onHover}
          />
        </Grid>
      );
      stacked += 1;
      if (stacked === pitch) {
        JSX.push(
          <Grid item key={`grid_${i}`}>
            <Grid container direction="column" spacing={spacing}>
              {col}
            </Grid>
          </Grid>
        );
        stacked = 0;
        col = [];
        pitch += nextOp;
        if (pitch === 1) {
          nextOp = 1;
        }

        if (pitch === maxPitch) {
          nextOp = -1;
        }
      }

      if (i === careerCount - 1 && col.length) {
        JSX.push(
          <Grid item key={`grid_${i + 1}`}>
            <Grid container direction="column" spacing={spacing}>
              {col}
            </Grid>
          </Grid>
        );
      }

      i++;
    }

    return (
      <Grid container spacing={spacing} alignItems="center">
        {JSX}
      </Grid>
    );
  }
}

export default withStyles(styles)(Layout);
