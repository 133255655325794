import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCareerPage from "../component/WrapperCareerPage";
import Loading from "components/Loading";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  categories: state.cpfmCategories,
  careers: state.careers,
});

const mapDispatchToProps = (dispatch) => ({
  getCareerById: (...args) => dispatch(Actions.getCareerById(...args)),
  getFullApp: () => dispatch(Actions.getFullApp()),
  getCpfmCategories: () => dispatch(Actions.getCpfmCategories()),
});

class CareerPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    categories: PropTypes.array,

    getCareerById: PropTypes.func,
    getFullApp: PropTypes.func,
    getCpfmCategories: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
      career: {},
    };

    this.refresh();
  }

  async refresh() {
    const {
      getCpfmCategories,
      getFullApp,
      categories,
      careers,
      location,
      getCareerById,
    } = this.props;

    const urlParams = getJsonFromUrl(location);

    if (urlParams.careerID) {
      getCareerById(urlParams.careerID).then((resp) =>
        this.setState({
          career: resp.payload,
          loading: !(
            this.props.categories.length > 0 && this.props.careers.length > 0
          ),
        })
      );
    }

    if (categories.length === 0) {
      getCpfmCategories().then(() => {
        this.setState({
          loading: !(
            this.props.careers.length > 0 && this.state.career.id !== undefined
          ),
        });
      });
    }

    if (careers.length === 0) {
      getFullApp().then(() => {
        this.setState({
          loading: !(
            this.props.categories.length > 0 &&
            this.state.career.id !== undefined
          ),
        });
      });
    }
  }

  render() {
    const { urlParams, loading, career } = this.state;

    const { categories, careers, history, location } = this.props;

    return loading ? (
      <div style={{ background: "#455a64", flex: 1 }} />
    ) : (
      <WrapperCareerPage
        career={career}
        careers={careers}
        categories={categories}
        location={location}
        history={history}
        urlParams={urlParams}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CareerPage);
