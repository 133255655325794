import * as Api from "api";
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
} from "constants/products";
import { networkAction } from "helpers/network/networkAction";

export const getProducts = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_PRODUCTS, Api.getProducts, [filters]);

export const getProductById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PRODUCT_BY_ID, Api.getProductById, [id]);

export const deleteProduct = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PRODUCT, Api.deleteProduct, [id]);

export const createProduct = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PRODUCT, Api.createProduct, [body]);

export const updateProduct = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PRODUCT, Api.updateProduct, [id, body]);
