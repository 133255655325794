import { serviceRoles } from "config/access";
import { SUB_CATEGORIES } from "constants/routes";
import { checkAccess } from "helpers/auth";
import CategoryPage from "./container/CategoryPage";

export default function getSettingsRoute(userAuth) {
  const { account, cpfm } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [cpfm.name]: [cpfm.roles.admin],
  };
  return {
    path: SUB_CATEGORIES,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: CategoryPage,
  };
}
