import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "papyrus";
const books = `${REACT_APP_API_PREFIX}/${service}/books`;

export function getBooks(filters) {
  const url = `${books}${filters ? `?filters=${JSON.stringify(filters)}` : ""}`;
  return {
    method: GET,
    url,
  };
}

export function getBookById(id) {
  const url = `${books}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateBook(id, body) {
  const url = `${books}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteBook(id) {
  const url = `${books}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createBook(body) {
  const url = `${books}`;
  return {
    method: POST,
    url,
    body,
  };
}
