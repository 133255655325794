// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperInquiriesPage from "../component/WrapperInquiriesPage";

const mapStateToProps = (state) => ({
  inquiries: state.inquiries,
});

const mapDispatchToProps = (dispatch) => ({
  getInquiries: (...args) => dispatch(Actions.getInquiries(...args)),
  getInquiryById: (...args) => dispatch(Actions.getInquiryById(...args)),
  createInquiry: (...args) => dispatch(Actions.createInquiry(...args)),
  deleteInquiry: (...args) => dispatch(Actions.deleteInquiry(...args)),
  updateInquiry: (...args) => dispatch(Actions.updateInquiry(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
});
class InquiriesPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    searchUsers: PropTypes.func,

    inquiries: PropTypes.array,
    getInquiries: PropTypes.func,
    getInquiryById: PropTypes.func,
    createInquiry: PropTypes.func,
    deleteInquiry: PropTypes.func,
    updateInquiry: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, inquiries } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: inquiries.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getInquiries } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getInquiries(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      inquiries,
      getInquiryById,
      createInquiry,
      deleteInquiry,
      updateInquiry,
      getInquiries,
      searchUsers,
    } = this.props;

    const { urlParams, loading } = this.state;

    const api = {
      getInquiries,
      getInquiryById,
      createInquiry,
      deleteInquiry,
      updateInquiry,
      refresh: this.refresh.bind(this),
    };

    return (
      <WrapperInquiriesPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        inquiries={inquiries}
        searchUsers={searchUsers}
        api={api}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InquiriesPage);
