import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import CalendyStudentList from "components/CalendyStudentList";
import CoachList from "components/CoachList";
import EventList from "components/EventList";
// components
import EventTypesList from "components/EventTypesList";
import FormCoach from "components/FormCoach";
import FormEventType from "components/FormEventType";
import Page from "components/Page";
import TimeAssetsList from "components/TimeAssetsList";
// routes
import { COACH, EVENT_TYPE, STUDENT } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperEventTypePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    timezones: PropTypes.array,
    loading: PropTypes.bool,
    getTimeAssets: PropTypes.func,

    eventTypes: PropTypes.array,
    createEventType: PropTypes.func,
    deleteEventType: PropTypes.func,
    sendStudentEmail: PropTypes.func,
    updateEventType: PropTypes.func,
    updateEvent: PropTypes.func,
    deleteEvent: PropTypes.func,

    createCoach: PropTypes.func,

    events: PropTypes.array,
    coaches: PropTypes.array,
    calendyStudents: PropTypes.array,
    timeAssets: PropTypes.array,
    searchUsers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedEventType: undefined,
      formEventTypeOpen: false,
      formCoachOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      eventTypes,
      events,
      coaches,
      history,
      getCalendyStudents,
      updateEvent,
      deleteEvent,
      calendyStudents,
      sendStudentEmail,
      search,
      timezones,
      refresh,
      timeAssets,
      getTimeAssets,
      searchUsers,
    } = this.props;

    switch (index) {
      case 1:
        return (
          <TimeAssetsList
            timeAssets={timeAssets}
            getTimeAssets={getTimeAssets}
            history={history}
            searchUsers={searchUsers}
          />
        );
      case 3:
        return (
          <EventTypesList
            eventTypes={eventTypes}
            onSelect={(selectedEventType) =>
              history.push(`${EVENT_TYPE}?id=${selectedEventType.id}`)
            }
          />
        );
      case 0:
        return (
          <CalendyStudentList
            searchUsers={searchUsers}
            calendyStudents={calendyStudents}
            history={history}
            getCalendyStudents={getCalendyStudents}
            onSelect={(selectedStudent) =>
              history.push(
                `${STUDENT}?uuid=${
                  selectedStudent.userUUID
                }&back=${encodeURIComponent(
                  `${window.location.pathname}${window.location.search}`
                )}`
              )
            }
          />
        );
      case 2:
        return (
          <EventList
            timezones={timezones}
            events={events}
            updateEvent={updateEvent}
            deleteEvent={deleteEvent}
            sendStudentEmail={sendStudentEmail}
            refresh={refresh}
            coaches={coaches}
            history={history}
            // onSelect={selectedEventType => history.push(`${EVENT_TYPE}?id=${selectedEventType.id}`)}
          />
        );
      case 4:
        return (
          <CoachList
            coaches={coaches}
            onSelect={(selectedCoach) =>
              history.push(`${COACH}?id=${selectedCoach.id}`)
            }
          />
        );
      default:
        return "Too far!";
    }
  }

  getFab() {
    const { index } = this.state;
    const { classes } = this.props;
    switch (index) {
      case 3:
        return (
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formEventTypeOpen: true })}
          >
            <AddIcon />
          </Fab>
        );
      case 4:
        return (
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formCoachOpen: true })}
          >
            <AddIcon />
          </Fab>
        );
      default:
        return [];
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createEventType,
      deleteEventType,
      updateEventType,
      createCoach,
      refresh,
    } = this.props;

    const {
      index,
      selectedEventType,
      formEventTypeOpen,
      formCoachOpen,
    } = this.state;

    return (
      <Page
        helmet="Enrollments"
        loading={loading}
        fab={this.getFab()}
        loadingMessage={"Fetching EventTypes"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Students"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Time Assets"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Scheduled Events"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Event Types"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Coaches"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormEventType
          open={formEventTypeOpen}
          eventType={selectedEventType}
          close={() => {
            this.setState({
              formEventTypeOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedEventType: undefined,
                }),
              200
            );
          }}
          createEventType={createEventType}
          deleteEventType={deleteEventType}
          updateEventType={updateEventType}
          refresh={refresh}
        />
        <FormCoach
          open={formCoachOpen}
          close={() => {
            this.setState({
              formCoachOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  formCoachOpen: undefined,
                }),
              200
            );
          }}
          createCoach={createCoach}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEventTypePage);
