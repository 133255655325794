import { GET_DOWNLOAD_REQUESTS } from "constants/downloadRequests";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_DOWNLOAD_REQUESTS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
