import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperAssessmentPage from "../component/WrapperAssessmentPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  assessments: state.assessments,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  getAssessments: (...args) => dispatch(Actions.getAssessments(...args)),
  getAssessmentById: (...args) => dispatch(Actions.getAssessmentById(...args)),
  createAssessment: (...args) => dispatch(Actions.createAssessment(...args)),
  deleteAssessment: (...args) => dispatch(Actions.deleteAssessment(...args)),
  updateAssessment: (...args) => dispatch(Actions.updateAssessment(...args)),
});
class AssessmentPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    assessments: PropTypes.array,
    getAssessments: PropTypes.func,
    getAssessmentById: PropTypes.func,
    createAssessment: PropTypes.func,
    deleteAssessment: PropTypes.func,
    updateAssessment: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, assessments } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: assessments.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getAssessments } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getAssessments(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      assessments,
      getAssessmentById,
      createAssessment,
      deleteAssessment,
      updateAssessment,
      getAssessments,
      app,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperAssessmentPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        getAssessments={getAssessments}
        assessments={assessments}
        getAssessmentById={getAssessmentById}
        createAssessment={createAssessment}
        deleteAssessment={deleteAssessment}
        updateAssessment={updateAssessment}
        urlParams={urlParams}
        app={app}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentPage);
