import CircularProgress from "@material-ui/core/CircularProgress";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { isExpired } from "helpers";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class EnrollmentsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    enrollments: PropTypes.array,
    courses: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    onSelect: PropTypes.func,
    getEnrollments: PropTypes.func,
    user: PropTypes.object,
    searchUsers: PropTypes.func,
  };

  render() {
    const {
      enrollments,
      history,
      onSelect,
      courses,
      classes,
      user,
      getEnrollments,
      searchUsers,
      onGroupSelect,
      groupSelectEnabled,
    } = this.props;

    return (
      <AdvancedSearch
        history={history}
        exportEndpoint="/aptitude/enrollments/csv"
        location={window.location}
        refresh={getEnrollments}
        keyword="enrollments"
        metaDataEndpoint="/aptitude/enrollments/searchMetadata"
        metaDataOveride={{
          courseID: {
            type: "autocomplete",
            data: courses,
            valueKey: "id",
            labelKey: "name",
          },
          userUID: {
            label: "User",
            type: "autoFill",
            getData: (search) =>
              searchUsers({
                text: search,
              }),
            valueKey: "uuid",
            labelTransform: (t) => `${t.firstName} ${t.lastName} (${t.email})`,
          },
        }}
      >
        <Table
          data={enrollments}
          noToolbar
          groupSelectEnabled={groupSelectEnabled}
          onGroupSelect={onGroupSelect}
          rowStyle={(d) => {
            return {
              opacity: isExpired(d.expirationDate) ? 0.7 : 1,
            };
          }}
          meta={[
            {
              path: "courseBackground",
              render: (t) => (
                <div
                  style={{
                    background: `url(${t})`,
                    width: 35,
                    height: 35,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    borderRadius: 4,
                  }}
                />
              ),
              width: 40,
            },
            {
              path: "courseName",
              title: "Course",
              render: (t, d) => (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    {t}
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {d.pricingPlanName}
                  </Typography>
                  {isExpired(d.expirationDate) ? (
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      <b style={{ color: "#f44336" }}>EXPIRED</b> since{" "}
                      {moment(d.expirationDate).format("lll")}
                    </Typography>
                  ) : (
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      <b style={{ color: "#4caf50" }}>Valid</b> expires{" "}
                      {moment(d.expirationDate).format("lll")} (
                      {moment(d.expirationDate).fromNow()} )
                    </Typography>
                  )}
                </React.Fragment>
              ),
            },
            {
              path: "userFullName",
              title: "User",
              render: (t, d) => (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    {t !== "null" ? t : "Unknown User"}
                  </Typography>
                </React.Fragment>
              ),
            },
            {
              path: "moduleName",
              title: "Progression",
              render: (t, d) => (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    {t !== "null" ? t : ""}
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {d.lectureName}
                  </Typography>
                </React.Fragment>
              ),
            },
            {
              path: "percentage",
              title: "Percentage",
              render: (t, d) => (
                <div className={classes.circularContainer}>
                  <CircularProgress
                    className={classes.back}
                    variant="static"
                    value={100}
                  />
                  <CircularProgress
                    className={classes.fore}
                    variant="static"
                    value={d.percentage}
                  />
                  <Typography
                    display="block"
                    color="primary"
                    className={classes.value}
                  >
                    {d.percentage}
                    <span style={{ fontSize: 10 }}>%</span>
                  </Typography>
                </div>
              ),
            },
          ]}
          title={"Enrollments"}
          onRowSelect={onSelect}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(EnrollmentsList);
