import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  whiteButton: {
    color: "white",
    borderColor: "white",
  },
  infoContainer: {
    padding: 20,
    maxWidth: 1400,
    margin: "auto",
    overflowY: "scroll",
  },
  whiteTitle: {
    color: "white",
    fontWeight: 900,
    textShadow: "1px 1px 4px black",
  },
  whiteText: {
    color: "white",
  },
  appBar: {
    zIndex: 999,
  },
  container: {
    height: "calc(calc(var(--vh, 1vh) * 100) - 64px)",
    width: "100vw",
    overflowY: "hidden",
    position: "relative",
  },
  overlay: {
    height: "calc(var(--vh, 1vh) * 100)",
    minWidth: "100vw",
    background:
      "linear-gradient(to bottom, rgba(96,125,139,0.85) 0%,rgba(38,50,56,0.85) 100%)",
    position: "fixed",
    top: 0,
  },
  layout: {
    position: "absolute",
    top: 0,
    cursor: "grab",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
    background: "white",
  },
  tabRoot: {
    textTransform: "initial",
    textAlign: "center",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#607d8b",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#607d8b",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#607d8b",
    },
  },
  tabSelected: {},
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
});
