import * as Api from "api";
import {
  CREATE_LEVEL,
  DELETE_LEVEL,
  GET_LEVELS,
  GET_LEVEL_BY_ID,
  GET_PROGRAMS_BY_LEVEL,
  UPDATE_LEVEL,
} from "constants/levels";
import { networkAction } from "helpers/network/networkAction";

export const getLevels = () => async (dispatch) =>
  networkAction(dispatch, GET_LEVELS, Api.getLevels, []);

export const getProgramsByLevel = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAMS_BY_LEVEL, Api.getProgramsByLevel, [id]);

export const getLevelById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_LEVEL_BY_ID, Api.getLevelById, [id]);

export const deleteLevel = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_LEVEL, Api.deleteLevel, [id]);

export const createLevel = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_LEVEL, Api.createLevel, [body]);

export const updateLevel = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_LEVEL, Api.updateLevel, [id, body]);
