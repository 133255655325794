// helpers
// actions
import * as Actions from "actions";
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCourseLogsPage from "../component/WrapperCourseLogsPage";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAptitudeChangelogs: (...args) =>
    dispatch(Actions.getAptitudeChangelogs(...args)),
});
class CourseLogsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    aptitudeChangelogs: PropTypes.array,
    getAptitudeChangelogs: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      aptitudeChangelogs: [],
      loading: false,
    };
    this.refresh();
  }

  async refresh() {
    const { getAptitudeChangelogs } = this.props;
    const { urlParams } = this.state;
    const r = await getAptitudeChangelogs(urlParams.filters);
    this.setState({
      aptitudeChangelogs: r.payload,
    });
  }

  render() {
    const { location, history } = this.props;

    const { urlParams, loading, aptitudeChangelogs } = this.state;

    return (
      <WrapperCourseLogsPage
        history={history}
        location={location}
        aptitudeChangelogs={aptitudeChangelogs}
        urlParams={urlParams}
        refresh={this.refresh.bind(this)}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseLogsPage);
