import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactSelect from "react-select";
// styles
import styles from "./styles";

class FormProgram extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    history: PropTypes.object,
    close: PropTypes.func,
    open: PropTypes.bool,
    academy: PropTypes.object,
    academies: PropTypes.array,
    createProgram: PropTypes.func,
    programTypes: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { academy } = this.props;

    this.state = {
      loading: false,
      name: "",
      programTypeID: "",
      academyID: academy ? academy.id : undefined,
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save() {
    const { createProgram, refresh, close, history } = this.props;

    const { name, programTypeID, academyID } = this.state;

    const { NotificationCenter } = this.context;

    this.setState({ loading: true });
    const resp = await createProgram({
      name,
      academyID,
      programTypeID,
    });

    this.setState({ loading: false });

    if (resp.success) {
      const urlParams = getJsonFromUrl(window.location);
      delete urlParams.programID;
      urlParams.programID = JSON.stringify(resp.payload.id);
      const search = Object.keys(urlParams)
        .map(
          (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
        )
        .join("&");
      history.push({
        search,
      });
      NotificationCenter.sweetAlert({
        title: "Program has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });
      this.setState({
        name: "",
        programTypeID: "",
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, close, programTypes, academies } =
      this.props;

    const { name, programTypeID, loading, academyID } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
        maxWidth={"md"}
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              Let's Create a New Program
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Academy:</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ReactSelect
                    value={
                      academyID
                        ? {
                            value:
                              academies &&
                              academies.find((a) => a.id === academyID),
                            label:
                              academyID &&
                              academies &&
                              academies.find((a) => a.id === academyID)?.name,
                          }
                        : undefined
                    }
                    placeHolder={"Academy"}
                    isClearable
                    onChange={(e) => {
                      this.setState({
                        academyID: e.value ? e.value.id : undefined,
                      });
                    }}
                    options={
                      academies &&
                      academies.map((a) => ({
                        value: a,
                        label: a.name,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="group-simple">
                      Program's Type
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "Add Groups to User",
                        id: "group-simple",
                      }}
                      value={programTypeID}
                      onChange={this.handleChange("programTypeID")}
                    >
                      {programTypes.map((pt) => (
                        <MenuItem key={`pt_${pt.id}`} value={pt.id}>
                          {pt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                Create
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormProgram));
