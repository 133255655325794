import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

// custom
import Page from "components/Page";
import AvatarScale from "components/AvatarScale";
import EventTypeCoach from "components/EventTypeCoach";
import TimezoneSelect from "components/TimezoneSelect";

// styles
import styles from "./styles";

class WrapperCoachPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    history: PropTypes.object,
    coach: PropTypes.object,
    updateCoach: PropTypes.func,
    deleteCoach: PropTypes.func,
    timezones: PropTypes.array,
    loading: PropTypes.bool,
    refreshKey: PropTypes.number,
    availabilities: PropTypes.array,
    refreshAvailabilities: PropTypes.func,
    getAvailabilities: PropTypes.func,
    createAvailability: PropTypes.func,
    deleteAvailability: PropTypes.func,
    updateAvailability: PropTypes.func,
    getDailyAvailabilities: PropTypes.func,
    coaches: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { UploadCenter } = this.context;
    UploadCenter.register(
      () => console.log("upload"),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.state = {
      imageLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading) {
      this.setState({
        ...nextProps.coach,
      });
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  userDidDrop(files) {
    this.setState({
      files,
      pending: files[0],
      avatarScaleOpen: true,
    });
  }

  async upload() {
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        this.setState({
          imageLoading: true,
        });
        UploadCenter.upload(file).then((r) => {
          this.setState({
            avatar: r.payload,
            imageLoading: false,
          });
          this.save("avatar");
        });
      }
    }
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteCoach, coach, history } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await deleteCoach(coach.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Coach has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      history.goBack();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save(name, numeric) {
    const { refresh, coach, updateCoach } = this.props;

    if (coach[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateCoach(coach.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  render() {
    const {
      classes,
      history,
      loading,
      coach,
      coaches,
      createAvailability,
      deleteAvailability,
      updateAvailability,
      getDailyAvailabilities,
      refreshAvailabilities,
      timezones,
      availabilities,
      refreshKey,
    } = this.props;

    const { UploadCenter } = this.context;

    const {
      fullName,
      email,
      tzName,
      imageLoading,
      pending,
      avatarScaleOpen,
    } = this.state;

    return (
      <Page helmet={"Coach"} loading={loading}>
        {coach ? (
          <Grid
            container
            alignItems="center"
            justify="space-between"
            spacing={2}
            style={{ maxWidth: 600 }}
          >
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.goBack()}
              >
                {"< Back"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar
                    style={{ width: 90, height: 90 }}
                    src={!imageLoading ? coach.avatar : undefined}
                  >
                    {imageLoading ? <CircularProgress /> : []}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      borderColor: "#2196f3",
                      color: "#2196f3",
                      textTransform: "none",
                    }}
                    variant="outlined"
                    onClick={() => UploadCenter.openFileDialog()}
                  >
                    Upload Picture
                  </Button>
                  <Typography display="block" color="textSecondary">
                    JPG, GIF or PNG. Max size of 5MB.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" variant="body2">
                Full Name:
              </Typography>
              <InputBase
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("fullName");
                }}
                onBlur={() => this.save("fullName")}
                disabled={loading}
                className={classes.inputBase}
                defaultValue={fullName}
                onChange={this.handleChange("fullName")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" variant="body2">
                Email:
              </Typography>
              <InputBase
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("email");
                }}
                onBlur={() => this.save("email")}
                disabled={loading}
                className={classes.inputBase}
                defaultValue={email}
                onChange={this.handleChange("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography display="block" variant="body2">
                Timezone:
              </Typography>
              <TimezoneSelect
                tzName={tzName}
                timezones={timezones}
                onChange={(e) =>
                  this.setState({ tzName: e }, () => this.save("tzName"))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={this.delete.bind(this)}
                style={{
                  borderColor: "#f44336",
                  color: "#f44336",
                  textTransform: "none",
                }}
              >
                Delete Coach
              </Button>
            </Grid>
          </Grid>
        ) : (
          []
        )}
        <AvatarScale
          open={avatarScaleOpen}
          close={() => this.setState({ avatarScaleOpen: false })}
          onSave={(b) => {
            this.setState({ files: [b] }, () => {
              this.upload();
            });
          }}
          onSave={(a) => {
            this.setState({ files: [a] }, () => {
              this.upload();
            });
          }}
          image={pending && pending.preview}
        />
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          {coach ? (
            <Grid item xs={12}>
              <EventTypeCoach
                coach={coach}
                coaches={coaches}
                updateAvailability={updateAvailability}
                getDailyAvailabilities={getDailyAvailabilities}
                deleteAvailability={deleteAvailability}
                refreshKey={refreshKey}
                createAvailability={createAvailability}
                refresh={refreshAvailabilities}
                availabilities={availabilities}
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCoachPage);
