import {
  GET_TIME_ASSETS,
} from 'constants/timeAssets';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_TIME_ASSETS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
