import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
// material-ui
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Back from "@material-ui/icons/ArrowBack";
import Play from "@material-ui/icons/PlayCircleOutline";
import Bubble from "components/Bubble";
import FullScreenVideoPlayer from "components/FullScreenVideoPlayer";
import MiniLayout from "components/MiniLayout";
// constants
import { CATEGORY, CPFM } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
// styles
import styles from "./styles";

let items = 50;
let maxPitch = 4;
let spacing = 16;
let smallSpacing = 4;
let bubbleSize = 110;
let smallBubbleSize = 9;

class WrapperCareerPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    categories: PropTypes.array,
    careers: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { careers } = this.props;
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.state = {
      index: undefined,
      init: false,
      videoPlayerOpen: false,
      careerCount: careers.length,
    };
    this.updateCol();
  }

  updateCol() {
    const { careers } = this.props;
    const careerCount = careers.length;
    let pitch = maxPitch;
    let nextOp = -1;
    let i = 0;
    let col = 0;
    let stacked = 0;
    while (i < careerCount) {
      stacked += 1;
      if (stacked === pitch) {
        col += 1;
        stacked = 0;
        pitch += nextOp;
        if (pitch === 1) {
          nextOp = 1;
        }

        if (pitch === maxPitch) {
          nextOp = -1;
        }
      }

      if (i === careerCount - 1 && stacked) {
        col += 1;
      }

      i++;
    }

    this.setState({ col });
  }

  getCareerOrder(careerCount) {
    const { categories } = this.props;

    let indexes = {};
    categories.forEach((c) => {
      indexes[c.title] = c.careers.length;
    });
    let ret = [];
    while (ret.length < careerCount) {
      categories.forEach((c) => {
        if (indexes[c.title] > 0) {
          const career = c.careers[c.careers.length - indexes[c.title]];
          career.category = c;
          indexes[c.title] -= 1;
          ret.push(career);
        }
      });
    }

    return ret;
  }

  componentWillMount() {
    const colors = [];

    for (var i = 0; i < items; i++) {
      colors.push(this.getRandomColor());
    }

    this.setState({
      colors,
    });
  }

  componentDidMount() {
    const { init } = this.state;
    ReactDOM.findDOMNode(this.refs.scrollContainer).addEventListener(
      "scroll",
      this.scrollEvent.bind(this)
    );

    if (!init) {
      this.updateDimensions();
    }
  }

  getRandomColor() {
    const possibleColors = [
      "#f44336",
      "#2196f3",
      "#3f51b5",
      "#009688",
      "#ff9800",
      "#cddc39",
      "#4caf50",
      "#e91e63",
      "#673ab7",
      "#ff5722",
    ];

    return possibleColors[Math.floor(Math.random() * possibleColors.length)];
  }

  updateDimensions() {
    const { col } = this.state;
    const layoutWidth = col * (bubbleSize + spacing) - spacing;
    const scrollContainer = ReactDOM.findDOMNode(this.refs.scrollContainer);

    if (scrollContainer) {
      const scrollLeft = scrollContainer.scrollLeft;
      const windowWidth = scrollContainer.clientWidth;
      const windowHeight = scrollContainer.clientHeight;

      if (windowHeight > 600) {
        maxPitch = 4;
        this.updateCol();
      } else {
        maxPitch = 3;
        this.updateCol();
      }

      bubbleSize = 100 + windowWidth / 65;

      const scrollRatio = ((scrollLeft + windowWidth) / layoutWidth) * 100;

      this.setState({
        scrollLeft,
        windowWidth,
        layoutWidth,
        scrollRatio: scrollRatio,
        init: true,
      });
    }
  }

  scrollEvent(e, v, t) {
    const { col } = this.state;

    const scrollLeft = ReactDOM.findDOMNode(
      this.refs.scrollContainer
    ).scrollLeft;
    const layoutWidth = col * (bubbleSize + spacing) - spacing;
    const windowWidth = ReactDOM.findDOMNode(
      this.refs.scrollContainer
    ).clientWidth;

    const scrollRatio = ((scrollLeft + windowWidth) / layoutWidth) * 100;
    this.setState({
      scrollLeft,
      windowWidth,
      layoutWidth,
      scrollRatio: scrollRatio,
    });
  }

  render() {
    const { classes, history, career, careers, categories } = this.props;

    const {
      col,
      scrollRatio,
      scrollLeft,
      windowWidth,
      layoutWidth,
      index,
      selected,
      videoPlayerOpen,
    } = this.state;

    const width = "md";

    let display = true;
    const bubbleContainer = ReactDOM.findDOMNode(this.refs.bubbleContainer);

    if (bubbleContainer === null) {
      display = false;
    }

    let size = bubbleContainer ? bubbleContainer.clientWidth * 0.7 : 100;

    if (size > 300) {
      size = 300;
    }

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "#455a64",
        }}
      >
        <div
          ref="scrollContainer"
          onScroll={this.scrollEvent.bind(this)}
          className={classes.container}
        >
          <div
            className={classes.infoContainer}
            style={{
              height:
                width !== "xs" && width !== "sm"
                  ? "calc(calc(var(--vh, 1vh) * 100) - 250px)"
                  : "calc(calc(var(--vh, 1vh) * 100) - 159px)",
            }}
          >
            <Grid
              spacing={1}
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                height: "100%",
              }}
            >
              <Grid item xs={12} sm={12} md={4} ref="bubbleContainer">
                <div>
                  {display ? (
                    <Zoom in>
                      <Bubble
                        comingSoon={career.comingSoon}
                        career={career}
                        size={size}
                        color={"#f44336"}
                        onPlayVideo={() =>
                          this.setState({ videoPlayerOpen: true })
                        }
                      />
                    </Zoom>
                  ) : (
                    []
                  )}
                </div>
                <div style={{ textAlign: "center", marginTop: 30 }}>
                  {career.videoURL && career.comingSoon !== true ? (
                    <Button
                      variant="contained"
                      onClick={() => this.setState({ videoPlayerOpen: true })}
                    >
                      <Play /> Play Video
                    </Button>
                  ) : (
                    <Button
                      disabled
                      variant="contained"
                      onClick={() => this.setState({ videoPlayerOpen: true })}
                    >
                      <Play /> Video Coming Soon
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <div style={{ padding: 20 }}>
                  <Button
                    gutterBottom
                    size="small"
                    variant="outlined"
                    className={classes.whiteButton}
                    onClick={history.goBack}
                  >
                    <Back /> Back to Wall
                  </Button>
                  <br />
                  <br />
                  {/* <Typography display="block" variant="title" className={classes.whiteText}>
                    Category
                  </Typography> */}
                  <Typography
                    display="block"
                    variant="h3"
                    gutterBottom
                    className={classes.whiteTitle}
                  >
                    Career Title
                  </Typography>
                  <Typography
                    display="block"
                    variant="body1"
                    className={classes.whiteText}
                    gutterBottom
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquid ex ea commodi consequat. Quis aute
                    iure reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Divider
                        style={{ color: "white", background: "white" }}
                      />
                      <Typography
                        display="block"
                        className={classes.whiteText}
                        variant="overline"
                      >
                        How to get there:
                      </Typography>
                      <Chip
                        variant="outlined"
                        size="small"
                        label="Master's degree"
                        style={{ color: "white", borderColor: "white" }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Divider
                        style={{ color: "white", background: "white" }}
                      />
                      <Typography
                        display="block"
                        className={classes.whiteText}
                        variant="overline"
                      >
                        What kinds of jobs are in this career:
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Chip
                            variant="outlined"
                            size="small"
                            label="Part Time"
                            style={{ color: "white", borderColor: "white" }}
                          />
                        </Grid>
                        <Grid item>
                          <Chip
                            variant="outlined"
                            size="small"
                            label="Full Time"
                            style={{ color: "white", borderColor: "white" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Divider
                        style={{ color: "white", background: "white" }}
                      />
                      <Typography
                        display="block"
                        className={classes.whiteText}
                        variant="overline"
                      >
                        School Options
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Chip
                            size="small"
                            label="Santa Barbara University"
                            style={{ borderColor: "white" }}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        display="block"
                        variant="caption"
                        style={{ color: "white", opacity: 0.63 }}
                      >
                        Click on Schools to open the CPFM map
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            position: "fixed",
            bottom: 0,
          }}
        >
          {width !== "xs" && width !== "sm" ? (
            <div
              style={{
                margin: "auto",
                marginBottom: 10,
              }}
            >
              <MiniLayout
                selectedCareer={career}
                interactionDisabled
                careers={careers}
                smallBubbleSize={smallBubbleSize}
                width={col * (smallBubbleSize + smallSpacing)}
                scrollRatio={scrollRatio}
                items={items}
                maxPitch={maxPitch}
                col={col}
                spacing={smallSpacing}
                scrollLeft={scrollLeft}
                windowWidth={windowWidth}
                layoutWidth={layoutWidth}
                selected={selected}
                onClick={() => history.push(CPFM)}
                onScrollRatioChange={(sr) => {
                  this.setState({
                    scrollRatio: sr,
                  });
                  ReactDOM.findDOMNode(this.refs.scrollContainer).scrollLeft =
                    (layoutWidth * sr) / 100 - windowWidth + 8;
                }}
              />
            </div>
          ) : (
            []
          )}
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                  selected: categories[v],
                });
                history.push(`?index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              {categories.map((c) => (
                <Tab
                  onClick={() => history.push(`${CATEGORY}?categoryID=${c.id}`)}
                  key={c.title}
                  label={<span style={{ color: c.color }}>{c.title}</span>}
                />
              ))}
            </Tabs>
          </AppBar>
        </div>
        <FullScreenVideoPlayer
          // path={`CPFM -> ${career.categories[0].title} -> ${career.title}`}
          path={`CPFM -> ${career.title}`}
          videoURL={career.videoURL}
          open={videoPlayerOpen}
          close={() => this.setState({ videoPlayerOpen: false })}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperCareerPage);
