import { ACADEMIES } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import AcademiesPage from "./container/AcademiesPage";

export default function getSettingsRoute(userAuth) {
  const { account, cpfm } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [cpfm.name]: [cpfm.roles.admin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: ACADEMIES,
    withSidebar: true,
    withAppBar: true,
    component: AcademiesPage,
  };
}
