// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCareerPage from "../component/WrapperCareerPage";

const mapStateToProps = (state) => ({
  careers: state.careers,
  categories: state.cpfmCategories,
  skillTags: state.skillTags,
  degreeLevels: state.degreeLevels,
  degreeRequirements: state.degreeRequirements,
  subCategories: state.subCategories,
  quizTags: state.quizTags,
  quizCategories: state.quizCategories,
  authors: state.authors,
});

const mapDispatchToProps = (dispatch) => ({
  getCareers: (...args) => dispatch(Actions.getCareers(...args)),
  getDegreeLevels: (...args) => dispatch(Actions.getDegreeLevels(...args)),
  getDegreeRequirements: (...args) =>
    dispatch(Actions.getDegreeRequirements(...args)),
  getCareers: (...args) => dispatch(Actions.getCareers(...args)),
  getCpfmCategories: (...args) => dispatch(Actions.getCpfmCategories(...args)),
  getFullApp: (...args) => dispatch(Actions.getFullApp(...args)),
  getCareerById: (...args) => dispatch(Actions.getCareerById(...args)),
  createCareer: (...args) => dispatch(Actions.createCareer(...args)),
  deleteCareer: (...args) => dispatch(Actions.deleteCareer(...args)),
  updateCareer: (...args) => dispatch(Actions.updateCareer(...args)),
  getCareerCategories: (...args) =>
    dispatch(Actions.getCareerCategories(...args)),
  addCareerCategory: (...args) => dispatch(Actions.addCareerCategory(...args)),
  deleteCareerCategory: (...args) =>
    dispatch(Actions.deleteCareerCategory(...args)),

  updateCareerTitle: (...args) => dispatch(Actions.updateCareerTitle(...args)),
  deleteCareerTitle: (...args) => dispatch(Actions.deleteCareerTitle(...args)),
  createCareerTitle: (...args) => dispatch(Actions.createCareerTitle(...args)),
  getCareerTitles: (...args) => dispatch(Actions.getCareerTitles(...args)),
  getCareerTitleById: (...args) =>
    dispatch(Actions.getCareerTitleById(...args)),

  updateSkillTag: (...args) => dispatch(Actions.updateSkillTag(...args)),
  deleteSkillTag: (...args) => dispatch(Actions.deleteSkillTag(...args)),
  createSkillTag: (...args) => dispatch(Actions.createSkillTag(...args)),
  getSkillTags: (...args) => dispatch(Actions.getSkillTags(...args)),
  getSkillTagById: (...args) => dispatch(Actions.getSkillTagById(...args)),

  updateCareerDegreeLevel: (...args) =>
    dispatch(Actions.updateCareerDegreeLevel(...args)),
  deleteCareerDegreeLevel: (...args) =>
    dispatch(Actions.deleteCareerDegreeLevel(...args)),
  createCareerDegreeLevel: (...args) =>
    dispatch(Actions.createCareerDegreeLevel(...args)),
  getCareerDegreeLevels: (...args) =>
    dispatch(Actions.getCareerDegreeLevels(...args)),
  getCareerDegreeLevelById: (...args) =>
    dispatch(Actions.getCareerDegreeLevelById(...args)),

  updateCareerDegreeRequirement: (...args) =>
    dispatch(Actions.updateCareerDegreeRequirement(...args)),
  deleteCareerDegreeRequirement: (...args) =>
    dispatch(Actions.deleteCareerDegreeRequirement(...args)),
  createCareerDegreeRequirement: (...args) =>
    dispatch(Actions.createCareerDegreeRequirement(...args)),
  getCareerDegreeRequirements: (...args) =>
    dispatch(Actions.getCareerDegreeRequirements(...args)),
  getCareerDegreeRequirementById: (...args) =>
    dispatch(Actions.getCareerDegreeRequirementById(...args)),

  updateSkillTag: (...args) => dispatch(Actions.updateSkillTag(...args)),
  deleteSkillTag: (...args) => dispatch(Actions.deleteSkillTag(...args)),
  createSkillTag: (...args) => dispatch(Actions.createSkillTag(...args)),
  getSkillTags: (...args) => dispatch(Actions.getSkillTags(...args)),
  getSkillTagById: (...args) => dispatch(Actions.getSkillTagById(...args)),

  getCareerMedia: (...args) => dispatch(Actions.getCareerMedia(...args)),
  getCareerMediumById: (...args) =>
    dispatch(Actions.getCareerMediumById(...args)),
  createCareerMedium: (...args) =>
    dispatch(Actions.createCareerMedium(...args)),
  deleteCareerMedium: (...args) =>
    dispatch(Actions.deleteCareerMedium(...args)),
  updateCareerMedium: (...args) =>
    dispatch(Actions.updateCareerMedium(...args)),

  getCareerSliderItems: (...args) =>
    dispatch(Actions.getCareerSliderItems(...args)),
  getCareerSliderItemById: (...args) =>
    dispatch(Actions.getCareerSliderItemById(...args)),
  createCareerSliderItem: (...args) =>
    dispatch(Actions.createCareerSliderItem(...args)),
  deleteCareerSliderItem: (...args) =>
    dispatch(Actions.deleteCareerSliderItem(...args)),
  updateCareerSliderItem: (...args) =>
    dispatch(Actions.updateCareerSliderItem(...args)),

  updateCareerSkill: (...args) => dispatch(Actions.updateCareerSkill(...args)),
  deleteCareerSkill: (...args) => dispatch(Actions.deleteCareerSkill(...args)),
  createCareerSkill: (...args) => dispatch(Actions.createCareerSkill(...args)),
  getCareerSkills: (...args) => dispatch(Actions.getCareerSkills(...args)),
  getCareerSkillById: (...args) =>
    dispatch(Actions.getCareerSkillById(...args)),

  getSubCategories: (...args) => dispatch(Actions.getSubCategories(...args)),
  getCareerSubCategories: (...args) =>
    dispatch(Actions.getCareerSubCategories(...args)),
  createCareerSubCategory: (...args) =>
    dispatch(Actions.createCareerSubCategory(...args)),
  deleteCareerSubCategory: (...args) =>
    dispatch(Actions.deleteCareerSubCategory(...args)),

  getAuthors: (...args) => dispatch(Actions.getAuthors(...args)),
  updateCareerAuthor: (...args) =>
    dispatch(Actions.updateCareerAuthor(...args)),
  deleteCareerAuthor: (...args) =>
    dispatch(Actions.deleteCareerAuthor(...args)),
  createCareerAuthor: (...args) =>
    dispatch(Actions.createCareerAuthor(...args)),
  getCareerAuthors: (...args) => dispatch(Actions.getCareerAuthors(...args)),
  getCareerAuthorById: (...args) =>
    dispatch(Actions.getCareerAuthorById(...args)),

  getQuizTags: (...args) => dispatch(Actions.getQuizTags(...args)),
  getCareerQuizTags: (...args) => dispatch(Actions.getCareerQuizTags(...args)),
  createCareerQuizTag: (...args) =>
    dispatch(Actions.createCareerQuizTag(...args)),
  deleteCareerQuizTag: (...args) =>
    dispatch(Actions.deleteCareerQuizTag(...args)),

  getQuizCategories: (...args) => dispatch(Actions.getQuizCategories(...args)),
});
class CareerPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    careers: PropTypes.array,
    getCareers: PropTypes.func,
    getCareerById: PropTypes.func,
    createCareer: PropTypes.func,
    deleteCareer: PropTypes.func,
    updateCareer: PropTypes.func,
    getCareerCategories: PropTypes.func,
    addCareerCategory: PropTypes.func,
    deleteCareerCategory: PropTypes.func,

    skillTags: PropTypes.func,
    updateSkillTag: PropTypes.func,
    deleteSkillTag: PropTypes.func,
    createSkillTag: PropTypes.func,
    getSkillTags: PropTypes.func,
    getSkillTagById: PropTypes.func,

    updateCareerDegreeLevel: PropTypes.func,
    deleteCareerDegreeLevel: PropTypes.func,
    createCareerDegreeLevel: PropTypes.func,
    getCareerDegreeLevels: PropTypes.func,
    getCareerDegreeLevelById: PropTypes.func,

    authors: PropTypes.array,
    getAuthors: PropTypes.func,
    updateCareerAuthor: PropTypes.func,
    deleteCareerAuthor: PropTypes.func,
    createCareerAuthor: PropTypes.func,
    getCareerAuthors: PropTypes.func,
    getCareerAuthorById: PropTypes.func,

    updateCareerDegreeRequirement: PropTypes.func,
    deleteCareerDegreeRequirement: PropTypes.func,
    createCareerDegreeRequirement: PropTypes.func,
    getCareerDegreeRequirements: PropTypes.func,
    getCareerDegreeRequirementById: PropTypes.func,

    updateCareerTitle: PropTypes.func,
    deleteCareerTitle: PropTypes.func,
    createCareerTitle: PropTypes.func,
    getCareerTitles: PropTypes.func,
    getCareerTitleById: PropTypes.func,

    updateCareerSkill: PropTypes.func,
    deleteCareerSkill: PropTypes.func,
    createCareerSkill: PropTypes.func,
    getCareerSkills: PropTypes.func,
    getCareerSkillById: PropTypes.func,

    getCareerMedia: PropTypes.func,
    getCareerMediumById: PropTypes.func,
    createCareerMedium: PropTypes.func,
    deleteCareerMedium: PropTypes.func,
    updateCareerMedium: PropTypes.func,

    getCareerSliderItems: PropTypes.func,
    getCareerSliderItemById: PropTypes.func,
    createCareerSliderItem: PropTypes.func,
    deleteCareerSliderItem: PropTypes.func,
    updateCareerSliderItem: PropTypes.func,

    getCpfmCategories: PropTypes.func,
    categories: PropTypes.array,

    getDegreeLevels: PropTypes.func,
    degreeLevels: PropTypes.array,

    getDegreeRequirements: PropTypes.func,
    degreeRequirements: PropTypes.array,

    getSubCategories: PropTypes.func,
    getCareerSubCategories: PropTypes.func,
    createCareerSubCategory: PropTypes.func,
    deleteCareerSubCategory: PropTypes.func,

    getQuizTags: PropTypes.func,
    getCareerQuizTags: PropTypes.func,
    createCareerQuizTag: PropTypes.func,
    deleteCareerQuizTag: PropTypes.func,

    quizCategories: PropTypes.array,
    getQuizCategories: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      careers,
      getSkillTags,
      getCpfmCategories,
      getDegreeRequirements,
      getSubCategories,
      getDegreeLevels,
      getQuizTags,
      getQuizCategories,
      getAuthors,
    } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: careers.length === 0,
    };
    getSkillTags();
    getCpfmCategories();
    getDegreeRequirements();
    getSubCategories();
    getDegreeLevels();
    getQuizTags();
    getQuizCategories();
    getAuthors();
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getFullApp, getCpfmCategories } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await getFullApp(filters);

    this.setState({ loading: false });
  }

  render() {
    const {
      getCareerAuthors,
      updateCareerAuthor,
      deleteCareerAuthor,
      createCareerAuthor,
      getCareerAuthorById,
      careers,
      categories,
      getCareerById,
      createCareer,
      deleteCareer,
      updateCareer,
      addCareerCategory,
      deleteCareerCategory,
      getCareerCategories,
      history,
      getCareers,
      getFullApp,
      updateSkillTag,
      deleteSkillTag,
      createSkillTag,
      getSkillTags,
      getSkillTagById,
      updateCareerSkill,
      deleteCareerSkill,
      createCareerSkill,
      getCareerSkills,
      getCareerSkillById,
      skillTags,
      degreeLevels,
      degreeRequirements,
      getCareerMedia,
      getCareerMediumById,
      createCareerMedium,
      subCategories,
      deleteCareerMedium,
      updateCareerMedium,
      getCareerSubCategories,
      createCareerSubCategory,
      deleteCareerSubCategory,
      getCareerSliderItems,
      getCareerSliderItemById,
      createCareerSliderItem,
      deleteCareerSliderItem,
      updateCareerSliderItem,
      updateCareerTitle,
      deleteCareerTitle,
      createCareerTitle,
      getCareerTitles,
      getCareerTitleById,
      getQuizTags,
      getCareerQuizTags,
      createCareerQuizTag,
      deleteCareerQuizTag,
      quizTags,
      quizCategories,
      updateCareerDegreeLevel,
      deleteCareerDegreeLevel,
      createCareerDegreeLevel,
      getCareerDegreeLevels,
      getCareerDegreeLevelById,
      updateCareerDegreeRequirement,
      deleteCareerDegreeRequirement,
      createCareerDegreeRequirement,
      getCareerDegreeRequirements,
      getCareerDegreeRequirementById,
      authors,
    } = this.props;

    const { urlParams, loading } = this.state;

    const api = {
      getCareerAuthors,
      updateCareerAuthor,
      deleteCareerAuthor,
      createCareerAuthor,
      getCareerAuthorById,
      getCareerById,
      createCareer,
      deleteCareer,
      updateCareer,
      addCareerCategory,
      deleteCareerCategory,
      getCareerCategories,
      getCareers,
      getFullApp,
      updateSkillTag,
      deleteSkillTag,
      createSkillTag,
      getSkillTags,
      getSkillTagById,
      updateCareerSkill,
      deleteCareerSkill,
      createCareerSkill,
      getCareerSkills,
      getCareerSkillById,
      degreeLevels,
      degreeRequirements,
      getCareerMedia,
      getCareerMediumById,
      createCareerMedium,
      deleteCareerMedium,
      updateCareerMedium,
      getCareerSubCategories,
      createCareerSubCategory,
      deleteCareerSubCategory,
      getCareerSliderItems,
      getCareerSliderItemById,
      createCareerSliderItem,
      deleteCareerSliderItem,
      updateCareerSliderItem,
      updateCareerTitle,
      deleteCareerTitle,
      createCareerTitle,
      getCareerTitles,
      getCareerTitleById,
      getQuizTags,
      getCareerQuizTags,
      createCareerQuizTag,
      deleteCareerQuizTag,
      updateCareerDegreeLevel,
      deleteCareerDegreeLevel,
      createCareerDegreeLevel,
      getCareerDegreeLevels,
      getCareerDegreeLevelById,
      updateCareerDegreeRequirement,
      deleteCareerDegreeRequirement,
      createCareerDegreeRequirement,
      getCareerDegreeRequirements,
      getCareerDegreeRequirementById,
    };

    return (
      <WrapperCareerPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        subCategories={subCategories}
        careers={careers}
        categories={categories}
        skillTags={skillTags}
        quizCategories={quizCategories}
        quizTags={quizTags}
        api={api}
        urlParams={urlParams}
        history={history}
        degreeLevels={degreeLevels}
        degreeRequirements={degreeRequirements}
        authors={authors}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CareerPage);
