import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Table from "components/TablePagination";
import CategoryChip from "components/CategoryChip";
import AdvancedSearch from "components/AdvancedSearchRefactor";

// styles
import styles from "./styles";

class CategoriesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    categories: PropTypes.array,
    onSelect: PropTypes.func,
    getCategories: PropTypes.func,
    history: PropTypes.func,
  };

  onSelect(selectedCategory) {
    const { onSelect } = this.props;
    this.setState({ selectedCategory });
    if (onSelect) {
      onSelect(selectedCategory);
    }
  }

  render() {
    const { categories, getCategories, history } = this.props;

    return (
      // <AdvancedSearch
      //   history={history}
      //   location={window.location}
      //   refresh={getCategories}
      //   keyword="categories"
      //   metaDataEndpoint="/cpfm/categories/searchMetadata"
      // >
      <Table
        data={categories}
        meta={[
          {
            path: "title",
            title: "Title",
            numeric: false,
            component: CategoryChip,
          },
        ]}
        title={"Categories"}
        onRowSelect={this.onSelect.bind(this)}
      />
      // </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(CategoriesList);
