import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Availabilities from "components/Availabilities";
import Calendar from "components/Calendar";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";



class EventTypeCoach extends Component {
  static propTypes = {
    classes: PropTypes.object,
    coach: PropTypes.object,
    coaches: PropTypes.array,
    updateAvailability: PropTypes.func,
    getDailyAvailabilities: PropTypes.func,
    deleteAvailability: PropTypes.func,
    refreshKey: PropTypes.number,
    createAvailability: PropTypes.func,
    refresh: PropTypes.func,
    availabilities: PropTypes.array,
  };

  state = { expanded: false, anchorEl: null };

  render() {
    const {
      classes,
      coach,
      coaches,
      refreshKey,
      createAvailability,
      refresh,
      availabilities,
      updateAvailability,
      getDailyAvailabilities,
      deleteAvailability,
    } = this.props;

    const { expanded, combinedCoach, anchorEl } = this.state;

    return (
      <ExpansionPanel
        onChange={() =>
          this.setState((prevState) => ({ expanded: !prevState.expanded }))
        }
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar src={coach && coach.avatar} />
            </Grid>
            <Grid item>
              <Typography display="block" variant="body2">
                {coach.fullName} Availabilities
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Chose when student can schedule appointments with{" "}
                {coach.fullName}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {expanded ? (
            <Grid container justify="space-between">
              <Grid item xs={12} md={6} lg={8}>
                {combinedCoach === undefined ? (
                  <React.Fragment>
                    <Chip
                      label="Combine with ▾"
                      onClick={(e) =>
                        this.setState({
                          anchorEl: e.currentTarget,
                        })
                      }
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={(e) => this.setState({ anchorEl: null })}
                    >
                      {coaches &&
                        coaches
                          .filter((c) => c.id !== coach.id)
                          .map((c) => (
                            <MenuItem
                              onClick={() =>
                                this.setState(
                                  { anchorEl: null, combinedCoach: c },
                                  refresh
                                )
                              }
                            >
                              <ListItemIcon className={classes.icon}>
                                <Avatar src={c.avatar} />
                              </ListItemIcon>
                              <ListItemText
                                classes={{ primary: classes.primary }}
                                inset
                                primary={c.fullName}
                              />
                            </MenuItem>
                          ))}
                    </Menu>
                  </React.Fragment>
                ) : (
                  <Chip
                    label={`Combined with ${combinedCoach.fullName}`}
                    style={{
                      background: "#2196f3",
                      color: "white",
                    }}
                    avatar={<Avatar src={combinedCoach.avatar} />}
                    onDelete={(e) =>
                      this.setState(
                        {
                          combinedCoach: undefined,
                        },
                        refresh
                      )
                    }
                  />
                )}
                <Calendar
                  onSelect={(e) => console.log(e)}
                  key={refreshKey}
                  getDailyAvailabilities={(d) =>
                    getDailyAvailabilities(
                      coach.id,
                      d,
                      12,
                      combinedCoach && combinedCoach.id
                    )
                  }
                />
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  <b>Important: </b>
                  {`Times are local to ${coach.fullName}'s timezone (${coach.tzName})`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography display="block" variant="title">
                      Availabilities
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      variant="outlined"
                      label="+ Availability"
                      onClick={() =>
                        createAvailability({ coachID: coach.id }).then(() =>
                          refresh()
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Availabilities
                  availabilities={availabilities.filter(
                    (f) => f.coachID === coach.id
                  )}
                  coach={coach}
                  key={refreshKey}
                  updateAvailability={updateAvailability}
                  deleteAvailability={deleteAvailability}
                  createAvailability={createAvailability}
                  refresh={refresh}
                />
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(EventTypeCoach);
