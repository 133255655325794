import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// components
import DegreeLevelsList from 'components/DegreeLevelsList';
import Page from 'components/Page';
import FormDegreeLevel from 'components/FormDegreeLevel';

// styles
import styles from './styles';

class WrapperDegreeLevelsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //degreeLevels
    degreeLevels: PropTypes.array,
    getDegreeLevelById: PropTypes.func,
    createDegreeLevel: PropTypes.func,
    deleteDegreeLevel: PropTypes.func,
    updateDegreeLevel: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedDegreeLevel: undefined,
      formDegreeLevelOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { degreeLevels } = this.props;

    switch (index) {
      case 0:
        return (
          <DegreeLevelsList
            degreeLevels={degreeLevels}
            onSelect={(selectedDegreeLevel) => this.setState({
              selectedDegreeLevel,
              formDegreeLevelOpen: true,
            })}
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createDegreeLevel,
      deleteDegreeLevel,
      updateDegreeLevel,
      refresh,
    } = this.props;

    const {
      index,
      selectedDegreeLevel,
      formDegreeLevelOpen,
    } = this.state;

    return (
      <Page
        helmet={'DegreeLevels'}
        loadingMessage={'Loading Document'}
        loading={loading}
        fab={(
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formDegreeLevelOpen: true })}
          >
            <AddIcon />
          </Fab>
        )}
        tabs={(
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          >
            <Tab
              label="DegreeLevels"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        )}
      >
        {
          this.getStepContent()
        }
        <FormDegreeLevel
          open={formDegreeLevelOpen}
          degreeLevel={selectedDegreeLevel}
          close={() => {
            this.setState({
              formDegreeLevelOpen: false,
            });
            setTimeout(() => this.setState({
              selectedDegreeLevel: undefined,
            }), 200);
          }
          }
          createDegreeLevel={createDegreeLevel}
          deleteDegreeLevel={deleteDegreeLevel}
          updateDegreeLevel={updateDegreeLevel}
          refresh={refresh}
        />
      </Page>
    )
  }
}
export default withStyles(styles)(WrapperDegreeLevelsPage);

