import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const categorySubCategories = `${REACT_APP_API_PREFIX}/${service}/categorySubCategories`;

export function getCategorySubCategories(filters) {
  const url = `${categorySubCategories}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCategorySubCategoryById(id) {
  const url = `${categorySubCategories}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateCategorySubCategory(id, body) {
  const url = `${categorySubCategories}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCategorySubCategory(id) {
  const url = `${categorySubCategories}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCategorySubCategory(body) {
  const url = `${categorySubCategories}`;
  return {
    method: POST,
    url,
    body,
  };
}
