import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const ages = `/${service}/ages`;

export function getAges() {
  const url = `${ages}`;
  return {
    method: GET,
    url,
  };
}

export function getProgramsByAge(id) {
  const url = `${ages}/${id}/programs`;
  return {
    method: GET,
    url,
  };
}

export function getAgeById(id) {
  const url = `${ages}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateAge(id, body) {
  const url = `${ages}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAge(id) {
  const url = `${ages}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAge(body) {
  const url = `${ages}`;
  return {
    method: POST,
    url,
    body,
  };
}
