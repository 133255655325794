import React from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

// custom
import AcademiesList from "components/AcademiesList";
import FormAcademy from "components/FormAcademy";

// reactor
import Page from "components/Page";

// constants
import { ACADEMY } from "constants/routes";

// styles
import styles from "./styles";

class WrapperAcademyPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    academies: PropTypes.array,
    createAcademy: PropTypes.func,
    deleteAcademy: PropTypes.func,
    updateAcademy: PropTypes.func,
    getAcademies: PropTypes.func,
    filters: PropTypes.array,
    location: PropTypes.object,
    dataLoading: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedAcademy: undefined,
      formAcademyOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      academies,
      history,
      filters,
      location,
      dataLoading,
      getAcademies,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <AcademiesList
            filters={filters}
            getAcademies={getAcademies}
            location={location}
            history={history}
            dataLoading={dataLoading}
            academies={academies}
            onDoubleSelect={(selectedAcademy) =>
              this.setState({ selectedAcademy, formAcademyOpen: true })
            }
            onSelect={(selectedAcademy) =>
              history.push(`${ACADEMY}?id=${selectedAcademy.id}`)
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createAcademy,
      deleteAcademy,
      updateAcademy,
      refresh,
    } = this.props;

    const { index, selectedAcademy, formAcademyOpen } = this.state;

    return (
      <Page
        helmet="Academies"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formAcademyOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Academies"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormAcademy
          open={formAcademyOpen}
          history={history}
          academy={selectedAcademy}
          close={() => {
            this.setState({
              formAcademyOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedAcademy: undefined,
                }),
              200
            );
          }}
          createAcademy={createAcademy}
          deleteAcademy={deleteAcademy}
          updateAcademy={updateAcademy}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperAcademyPage);
