// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperEnrollmentPage from "../component/WrapperEnrollmentPage";

const mapStateToProps = (state) => ({
  enrollments: state.enrollments,
  courses: state.courses,
  users: state.users,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getEnrollments: (...args) => dispatch(Actions.getEnrollments(...args)),
  getCourses: (...args) => dispatch(Actions.getCourses(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
  getEnrollmentById: (...args) => dispatch(Actions.getEnrollmentById(...args)),
  createEnrollment: (...args) => dispatch(Actions.createEnrollment(...args)),
  deleteEnrollment: (...args) => dispatch(Actions.deleteEnrollment(...args)),
  updateEnrollment: (...args) => dispatch(Actions.updateEnrollment(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
});
class EnrollmentPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    enrollments: PropTypes.array,
    getEnrollments: PropTypes.func,
    getCourses: PropTypes.func,
    getUsers: PropTypes.func,
    users: PropTypes.array,
    courses: PropTypes.array,
    getEnrollmentById: PropTypes.func,
    createEnrollment: PropTypes.func,
    searchUsers: PropTypes.func,
    deleteEnrollment: PropTypes.func,
    updateEnrollment: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, getCourses, getUsers } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
      loading: true,
      dataLoading: false,
    };
    getCourses();
    getUsers();
    this.refresh();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      const urlParams = getJsonFromUrl(nextProps.location);
      console.log(urlParams);
      this.setState(
        {
          filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
        },
        this.refresh.bind(this)
      );
    }
  }

  async refresh() {
    const { getEnrollments } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    this.setState({ dataLoading: true });
    await Promise.all([
      getEnrollments(urlParams.filters ? JSON.parse(urlParams.filters) : []),
    ]);

    this.setState({
      loading: false,
      dataLoading: false,
      refreshKey: new Date().getTime(),
    });
  }

  render() {
    const {
      history,
      location,
      enrollments,
      getEnrollmentById,
      createEnrollment,
      deleteEnrollment,
      updateEnrollment,
      getEnrollments,
      courses,
      user,
      searchUsers,
      users,
    } = this.props;

    const { urlParams, loading, dataLoading, filters, refreshKey } = this.state;

    return (
      <WrapperEnrollmentPage
        history={history}
        location={location}
        filters={filters}
        courses={courses}
        dataLoading={dataLoading}
        searchUsers={searchUsers}
        getEnrollments={getEnrollments}
        refreshKey={refreshKey}
        refresh={this.refresh.bind(this)}
        loading={loading}
        enrollments={enrollments}
        users={users}
        user={user}
        getEnrollmentById={getEnrollmentById}
        createEnrollment={createEnrollment}
        deleteEnrollment={deleteEnrollment}
        updateEnrollment={updateEnrollment}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentPage);
