import { networkAction } from 'helpers/network/networkAction';

import {
  GET_SKILL_TAGS,
  GET_SKILL_TAG_BY_ID,
  DELETE_SKILL_TAG,
  UPDATE_SKILL_TAG,
  CREATE_SKILL_TAG,
} from 'constants/skillTags';

import * as Api from 'api';

export const getSkillTags = () => async dispatch => networkAction(
  dispatch,
  GET_SKILL_TAGS,
  Api.getSkillTags,
  []
);

export const getSkillTagById = (id) => async dispatch => networkAction(
  dispatch,
  GET_SKILL_TAG_BY_ID,
  Api.getSkillTagById,
  [id]
);

export const deleteSkillTag = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_SKILL_TAG,
  Api.deleteSkillTag,
  [id]
);

export const createSkillTag = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_SKILL_TAG,
  Api.createSkillTag,
  [body]
);

export const updateSkillTag = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_SKILL_TAG,
  Api.updateSkillTag,
  [id, body]
);
