import { networkAction } from 'helpers/network/networkAction';

import {
  GET_BOOKS,
  GET_BOOK_BY_ID,
  DELETE_BOOK,
  UPDATE_BOOK,
  CREATE_BOOK,
} from 'constants/books';

import * as Api from 'api';

export const getBooks = () => async dispatch => networkAction(
  dispatch,
  GET_BOOKS,
  Api.getBooks,
  []
);

export const getBookById = (id) => async dispatch => networkAction(
  dispatch,
  GET_BOOK_BY_ID,
  Api.getBookById,
  [id]
);

export const deleteBook = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_BOOK,
  Api.deleteBook,
  [id]
);

export const createBook = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_BOOK,
  Api.createBook,
  [body]
);

export const updateBook = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_BOOK,
  Api.updateBook,
  [id, body]
);
