import * as Api from "api";
import {
  CREATE_CAREER_SLIDE_ITEM,
  DELETE_CAREER_SLIDE_ITEM,
  GET_CAREER_SLIDE_ITEMS,
  GET_CAREER_SLIDE_ITEM_BY_ID,
  UPDATE_CAREER_SLIDE_ITEM,
} from "constants/careerSliderItems";
import { networkAction } from "helpers/network/networkAction";

export const getCareerSliderItems = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_SLIDE_ITEMS, Api.getCareerSliderItems, [
    filters,
  ]);

export const getCareerSliderItemById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_CAREER_SLIDE_ITEM_BY_ID,
    Api.getCareerSliderItemById,
    [id]
  );

export const deleteCareerSliderItem = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_CAREER_SLIDE_ITEM,
    Api.deleteCareerSliderItem,
    [id]
  );

export const createCareerSliderItem = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_CAREER_SLIDE_ITEM,
    Api.createCareerSliderItem,
    [body]
  );

export const updateCareerSliderItem = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_CAREER_SLIDE_ITEM,
    Api.updateCareerSliderItem,
    [id, body]
  );
