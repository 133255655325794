// actions
import * as Actions from "actions";
import { getJsonFromUrl } from "helpers";
// helpers
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperDownloadRequestPage from "../component/WrapperDownloadRequestPage";

const mapStateToProps = (state) => ({
  downloadRequests: state.downloadRequests,
  books: state.books,
});

const mapDispatchToProps = (dispatch) => ({
  getDownloadRequests: (...args) =>
    dispatch(Actions.getDownloadRequests(...args)),
  getDownloadRequestById: (...args) =>
    dispatch(Actions.getDownloadRequestById(...args)),
  createDownloadRequest: (...args) =>
    dispatch(Actions.createDownloadRequest(...args)),
  deleteDownloadRequest: (...args) =>
    dispatch(Actions.deleteDownloadRequest(...args)),
  updateDownloadRequest: (...args) =>
    dispatch(Actions.updateDownloadRequest(...args)),
  getBooks: (...args) => dispatch(Actions.getBooks(...args)),
});
class DownloadRequestPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    downloadRequests: PropTypes.array,
    getDownloadRequests: PropTypes.func,
    getDownloadRequestById: PropTypes.func,
    createDownloadRequest: PropTypes.func,
    deleteDownloadRequest: PropTypes.func,
    updateDownloadRequest: PropTypes.func,

    getBooks: PropTypes.func,
    books: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, downloadRequests, getBooks } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: downloadRequests.length === 0,
    };

    getBooks();
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getDownloadRequests } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getDownloadRequests(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      downloadRequests,
      getDownloadRequestById,
      createDownloadRequest,
      deleteDownloadRequest,
      updateDownloadRequest,
      getDownloadRequests,
      books,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperDownloadRequestPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        books={books}
        getDownloadRequests={getDownloadRequests}
        downloadRequests={downloadRequests.sort(
          (a, b) =>
            moment(b.inquiryDate).valueOf() - moment(a.inquiryDate).valueOf()
        )}
        getDownloadRequestById={getDownloadRequestById}
        createDownloadRequest={createDownloadRequest}
        deleteDownloadRequest={deleteDownloadRequest}
        updateDownloadRequest={updateDownloadRequest}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadRequestPage);
