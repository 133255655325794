import { GET, PUT, DELETE, POST } from "constants/methods";
const events = "/calendy/events";

export function getEvents(filters) {
  const url = `${events}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function sendStudentEmail(id) {
  const url = `${events}/${id}/sendStudentEmail`;
  return {
    method: GET,
    url,
  };
}

export function getEventById(id) {
  const url = `${events}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEvent(id, body) {
  const url = `${events}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEvent(id) {
  const url = `${events}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEvent(body) {
  const url = `${events}`;
  return {
    method: POST,
    url,
    body,
  };
}
