// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CareersList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    careers: PropTypes.array,
    onSelect: PropTypes.func,
    getCareers: PropTypes.func,
    history: PropTypes.object,
  };

  onSelect(selectedCareer) {
    const { onSelect } = this.props;
    this.setState({ selectedCareer });
    if (onSelect) {
      onSelect(selectedCareer);
    }
  }

  render() {
    const { careers, history, getCareers } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getCareers}
        keyword="careers"
        metaDataEndpoint="/cpfm/careers/searchMetadata"
      >
        <Table
          data={careers}
          meta={[
            {
              path: "icon",
              numeric: false,
              render: (t) => <img src={t} style={{ width: 20 }} />,
              width: 30,
            },
            {
              path: "title",
              title: "Title",
              numeric: false,
              render: (t) => (
                <span style={{ fontFamily: "Kimi", fontSize: 18 }}>{t}</span>
              ),
            },
          ]}
          title={"Careers"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(CareersList);
