import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "cpfm";
const categories = `/${service}/categories`;

export function getCpfmCategories(filters) {
  const url = `${categories}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCpfmCategoryById(id) {
  const url = `${categories}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getCpfmCategoryCareers(id) {
  const url = `${categories}/${id}/careers`;
  return {
    method: GET,
    url,
  };
}

export function updateCpfmCategory(id, body) {
  const url = `${categories}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCpfmCategory(id) {
  const url = `${categories}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCpfmCategory(body) {
  const url = `${categories}`;
  return {
    method: POST,
    url,
    body,
  };
}
