import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

// styles
import styles from "./styles";

class CategoryChip extends Component {
  static propTypes = {
    classes: PropTypes.object,
    datum: PropTypes.object,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    category: PropTypes.object,
  };

  render() {
    const { classes, datum, category, onDelete, onClick } = this.props;

    let _category = category;
    if (datum) {
      _category = datum;
    }

    if (_category === undefined) return <div></div>;

    return (
      <div className={classes.container}>
        <Chip
          label={_category.title}
          style={{
            background: _category.color,
            color: "white",
          }}
          onDelete={onDelete}
          onClick={onClick}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CategoryChip);
