import { Divider, List, ListItem, Popover, Tooltip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppsIcon from "@material-ui/icons/Apps";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import BrightnessHigh from "@material-ui/icons/BrightnessHigh";
import BrightnessLow from "@material-ui/icons/BrightnessLow";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import Public from "@material-ui/icons/Public";
import Eye from "@material-ui/icons/RemoveRedEye";
import App from "components/App/App";
// reactor
import Apps from "components/Apps";
import { REACT_APP_FRONT_BASE, REACT_APP_LOGO } from "config";
import { MY_ACCOUNT } from "constants/routes";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class Bar extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    cookies: PropTypes.object,
    onMenuOpen: PropTypes.func,
    user: PropTypes.object,
    userApps: PropTypes.array,
    history: PropTypes.object,
    app: PropTypes.object,
    setDarkMode: PropTypes.func,
    stopImpersonate: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
    getCurrentUser: PropTypes.func,
    signout: PropTypes.func,
    bazaarCustomer: PropTypes.any,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = {
    anchorEl: null,
    appsOpen: false,
  };

  getMenu() {
    const {
      signout,
      history,
      user,
      stopImpersonate,
      classes,
      userApps,
    } = this.props;

    const { anchorEl } = this.state;
    const accountOpen = Boolean(anchorEl);

    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableAutoFocusItem
        open={accountOpen}
        onClose={this.handleAppBarClose}
      >
        <Hidden smUp>
          {user && user.impersonate ? (
            <MenuItem
              onClick={async () => {
                await stopImpersonate();
                window.location.reload();
              }}
              style={{
                background: "#f44336",
                color: "white",
              }}
            >
              <ListItemIcon>
                <Eye style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.white,
                }}
                primary={`Stop Impersonating ${user.firstName} ${user.lastName}`}
              />
            </MenuItem>
          ) : (
            <div />
          )}
        </Hidden>
        {userApps.length > 1 ? (
          <Hidden smUp>
            <MenuItem onClick={() => this.setState({ appsOpen: true })}>
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Apps" />
            </MenuItem>
          </Hidden>
        ) : (
          []
        )}
        <Tooltip title={`${user.firstName} ${user.lastName}`}>
          <MenuItem onClick={() => history.push(MY_ACCOUNT)}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="My Account" />
          </MenuItem>
        </Tooltip>
        <MenuItem
          onClick={async () => {
            await signout();
            window.location.replace(`${REACT_APP_FRONT_BASE}/signin`);
          }}
        >
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </MenuItem>
      </Menu>
    );
  }

  handleAppBarMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAppBarClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      onMenuOpen,
      user,
      userApps,
      history,
      app,
      theme,
      stopImpersonate,
      setOrganizationByDefault,
      signout,
      cookies,
      setDarkMode,
    } = this.props;

    const { anchorEl, anchorOrganization } = this.state;
    const accountOpen = Boolean(anchorEl);
    const organizationOpen = Boolean(anchorOrganization);

    let currentOrganization = { name: "No Organization" };
    if (user.organizations && user.organizations.length) {
      currentOrganization = user.organizations.find((o) => o.default);
      if (currentOrganization === undefined) {
        currentOrganization = user.organizations[0];
      }
    }

    return (
      <AppBar position="static" className={classes.appBar}>
        <Grid
          container
          justify="space-between"
          className={classes.appGrid}
          alignItems="center"
        >
          <Grid item>
            {/* Top Left Menu : Logo and Menu Icon */}
            <Grid container alignItems="center">
              <Grid item>
                <Hidden mdUp implementation="css">
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={onMenuOpen}
                  >
                    <MenuIcon fontSize="small" />
                  </IconButton>
                </Hidden>
              </Grid>
              <Hidden smDown implementation="css">
                <Grid item style={{ display: "flex" }}>
                  <img
                    alt="logo"
                    src={REACT_APP_LOGO}
                    height="35"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  />
                  <App
                    fullName
                    color={theme.palette.primary.color[500]}
                    name={"Admin"}
                    size={30}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item>
            {/* Desktop Top Right Menu */}
            <Grid container alignItems="center">
              {userApps.length > 1 ? (
                <Grid item>
                  <Apps
                    userApps={userApps}
                    history={history}
                    app={app}
                    baseHostname={REACT_APP_FRONT_BASE}
                  />
                </Grid>
              ) : (
                []
              )}
              <Grid item>
                {user && user.impersonate ? (
                  <Chip
                    label={`Impersonating ${user.firstName} ${user.lastName}`}
                    avatar={
                      <Avatar
                        style={{
                          background: "#d32f2f",
                          color: "white",
                        }}
                      >
                        <Eye />
                      </Avatar>
                    }
                    style={{
                      background: "#f44336",
                      color: "white",
                    }}
                    onDelete={async () => {
                      await stopImpersonate();
                      window.location.reload();
                    }}
                  />
                ) : (
                  []
                )}
              </Grid>
              <Grid item>
                <Tooltip
                  title={`${user.firstName} ${user.lastName} (${currentOrganization.name})`}
                >
                  <IconButton
                    aria-owns={accountOpen ? "menu-appbar" : null}
                    aria-haspopup="true"
                    onClick={(e) =>
                      this.setState({ anchorMe: e.currentTarget })
                    }
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(this.state.anchorMe)}
                  anchorEl={this.state.anchorMe}
                  onClose={() => this.setState({ anchorMe: null })}
                >
                  <div style={{ width: 300, padding: 15, textAlign: "center" }}>
                    <Grid container justify="center" spacing={1}>
                      <Grid item xs={12}>
                        <Avatar
                          style={{ width: 75, height: 75, margin: "auto" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {user.firstName} {user.lastName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {user.email}
                        </Typography>
                        {user &&
                        user.organizations &&
                        user.organizations.length > 1 ? (
                          <>
                            <Chip
                              label={
                                user.organizations?.find(
                                  (o) => o.id === user.organizationID
                                )?.name ?? "None"
                              }
                              style={{
                                background: "rgba(155,155,155,0.3)",
                              }}
                              avatar={
                                <Avatar
                                  style={{
                                    background: "none",
                                  }}
                                >
                                  <Public />
                                </Avatar>
                              }
                              deleteIcon={<ArrowDropDown />}
                              onDelete={(e) =>
                                this.setState({
                                  anchorOrganization: e.currentTarget,
                                })
                              }
                              onClick={(e) =>
                                this.setState({
                                  anchorOrganization: e.currentTarget,
                                })
                              }
                            />
                            <Menu
                              id="simple-menu"
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              anchorEl={anchorOrganization}
                              open={organizationOpen}
                              onClose={() =>
                                this.setState({ anchorOrganization: null })
                              }
                            >
                              {user &&
                                user.organizations &&
                                user.organizations.map((o) => (
                                  <MenuItem
                                    dense
                                    onClick={async () => {
                                      await setOrganizationByDefault(o.id);
                                      window.location.reload();
                                    }}
                                    selected={o.id === currentOrganization.id}
                                  >
                                    {o.name}
                                  </MenuItem>
                                ))}
                            </Menu>
                          </>
                        ) : (
                          []
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                        <List dense style={{ padding: 0 }}>
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              setDarkMode(!app.darkMode);
                              cookies.set("darkMode", !app.darkMode, {
                                path: "/",
                              });
                            }}
                          >
                            <ListItemIcon>
                              {app.darkMode ? (
                                <BrightnessLow />
                              ) : (
                                <BrightnessHigh />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                app.darkMode ? "Light Mode" : "Dark Mode"
                              }
                            />
                          </ListItem>
                        </List>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          style={{
                            textTransform: "none",
                          }}
                          onClick={async () => {
                            await signout();
                            window.location.replace(
                              `${REACT_APP_FRONT_BASE}/signin`
                            );
                          }}
                        >
                          <PowerSettingsNew
                            style={{ marginRight: 8, fontSize: 18 }}
                          />{" "}
                          Sign Out
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

export default withCookies(withStyles(styles, { withTheme: true })(Bar));
