// material-ui
import { Fab } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormSubCategory from "components/FormCpfmSubCategory";
import Page from "components/Page";
import SubCategoriesList from "components/SubCategoriesList";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCategoryPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //subCategories
    subCategories: PropTypes.array,
    getSubCategoryById: PropTypes.func,
    createSubCategory: PropTypes.func,
    deleteSubCategory: PropTypes.func,
    updateSubCategory: PropTypes.func,
    getCategories: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCategory: undefined,
      formCategoryOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { subCategories, history, getCategories } = this.props;

    switch (index) {
      case 0:
        return (
          <SubCategoriesList
            getCategories={getCategories}
            history={history}
            subCategories={subCategories}
            onSelect={(selectedSubCategory) =>
              this.setState({
                selectedSubCategory,
                formCategoryOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createSubCategory,
      deleteSubCategory,
      updateSubCategory,
      refresh,
    } = this.props;

    const { index, selectedSubCategory, formCategoryOpen } = this.state;

    return (
      <Page
        loading={loading}
        helmet="Categories"
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formCategoryOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormSubCategory
          open={formCategoryOpen}
          subCategory={selectedSubCategory}
          close={() => {
            this.setState({
              formCategoryOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCategory: undefined,
                }),
              200
            );
          }}
          createSubCategory={createSubCategory}
          deleteSubCategory={deleteSubCategory}
          updateSubCategory={updateSubCategory}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCategoryPage);
