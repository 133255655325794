import * as Api from "api";
import {
  CREATE_CAREER_MEDIUM,
  DELETE_CAREER_MEDIUM,
  GET_CAREER_MEDIAS,
  GET_CAREER_MEDIUM_BY_ID,
  UPDATE_CAREER_MEDIUM,
} from "constants/careerMedia";
import { networkAction } from "helpers/network/networkAction";

export const getCareerMedia = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_MEDIAS, Api.getCareerMedia, [filters]);

export const getCareerMediumById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_MEDIUM_BY_ID, Api.getCareerMediumById, [
    id,
  ]);

export const deleteCareerMedium = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CAREER_MEDIUM, Api.deleteCareerMedium, [id]);

export const createCareerMedium = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CAREER_MEDIUM, Api.createCareerMedium, [body]);

export const updateCareerMedium = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CAREER_MEDIUM, Api.updateCareerMedium, [
    id,
    body,
  ]);
