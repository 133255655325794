// material-ui
import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import ProgramCount from "hooks/ProgramCount";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  programTypes: state.programTypes,
});

const mapDispatchToProps = (dispatch) => ({});

class DivisionsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    divisions: PropTypes.array,
    onSelect: PropTypes.func,
  };

  state = {
    value: 1,
  };

  onSelect(selectedDivision, index) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedDivision, index);
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { divisions, programTypes, classes } = this.props;
    const { value } = this.state;

    return (
      <>
        <div>
          <Tabs
            value={value}
            onChange={this.handleChange.bind(this)}
            aria-label="simple tabs example"
            tabItemContainerStyle={{ width: "100px" }}
            variant="scrollable"
            scrollButtons="auto"
            size="small"
            style={{ minHeight: "auto" }}
          >
            {programTypes?.map((pt) => (
              <Tab
                classes={{
                  root: classes.tabsRoot,
                  wrapper: classes.labelContainer,
                }}
                style={{
                  background: pt.color,
                }}
                label={pt.name}
              />
            ))}
          </Tabs>
        </div>
        <Table
          data={divisions.filter(
            (d) => d.programTypeID === programTypes[value]?.id
          )}
          meta={[
            {
              path: "name",
              title: "Name",
            },
            {
              path: "programTypeID",
              title: "programTypeID",
              transform: (t, d) =>
                programTypes.find((pt) => pt.id === d.programTypeID)?.name ??
                "undefined",
            },
            {
              path: "programCount",
              title: "Programs#",
              render: (t, d) => (
                <ProgramCount
                  value={t}
                  id={d.id}
                  title={`${d.name} grade related programs`}
                  type="divisions"
                />
              ),
            },
          ]}
          onRowSelect={this.onSelect.bind(this)}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DivisionsList));
