import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCoursePage from "../component/WrapperCoursePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  courses: state.courses,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  getCourses: () => dispatch(Actions.getCourses()),
  getCourseById: (...args) => dispatch(Actions.getCourseById(...args)),
  createCourse: (...args) => dispatch(Actions.createCourse(...args)),
  deleteCourse: (...args) => dispatch(Actions.deleteCourse(...args)),
  updateCourse: (...args) => dispatch(Actions.updateCourse(...args)),
});
class CoursePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,

    courses: PropTypes.array,
    getCourses: PropTypes.func,
    getCourseById: PropTypes.func,
    createCourse: PropTypes.func,
    deleteCourse: PropTypes.func,
    updateCourse: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, courses } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: courses.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getCourses } = this.props;
    await Promise.all([getCourses()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      courses,
      getCourseById,
      createCourse,
      deleteCourse,
      updateCourse,
      history,
      app,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCoursePage
        refresh={this.refresh.bind(this)}
        loading={loading}
        app={app}
        courses={courses}
        history={history}
        getCourseById={getCourseById}
        createCourse={createCourse}
        deleteCourse={deleteCourse}
        updateCourse={updateCourse}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CoursePage);
