import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const skillTags = `${REACT_APP_API_PREFIX}/${service}/skillTags`;

export function getSkillTags(filters) {
  const url = `${skillTags}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getSkillTagById(id) {
  const url = `${skillTags}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateSkillTag(id, body) {
  const url = `${skillTags}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSkillTag(id) {
  const url = `${skillTags}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSkillTag(body) {
  const url = `${skillTags}`;
  return {
    method: POST,
    url,
    body,
  };
}
