import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROGRAM_LEVELS,
  GET_PROGRAM_LEVEL_BY_ID,
  DELETE_PROGRAM_LEVEL,
  UPDATE_PROGRAM_LEVEL,
  CREATE_PROGRAM_LEVEL,
} from "constants/programLevels";

import * as Api from "api";

export const getProgramLevels = () => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAM_LEVELS, Api.getProgramLevels, []);

export const getProgramLevelById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAM_LEVEL_BY_ID, Api.getProgramLevelById, [
    id,
  ]);

export const deleteProgramLevel = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PROGRAM_LEVEL, Api.deleteProgramLevel, [id]);

export const createProgramLevel = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PROGRAM_LEVEL, Api.createProgramLevel, [body]);

export const updateProgramLevel = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROGRAM_LEVEL, Api.updateProgramLevel, [
    id,
    body,
  ]);
