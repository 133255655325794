import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormDivision extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    division: PropTypes.object,

    createDivision: PropTypes.func,
    updateDivision: PropTypes.func,
    deleteDivision: PropTypes.func,

    programTypes: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      programTypeID: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.division);
    }
  }

  handleChange = (v) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [v]: value,
    });
  };

  init(division) {
    this.setState({
      loading: false,
      name: division ? division.name : "",
      programTypeID: division ? division.programTypeID : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this division, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          division: "default",
        },
        confirm: {
          label: "I am sure",
          division: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteDivision, division, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteDivision(division.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Division has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateDivision, createDivision, close, division, refresh } =
      this.props;

    const { name, programTypeID } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (division) {
      resp = await updateDivision(division.id, {
        name,
        programTypeID,
      });
    } else {
      resp = await createDivision({
        name,
        programTypeID,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      refresh();
      close();
      NotificationCenter.sweetAlert({
        title: division
          ? "Division has been updated."
          : "Division has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const { classes, fullScreen, open, division, close, programTypes } =
      this.props;

    const { name, loading, programTypeID } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {division ? `${division.name}` : "Let's Create a New Division"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {division
                  ? "To update the division, fill the form below :"
                  : "To create a new division, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="group-simple">Program Type</InputLabel>
                    <Select
                      value={programTypeID}
                      onChange={this.handleChange("programTypeID")}
                    >
                      {programTypes.map((pt) => (
                        <MenuItem key={`pt_${pt.id}`} value={pt.id}>
                          {pt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {division && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {division ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormDivision));
