import * as Api from "api";
import {
  CREATE_CAREER_SKILL,
  DELETE_CAREER_SKILL,
  GET_CAREER_SKILLS,
  GET_CAREER_SKILL_BY_ID,
  UPDATE_CAREER_SKILL,
} from "constants/careerSkills";
import { networkAction } from "helpers/network/networkAction";

export const getCareerSkills = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_SKILLS, Api.getCareerSkills, [filters]);

export const getCareerSkillById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_SKILL_BY_ID, Api.getCareerSkillById, [id]);

export const deleteCareerSkill = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CAREER_SKILL, Api.deleteCareerSkill, [id]);

export const createCareerSkill = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CAREER_SKILL, Api.createCareerSkill, [body]);

export const updateCareerSkill = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CAREER_SKILL, Api.updateCareerSkill, [
    id,
    body,
  ]);
