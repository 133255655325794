import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { FormMasterTag } from "components";
// custom
import AgesList from "components/AgesList";
import DivisionsList from "components/DivisionsList";
import FormAge from "components/FormAge";
import FormDivision from "components/FormDivision";
import FormGrade from "components/FormGrade";
import FormInstrument from "components/FormInstrument";
import FormLevel from "components/FormLevel";
import FormMusicalFamily from "components/FormMusicalFamily";
import FormProgramType from "components/FormProgramType";
import GradesList from "components/GradesList";
import InstrumentsList from "components/InstrumentsList";
import LevelsList from "components/LevelsList";
import MasterTagsList from "components/MasterTagsList";
import MusicalFamiliesList from "components/MusicalFamiliesList";
// reactor
import Page from "components/Page";
import ProgramTypesList from "components/ProgramTypesList";
import SubCategoriesList from "components/SubCategoriesList";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperSettingsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    api: PropTypes.object,
    loading: PropTypes.bool,
    ages: PropTypes.array,
    divisions: PropTypes.array,
    institutions: PropTypes.array,
    programSubcategories: PropTypes.array,
    levels: PropTypes.array,
    instruments: PropTypes.array,
    programTypes: PropTypes.array,
    grades: PropTypes.array,
    musicalFamilies: PropTypes.array,
    masterTags: PropTypes.array,
    programSubcategories: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      formAgeOpen: false,
      formLevelOpen: false,
      formGradeOpen: false,
      formInstrumentOpen: false,
      formMusicalFamilyOpen: false,
      formProgramTypeOpen: false,
      formDivisionOpen: false,
      formMasterTagOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      ages,
      institutions,
      levels,
      grades,
      instruments,
      musicalFamilies,
      programTypes,
      divisions,
      masterTags,
      programSubcategories,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <AgesList
            ages={ages}
            onSelect={(a) =>
              this.setState({ selectedAge: a, formAgeOpen: true })
            }
          />
        );
      case 1:
        return (
          <LevelsList
            levels={levels}
            onSelect={(a) =>
              this.setState({ selectedLevel: a, formLevelOpen: true })
            }
          />
        );
      case 2:
        return (
          <GradesList
            grades={grades}
            onSelect={(a) =>
              this.setState({ selectedGrade: a, formGradeOpen: true })
            }
          />
        );
      case 3:
        return (
          <InstrumentsList
            instruments={instruments}
            onSelect={(a) =>
              this.setState({ selectedInstrument: a, formInstrumentOpen: true })
            }
          />
        );
      case 4:
        return (
          <MusicalFamiliesList
            musicalFamilies={musicalFamilies}
            onSelect={(a) =>
              this.setState({
                selectedMusicalFamily: a,
                formMusicalFamilyOpen: true,
              })
            }
          />
        );
      case 5:
        return (
          <ProgramTypesList
            programTypes={programTypes}
            onSelect={(a) =>
              this.setState({
                selectedProgramType: a,
                formProgramTypeOpen: true,
              })
            }
          />
        );
      case 6:
        return (
          <DivisionsList
            divisions={divisions}
            onSelect={(a) =>
              this.setState({ selectedDivision: a, formDivisionOpen: true })
            }
          />
        );
      // case 7:
      //   return (
      //     <InstitutionsList
      //       institutions={institutions}
      //       onSelect={(a) =>
      //         this.setState({
      //           selectedInstitution: a,
      //           formInstitutionOpen: true,
      //         })
      //       }
      //     />
      //   );
      case 7:
        return (
          <MasterTagsList
            masterTags={masterTags}
            onSelect={(a) =>
              this.setState({
                selectedMasterTag: a,
                formMasterTagOpen: true,
              })
            }
          />
        );
      case 8:
        return (
          <SubCategoriesList
            subCategories={programSubcategories}
            onSelect={
              (a) => console.log(a)
              // this.setState({
              //   selectedMasterTag: a,
              //   formMasterTagOpen: true,
              // })
            }
          />
        );
      default:
        return (
          <Typography display="block" variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  getCreateFunc() {
    const { index } = this.state;

    switch (index) {
      case 0:
        return () => this.setState({ formAgeOpen: true });
      case 1:
        return () => this.setState({ formLevelOpen: true });
      case 2:
        return () => this.setState({ formGradeOpen: true });
      case 3:
        return () => this.setState({ formInstrumentOpen: true });
      case 4:
        return () => this.setState({ formMusicalFamilyOpen: true });
      case 5:
        return () => this.setState({ formProgramTypeOpen: true });
      case 6:
        return () => this.setState({ formDivisionOpen: true });
      case 7:
        return () => this.setState({ formMasterTagOpen: true });
      default:
        return () => console.log("Unknowned Action");
    }
  }

  render() {
    const { classes, history, loading, api, musicalFamilies, programTypes } =
      this.props;

    const { index } = this.state;

    console.log(this.state.selectedLevel);

    return (
      <Page
        helmet="Settings"
        loadingMessage={"Loading Settings"}
        loading={loading}
        fab={
          index < 8 && (
            <Fab
              color="primary"
              aria-label="Add"
              onClick={this.getCreateFunc()}
            >
              <AddIcon />
            </Fab>
          )
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            <Tab
              label="Ages"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Levels"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Grades"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Instruments"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Musical Families"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Program Types"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Divisions"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Master Tags"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Subcategories"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            {/* <Tab
              label="Institutions"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            /> */}
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormAge
          open={this.state.formAgeOpen}
          close={() =>
            this.setState({ formAgeOpen: false, selectedAge: undefined })
          }
          age={this.state.selectedAge}
          createAge={api.createAge}
          updateAge={api.updateAge}
          deleteAge={api.deleteAge}
          refresh={api.getAges}
        />
        {/* <FormInstitution
          open={this.state.formInstitutionOpen}
          close={() =>
            this.setState({
              formInstitutionOpen: false,
              selectedInstitution: undefined,
            })
          }
          institution={this.state.selectedInstitution}
          createInstitution={api.createInstitution}
          updateInstitution={api.updateInstitution}
          deleteInstitution={api.deleteInstitution}
          refresh={api.getInstitutions}
        /> */}
        <FormLevel
          open={this.state.formLevelOpen}
          close={() =>
            this.setState({ formLevelOpen: false, selectedLevel: undefined })
          }
          level={this.state.selectedLevel}
          createLevel={api.createLevel}
          updateLevel={api.updateLevel}
          deleteLevel={api.deleteLevel}
          refresh={api.getLevels}
        />
        <FormGrade
          open={this.state.formGradeOpen}
          close={() =>
            this.setState({ formGradeOpen: false, selectedGrade: undefined })
          }
          grade={this.state.selectedGrade}
          createGrade={api.createGrade}
          updateGrade={api.updateGrade}
          deleteGrade={api.deleteGrade}
          refresh={api.getGrades}
        />
        <FormInstrument
          open={this.state.formInstrumentOpen}
          close={() =>
            this.setState({
              formInstrumentOpen: false,
              selectedInstrument: undefined,
            })
          }
          instrument={this.state.selectedInstrument}
          createInstrument={api.createInstrument}
          updateInstrument={api.updateInstrument}
          deleteInstrument={api.deleteInstrument}
          musicalFamilies={musicalFamilies}
          refresh={api.getInstruments}
        />
        <FormMusicalFamily
          open={this.state.formMusicalFamilyOpen}
          close={() =>
            this.setState({
              formMusicalFamilyOpen: false,
              selectedMusicalFamily: undefined,
            })
          }
          musicalFamily={this.state.selectedMusicalFamily}
          createMusicalFamily={api.createMusicalFamily}
          updateMusicalFamily={api.updateMusicalFamily}
          deleteMusicalFamily={api.deleteMusicalFamily}
          refresh={api.getMusicalFamilies}
        />
        <FormProgramType
          open={this.state.formProgramTypeOpen}
          close={() =>
            this.setState({
              formProgramTypeOpen: false,
              selectedProgramType: undefined,
            })
          }
          programType={this.state.selectedProgramType}
          createProgramType={api.createProgramType}
          updateProgramType={api.updateProgramType}
          deleteProgramType={api.deleteProgramType}
          createProgramSubcategory={api.createProgramSubcategory}
          updateProgramSubcategory={api.updateProgramSubcategory}
          deleteProgramSubcategory={api.deleteProgramSubcategory}
          refresh={() => {
            api.getProgramTypes();
            api.getProgramSubcategories();
          }}
        />
        <FormDivision
          open={this.state.formDivisionOpen}
          close={() =>
            this.setState({
              formDivisionOpen: false,
              selectedDivision: undefined,
            })
          }
          division={this.state.selectedDivision}
          createDivision={api.createDivision}
          updateDivision={api.updateDivision}
          deleteDivision={api.deleteDivision}
          refresh={api.getDivisions}
          programTypes={programTypes}
        />
        <FormMasterTag
          open={this.state.formMasterTagOpen}
          close={() =>
            this.setState({
              formMasterTagOpen: false,
              selectedMasterTag: undefined,
            })
          }
          masterTag={this.state.selectedMasterTag}
          createMasterTag={api.createMasterTag}
          updateMasterTag={api.updateMasterTag}
          deleteMasterTag={api.deleteMasterTag}
          refresh={api.getMasterTags}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSettingsPage);
