import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperMapPage from "../component/WrapperMapPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  // signin: (...args) => dispatch(Actions.signin(...args)),
});
class MapPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: false,
    };
  }

  // componentDidMount() {
  //   this.refresh();
  // }

  // async refresh() {
  //   const { itemID } = this.state;
  //   const { getItemById } = this.props;

  //   const resp = await getItemById(itemID);
  //   this.setState({ item: resp.payload, loading: false, });
  // }

  render() {
    const { location, history } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperMapPage
        history={history}
        location={location}
        urlParams={urlParams}
        loading={loading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
