import * as Api from "api";
import {
  CREATE_CAREER_QUIZ_TAG,
  DELETE_CAREER_QUIZ_TAG,
  GET_CAREER_QUIZ_TAGS,
  GET_CAREER_QUIZ_TAG_BY_ID,
  UPDATE_CAREER_QUIZ_TAG,
} from "constants/careerQuizTags";
import { networkAction } from "helpers/network/networkAction";

export const getCareerQuizTags = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_QUIZ_TAGS, Api.getCareerQuizTags, [
    filters,
  ]);

export const getCareerQuizTagById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_QUIZ_TAG_BY_ID, Api.getCareerQuizTagById, [
    id,
  ]);

export const deleteCareerQuizTag = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CAREER_QUIZ_TAG, Api.deleteCareerQuizTag, [
    id,
  ]);

export const createCareerQuizTag = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CAREER_QUIZ_TAG, Api.createCareerQuizTag, [
    body,
  ]);

export const updateCareerQuizTag = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CAREER_QUIZ_TAG, Api.updateCareerQuizTag, [
    id,
    body,
  ]);
