import {
  GET_DEGREE_LEVELS,
} from 'constants/degreeLevels';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_DEGREE_LEVELS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
