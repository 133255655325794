import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCpfmPage from "../component/WrapperCpfmPage";
import Loading from "components/Loading";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  careers: state.careers,
  categories: state.cpfmCategories,
  // categories: state.tmpCategories,
});

const mapDispatchToProps = (dispatch) => ({
  getFullApp: () => dispatch(Actions.getFullApp()),
  getCpfmCategories: () => dispatch(Actions.getCpfmCategories()),
});
class CpfmPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    careers: PropTypes.array,
    categories: PropTypes.array,
    getFullApp: PropTypes.func,
    getCpfmCategories: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, categories, careers } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: careers.length === 0 && categories.length === 0,
    };
    if (careers.length === 0 && categories.length === 0) {
      this.refresh();
    }
  }

  async refresh() {
    const { getFullApp, getCpfmCategories } = this.props;
    await Promise.all([getFullApp(), getCpfmCategories()]);
    this.setState({ loading: false });
  }

  render() {
    const { urlParams, loading } = this.state;

    const { categories, history, location, careers } = this.props;

    return loading ? (
      <div style={{ flex: 1, background: "#455a64" }} />
    ) : (
      <WrapperCpfmPage
        careers={careers}
        categories={categories}
        location={location}
        history={history}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CpfmPage);
