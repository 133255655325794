import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const careerDegreeLevels = `${REACT_APP_API_PREFIX}/${service}/careerDegreeLevels`;

export function getCareerDegreeLevels(filters) {
  const url = `${careerDegreeLevels}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCareerDegreeLevelById(id) {
  const url = `${careerDegreeLevels}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateCareerDegreeLevel(id, body) {
  const url = `${careerDegreeLevels}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCareerDegreeLevel(id) {
  const url = `${careerDegreeLevels}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCareerDegreeLevel(body) {
  const url = `${careerDegreeLevels}`;
  return {
    method: POST,
    url,
    body,
  };
}
