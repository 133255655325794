import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

// constants
import { CATEGORY } from "constants/routes";

// material-ui
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Back from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

// Custom
import MiniLayout from "components/MiniLayout";
import FullScreenVideoPlayer from "components/FullScreenVideoPlayer";
import Bubble from "components/Bubble";

// styles
import styles from "./styles";

// styles
import CatBackground from "./catBackground.png";

// routes
import { CPFM } from "constants/routes";
let items = 50;
let maxPitch = 4;
let spacing = 16;
let smallSpacing = 4;
let bubbleSize = 110;
let smallBubbleSize = 9;

class WrapperCategoryPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    categories: PropTypes.array,
    relatedCareers: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { careers } = this.props;
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.state = {
      index: undefined,
      init: false,
      videoPlayerOpen: false,
      careerCount: careers.length,
    };
    this.updateCol();
  }

  updateCol() {
    const { careers } = this.props;

    const careerCount = careers.length;
    let pitch = maxPitch;
    let nextOp = -1;
    let i = 0;
    let col = 0;
    let stacked = 0;
    while (i < careerCount) {
      stacked += 1;
      if (stacked === pitch) {
        col += 1;
        stacked = 0;
        pitch += nextOp;
        if (pitch === 1) {
          nextOp = 1;
        }

        if (pitch === maxPitch) {
          nextOp = -1;
        }
      }

      if (i === careerCount - 1 && stacked) {
        col += 1;
      }

      i++;
    }

    this.setState({ col });
  }

  getCareerOrder(careerCount) {
    const { categories } = this.props;

    let indexes = {};
    categories.forEach((c) => {
      indexes[c.title] = c.careers.length;
    });
    let ret = [];
    while (ret.length < careerCount) {
      categories.forEach((c) => {
        if (indexes[c.title] > 0) {
          const career = c.careers[c.careers.length - indexes[c.title]];
          career.category = c;
          indexes[c.title] -= 1;
          ret.push(career);
        }
      });
    }

    return ret;
  }

  componentWillMount() {
    const colors = [];

    for (var i = 0; i < items; i++) {
      colors.push(this.getRandomColor());
    }

    this.setState({
      colors,
    });
  }

  componentDidMount() {
    const { init } = this.state;
    ReactDOM.findDOMNode(this.refs.scrollContainer).addEventListener(
      "scroll",
      this.scrollEvent.bind(this)
    );

    if (!init) {
      this.updateDimensions();
    }
  }

  getRandomColor() {
    const possibleColors = [
      "#f44336",
      "#2196f3",
      "#3f51b5",
      "#009688",
      "#ff9800",
      "#cddc39",
      "#4caf50",
      "#e91e63",
      "#673ab7",
      "#ff5722",
    ];

    return possibleColors[Math.floor(Math.random() * possibleColors.length)];
  }

  updateDimensions() {
    const { col } = this.state;
    const layoutWidth = col * (bubbleSize + spacing) - spacing;
    const scrollContainer = ReactDOM.findDOMNode(this.refs.scrollContainer);

    if (scrollContainer) {
      const scrollLeft = scrollContainer.scrollLeft;
      const windowWidth = scrollContainer.clientWidth;
      const windowHeight = scrollContainer.clientHeight;

      if (windowHeight > 600) {
        maxPitch = 4;
        this.updateCol();
      } else {
        maxPitch = 3;
        this.updateCol();
      }

      bubbleSize = 100 + windowWidth / 65;

      const scrollRatio = ((scrollLeft + windowWidth) / layoutWidth) * 100;

      this.setState({
        scrollLeft,
        windowWidth,
        layoutWidth,
        scrollRatio: scrollRatio,
        init: true,
      });
    }
  }

  scrollEvent(e, v, t) {
    const { col } = this.state;

    const scrollLeft = ReactDOM.findDOMNode(this.refs.scrollContainer)
      .scrollLeft;
    const layoutWidth = col * (bubbleSize + spacing) - spacing;
    const windowWidth = ReactDOM.findDOMNode(this.refs.scrollContainer)
      .clientWidth;

    const scrollRatio = ((scrollLeft + windowWidth) / layoutWidth) * 100;
    this.setState({
      scrollLeft,
      windowWidth,
      layoutWidth,
      scrollRatio: scrollRatio,
    });
  }

  render() {
    const {
      classes,
      history,
      categories,
      careers,
      category,
      refresh,
      relatedCareers,
    } = this.props;

    const {
      col,
      scrollRatio,
      scrollLeft,
      windowWidth,
      layoutWidth,
      index,
      selected,
      videoPlayerOpen,
    } = this.state;

    const width = "md";

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "#455a64",
        }}
      >
        <div
          ref="scrollContainer"
          onScroll={this.scrollEvent.bind(this)}
          className={classes.container}
        >
          <div
            style={{
              overflowY: "scroll",
              height:
                width !== "xs" && width !== "sm"
                  ? "calc(calc(var(--vh, 1vh) * 100) - 190px)"
                  : "calc(calc(var(--vh, 1vh) * 100) - 97px)",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                style={{
                  background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%), url(${CatBackground}) no-repeat`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  padding: 20,
                  width: "100%",
                  borderBottom: `solid 10px ${category.color}`,
                }}
              >
                <Button
                  gutterBottom
                  size="small"
                  variant="outlined"
                  className={classes.whiteButton}
                  onClick={() => history.push(CPFM)}
                >
                  <Back /> Back to Wall
                </Button>
                <Typography
                  display="block"
                  variant="display2"
                  gutterBottom
                  className={classes.whiteTitle}
                >
                  {category.title}
                </Typography>
                <Typography
                  display="block"
                  variant="body1"
                  className={classes.whiteText}
                  gutterBottom
                >
                  {category.description}
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={2}
                style={{ width: "100%", marginTop: 16 }}
              >
                {relatedCareers.map((c) => (
                  <Grid item>
                    <Bubble
                      career={{
                        ...c,
                        categories: [category],
                      }}
                      history={history}
                      size={150}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            position: "fixed",
            bottom: 0,
          }}
        >
          {width !== "xs" && width !== "sm" ? (
            <div
              style={{
                margin: "auto",
                marginBottom: 10,
              }}
            >
              <MiniLayout
                selectedCategory={category}
                interactionDisabled
                careers={careers}
                smallBubbleSize={smallBubbleSize}
                width={col * (smallBubbleSize + smallSpacing)}
                scrollRatio={scrollRatio}
                items={items}
                maxPitch={maxPitch}
                col={col}
                spacing={smallSpacing}
                scrollLeft={scrollLeft}
                windowWidth={windowWidth}
                layoutWidth={layoutWidth}
                selected={selected}
                onClick={() => history.push(CPFM)}
                onScrollRatioChange={(sr) => {
                  this.setState({
                    scrollRatio: sr,
                  });
                  ReactDOM.findDOMNode(this.refs.scrollContainer).scrollLeft =
                    (layoutWidth * sr) / 100 - windowWidth + 8;
                }}
              />
            </div>
          ) : (
            []
          )}
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                  selected: categories[v],
                });
                history.push(`?index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              {categories.map((c) => (
                <Tab
                  onClick={() => {
                    history.push(`${CATEGORY}?categoryID=${c.id}`);
                    setTimeout(refresh, 100);
                  }}
                  key={c.title}
                  label={<span style={{ color: c.color }}>{c.title}</span>}
                />
              ))}
            </Tabs>
          </AppBar>
        </div>
        <FullScreenVideoPlayer
          open={videoPlayerOpen}
          close={() => this.setState({ videoPlayerOpen: false })}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperCategoryPage);
