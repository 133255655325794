// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/TablePagination";
import ProgramCount from "hooks/ProgramCount";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class SubCategoriesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    subCategories: PropTypes.array,
    onSelect: PropTypes.func,
    getSubCategories: PropTypes.func,
    history: PropTypes.func,
  };

  onSelect(selectedCategory) {
    const { onSelect } = this.props;
    this.setState({ selectedCategory });
    if (onSelect) {
      onSelect(selectedCategory);
    }
  }

  render() {
    const { subCategories, getSubCategories, history } = this.props;

    return (
      // <AdvancedSearch
      //   history={history}
      //   location={window.location}
      //   refresh={getSubCategories}
      //   keyword="subCategories"
      //   metaDataEndpoint="/cpfm/subCategories/searchMetadata"
      // >
      <Table
        data={subCategories}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "programCount",
            title: "Programs#",
            render: (t, d) => (
              <ProgramCount
                value={t}
                id={d.id}
                title={`${d.name} related programs`}
                type="programSubcategories"
              />
            ),
          },
        ]}
        title={"SubCategories"}
        onRowSelect={this.onSelect.bind(this)}
      />
      // </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(SubCategoriesList);
