import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// styles
import styles from "./styles";

class Timezone extends Component {
  static propTypes = {
    classes: PropTypes.object,
    tzName: PropTypes.string,
    timezones: PropTypes.array,
  };

  render() {
    const { tzName, timezones } = this.props;

    const find = timezones && timezones.find((t) => tzName === t.name);
    return <span>{find ? find.display : tzName}</span>;
  }
}

export default withStyles(styles)(Timezone);
