export const GET_PROGRAM_RELATE_SUBCATEGORIES =
  "GET_PROGRAM_RELATE_SUBCATEGORIES";
export const GET_PROGRAM_RELATE_SUBCATEGORY_BY_ID =
  "GET_PROGRAM_RELATE_SUBCATEGORY_BY_ID";
export const UPDATE_PROGRAM_RELATE_SUBCATEGORY =
  "UPDATE_PROGRAM_RELATE_SUBCATEGORY";
export const DELETE_PROGRAM_RELATE_SUBCATEGORY =
  "DELETE_PROGRAM_RELATE_SUBCATEGORY";
export const CREATE_PROGRAM_RELATE_SUBCATEGORY =
  "CREATE_PROGRAM_RELATE_SUBCATEGORY";
export const GET_PROGRAMS_BY_PROGRAM_SUBCATEGORY =
  "GET_PROGRAMS_BY_PROGRAM_SUBCATEGORY";
