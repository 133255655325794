import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// styles
import styles from "./styles";

class Day extends Component {
  static propTypes = {
    classes: PropTypes.object,
    dayCursor: PropTypes.object,
    getDailyAvailabilities: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { getDailyAvailabilities, dayCursor } = this.props;
    this.state = {
      loading: true,
      dailyAvailabilities: [],
    };
    getDailyAvailabilities(dayCursor.format("YYYY-MM-DD")).then((resp) =>
      this.setState({
        dailyAvailabilities: resp.payload,
        loading: false,
      })
    );
  }

  render() {
    const { classes, dayCursor } = this.props;
    const { loading, dailyAvailabilities } = this.state;

    return (
      <div className={classes.flex}>
        <div className={classes.box}>
          <Typography display="block" variant="body2">
            {dayCursor.format("DD")}
          </Typography>
          {loading ? (
            <CircularProgress size={15} style={{ color: "#9e9e9e" }} />
          ) : (
            <div>
              {dailyAvailabilities &&
                dailyAvailabilities.map &&
                dailyAvailabilities.map((ta) => (
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                    className={classes.hours}
                  >
                    {ta}
                  </Typography>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Day);
