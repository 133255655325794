import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROGRAM_INSTRUMENTS,
  GET_PROGRAM_INSTRUMENT_BY_ID,
  DELETE_PROGRAM_INSTRUMENT,
  UPDATE_PROGRAM_INSTRUMENT,
  CREATE_PROGRAM_INSTRUMENT,
} from "constants/programInstruments";

import * as Api from "api";

export const getProgramInstruments = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAM_INSTRUMENTS,
    Api.getProgramInstruments,
    []
  );

export const getProgramInstrumentById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAM_INSTRUMENT_BY_ID,
    Api.getProgramInstrumentById,
    [id]
  );

export const deleteProgramInstrument = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_PROGRAM_INSTRUMENT,
    Api.deleteProgramInstrument,
    [id]
  );

export const createProgramInstrument = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_PROGRAM_INSTRUMENT,
    Api.createProgramInstrument,
    [body]
  );

export const updateProgramInstrument = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_PROGRAM_INSTRUMENT,
    Api.updateProgramInstrument,
    [id, body]
  );
