import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Portal from "@material-ui/core/Portal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Search from "@material-ui/icons/Search";
import Avatar from "@material-ui/core/Avatar";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import Map from "@material-ui/icons/Map";

// reactor
import Page from "components/Page";

// constants
import { REACT_APP_FRONT } from "config";
import { PLAN } from "constants/routes";

// fake
import Categories from "./categories.json";
import Ages from "./ages.json";
import Grades from "./grades.json";
import Levels from "./levels.json";
import Instruments from "./instruments.json";

// styles
import styles from "./styles";

class WrapperMapPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { MessageCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      name: "",
      location: "",
      website: "",
      categoryID: "",
      pendingOption: "",
      dateFrom: "",
      dateTo: "",
      divisions: [],
      instruments: [],
      ageType: 0,
      ageFrom: "",
      ageTo: "",
      gradeFrom: "",
      gradeTo: "",
      ageOp: "Down",
      age: "",
      level: "",
      grades: [],
      options: [],
    };
    MessageCenter.open("right");
  }

  getStepContent() {
    const { index } = this.state;

    return (
      <Typography display="block" variant="title">
        Programs Table (Coming Soon)
      </Typography>
    );
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  addDivision(division) {
    const { divisions } = this.state;
    divisions.push(division);
    this.setState({
      divisions,
    });
  }

  deleteDivision(index) {
    const { divisions } = this.state;
    divisions.splice(index, 1);
    this.setState({
      divisions,
    });
  }

  addInstrument(division) {
    const { instruments } = this.state;
    instruments.push(division);
    this.setState({
      instruments,
    });
  }

  deleteInstrument(index) {
    const { instruments } = this.state;
    instruments.splice(index, 1);
    this.setState({
      instruments,
    });
  }

  addGrade(division) {
    const { grades } = this.state;
    grades.push(division);
    this.setState({
      grades,
    });
  }

  deleteGrade(index) {
    const { grades } = this.state;
    grades.splice(index, 1);
    this.setState({
      grades,
    });
  }

  deleteOption(index) {
    const { options } = this.state;
    options.splice(index, 1);
    this.setState({
      options,
    });
  }

  render() {
    const { classes, history, loading } = this.props;

    const {
      divisionEl,
      instrumentEl,
      gradeEl,
      divisions,
      instruments,
      grades,
    } = this.state;

    const { MessageCenter } = this.context;

    console.log(instruments);

    const {
      index,
      name,
      location,
      website,
      categoryID,
      dateFrom,
      dateTo,
      ageType,
      ageFrom,
      ageTo,
      gradeTo,
      gradeFrom,
      level,
      options,
      pendingOption,
      ageOp,
      grade,
      age,
    } = this.state;

    return (
      <Page
        helmet="Map"
        loadingMessage={"Loading Map"}
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Map"
            onClick={() => window.open(`${REACT_APP_FRONT}${PLAN}`, "_blank")}
          >
            <Map />
          </Fab>
        }
        header={
          <Grid
            container
            justify="space-between"
            style={{ padding: "16px 16px 0px 16px" }}
          >
            <Grid item>
              <Chip
                label="All Locations"
                avatar={
                  <Avatar>
                    <ArrowLeft />
                  </Avatar>
                }
              />
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton>
                    <Edit />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography display="block" variant="h6">
                    California State U-San Bernardino
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    5500 University Pkwy, San Bernardino, CA 92407, USA
                  </Typography>
                  <Typography display="block" variant="caption" color="primary">
                    https://www.csusb.edu/
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Chip
                label="Location"
                avatar={
                  <Avatar>
                    <Search />
                  </Avatar>
                }
              />
            </Grid>
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            {Categories.map((c) => (
              <Tab
                key={c.id}
                label={c.name}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            ))}
          </Tabs>
        }
      >
        {this.getStepContent()}
        <Portal container={MessageCenter.getPortalContainer("right")}>
          <div className={classes.drawer}>
            <Grid container spacing={2} justify="space-between">
              <Grid item>
                <Typography
                  display="block"
                  variant="h5"
                  style={{ fontWeight: 900 }}
                >
                  New Program
                </Typography>
              </Grid>
              <Grid item>
                <Chip label="Show on Map" />
              </Grid>
            </Grid>
            <Grid container className={classes.section}>
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color="primary"
                  variant="subheading"
                  style={{ fontWeight: 800 }}
                >
                  Categories & Divisions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="group-simple">Category</InputLabel>
                  <Select
                    inputProps={{
                      name: "Add Groups to User",
                      id: "group-simple",
                    }}
                    value={categoryID}
                    onChange={(e) => {
                      this.handleChange("categoryID")(e);
                      this.setState({ divisions: [] });
                    }}
                  >
                    {Categories.map((c) => (
                      <MenuItem key={`c_${c.id}`} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {categoryID &&
              Categories.find((c) => c.id === categoryID).divisions.filter(
                (d) => divisions.findIndex((div) => div === d) === -1
              ).length ? (
                <Grid item xs={12}>
                  <Chip
                    label="+ Division"
                    aria-owns={divisionEl ? "simple-menu" : undefined}
                    onClick={(e) =>
                      this.setState({ divisionEl: e.currentTarget })
                    }
                    style={{ marginBottom: 8 }}
                    variant="outlined"
                  />
                  <Menu
                    id="simple-menu"
                    anchorEl={divisionEl}
                    open={Boolean(divisionEl)}
                    onClose={() => this.setState({ divisionEl: null })}
                  >
                    {Categories.find((c) => c.id === categoryID)
                      .divisions.filter(
                        (d) => divisions.findIndex((div) => div === d) === -1
                      )
                      .map((d) => (
                        <MenuItem
                          onClick={() => this.addDivision(d)}
                          key={`divToAdd_${d}`}
                        >
                          {d}
                        </MenuItem>
                      ))}
                  </Menu>
                  <Grid container spacing={1}>
                    {divisions &&
                      divisions.map((div, i) => (
                        <Grid item>
                          <Chip
                            label={div}
                            onDelete={() => this.deleteDivision(Number(i))}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              ) : (
                []
              )}
            </Grid>
            <Grid container className={classes.section}>
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color="primary"
                  variant="subheading"
                  style={{ fontWeight: 800 }}
                >
                  About the Program
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  fullWidth
                  value={name}
                  onChange={this.handleChange("name")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Website"
                  fullWidth
                  value={website}
                  onChange={this.handleChange("website")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Date From"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="date"
                  value={dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : ""}
                  onChange={this.handleChange("dateFrom")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="date To"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="date"
                  value={dateTo ? moment(dateTo).format("YYYY-MM-DD") : ""}
                  onChange={this.handleChange("dateTo")}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.section}>
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color="primary"
                  variant="subheading"
                  style={{ fontWeight: 800 }}
                >
                  Instruments
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Chip
                  label="+ Instrument"
                  aria-owns={instrumentEl ? "simple-menu" : undefined}
                  onClick={(e) =>
                    this.setState({ instrumentEl: e.currentTarget })
                  }
                  style={{ marginBottom: 8 }}
                  variant="outlined"
                />
                <Menu
                  id="simple-menu"
                  anchorEl={instrumentEl}
                  open={Boolean(instrumentEl)}
                  onClose={() => this.setState({ instrumentEl: null })}
                >
                  {Instruments.filter(
                    (i) =>
                      instruments.findIndex((instru) => instru === i) === -1
                  ).map((i) => (
                    <MenuItem
                      onClick={() => this.addInstrument(i)}
                      key={`instruToAdd_${i}`}
                    >
                      {i}
                    </MenuItem>
                  ))}
                </Menu>
                <Grid container spacing={1}>
                  {instruments &&
                    instruments.map((instru, ind) => (
                      <Grid item>
                        <Chip
                          label={instru}
                          onDelete={() => this.deleteInstrument(Number(ind))}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.section}>
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color="primary"
                  variant="subheading"
                  style={{ fontWeight: 800 }}
                >
                  Ages
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Chip
                  label={"Range"}
                  color={ageType === 0 ? "Primary" : undefined}
                  onClick={() => this.setState({ ageType: 0 })}
                />
                <Chip
                  label={"Up/Down"}
                  color={ageType === 1 ? "Primary" : undefined}
                  onClick={() => this.setState({ ageType: 1 })}
                />
              </Grid>
              <Grid item xs={12}>
                {ageType === 0 ? (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="group-simple">From Age</InputLabel>
                        <Select
                          inputProps={{
                            name: "From",
                            id: "group-simple",
                          }}
                          value={ageFrom}
                          onChange={this.handleChange("ageFrom")}
                        >
                          {Ages.map((c) => (
                            <MenuItem key={`c_${c.id}`} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="group-simple">To Age</InputLabel>
                        <Select
                          inputProps={{
                            name: "To",
                            id: "group-simple",
                          }}
                          value={ageTo}
                          onChange={this.handleChange("ageTo")}
                        >
                          {Ages.map((c) => (
                            <MenuItem key={`c_${c.id}`} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="group-simple">
                          From Grade
                        </InputLabel>
                        <Select
                          inputProps={{
                            name: "From",
                            id: "group-simple",
                          }}
                          value={gradeFrom}
                          onChange={this.handleChange("gradeFrom")}
                        >
                          {Grades.map((c) => (
                            <MenuItem key={`g_${c.id}`} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="group-simple">To Grade</InputLabel>
                        <Select
                          inputProps={{
                            name: "To",
                            id: "group-simple",
                          }}
                          value={gradeTo}
                          onChange={this.handleChange("gradeTo")}
                        >
                          {Grades.map((c) => (
                            <MenuItem key={`g_${c.id}`} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
                {ageType === 1 ? (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="group-simple">Op</InputLabel>
                        <Select
                          inputProps={{
                            name: "From",
                            id: "group-simple",
                          }}
                          value={ageOp}
                          onChange={this.handleChange("ageOp")}
                        >
                          <MenuItem value="Down">Down to</MenuItem>
                          <MenuItem value="Up">Up to</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="group-simple">Age</InputLabel>
                        <Select
                          inputProps={{
                            name: "To",
                            id: "group-simple",
                          }}
                          value={age}
                          onChange={this.handleChange("age")}
                        >
                          {Ages.map((c) => (
                            <MenuItem key={`c_${c.id}`} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="group-simple">Op</InputLabel>
                        <Select
                          inputProps={{
                            name: "From",
                            id: "group-simple",
                          }}
                          value={ageOp}
                          onChange={this.handleChange("ageOp")}
                        >
                          <MenuItem value="Down">Down to</MenuItem>
                          <MenuItem value="Up">Up to</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="group-simple">Grade</InputLabel>
                        <Select
                          inputProps={{
                            name: "To",
                            id: "group-simple",
                          }}
                          value={grade}
                          onChange={this.handleChange("grade")}
                        >
                          {Grades.map((c) => (
                            <MenuItem key={`g_${c.id}`} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="group-simple">Level</InputLabel>
                  <Select
                    inputProps={{
                      name: "level",
                      id: "group-simple",
                    }}
                    value={level}
                    onChange={this.handleChange("level")}
                  >
                    {Levels.map((c) => (
                      <MenuItem key={`level_${c}`} value={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.section}>
              <Grid item xs={12}>
                <Typography
                  display="block"
                  color="primary"
                  variant="subheading"
                  style={{ fontWeight: 800 }}
                >
                  Options
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Press Enter to add the option.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Options"
                  fullWidth
                  value={pendingOption}
                  multiline
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      options.push(pendingOption);
                      this.setState({
                        options,
                        pendingOption: "",
                      });
                    }
                  }}
                  onChange={this.handleChange("pendingOption")}
                />
                <Grid container spacing={1}>
                  {options &&
                    options.map((op, ind) => (
                      <Grid item>
                        <Chip
                          label={op}
                          onDelete={() => this.deleteOption(Number(ind))}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Portal>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMapPage);
