import { networkAction } from 'helpers/network/networkAction';

import {
  GET_DEGREE_REQUIREMENTS,
  GET_DEGREE_REQUIREMENT_BY_ID,
  DELETE_DEGREE_REQUIREMENT,
  UPDATE_DEGREE_REQUIREMENT,
  CREATE_DEGREE_REQUIREMENT,
} from 'constants/degreeRequirements';

import * as Api from 'api';

export const getDegreeRequirements = () => async dispatch => networkAction(
  dispatch,
  GET_DEGREE_REQUIREMENTS,
  Api.getDegreeRequirements,
  []
);

export const getDegreeRequirementById = (id) => async dispatch => networkAction(
  dispatch,
  GET_DEGREE_REQUIREMENT_BY_ID,
  Api.getDegreeRequirementById,
  [id]
);

export const deleteDegreeRequirement = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_DEGREE_REQUIREMENT,
  Api.deleteDegreeRequirement,
  [id]
);

export const createDegreeRequirement = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_DEGREE_REQUIREMENT,
  Api.createDegreeRequirement,
  [body]
);

export const updateDegreeRequirement = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_DEGREE_REQUIREMENT,
  Api.updateDegreeRequirement,
  [id, body]
);
