// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class DegreeLevelsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    degreeLevels: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedDegreeLevel) {
    const { onSelect } = this.props;
    this.setState({ selectedDegreeLevel });
    if (onSelect) {
      onSelect(selectedDegreeLevel);
    }
  }

  render() {
    const { degreeLevels } = this.props;

    return (
      <Table
        data={degreeLevels}
        meta={[
          {
            path: "pos",
            title: "Pos",
            numeric: false,
            width: 30,
          },
          {
            path: "value",
            title: "Value",
            numeric: false,
          },
        ]}
        title={"Degree Levels"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(DegreeLevelsList);
