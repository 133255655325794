import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";

// open components
import ReactPlayer from "react-player";

const styles = {
  appBar: {
    position: "relative",
    background: "#212121",
  },
  flex: {
    flex: 1,
  },
};

class FullScreenVideoPlayer extends React.Component {
  static propTypes = {
    videoURL: PropTypes.string,
    path: PropTypes.string,
    open: PropTypes.bool,
    close: PropTypes.func,
  };

  render() {
    const { classes, open, close, videoURL, path } = this.props;
    return (
      <Dialog fullScreen open={open} onClose={close} TransitionComponent={Fade}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={close} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              display="block"
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {path}
            </Typography>
            {/* <Button color="inherit" onClick={this.handleClose}>
                save
              </Button> */}
          </Toolbar>
        </AppBar>
        <div
          style={{
            height: "calc(100% - 64px)",
            width: "100%",
            position: "relative",
            background: "black",
          }}
        >
          <ReactPlayer
            // key={selectedLecture.file}
            ref="ReactPlayer"
            // onProgress={::this.onProgress}
            url={videoURL}
            controls
            width="100%"
            height="100%"
          />
        </div>
      </Dialog>
    );
  }
}

FullScreenVideoPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenVideoPlayer);
