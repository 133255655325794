import { serviceRoles } from "config/access";
import { COACH } from "constants/routes";
import { checkAccess } from "helpers/auth";
import CoachPage from "./container/CoachPage";

export default function getRoute(userAuth) {
  const { account, aptitude } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [aptitude.name]: [aptitude.roles.admin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: COACH,
    withSidebar: true,
    withAppBar: true,
    component: CoachPage,
  };
}
