import { CATEGORY } from "constants/routes";
import CategoryPage from "./container/CategoryPage";
import { checkAccess } from "helpers/auth";

export default function getSettingsRoute(userAuth) {
  const requireAuth = {};
  return {
    path: CATEGORY,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: false,
    withAppBar: true,
    component: CategoryPage,
  };
}
