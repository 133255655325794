import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
// custom
import CourseEdit from "components/CourseEdit";
import EnrollmentsList from "components/EnrollmentsList";
import ModulesAndLectures from "components/ModulesAndLectures";
// reactor
import Page from "components/Page";
import { REACT_APP_STUDENT } from "config";
// constants
import { COURSES } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCoursePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    course: PropTypes.object,
    courses: PropTypes.array,
    loading: PropTypes.bool,
    updateCourse: PropTypes.func,
    getLectureMedia: PropTypes.func,
    updateModule: PropTypes.func,
    updateLecture: PropTypes.func,
    getCourseModules: PropTypes.func,
    getModuleLectures: PropTypes.func,
    refresh: PropTypes.func,
    refreshKey: PropTypes.string,
    deleteMedia: PropTypes.func,
    createLectureMedia: PropTypes.func,
    createLecture: PropTypes.func,
    deleteCourse: PropTypes.func,
    deleteLecture: PropTypes.func,
    createModule: PropTypes.func,
    deleteModule: PropTypes.func,
    getEnrollments: PropTypes.func,
    enrollments: PropTypes.array,
    user: PropTypes.object,
    searchUsers: PropTypes.func,
    app: PropTypes.object,
  };

  static contextTypes = {
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { UploadCenter } = this.context;
    this.state = {
      reorder: false,
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );
  }

  // componentWillUnmount() {
  //   const { UploadCenter } = this.context;
  //   UploadCenter.disregard();
  // }

  getStepContent() {
    const {
      course,
      updateCourse,
      updateModule,
      updateLecture,
      refresh,
      refreshKey,
      getCourseModules,
      getModuleLectures,
      getLectureMedia,
      deleteMedia,
      createLectureMedia,
      createLecture,
      deleteLecture,
      createModule,
      deleteModule,
      deleteCourse,
      getEnrollments,
      history,
      courses,
      enrollments,
      user,
      searchUsers,
      app,
    } = this.props;

    const { UploadCenter } = this.context;

    const { index, reorder } = this.state;

    switch (index) {
      case 0:
        return (
          <CourseEdit
            course={course}
            history={history}
            app={app}
            updateCourse={updateCourse}
            deleteCourse={deleteCourse}
            refresh={refresh}
            key={refreshKey}
          />
        );
      case 1:
        return (
          <ModulesAndLectures
            course={course}
            app={app}
            disabled={course.locked}
            reorder={reorder}
            getLectureMedia={getLectureMedia}
            updateModule={updateModule}
            updateLecture={updateLecture}
            getCourseModules={getCourseModules}
            getModuleLectures={getModuleLectures}
            deleteMedia={deleteMedia}
            createLectureMedia={createLectureMedia}
            createLecture={createLecture}
            deleteLecture={deleteLecture}
            createModule={createModule}
            deleteModule={deleteModule}
            openFileDialog={(lectureID, callback) => {
              this.setState(
                {
                  lectureID,
                  callback,
                },
                UploadCenter.openFileDialog
              );
            }}
          />
        );
      case 2:
        return (
          <EnrollmentsList
            history={history}
            courses={courses}
            getEnrollments={getEnrollments}
            enrollments={enrollments}
            user={user}
            searchUsers={searchUsers}
            onSelect={(selectedSurvey) =>
              window.open(
                `${REACT_APP_STUDENT}/enrollment?id=${selectedSurvey.id}`,
                "_blank"
              )
            }
          />
        );
      default:
        return (
          <Typography display="block" variant="h6">
            Coming Soon
          </Typography>
        );
    }
  }

  async upload() {
    const { createLectureMedia } = this.props;
    const { files, lectureID, callback } = this.state;

    await new Promise(async (r) => {
      const funcs = [];
      for (const k in files) {
        if (files.hasOwnProperty(k)) {
          const file = files[k];
          funcs.push(createLectureMedia(lectureID, file));
        }
      }

      await Promise.all(funcs);
      r();
    });
    callback();
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  handleLockChange() {
    const { updateCourse, course, refresh } = this.props;
    updateCourse(course.id, { locked: !course.locked }).then(refresh);
  }

  render() {
    const { classes, history, loading, course } = this.props;

    const { index, reorder } = this.state;

    return (
      <Page
        helmet={classes ? course.name : "Loading Course"}
        loadingMessage={"Loading Course"}
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }} justify="space-between">
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator="-">
                <Chip
                  onClick={() => history.push(COURSES)}
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back />
                    </Avatar>
                  }
                  variant="outlined"
                  className={classes.chip}
                  label="Back to Courses"
                />
                <Chip
                  variant="outlined"
                  className={classes.chip}
                  label={course.name}
                />
              </Breadcrumbs>
            </Grid>
            {course?.locked !== undefined ? (
              <Grid item>
                <Tooltip
                  title={`Click to ${course.locked ? "Unlock" : "Lock"}`}
                >
                  <IconButton onClick={this.handleLockChange.bind(this)}>
                    {!course?.locked ? (
                      <span className="fad fa-lock-open-alt" />
                    ) : (
                      <span className="fad fa-lock-alt" />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              []
            )}
            {/* <Grid item>
              {index === 1 ? (
                <Chip
                  label={reorder ? "Done Reordering" : "Reorder"}
                  color={reorder ? "primary" : "default"}
                  onClick={() => this.setState({ reorder: !reorder })}
                />
              ) : (
                []
              )}
              <Chip
                label={"Preview"}
                style={{ marginLeft: 4 }}
                onClick={() =>
                  window.open(
                    `${REACT_APP_STUDENT}/enrollment?previewID=${course.id}`
                  )
                }
              />
            </Grid> */}
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: `?id=${course.id}&index=${v}`,
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            <Tab
              label="Information"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Modules & Lectures"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCoursePage);
