import { ListSubheader } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Left from "@material-ui/icons/KeyboardArrowLeft";
import Right from "@material-ui/icons/KeyboardArrowRight";
import classNames from "classnames";
// config
import { menu } from "config/menu";
import { SIGNIN } from "constants/routes";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
// styles
import styles from "./styles";

class LeftMenu extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    routes: PropTypes.array,
    history: PropTypes.object,
    user: PropTypes.object,
    handleDrawerToggle: PropTypes.func,
    mobileOpen: PropTypes.bool,
    location: PropTypes.object,
  };

  state = {
    miniActive: false,
  };

  getMenu() {
    const { routes, history, user, location, classes } = this.props;

    const { miniActive } = this.state;

    const route = routes.find((r) => r.path === location.pathname);

    const JSX = [];
    for (const k in menu) {
      if (menu.hasOwnProperty(k)) {
        const menuItem = menu[k];
        if (menuItem.type === "header") {
          if (!miniActive)
            JSX.push(
              <ListSubheader
                dense
                style={{
                  background: "	rgba(33,150,243,0.1)",
                  backdropFilter: "blur(10px)",
                  WebkitBackdropFilter: "blur(10px)",
                }}
              >
                {menuItem.label}
              </ListSubheader>
            );
        }

        if (menuItem.type === "route") {
          const Icon = menuItem.icon;
          const currentRoute = routes.find((e) => e.path === menuItem.path);
          if (currentRoute && currentRoute.onEnter && currentRoute.onEnter()) {
            let selected = false;
            if (menuItem.path && menuItem.path === route.path) {
              selected = true;
            }

            JSX.push(
              <Tooltip
                title={menuItem.label}
                disableHoverListener={!miniActive}
                key={menuItem.label}
              >
                <ListItem
                  selected={selected && menuItem.nested === undefined}
                  key={menuItem.path}
                  button
                  divider={menuItem.divider || Number(k) === menu.length - 1}
                  onClick={() => {
                    if (menuItem.path) {
                      history.push(menuItem.path);
                      if (menuItem.nested) {
                        if (this.state[menuItem.label] === undefined) {
                          this.setState({ [menuItem.label]: true });
                        } else {
                          this.setState((prevState) => ({
                            [menuItem.label]: !prevState[menuItem.label],
                          }));
                        }
                      }
                    }
                  }}
                >
                  <ListItemIcon>{Icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary:
                        selected && menuItem.nested === undefined
                          ? classes.selected
                          : undefined,
                    }}
                    primary={menuItem.label}
                  />
                  {menuItem.nested && (
                    <div>
                      {this.state[menuItem.label] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </div>
                  )}
                </ListItem>
              </Tooltip>
            );

            if (menuItem.nested) {
              const nestedMenu = [];
              for (const m in menuItem.nested) {
                if (menuItem.nested.hasOwnProperty(m)) {
                  const n = menuItem.nested[m];
                  const NestedIcon = n.icon;
                  if (n.path && n.path === route.path) {
                    selected = true;
                  } else {
                    selected = false;
                  }

                  nestedMenu.push(
                    <ListItem
                      selected={selected}
                      key={`nested_${n.path}`}
                      style={{ background: "rgba(155,155,155,0.1)" }}
                      button
                      className={classes.nested}
                      divider={n.divider || Number(k) === menu.length - 1}
                      onClick={() => {
                        if (n.path) {
                          history.push(n.path);
                        }
                      }}
                    >
                      <ListItemIcon
                        style={{
                          paddingLeft: miniActive ? 0 : 18,
                          WebkitTransition: "padding-left 0.2s",
                        }}
                      >
                        {NestedIcon}
                      </ListItemIcon>
                      <ListItemText
                        classes={{
                          primary: selected ? classes.selected : undefined,
                        }}
                        primary={n.label}
                      />
                    </ListItem>
                  );
                }
              }

              JSX.push(
                <Collapse
                  key={`collapse_${menuItem.path}`}
                  in={
                    this.state[menuItem.label] !== undefined
                      ? this.state[menuItem.label]
                      : menuItem.nested.find((n) => n.path === route.path) !==
                        undefined
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    dense
                    disablePadding
                    className={classes.background}
                  >
                    {nestedMenu}
                  </List>
                </Collapse>
              );
            }
          }
        }
      }
    }

    return (
      <div>
        <List
          className={classes.list}
          component="nav"
          style={{ padding: 0, paddingBottom: 60 }}
          dense
        >
          {JSX}
          {user.services === undefined && (
            <ListItem
              key={"signin"}
              button
              divider
              onClick={() => {
                history.push(SIGNIN);
              }}
            >
              <ListItemIcon>
                <Fingerprint />
              </ListItemIcon>
              <ListItemText primary={"Signin"} />
            </ListItem>
          )}
        </List>
      </div>
    );
  }

  render() {
    const { classes, theme, mobileOpen, handleDrawerToggle } = this.props;
    const { miniActive } = this.state;

    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          height: "100vh",
        }}
      >
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div className={classes.drawerContainer}>{this.getMenu()}</div>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            open={miniActive}
            classes={{
              paper: classNames(
                classes.drawerPaper,
                this.state.miniActive && classes.drawerPaperClose
              ),
            }}
            style={{
              height: "100%",
            }}
          >
            <div className={classes.drawerContainer}>{this.getMenu()}</div>
          </Drawer>
        </Hidden>
        <div
          style={{
            height: 40,
            position: "absolute",
            zIndex: 1300,
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            bottom: 0,
            width: "100%",
            cursor: "pointer",
            background: !miniActive ? "rgba(155,155,155,0.2)" : undefined,
          }}
          onClick={() => {
            this.setState({ miniActive: !miniActive });
          }}
        >
          <Grid
            container
            style={{
              height: 39,
              borderTop: "solid 1px rgba(155,155,155,0.3)",
            }}
            alignItems="center"
            justify="center"
          >
            <Grid item className={classes.darkModeFix}>
              {miniActive ? <Right /> : <Left />}
            </Grid>
            {!miniActive ? (
              <Grow in>
                <Grid item>
                  <Typography display="block" variant="body2">
                    Collapse Menu
                  </Typography>
                </Grid>
              </Grow>
            ) : (
              []
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LeftMenu);
