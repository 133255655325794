import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class CoachesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    coaches: PropTypes.array,
    onSelect: PropTypes.func,
  };

  render() {
    const { coaches, classes, onSelect } = this.props;

    return (
      <Grid container spacing={2}>
        {coaches.map((e) => (
          <Grid item key={e.id} className={classes.item}>
            <Paper className={classes.paper} onClick={() => onSelect(e)}>
              <Avatar
                style={{ width: 60, height: 60, margin: "auto" }}
                src={e.avatar}
              />
              <Typography display="block" variant="title">
                {e.fullName}
              </Typography>
              <Typography
                display="block"
                variant="body2"
                color="textSecondary"
              ></Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(CoachesList);
