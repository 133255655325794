import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormInquiry extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    inquiry: PropTypes.object,

    createInquiry: PropTypes.func,
    updateInquiry: PropTypes.func,
    deleteInquiry: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      question: "",
      kind: "",
      description: "",
      data: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.inquiry);
    }
  }

  handleChange = (v) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [v]: value,
    });
  };

  init(inquiry) {
    this.setState({
      loading: false,
      name: inquiry ? inquiry.name : "",
      question: inquiry ? inquiry.question : "",
      kind: inquiry ? inquiry.kind : "",
      description: inquiry ? inquiry.description : "",
      data: inquiry ? inquiry.data : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this inquiry, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteInquiry, inquiry, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteInquiry(inquiry.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Inquiry has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateInquiry,
      createInquiry,
      close,
      inquiry,
      refresh,
    } = this.props;

    const { name, description, question, kind, data } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (inquiry) {
      resp = await updateInquiry(inquiry.id, {
        name,
        description,
        question,
        kind,
        data,
      });
    } else {
      resp = await createInquiry({
        name,
        description,
        question,
        kind,
        data,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      refresh();
      close();
      NotificationCenter.sweetAlert({
        title: inquiry
          ? "Inquiry has been updated."
          : "Inquiry has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const { classes, fullScreen, open, inquiry, close } = this.props;

    const { name, description, question, kind, data, loading } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {inquiry ? `${inquiry.value}` : "Let's Create a New Inquiry"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {inquiry
                  ? "To update the inquiry, fill the form below :"
                  : "To create a new inquiry, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    className={classes.textField}
                    value={description}
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Question"
                    className={classes.textField}
                    value={question}
                    onChange={this.handleChange("question")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Kind"
                    className={classes.textField}
                    value={kind}
                    select
                    onChange={this.handleChange("kind")}
                  >
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="checkbox">Checkbox</MenuItem>
                    <MenuItem value="radio">Radio</MenuItem>
                    <MenuItem value="select">Select</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Data"
                    className={classes.textField}
                    value={data}
                    onChange={this.handleChange("data")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {inquiry && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {inquiry ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormInquiry));
