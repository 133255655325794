import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Portal from "@material-ui/core/Portal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GoogleMapReact from "google-map-react";

// reactor
import Page from "components/Page";

// styles
import styles from "./styles";

class WrapperPlanPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { MessageCenter } = this.context;
    MessageCenter.open("left");
  }

  render() {
    const { classes } = this.props;
    const { MessageCenter } = this.context;

    return (
      <Page helmet="Plan" loadingMessage={"Loading Plan"} noPadding>
        <div style={{ flexGrow: 1, background: "#fff8e1" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyD67kHvhZC_mBkppkJIMAkZDu4c279J_As",
            }}
            center={{
              lat: 40.338803,
              lng: -99.083565,
            }}
            // onGoogleApiLoaded={({ map, maps }) => this.renderPolylines(map, maps)}
            // options={app.darkMode ? { styles: darkGoogleMaps } : undefined}
            defaultZoom={5}
          />
        </div>
        <Portal container={MessageCenter.getPortalContainer("left")}>
          <div className={classes.drawer}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <img
                  alt="cpfm"
                  src="https://storage.googleapis.com/college101-logos/cpfm/logo_main.png"
                  width="150"
                />
              </Grid>
            </Grid>
          </div>
        </Portal>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperPlanPage);
