// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import PropTypes from "prop-types";
import React, { Component } from "react";
// import AdvancedSearch from 'components/AdvancedSearch';
// styles
import styles from "./styles";

class AcademiesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    academies: PropTypes.array,
    onSelect: PropTypes.func,
    getAcademies: PropTypes.func,
    onDoubleSelect: PropTypes.func,
    advancedSearch: PropTypes.bool,
    filters: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    dataLoading: PropTypes.bool,
  };

  onSelect(selectedAcademy) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedAcademy);
    }
  }

  onDoubleSelect(selectedAcademy) {
    const { onDoubleSelect } = this.props;
    if (onDoubleSelect) {
      onDoubleSelect(selectedAcademy);
    }
  }

  render() {
    const { academies, history, getAcademies } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getAcademies}
        keyword="academies"
        metaDataEndpoint="/cpfm/academies/searchMetadata"
      >
        <Table
          data={academies}
          noToolbar
          meta={[
            {
              path: "picture",
              title: "",
              render: (t) => {
                if (t)
                  return (
                    <img src={t} alt="icon" style={{ width: 40, height: 40 }} />
                  );
              },
            },
            {
              path: "name",
              title: "Name",
            },
            {
              path: "address",
              title: "Address",
              transform: (t, d) =>
                `${!d.latitude ? "⚠️" : "✅"} - ${d.address}, ${d.zipcode} ${
                  d.city
                } ${d.state} ${d.country} (${d.latitude}, ${d.longitude})`,
            },
          ]}
          title={"Academies"}
          onRowSelect={this.onSelect.bind(this)}
          onRowDoubleSelect={this.onDoubleSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(AcademiesList);
