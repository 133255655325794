import { networkAction } from "helpers/network/networkAction";

import {
  GET_ENROLLMENTS,
  GET_ENROLLMENT_BY_ID,
  DELETE_ENROLLMENT,
  UPDATE_ENROLLMENT,
  CREATE_ENROLLMENT,
} from "constants/enrollments";

import * as Api from "api";

export const getEnrollments = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_ENROLLMENTS, Api.getEnrollments, [filters]);

export const getEnrollmentById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ENROLLMENT_BY_ID, Api.getEnrollmentById, [id]);

export const deleteEnrollment = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ENROLLMENT, Api.deleteEnrollment, [id]);

export const createEnrollment = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ENROLLMENT, Api.createEnrollment, [body]);

export const updateEnrollment = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ENROLLMENT, Api.updateEnrollment, [id, body]);
