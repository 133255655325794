import { GET, PUT, DELETE, POST } from "constants/methods";
const availabilities = "/calendy/availabilities";

export function getAvailabilities() {
  const url = `${availabilities}`;
  return {
    method: GET,
    url,
  };
}

export function getAvailabilityById(id) {
  const url = `${availabilities}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateAvailability(id, body) {
  const url = `${availabilities}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAvailability(id) {
  const url = `${availabilities}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAvailability(body) {
  const url = `${availabilities}`;
  return {
    method: POST,
    url,
    body,
  };
}
