// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class QuizCategoriesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    quizCategories: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedQuizCategory) {
    const { onSelect } = this.props;
    this.setState({ selectedQuizCategory });
    if (onSelect) {
      onSelect(selectedQuizCategory);
    }
  }

  render() {
    const { quizCategories } = this.props;

    return (
      <Table
        data={quizCategories}
        meta={[
          {
            path: "title",
            title: "Title",
            numeric: false,
          },
          {
            path: "description",
            title: "Description",
            numeric: false,
          },
          {
            path: "question",
            title: "Question",
            numeric: false,
          },
        ]}
        title={"Quiz Categories"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(QuizCategoriesList);
