import { GET_COACHES } from "constants/coaches";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_COACHES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
