import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROGRAM_TYPES,
  GET_PROGRAM_TYPE_BY_ID,
  DELETE_PROGRAM_TYPE,
  UPDATE_PROGRAM_TYPE,
  CREATE_PROGRAM_TYPE,
} from "constants/programTypes";

import * as Api from "api";

export const getProgramTypes = () => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAM_TYPES, Api.getProgramTypes, []);

export const getProgramTypeById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROGRAM_TYPE_BY_ID, Api.getProgramTypeById, [id]);

export const deleteProgramType = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PROGRAM_TYPE, Api.deleteProgramType, [id]);

export const createProgramType = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PROGRAM_TYPE, Api.createProgramType, [body]);

export const updateProgramType = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROGRAM_TYPE, Api.updateProgramType, [
    id,
    body,
  ]);
