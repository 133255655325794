import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "aptitude";
const changelogs = `${REACT_APP_API_PREFIX}/${service}/changelogs`;

export function getAptitudeChangelogs(filters) {
  const url = `${changelogs}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getAptitudeChangelogById(id) {
  const url = `${changelogs}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateAptitudeChangelog(id, body) {
  const url = `${changelogs}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAptitudeChangelog(id) {
  const url = `${changelogs}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAptitudeChangelog(body) {
  const url = `${changelogs}`;
  return {
    method: POST,
    url,
    body,
  };
}
