import { networkAction } from "helpers/network/networkAction";

import {
  GET_CLUB_MEMBERS,
  GET_CLUB_MEMBERS_BY_ID,
  DELETE_CLUB_MEMBERS,
  UPDATE_CLUB_MEMBERS,
  CREATE_CLUB_MEMBERS,
} from "constants/clubMembers";

import * as Api from "api";

export const getClubMembers = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CLUB_MEMBERS, Api.getClubMembers, [filters]);

export const getClubMemberById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CLUB_MEMBERS_BY_ID, Api.getClubMemberById, [id]);

export const deleteClubMember = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CLUB_MEMBERS, Api.deleteClubMember, [id]);

export const createClubMember = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CLUB_MEMBERS, Api.createClubMember, [body]);

export const updateClubMember = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CLUB_MEMBERS, Api.updateClubMember, [
    id,
    body,
  ]);
