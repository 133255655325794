import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "cpfm";
const types = `/${service}/programTypes`;

export function getProgramTypes() {
  const url = `${types}`;
  return {
    method: GET,
    url,
  };
}

export function getProgramTypeById(id) {
  const url = `${types}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProgramType(id, body) {
  const url = `${types}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProgramType(id) {
  const url = `${types}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProgramType(body) {
  const url = `${types}`;
  return {
    method: POST,
    url,
    body,
  };
}
