import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import CareersList from "components/CareersList";
import FormCareer from "components/FormCareer";
// components
import Page from "components/Page";
import {
  getJsonFromUrl,
  removeURLParameter,
  updateURLParameter,
} from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCareerPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    authors: PropTypes.array,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    quizCategories: PropTypes.array,
    loading: PropTypes.bool,
    skillTags: PropTypes.array,
    quizTags: PropTypes.array,
    degreeLevels: PropTypes.array,
    degreeRequirements: PropTypes.array,
    subCategories: PropTypes.array,

    //careers
    careers: PropTypes.array,
    categories: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams, careers } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCareer: undefined,
      formCareerOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.careers.length > 0 && this.props.careers.length === 0) {
      const urlParams = getJsonFromUrl(window.location);
      if (Number(urlParams.id)) {
        const selectedCareer = nextProps.careers.find(
          (c) => c.id === Number(urlParams.id)
        );
        setTimeout(
          () =>
            this.setState({
              selectedCareer,
              formCareerOpen: true,
            }),
          200
        );
      }
    }
  }

  async quickCreate() {
    const { api, history } = this.props;
    const { careerTitle } = this.state;
    const r1 = await api.createCareer({
      title: careerTitle,
    });

    if (r1.success) {
      const r2 = await api.getCareerById(r1.payload.id);
      this.setState({
        selectedCareer: r2.payload,
        formCareerOpen: true,
        quickCreateOpen: false,
        careerTitle: "",
      });
      history.push({
        search: updateURLParameter("id", r2.payload.id),
      });
    }
  }

  getStepContent() {
    const { index } = this.state;
    const { careers, categories, api, history } = this.props;

    if (index === 0) {
      return (
        <CareersList
          careers={careers}
          history={history}
          getCareers={api.getCareers}
          onSelect={(selectedCareer) => {
            this.setState({
              selectedCareer,
              formCareerOpen: true,
            });
            history.push({
              search: updateURLParameter("id", selectedCareer.id),
            });
          }}
        />
      );
    }

    return (
      <CareersList
        careers={careers.filter(
          (career) =>
            career?.categories?.find(
              (category) => category.id === categories[index - 1].id
            ) !== undefined
        )}
        getCareers={api.getCareers}
        history={history}
        onSelect={(selectedCareer) =>
          this.setState({
            selectedCareer,
            formCareerOpen: true,
          })
        }
      />
    );
  }

  render() {
    const {
      classes,
      categories,
      history,
      loading,
      api,
      refresh,
      skillTags,
      degreeRequirements,
      subCategories,
      degreeLevels,
      quizTags,
      quizCategories,
      authors,
    } = this.props;

    const { index, selectedCareer, formCareerOpen } = this.state;

    return (
      <Page
        loading={loading}
        helmet="Careers"
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ quickCreateOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            {categories &&
              categories.map((c) => (
                <Tab
                  key={c.title}
                  label={c.title}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  style={{
                    color: c.color,
                  }}
                />
              ))}
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormCareer
          open={formCareerOpen}
          authors={authors}
          career={selectedCareer}
          degreeRequirements={degreeRequirements}
          degreeLevels={degreeLevels}
          categories={categories}
          quizCategories={quizCategories}
          quizTags={quizTags}
          close={() => {
            this.setState({
              formCareerOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCareer: undefined,
                }),
              200
            );
            history.push({
              search: removeURLParameter("id"),
            });
          }}
          skillTags={skillTags}
          api={api}
          history={history}
          subCategories={subCategories}
          refresh={refresh}
        />
        <Dialog
          open={this.state.quickCreateOpen}
          onClose={() => this.setState({ quickCreateOpen: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Career</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a new career, please enter its title in the text field
              below:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Career Title"
              type="name"
              fullWidth
              value={this.state.careerTitle}
              onChange={(e) => this.setState({ careerTitle: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ quickCreateOpen: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.quickCreate.bind(this)}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCareerPage);
