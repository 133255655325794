import { emphasize } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: 24,
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
  backChip: {
    backgroundColor: "none",
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "none",
    },
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing.unit * 1.5,
  },
  drawer: {
    background: theme.palette.background.paper,
    width: 300,
    padding: 20,
    height: "calc(calc(var(--vh, 1vh) * 100) - 40px)",
    overflow: "scroll",
  },
});
