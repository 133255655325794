export const routeNames = [
  "Default",
  "Signin",
  "MyAccount",
  "Authors",
  "Courses",
  "Course",
  "Assessments",
  "Assessment",
  "Enrollments",
  "Surveys",
  "Map",
  "Plan",
  "Academies",
  "Academy",
  "Settings",
  "Programs",
  "DownloadRequests",
  "EventTypes",
  "EventType",
  "Coach",
  "ClubMembers",
  "Student",
  "Process",
  "Career",
  "Careers",
  "Category",
  "Categories",
  "Cpfm",
  "DegreeRequirements",
  "DegreeLevels",
  "Institutions",
  "SubCategories",
  "Search",
  "Inquiries",
  "SkillTags",
  "AssessmentLogs",
  "CourseLogs",
  "QuizTags",
  "QuizCategories",
];
