import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachFile from "@material-ui/icons/AttachFileTwoTone";
import Audio from "@material-ui/icons/AudiotrackTwoTone";
import Delete from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grain from "@material-ui/icons/Grain";
import Video from "@material-ui/icons/OndemandVideoTwoTone";
import File from "components/File";
// custom
import MarkdownEditor from "components/MarkdownEditor";
// config
import { REACT_APP_API } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
// styles
import styles from "./styles";

class LectureEdit extends Component {
  static propTypes = {
    classes: PropTypes.object,
    lecture: PropTypes.object,
    disabled: PropTypes.bool,
    updateLecture: PropTypes.func,
    getLectureMedia: PropTypes.func,
    deleteMedia: PropTypes.func,
    createLectureMedia: PropTypes.func,
    deleteLecture: PropTypes.func,
    refresh: PropTypes.func,
    app: PropTypes.object,
    openFileDialog: PropTypes.func,
    reorder: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { lecture } = this.props;
    this.state = {
      loading: false,
      media: [],
      expanded: false,
      ...lecture,
    };
  }

  componentDidMount() {
    this.getLectureMedia();
  }

  componentWillUnmount() {
    this.save("description");
    this.save("actions");
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async getLectureMedia() {
    const { getLectureMedia, lecture } = this.props;
    const resp = await getLectureMedia(lecture.id);
    if (resp.success) {
      let audio = 0;
      let other = 0;
      const media = resp.payload;
      for (const k in media) {
        if (media.hasOwnProperty(k)) {
          const medium = media[k];
          if (medium.type.indexOf("audio") !== -1) {
            audio += 1;
          } else {
            other += 1;
          }
        }
      }
      this.setState({ media, other, audio });
    }
  }

  callback() {
    const { NotificationCenter } = this.context;
    const { files } = this.state;
    NotificationCenter.sweetAlert(
      {
        title: "Success",
        success: true,
        body: `Your file${files.length > 1 ? "s" : ""} ${
          files.length > 1 ? "have" : "has"
        } been uploaded`,
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: "Ok",
          level: "success",
        },
      }
    );

    setTimeout(NotificationCenter.hide, 2000);
  }

  async save(name, numeric) {
    const { updateLecture, lecture, refresh } = this.props;

    if (lecture[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateLecture(lecture.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async deleteLecture() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "ou won't be able to recover the lecture.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteLecture, lecture, refresh } = this.props;
    await deleteLecture(lecture.id);
    refresh();
  }

  render() {
    const {
      classes,
      lecture,
      deleteMedia,
      app,
      reorder,
      disabled,
      openFileDialog,
    } = this.props;
    const {
      loading,
      name,
      other,
      audio,
      description,
      actions,
      expanded,
      file,
      order,
      media,
    } = this.state;

    return (
      <ExpansionPanel
        onChange={(e, b) => this.setState({ expanded: b })}
        className={loading ? classes.paperLoading : classes.paper}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container spacing={2} alignItems="center">
            {reorder ? (
              <Grid item>
                <Grain className={classes.grip} id="grabLecture" />
              </Grid>
            ) : (
              []
            )}
            {lecture.file && lecture.file.length ? (
              <Grid item>
                <Badge
                  className={classes.margin}
                  badgeContent={1}
                  color="primary"
                >
                  <Video className={classes.darkModeFix} />
                </Badge>
              </Grid>
            ) : (
              <div />
            )}
            {other ? (
              <Grid item>
                <Badge
                  className={classes.margin}
                  badgeContent={other}
                  color="primary"
                >
                  <AttachFile className={classes.darkModeFix} />
                </Badge>
              </Grid>
            ) : (
              <div />
            )}
            {audio ? (
              <Grid item>
                <Badge
                  className={classes.margin}
                  badgeContent={audio}
                  color="primary"
                >
                  <Audio className={classes.darkModeFix} />
                </Badge>
              </Grid>
            ) : (
              <div />
            )}
            <Grid item xs={8}>
              <InputBase
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("name");
                }}
                disabled={loading || disabled}
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onBlur={() => this.save("name")}
                className={classes.inputBase}
                value={name}
                onChange={this.handleChange("name")}
              />
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        {expanded ? (
          <ExpansionPanelDetails className={classes.expanded}>
            <div style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography display="block" variant="body2">
                    Description:
                  </Typography>
                  <MarkdownEditor
                    value={description}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("description");
                    }}
                    onBlur={() => this.save("description")}
                    disabled={loading || disabled}
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography display="block" variant="body2">
                    Actions:
                  </Typography>
                  <MarkdownEditor
                    value={actions}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("actions");
                    }}
                    onBlur={() => this.save("actions")}
                    disabled={loading || disabled}
                    onChange={this.handleChange("actions")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography display="block" variant="body2">
                    Lecture Order:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <InputBase
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("order", true);
                    }}
                    disabled={loading || disabled}
                    fullWidth
                    onBlur={() => this.save("order", true)}
                    className={classes.inputBase}
                    value={order}
                    onChange={this.handleChange("order")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography display="block" variant="body2">
                    Video URL:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <InputBase
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.save("file");
                    }}
                    disabled={loading || disabled}
                    fullWidth
                    onBlur={() => this.save("file")}
                    className={classes.inputBase}
                    value={file}
                    onChange={this.handleChange("file")}
                  />
                </Grid>
              </Grid>
              {file ? (
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography display="block" variant="body2">
                      Video Preview:
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <ReactPlayer
                      url={file}
                      key={file}
                      controls
                      width="100%"
                      height="600px"
                      style={{
                        background: "black",
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <div />
              )}
              {audio ? (
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography display="block" variant="body2">
                      Audio Preview:
                    </Typography>
                  </Grid>
                  {media.map((f) => (
                    <Grid item key={f.id}>
                      {f.type.indexOf("audio") !== -1 ? (
                        <ReactAudioPlayer
                          crossorigin="use-credentials"
                          src={`${REACT_APP_API}/aptitude/media/${f.id}/download`}
                          controls
                          controlsList="nodownload"
                        />
                      ) : (
                        <div />
                      )}
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div />
              )}
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography display="block" variant="body2">
                    Files:
                  </Typography>
                </Grid>
                {media &&
                  media.map((f) => (
                    <Grid item key={f.id}>
                      <File
                        file={f}
                        deleteFile={deleteMedia}
                        refresh={this.getLectureMedia.bind(this)}
                      />
                    </Grid>
                  ))}
              </Grid>
              {/* <Grid container spacing={2}>
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <Chip
                    onClick={() => {
                      openFileDialog(
                        lecture.id,
                        this.getLectureMedia.bind(this)
                      );
                    }}
                    avatar={
                      <Avatar>
                        <Upload />
                      </Avatar>
                    }
                    label={"Upload New Files"}
                    variant="outlined"
                    color="primary"
                  />
                </Grid>
              </Grid> */}
              <Grid container spacing={2} justify="flex-end">
                <Grid item xs={2}>
                  <Chip
                    color="primary"
                    disabled={disabled}
                    variant="outlined"
                    label="Delete Lecture"
                    avatar={
                      <Avatar style={{ background: undefined }}>
                        <Delete style={{ color: "white" }} />
                      </Avatar>
                    }
                    onClick={this.deleteLecture.bind(this)}
                  />
                </Grid>
              </Grid>
            </div>
          </ExpansionPanelDetails>
        ) : (
          <div />
        )}
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(LectureEdit);
