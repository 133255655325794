import {
  GET_PRODUCTS,
} from 'constants/products';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_PRODUCTS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
