import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

// components
import MasterTagsList from 'components/MasterTagsList';
import Page from 'components/Page';
import FormMasterTag from 'components/FormMasterTag';

// styles
import styles from './styles';

class WrapperMasterTagsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //masterTags
    masterTags: PropTypes.array,
    getMasterTagById: PropTypes.func,
    createMasterTag: PropTypes.func,
    deleteMasterTag: PropTypes.func,
    updateMasterTag: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedMasterTag: undefined,
      formMasterTagOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { masterTags } = this.props;

    switch (index) {
      case 0:
        return (
          <MasterTagsList
            masterTags={masterTags}
            onSelect={(selectedMasterTag) => this.setState({
              selectedMasterTag,
              formMasterTagOpen: true,
            })}
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createMasterTag,
      deleteMasterTag,
      updateMasterTag,
      refresh,
    } = this.props;

    const {
      index,
      selectedMasterTag,
      formMasterTagOpen,
    } = this.state;

    return (
      <Page
        helmet={'MasterTags'}
        loadingMessage={'Loading Document'}
        loading={loading}
        fab={(
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formMasterTagOpen: true })}
          >
            <AddIcon />
          </Fab>
        )}
        tabs={(
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          >
            <Tab
              label="MasterTags"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        )}
      >
        {
          this.getStepContent()
        }
        <FormMasterTag
          open={formMasterTagOpen}
          masterTag={selectedMasterTag}
          close={() => {
            this.setState({
              formMasterTagOpen: false,
            });
            setTimeout(() => this.setState({
              selectedMasterTag: undefined,
            }), 200);
          }
          }
          createMasterTag={createMasterTag}
          deleteMasterTag={deleteMasterTag}
          updateMasterTag={updateMasterTag}
          refresh={refresh}
        />
      </Page>
    )
  }
}
export default withStyles(styles)(WrapperMasterTagsPage);

