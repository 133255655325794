import {
  GET_INSTITUTIONS,
} from 'constants/institutions';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_INSTITUTIONS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
