// import PropTypes from 'prop-types';
// material-ui
import { IconButton, InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CareerTitle({ title, id, api, getCareerTitles }) {
  const [value, setValue] = React.useState(title || "");
  const classes = useStyles(styles);

  const deleteCareerTitle = () => {
    api.deleteCareerTitle(id).then(getCareerTitles);
  };

  const save = () => {
    api.updateCareerTitle(id, { title: value }).then(getCareerTitles);
  };

  return (
    <div className={classes.container}>
      <InputBase
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={classes.input}
        onBlur={save}
      />
      <IconButton
        variant="small"
        style={{ padding: 4 }}
        onClick={deleteCareerTitle}
      >
        <CloseIcon style={{ fontSize: 18 }} />
      </IconButton>
    </div>
  );
}
