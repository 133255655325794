import { GET, PUT, DELETE, POST } from "constants/methods";
const calendyStudents = "/calendy/students";

export function getCalendyStudents(filters) {
  const url = `${calendyStudents}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCalendyStudentById(id) {
  const url = `${calendyStudents}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCalendyStudent(id, body) {
  const url = `${calendyStudents}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCalendyStudent(id) {
  const url = `${calendyStudents}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCalendyStudent(body) {
  const url = `${calendyStudents}`;
  return {
    method: POST,
    url,
    body,
  };
}
