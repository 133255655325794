import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "cpfm";
const programs = `/${service}/programs`;

export function getPrograms(filters) {
  const url = `${programs}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getProgramById(id) {
  const url = `${programs}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProgram(id, body) {
  const url = `${programs}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProgram(id) {
  const url = `${programs}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProgram(body) {
  const url = `${programs}`;
  return {
    method: POST,
    url,
    body,
  };
}
