export default (theme) => ({
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
  item: {
    minWidth: 300,
    textAlign: "center",
  },
  paper: {
    padding: 16,
    cursor: "pointer",
    textAlign: "center",
    borderTop: "solid 4px #ff9800",
  },
});
