// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperAuthorsPage from "../component/WrapperAuthorsPage";

const mapStateToProps = (state) => ({
  authors: state.authors,
});

const mapDispatchToProps = (dispatch) => ({
  getAuthors: () => dispatch(Actions.getAuthors()),
  getAuthorById: (...args) => dispatch(Actions.getAuthorById(...args)),
  createAuthor: (...args) => dispatch(Actions.createAuthor(...args)),
  deleteAuthor: (...args) => dispatch(Actions.deleteAuthor(...args)),
  updateAuthor: (...args) => dispatch(Actions.updateAuthor(...args)),
});
class AuthorsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    authors: PropTypes.array,
    getAuthors: PropTypes.func,
    getAuthorById: PropTypes.func,
    createAuthor: PropTypes.func,
    deleteAuthor: PropTypes.func,
    updateAuthor: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, authors } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: authors.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getAuthors } = this.props;
    await Promise.all([getAuthors()]);

    this.setState({ loading: false });
  }

  render() {
    const { authors, getAuthorById, createAuthor, deleteAuthor, updateAuthor } =
      this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperAuthorsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        authors={authors}
        getAuthorById={getAuthorById}
        createAuthor={createAuthor}
        deleteAuthor={deleteAuthor}
        updateAuthor={updateAuthor}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AuthorsPage);
