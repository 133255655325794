// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { durationFormatter } from "helpers";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TimeAssetsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    timeAssets: PropTypes.array,
    getTimeAssets: PropTypes.func,
    history: PropTypes.object,
    onSelect: PropTypes.func,
    searchUsers: PropTypes.func,
  };

  onSelect(selectedTimeAsset) {
    const { onSelect } = this.props;
    this.setState({ selectedTimeAsset });
    if (onSelect) {
      onSelect(selectedTimeAsset);
    }
  }

  render() {
    const { timeAssets, getTimeAssets, history, searchUsers } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getTimeAssets}
        keyword="timeAssets"
        metaDataEndpoint="/calendy/timeAssets/searchMetadata"
        exportEndpoint="/calendy/timeAssets/csv"
        metaDataOveride={{
          "students.userUUID": {
            type: "autoFill",
            label: "User",
            getData: (search) =>
              searchUsers({
                text: search,
              }),
            valueKey: "uuid",
            labelTransform: (t) => `${t.firstName} ${t.lastName} (${t.email})`,
          },
        }}
      >
        <Table
          data={timeAssets}
          meta={[
            {
              path: "userFullName",
              title: "User",
            },
            {
              path: "email",
              title: "Email",
            },
            {
              path: "datePurchased",
              title: "Date Purchased",
              numeric: false,
              render: (t) => moment(t).format("lll"),
            },
            {
              path: "duration",
              title: "Duration",
              render: (t, d) => durationFormatter(d.duration),
            },
          ]}
          title={"TimeAssets"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(TimeAssetsList);
