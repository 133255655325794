import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// components
import Page from "components/Page";
import Table from "components/TablePagination";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCourseLogsPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    aptitudeChangelogs: PropTypes.array,
    urlParams: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  render() {
    const { aptitudeChangelogs, history, getAptitudeChangelogs } = this.props;

    return (
      <Page helmet="Course Logs">
        <AdvancedSearch
          history={history}
          location={window.location}
          refresh={getAptitudeChangelogs}
          keyword="changelogsAptitude"
          metaDataEndpoint="/aptitude/changelogs/searchMetadata"
        >
          <Table
            data={aptitudeChangelogs || []}
            noToolbar
            meta={[
              {
                path: "userName",
                title: "User",
              },
              {
                path: "modelName",
                title: "Course Name",
              },
              {
                path: "modelID",
                title: "CourseID",
              },
              {
                path: "method",
                title: "Method",
              },
              {
                path: "createdAt",
                title: "Date",
                transform: (t) => moment(t).format("lll"),
              },
            ]}
            title={"Change logs"}
          />
        </AdvancedSearch>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCourseLogsPage);
