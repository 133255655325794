import React from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

// custom
import AssessmentsList from "components/AssessmentsList";
import FormAssessment from "components/FormAssessment";

// reactor
import Page from "components/Page";

// constants
import { ASSESSMENT } from "constants/routes";

// styles
import styles from "./styles";

class WrapperAssessmentPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    assessments: PropTypes.array,
    createAssessment: PropTypes.func,
    deleteAssessment: PropTypes.func,
    getAssessments: PropTypes.func,
    updateAssessment: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { UploadCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedAssessment: undefined,
      formAssessmentOpen: false,
    };

    UploadCenter.register(
      () => console.log("upload"),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );
  }

  componentWillUnmount() {
    const { UploadCenter } = this.context;
    UploadCenter.disregard();
  }

  getStepContent() {
    const { index } = this.state;
    const { assessments, history, getAssessments } = this.props;

    switch (index) {
      case 0:
        return (
          <AssessmentsList
            assessments={assessments}
            getAssessments={getAssessments}
            history={history}
            onSelect={(selectedAssessment) =>
              history.push(`${ASSESSMENT}?id=${selectedAssessment.id}`)
            }
          />
        );
      default:
    }

    return [];
  }

  userDidDrop(files) {
    const { createAssessment, refresh } = this.props;
    const { NotificationCenter } = this.context;
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (evt) => {
      const json = JSON.parse(evt.target.result);
      if (json) {
        createAssessment(json).then((resp) => {
          if (resp.success) {
            NotificationCenter.sweetAlert({
              title: "Success",
              body: "The Assessment has been imported",
              success: true,
              timestamp: new Date().getTime(),
            });
            refresh();
          } else {
            NotificationCenter.sweetAlert({
              title: "Error",
              body: "Something went wrong. Check your JSON",
              error: true,
              timestamp: new Date().getTime(),
            });
          }
        });
      }
    };
  }

  render() {
    const {
      classes,
      history,
      loading,
      createAssessment,
      deleteAssessment,
      updateAssessment,
      refresh,
    } = this.props;

    const { index, selectedAssessment, formAssessmentOpen } = this.state;

    return (
      <Page
        helmet="Assessments"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formAssessmentOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Assessments"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormAssessment
          open={formAssessmentOpen}
          assessment={selectedAssessment}
          close={() => {
            this.setState({
              formAssessmentOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedAssessment: undefined,
                }),
              200
            );
          }}
          createAssessment={createAssessment}
          deleteAssessment={deleteAssessment}
          updateAssessment={updateAssessment}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperAssessmentPage);
