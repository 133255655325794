// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperSurveyPage from "../component/WrapperSurveyPage";

const mapStateToProps = (state) => ({
  surveys: state.surveys,
  users: state.users,
  user: state.user,
  assessments: state.assessments,
});

const mapDispatchToProps = (dispatch) => ({
  getSurveys: (...args) => dispatch(Actions.getSurveys(...args)),
  getAssessments: (...args) => dispatch(Actions.getAssessments(...args)),
  getUsers: (...args) => dispatch(Actions.getUsers(...args)),
  getSurveyById: (...args) => dispatch(Actions.getSurveyById(...args)),
  createSurvey: (...args) => dispatch(Actions.createSurvey(...args)),
  deleteSurvey: (...args) => dispatch(Actions.deleteSurvey(...args)),
  updateSurvey: (...args) => dispatch(Actions.updateSurvey(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
});
class SurveyPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    users: PropTypes.array,
    assessments: PropTypes.array,
    surveys: PropTypes.array,
    getSurveys: PropTypes.func,
    getSurveyById: PropTypes.func,
    createSurvey: PropTypes.func,
    deleteSurvey: PropTypes.func,
    updateSurvey: PropTypes.func,
    user: PropTypes.object,
    searchUsers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getUsers, getAssessments } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
      loading: true,
      dataLoading: false,
    };
    getUsers();
    getAssessments();
    this.refresh();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      const urlParams = getJsonFromUrl(nextProps.location);
      console.log(urlParams);
      this.setState(
        {
          filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
        },
        this.refresh.bind(this)
      );
    }
  }

  async refresh() {
    console.log("refresh");
    const { getSurveys } = this.props;
    const { filters } = this.state;
    this.setState({ dataLoading: true });
    await Promise.all([getSurveys(filters)]);

    this.setState({ loading: false, dataLoading: false });
  }

  render() {
    const {
      history,
      location,
      surveys,
      getSurveyById,
      createSurvey,
      deleteSurvey,
      updateSurvey,
      users,
      assessments,
      getSurveys,
      user,
      searchUsers,
    } = this.props;

    const { urlParams, loading, dataLoading, filters } = this.state;

    return (
      <WrapperSurveyPage
        history={history}
        users={users}
        user={user}
        searchUsers={searchUsers}
        getSurveys={getSurveys}
        assessments={assessments}
        location={location}
        filters={filters}
        dataLoading={dataLoading}
        refresh={this.refresh.bind(this)}
        loading={loading}
        surveys={surveys}
        getSurveyById={getSurveyById}
        createSurvey={createSurvey}
        deleteSurvey={deleteSurvey}
        updateSurvey={updateSurvey}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyPage);
