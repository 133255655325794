// material-ui
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class ProgramTypesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    programTypes: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedProgramType, index) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedProgramType, index);
    }
  }

  render() {
    const { programTypes } = this.props;

    console.log(programTypes);

    return (
      <Table
        data={programTypes}
        meta={[
          {
            path: "id",
            title: "",
            width: 30,
          },
          {
            path: "visible",
            title: "",
            width: 30,
            render: (t, d) =>
              d.visible ? (
                <i class="fad fa-eye"></i>
              ) : (
                <i class="fas fa-eye-slash"></i>
              ),
          },
          {
            path: "name",
            title: "Name",
            render: (t, d) => (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    background: d.color || "#9e9e9e",
                    height: 20,
                    width: 20,
                    marginRight: 20,
                  }}
                />
                {t}
              </div>
            ),
          },
          {
            path: "subcategories",
            title: "Subcategories",
            render: (t, d) => (
              <div style={{ display: "flex" }}>
                <Typography variant="caption">
                  {d.subcategories
                    .reduce((a, v) => {
                      a.push(v.name);
                      return a;
                    }, [])
                    .join(", ")}
                </Typography>
              </div>
            ),
          },
        ]}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(ProgramTypesList);
