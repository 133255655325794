import * as Api from "api";
import {
  CREATE_PROGRAM_RELATE_SUBCATEGORY,
  DELETE_PROGRAM_RELATE_SUBCATEGORY,
  GET_PROGRAMS_BY_PROGRAM_SUBCATEGORY,
  GET_PROGRAM_RELATE_SUBCATEGORIES,
  GET_PROGRAM_RELATE_SUBCATEGORY_BY_ID,
  UPDATE_PROGRAM_RELATE_SUBCATEGORY,
} from "constants/programRelateSubcategories";
import { networkAction } from "helpers/network/networkAction";

export const getProgramRelateSubcategories = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAM_RELATE_SUBCATEGORIES,
    Api.getProgramRelateSubcategories,
    []
  );

export const getProgramRelateSubcategoryById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAM_RELATE_SUBCATEGORY_BY_ID,
    Api.getProgramRelateSubcategoryById,
    [id]
  );

export const getProgramsByProgramSubcategories = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PROGRAMS_BY_PROGRAM_SUBCATEGORY,
    Api.getProgramsByProgramSubcategories,
    [id]
  );

export const deleteProgramRelateSubcategory = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_PROGRAM_RELATE_SUBCATEGORY,
    Api.deleteProgramRelateSubcategory,
    [id]
  );

export const createProgramRelateSubcategory = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_PROGRAM_RELATE_SUBCATEGORY,
    Api.createProgramRelateSubcategory,
    [body]
  );

export const updateProgramRelateSubcategory = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_PROGRAM_RELATE_SUBCATEGORY,
    Api.updateProgramRelateSubcategory,
    [id, body]
  );
