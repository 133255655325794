export default (theme) => ({
  container: {
    padding: 5,
  },
  labelContainer: {
    padding: 0,
  },
  tabsRoot: {
    color: "white",
    padding: 2,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginRight: 6,
    textTransform: "none",
    minWidth: "auto",
    minHeight: "auto",
    fontSize: 12,
  },
});
