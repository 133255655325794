import * as Api from "api";
import {
  CREATE_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  GET_SUB_CATEGORIES,
  GET_SUB_CATEGORY_BY_ID,
  UPDATE_SUB_CATEGORY,
} from "constants/subCategories";
import { networkAction } from "helpers/network/networkAction";

export const getSubCategories = () => async (dispatch) =>
  networkAction(dispatch, GET_SUB_CATEGORIES, Api.getSubCategories, []);

export const getSubCategoryById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SUB_CATEGORY_BY_ID, Api.getSubCategoryById, [id]);

export const deleteSubCategory = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SUB_CATEGORY, Api.deleteSubCategory, [id]);

export const createSubCategory = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SUB_CATEGORY, Api.createSubCategory, [body]);

export const updateSubCategory = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SUB_CATEGORY, Api.updateSubCategory, [
    id,
    body,
  ]);
