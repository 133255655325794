export const GET_FULL_APP = "GET_FULL_APP";
export const GET_CAREER_CATEGORIES = "GET_CAREER_CATEGORIES";
export const DELETE_CAREER_CATEGORY = "DELETE_CAREER_CATEGORY";
export const ADD_CAREER_CATEGORY = "ADD_CAREER_CATEGORY";
export const GET_CAREERS = "GET_CAREERS";
export const GET_CAREER_BY_ID = "GET_CAREER_BY_ID";
export const UPDATE_CAREER = "UPDATE_CAREER";
export const DELETE_CAREER = "DELETE_CAREER";
export const CREATE_CAREER = "CREATE_CAREER";
export const GET_CAREER_AUTHORS = "GET_CAREER_AUTHORS";
