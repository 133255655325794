import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "harmony";
const surveys = `/${service}/surveys`;
const answers = `/${service}/answers`;

export function getSurveys(filters) {
  const url = `${surveys}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getProfile(id) {
  const url = `${surveys}/${id}/profile`;
  return {
    method: GET,
    url,
  };
}

export function answerQuestion(body) {
  const url = `${answers}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function getSurveyById(id) {
  const url = `${surveys}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSurvey(id, body) {
  const url = `${surveys}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSurvey(id) {
  const url = `${surveys}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSurvey(body) {
  const url = `${surveys}`;
  return {
    method: POST,
    url,
    body,
  };
}
