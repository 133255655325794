// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperClubMembersPage from "../component/WrapperClubMembersPage";

const mapStateToProps = (state) => ({
  clubMembers: state.clubMembers,
});

const mapDispatchToProps = (dispatch) => ({
  getClubMembers: (...args) => dispatch(Actions.getClubMembers(...args)),
  getClubMemberById: (...args) => dispatch(Actions.getClubMemberById(...args)),
  createClubMember: (...args) => dispatch(Actions.createClubMember(...args)),
  deleteClubMember: (...args) => dispatch(Actions.deleteClubMember(...args)),
  updateClubMember: (...args) => dispatch(Actions.updateClubMember(...args)),
  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
});
class ClubMembersPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    searchUsers: PropTypes.func,

    clubMembers: PropTypes.array,
    getClubMembers: PropTypes.func,
    getClubMemberById: PropTypes.func,
    createClubMember: PropTypes.func,
    deleteClubMember: PropTypes.func,
    updateClubMember: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, clubMembers } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: clubMembers.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getClubMembers } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getClubMembers(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      clubMembers,
      getClubMemberById,
      createClubMember,
      deleteClubMember,
      updateClubMember,
      getClubMembers,
      searchUsers,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperClubMembersPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        clubMembers={clubMembers}
        searchUsers={searchUsers}
        getClubMemberById={getClubMemberById}
        getClubMembers={getClubMembers}
        createClubMember={createClubMember}
        deleteClubMember={deleteClubMember}
        updateClubMember={updateClubMember}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClubMembersPage);
