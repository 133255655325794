// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperAcademyPage from "../component/WrapperAcademyPage";

const mapStateToProps = (state) => ({
  academies: state.academies,
  programSubcategories: state.programSubcategories,
  masterTags: state.masterTags,
});

const mapDispatchToProps = (dispatch) => ({
  getAcademies: (...args) => dispatch(Actions.getAcademies(...args)),
  getAcademyById: (...args) => dispatch(Actions.getAcademyById(...args)),
  getAcademyPrograms: (...args) =>
    dispatch(Actions.getAcademyPrograms(...args)),
  deleteAcademy: (...args) => dispatch(Actions.deleteAcademy(...args)),
  createAcademy: (...args) => dispatch(Actions.createAcademy(...args)),
  updateAcademy: (...args) => dispatch(Actions.updateAcademy(...args)),

  getAges: (...args) => dispatch(Actions.getAges(...args)),
  getAgeById: (...args) => dispatch(Actions.getAgeById(...args)),
  deleteAge: (...args) => dispatch(Actions.deleteAge(...args)),
  createAge: (...args) => dispatch(Actions.createAge(...args)),
  updateAge: (...args) => dispatch(Actions.updateAge(...args)),

  getDivisions: (...args) => dispatch(Actions.getDivisions(...args)),
  getDivisionById: (...args) => dispatch(Actions.getDivisionById(...args)),
  deleteDivision: (...args) => dispatch(Actions.deleteDivision(...args)),
  createDivision: (...args) => dispatch(Actions.createDivision(...args)),
  updateDivision: (...args) => dispatch(Actions.updateDivision(...args)),

  createProgramDivision: (...args) =>
    dispatch(Actions.createProgramDivision(...args)),
  deleteProgramDivision: (...args) =>
    dispatch(Actions.deleteProgramDivision(...args)),

  getInstitutions: (...args) => dispatch(Actions.getInstitutions(...args)),
  createProgramInstitution: (...args) =>
    dispatch(Actions.createProgramInstitution(...args)),
  deleteProgramInstitution: (...args) =>
    dispatch(Actions.deleteProgramInstitution(...args)),

  getGrades: (...args) => dispatch(Actions.getGrades(...args)),
  getGradeById: (...args) => dispatch(Actions.getGradeById(...args)),
  deleteGrade: (...args) => dispatch(Actions.deleteGrade(...args)),
  createGrade: (...args) => dispatch(Actions.createGrade(...args)),
  updateGrade: (...args) => dispatch(Actions.updateGrade(...args)),

  getLevels: (...args) => dispatch(Actions.getLevels(...args)),
  getLevelById: (...args) => dispatch(Actions.getLevelById(...args)),
  deleteLevel: (...args) => dispatch(Actions.deleteLevel(...args)),
  createLevel: (...args) => dispatch(Actions.createLevel(...args)),
  updateLevel: (...args) => dispatch(Actions.updateLevel(...args)),

  createProgramLevel: (...args) =>
    dispatch(Actions.createProgramLevel(...args)),
  deleteProgramLevel: (...args) =>
    dispatch(Actions.deleteProgramLevel(...args)),

  getMusicalFamilies: (...args) =>
    dispatch(Actions.getMusicalFamilies(...args)),
  getMusicalFamilyById: (...args) =>
    dispatch(Actions.getMusicalFamilyById(...args)),
  deleteMusicalFamily: (...args) =>
    dispatch(Actions.deleteMusicalFamily(...args)),
  createMusicalFamily: (...args) =>
    dispatch(Actions.createMusicalFamily(...args)),
  updateMusicalFamily: (...args) =>
    dispatch(Actions.updateMusicalFamily(...args)),

  createProgramMusicalFamily: (...args) =>
    dispatch(Actions.createProgramMusicalFamily(...args)),
  deleteProgramMusicalFamily: (...args) =>
    dispatch(Actions.deleteProgramMusicalFamily(...args)),

  getInstruments: (...args) => dispatch(Actions.getInstruments(...args)),
  getInstrumentById: (...args) => dispatch(Actions.getInstrumentById(...args)),
  deleteInstrument: (...args) => dispatch(Actions.deleteInstrument(...args)),
  createInstrument: (...args) => dispatch(Actions.createInstrument(...args)),
  updateInstrument: (...args) => dispatch(Actions.updateInstrument(...args)),

  deleteProgramInstrument: (...args) =>
    dispatch(Actions.deleteProgramInstrument(...args)),
  createProgramInstrument: (...args) =>
    dispatch(Actions.createProgramInstrument(...args)),

  getPrograms: (...args) => dispatch(Actions.getPrograms(...args)),
  getProgramById: (...args) => dispatch(Actions.getProgramById(...args)),
  deleteProgram: (...args) => dispatch(Actions.deleteProgram(...args)),
  createProgram: (...args) => dispatch(Actions.createProgram(...args)),
  updateProgram: (...args) => dispatch(Actions.updateProgram(...args)),

  getProgramTypes: (...args) => dispatch(Actions.getProgramTypes(...args)),
  getProgramTypeById: (...args) =>
    dispatch(Actions.getProgramTypeById(...args)),
  deleteProgramType: (...args) => dispatch(Actions.deleteProgramType(...args)),
  createProgramType: (...args) => dispatch(Actions.createProgramType(...args)),
  updateProgramType: (...args) => dispatch(Actions.updateProgramType(...args)),

  getProgramTags: (...args) => dispatch(Actions.getProgramTags(...args)),
  getProgramTagById: (...args) => dispatch(Actions.getProgramTagById(...args)),
  deleteProgramTag: (...args) => dispatch(Actions.deleteProgramTag(...args)),
  createProgramTag: (...args) => dispatch(Actions.createProgramTag(...args)),
  updateProgramTag: (...args) => dispatch(Actions.updateProgramTag(...args)),

  getProgramSubcategories: (...args) =>
    dispatch(Actions.getProgramSubcategories(...args)),

  getProgramRelateSubcategories: (...args) =>
    dispatch(Actions.getProgramRelateSubcategories(...args)),
  getProgramRelateSubcategoryById: (...args) =>
    dispatch(Actions.getProgramRelateSubcategoryById(...args)),
  deleteProgramRelateSubcategory: (...args) =>
    dispatch(Actions.deleteProgramRelateSubcategory(...args)),
  createProgramRelateSubcategory: (...args) =>
    dispatch(Actions.createProgramRelateSubcategory(...args)),
  updateProgramRelateSubcategory: (...args) =>
    dispatch(Actions.updateProgramRelateSubcategory(...args)),

  getMasterTags: (...args) => dispatch(Actions.getMasterTags(...args)),
});

class AcademyPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    academies: PropTypes.array,

    getAcademies: PropTypes.func,
    getAcademyById: PropTypes.func,
    getAcademyPrograms: PropTypes.func,
    deleteAcademy: PropTypes.func,
    createAcademy: PropTypes.func,
    updateAcademy: PropTypes.func,

    getAges: PropTypes.func,
    getAgeById: PropTypes.func,
    deleteAge: PropTypes.func,
    createAge: PropTypes.func,
    updateAge: PropTypes.func,

    getProgramTags: PropTypes.func,
    getProgramTagById: PropTypes.func,
    deleteProgramTag: PropTypes.func,
    createProgramTag: PropTypes.func,
    updateProgramTag: PropTypes.func,

    getDivisions: PropTypes.func,
    getDivisionById: PropTypes.func,
    deleteDivision: PropTypes.func,
    createDivision: PropTypes.func,
    updateDivision: PropTypes.func,

    createProgramDivision: PropTypes.func,
    deleteProgramDivision: PropTypes.func,

    getGrades: PropTypes.func,
    getGradeById: PropTypes.func,
    deleteGrade: PropTypes.func,
    createGrade: PropTypes.func,
    updateGrade: PropTypes.func,

    getLevels: PropTypes.func,
    getLevelById: PropTypes.func,
    deleteLevel: PropTypes.func,
    createLevel: PropTypes.func,
    updateLevel: PropTypes.func,

    createProgramLevel: PropTypes.func,
    deleteProgramLevel: PropTypes.func,

    getMusicalFamilies: PropTypes.func,
    getMusicalFamilyById: PropTypes.func,
    deleteMusicalFamily: PropTypes.func,
    createMusicalFamily: PropTypes.func,
    updateMusicalFamily: PropTypes.func,

    createProgramMusicalFamily: PropTypes.func,
    deleteProgramMusicalFamily: PropTypes.func,

    getInstruments: PropTypes.func,
    getInstrumentById: PropTypes.func,
    deleteInstrument: PropTypes.func,
    createInstrument: PropTypes.func,
    updateInstrument: PropTypes.func,

    deleteProgramInstrument: PropTypes.func,
    createProgramInstrument: PropTypes.func,

    getPrograms: PropTypes.func,
    getProgramById: PropTypes.func,
    deleteProgram: PropTypes.func,
    createProgram: PropTypes.func,
    updateProgram: PropTypes.func,

    getProgramTypes: PropTypes.func,
    getProgramTypeById: PropTypes.func,
    deleteProgramType: PropTypes.func,
    createProgramType: PropTypes.func,
    updateProgramType: PropTypes.func,

    getProgramRelateSubcategories: PropTypes.func,
    getProgramRelateSubcategoryById: PropTypes.func,
    deleteProgramRelateSubcategory: PropTypes.func,
    createProgramRelateSubcategory: PropTypes.func,
    updateProgramRelateSubcategory: PropTypes.func,

    getProgramSubcategories: PropTypes.func,
    getMasterTags: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getAcademies, academies } = this.props;

    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      academyID: urlParams.id,
      programID: urlParams.programID,
      loading: true,
      contentLoading: false,
    };
    if (academies.length === 0) {
      getAcademies();
    }
    this.init().then(this.refresh.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    const nextUrlParams = getJsonFromUrl(nextProps.location);
    const urlParams = getJsonFromUrl(this.props.location);
    if (nextUrlParams.id !== urlParams.id) {
      this.setState({
        loading: true,
        academyID: nextUrlParams.id,
      });
      setTimeout(this.refresh.bind(this), 100);
    }

    if (nextUrlParams.programID === undefined) {
      this.setState({
        selectedProgram: undefined,
        programID: undefined,
      });
      return;
    }

    if (nextUrlParams.programID !== urlParams.programID) {
      this.setState({
        contentLoading: true,
        programID: nextUrlParams.programID,
      });
      setTimeout(this.refresh.bind(this), 100);
    }
  }

  async init() {
    const {
      getProgramTypes,
      getGrades,
      getLevels,
      getAges,
      getDivisions,
      getInstruments,
      getMusicalFamilies,
      getInstitutions,
      getProgramSubcategories,
      getMasterTags,
    } = this.props;

    const resps = await Promise.all([
      getProgramTypes(),
      getGrades(),
      getAges(),
      getLevels(),
      getDivisions(),
      getInstruments(),
      getMusicalFamilies(),
      getInstitutions(),
      getProgramSubcategories(),
      getMasterTags(),
    ]);
    this.setState({
      programTypes: resps[0].payload,
      grades: resps[1].payload,
      ages: resps[2].payload,
      levels: resps[3].payload,
      divisions: resps[4].payload,
      instruments: resps[5].payload,
      musicalFamilies: resps[6].payload,
      institutions: resps[7].payload,
      programSubcategories: resps[8].payload,
      masterTags: resps[9].payload,
    });
  }

  async refresh() {
    const { academyID, programID } = this.state;
    const { getAcademyById, getProgramById, getAcademyPrograms } = this.props;

    const proms = [getAcademyById(academyID), getAcademyPrograms(academyID)];

    if (programID) {
      proms.push(getProgramById(programID));
    }

    const resps = await Promise.all(proms);

    this.setState({
      academy: resps[0].payload,
      programs: resps[1].payload,
      selectedProgram: resps[2] ? resps[2].payload : undefined,
      filteredPrograms: this.filterPrograms(resps[1].payload),
      loading: false,
      contentLoading: false,
      refreshKey: new Date().getTime(),
    });
  }

  filterPrograms(programs) {
    const { programTypes } = this.state;
    const filteredPrograms = {};

    for (const key in programTypes) {
      if (programTypes.hasOwnProperty(key)) {
        const programType = programTypes[key];
        const programsForType = programs.filter(
          (p) => p.programTypeID === programType.id
        ); // eslint-disable-line
        if (programsForType && programsForType.length) {
          filteredPrograms[programType.name] = programsForType;
        }
      }
    }

    return filteredPrograms;
  }

  render() {
    const {
      location,
      history,
      academies,

      getAcademies,
      getAcademyById,
      deleteAcademy,
      createAcademy,
      updateAcademy,

      getAges,
      getAgeById,
      deleteAge,
      createAge,
      updateAge,

      getDivisions,
      getDivisionById,
      deleteDivision,
      createDivision,
      updateDivision,

      createProgramDivision,
      deleteProgramDivision,

      createProgramInstitution,
      deleteProgramInstitution,

      getGrades,
      getGradeById,
      deleteGrade,
      createGrade,
      updateGrade,

      getLevels,
      getLevelById,
      deleteLevel,
      createLevel,
      updateLevel,

      createProgramLevel,
      deleteProgramLevel,

      getMusicalFamilies,
      getMusicalFamilyById,
      deleteMusicalFamily,
      createMusicalFamily,
      updateMusicalFamily,

      createProgramMusicalFamily,
      deleteProgramMusicalFamily,

      getInstruments,
      getInstrumentById,
      deleteInstrument,
      createInstrument,
      updateInstrument,

      deleteProgramInstrument,
      createProgramInstrument,

      getPrograms,
      getProgramById,
      deleteProgram,
      createProgram,
      updateProgram,

      getProgramTypes,
      getProgramTypeById,
      deleteProgramType,
      createProgramType,
      updateProgramType,

      getProgramRelateSubcategories,
      getProgramRelateSubcategoryById,
      deleteProgramRelateSubcategory,
      createProgramRelateSubcategory,
      updateProgramRelateSubcategory,

      getProgramTags,
      getProgramTagById,
      deleteProgramTag,
      createProgramTag,
      updateProgramTag,
    } = this.props;

    const {
      urlParams,
      loading,
      programs,
      filteredPrograms,
      academy,
      programTypes,
      grades,
      ages,
      levels,
      divisions,
      instruments,
      institutions,
      musicalFamilies,
      selectedProgram,
      contentLoading,
      programSubcategories,
      masterTags,
      refreshKey,
    } = this.state;

    const api = {
      getAcademies,
      getAcademyById,
      deleteAcademy,
      createAcademy,
      updateAcademy,

      getAges,
      getAgeById,
      deleteAge,
      createAge,
      updateAge,

      getProgramTags,
      getProgramTagById,
      deleteProgramTag,
      createProgramTag,
      updateProgramTag,

      getDivisions,
      getDivisionById,
      deleteDivision,
      createDivision,
      updateDivision,

      createProgramDivision,
      deleteProgramDivision,

      createProgramInstitution,
      deleteProgramInstitution,

      getGrades,
      getGradeById,
      deleteGrade,
      createGrade,
      updateGrade,

      getLevels,
      getLevelById,
      deleteLevel,
      createLevel,
      updateLevel,

      createProgramLevel,
      deleteProgramLevel,

      getMusicalFamilies,
      getMusicalFamilyById,
      deleteMusicalFamily,
      createMusicalFamily,
      updateMusicalFamily,

      createProgramMusicalFamily,
      deleteProgramMusicalFamily,

      getInstruments,
      getInstrumentById,
      deleteInstrument,
      createInstrument,
      updateInstrument,

      deleteProgramInstrument,
      createProgramInstrument,

      getPrograms,
      getProgramById,
      deleteProgram,
      createProgram,
      updateProgram,

      getProgramTypes,
      getProgramTypeById,
      deleteProgramType,
      createProgramType,
      updateProgramType,

      getProgramRelateSubcategories,
      getProgramRelateSubcategoryById,
      deleteProgramRelateSubcategory,
      createProgramRelateSubcategory,
      updateProgramRelateSubcategory,
    };

    return (
      <WrapperAcademyPage
        history={history}
        location={location}
        urlParams={urlParams}
        loading={loading}
        contentLoading={contentLoading}
        api={api}
        programs={programs}
        filteredPrograms={filteredPrograms}
        academy={academy}
        programTypes={programTypes}
        grades={grades}
        ages={ages}
        levels={levels}
        divisions={divisions}
        institutions={institutions}
        programSubcategories={programSubcategories}
        academies={academies}
        instruments={instruments}
        musicalFamilies={musicalFamilies}
        refreshKey={refreshKey}
        selectedProgram={selectedProgram}
        masterTags={masterTags}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AcademyPage);
