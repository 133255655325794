import { serviceRoles } from "config/access";
import { EVENT_TYPE } from "constants/routes";
import { checkAccess } from "helpers/auth";
import EventTypePage from "./container/EventTypePage";

export default function getSettingsRoute(userAuth) {
  const { account, calendy } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [calendy.name]: [calendy.roles.admin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: EVENT_TYPE,
    withSidebar: true,
    withAppBar: true,
    component: EventTypePage,
  };
}
