import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperDegreeLevelsPage from '../component/WrapperDegreeLevelsPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  degreeLevels: state.degreeLevels,
});

const mapDispatchToProps = dispatch => ({
  getDegreeLevels: () => dispatch(Actions.getDegreeLevels()),
  getDegreeLevelById: (...args) => dispatch(Actions.getDegreeLevelById(...args)),
  createDegreeLevel: (...args) => dispatch(Actions.createDegreeLevel(...args)),
  deleteDegreeLevel: (...args) => dispatch(Actions.deleteDegreeLevel(...args)),
  updateDegreeLevel: (...args) => dispatch(Actions.updateDegreeLevel(...args)),
});
class DegreeLevelsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    degreeLevels: PropTypes.array,
    getDegreeLevels: PropTypes.func,
    getDegreeLevelById: PropTypes.func,
    createDegreeLevel: PropTypes.func,
    deleteDegreeLevel: PropTypes.func,
    updateDegreeLevel: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, degreeLevels } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: degreeLevels.length === 0,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.refresh();
  }

  async refresh() {
    const { getDegreeLevels } = this.props;
    await Promise.all([
      getDegreeLevels(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      degreeLevels,
      getDegreeLevelById,
      createDegreeLevel,
      deleteDegreeLevel,
      updateDegreeLevel,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperDegreeLevelsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        degreeLevels={degreeLevels}
        getDegreeLevelById={getDegreeLevelById}
        createDegreeLevel={createDegreeLevel}
        deleteDegreeLevel={deleteDegreeLevel}
        updateDegreeLevel={updateDegreeLevel}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DegreeLevelsPage);
