import * as Api from "api";
import {
  CREATE_CAREER_TITLE,
  DELETE_CAREER_TITLE,
  GET_CAREER_TITLES,
  GET_CAREER_TITLE_BY_ID,
  UPDATE_CAREER_TITLE,
} from "constants/careerTitles";
import { networkAction } from "helpers/network/networkAction";

export const getCareerTitles = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_TITLES, Api.getCareerTitles, [filters]);

export const getCareerTitleById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CAREER_TITLE_BY_ID, Api.getCareerTitleById, [id]);

export const deleteCareerTitle = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CAREER_TITLE, Api.deleteCareerTitle, [id]);

export const createCareerTitle = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CAREER_TITLE, Api.createCareerTitle, [body]);

export const updateCareerTitle = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CAREER_TITLE, Api.updateCareerTitle, [
    id,
    body,
  ]);
