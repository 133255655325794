import { networkAction } from 'helpers/network/networkAction';

import {
  GET_QUIZ_TAGS,
  GET_QUIZ_TAG_BY_ID,
  DELETE_QUIZ_TAG,
  UPDATE_QUIZ_TAG,
  CREATE_QUIZ_TAG,
} from 'constants/quizTags';

import * as Api from 'api';

export const getQuizTags = () => async dispatch => networkAction(
  dispatch,
  GET_QUIZ_TAGS,
  Api.getQuizTags,
  []
);

export const getQuizTagById = (id) => async dispatch => networkAction(
  dispatch,
  GET_QUIZ_TAG_BY_ID,
  Api.getQuizTagById,
  [id]
);

export const deleteQuizTag = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_QUIZ_TAG,
  Api.deleteQuizTag,
  [id]
);

export const createQuizTag = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_QUIZ_TAG,
  Api.createQuizTag,
  [body]
);

export const updateQuizTag = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_QUIZ_TAG,
  Api.updateQuizTag,
  [id, body]
);
