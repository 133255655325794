import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "aptitude";
const courses = `/${service}/courses`;

export function getCourses(filters) {
  const url = `${courses}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCourseById(id) {
  const url = `${courses}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getCourseModules(id) {
  const url = `${courses}/${id}/modules`;
  return {
    method: GET,
    url,
  };
}

export function updateCourse(id, body) {
  const url = `${courses}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCourse(id) {
  const url = `${courses}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCourse(body) {
  const url = `${courses}`;
  return {
    method: POST,
    url,
    body,
  };
}
