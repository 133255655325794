// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperSettingsPage from "../component/WrapperSettingsPage";

const mapStateToProps = (state) => ({
  ages: state.ages,
  levels: state.levels,
  grades: state.grades,
  instruments: state.instruments,
  musicalFamilies: state.musicalFamilies,
  programTypes: state.programTypes,
  divisions: state.divisions,
  institutions: state.institutions,
  programSubcategories: state.programSubcategories,
  masterTags: state.masterTags,
  programSubcategories: state.programSubcategories,
});

const mapDispatchToProps = (dispatch) => ({
  getAges: (...args) => dispatch(Actions.getAges(...args)),
  getAgeById: (...args) => dispatch(Actions.getAgeById(...args)),
  deleteAge: (...args) => dispatch(Actions.deleteAge(...args)),
  createAge: (...args) => dispatch(Actions.createAge(...args)),
  updateAge: (...args) => dispatch(Actions.updateAge(...args)),

  getDivisions: (...args) => dispatch(Actions.getDivisions(...args)),
  getDivisionById: (...args) => dispatch(Actions.getDivisionById(...args)),
  deleteDivision: (...args) => dispatch(Actions.deleteDivision(...args)),
  createDivision: (...args) => dispatch(Actions.createDivision(...args)),
  updateDivision: (...args) => dispatch(Actions.updateDivision(...args)),

  getInstitutions: (...args) => dispatch(Actions.getInstitutions(...args)),
  getInstitutionById: (...args) =>
    dispatch(Actions.getInstitutionById(...args)),
  deleteInstitution: (...args) => dispatch(Actions.deleteInstitution(...args)),
  createInstitution: (...args) => dispatch(Actions.createInstitution(...args)),
  updateInstitution: (...args) => dispatch(Actions.updateInstitution(...args)),

  getGrades: (...args) => dispatch(Actions.getGrades(...args)),
  getGradeById: (...args) => dispatch(Actions.getGradeById(...args)),
  deleteGrade: (...args) => dispatch(Actions.deleteGrade(...args)),
  createGrade: (...args) => dispatch(Actions.createGrade(...args)),
  updateGrade: (...args) => dispatch(Actions.updateGrade(...args)),

  getLevels: (...args) => dispatch(Actions.getLevels(...args)),
  getLevelById: (...args) => dispatch(Actions.getLevelById(...args)),
  deleteLevel: (...args) => dispatch(Actions.deleteLevel(...args)),
  createLevel: (...args) => dispatch(Actions.createLevel(...args)),
  updateLevel: (...args) => dispatch(Actions.updateLevel(...args)),

  getMasterTags: (...args) => dispatch(Actions.getMasterTags(...args)),
  getMasterTagById: (...args) => dispatch(Actions.getMasterTagById(...args)),
  deleteMasterTag: (...args) => dispatch(Actions.deleteMasterTag(...args)),
  createMasterTag: (...args) => dispatch(Actions.createMasterTag(...args)),
  updateMasterTag: (...args) => dispatch(Actions.updateMasterTag(...args)),

  getInstruments: (...args) => dispatch(Actions.getInstruments(...args)),
  getInstrumentById: (...args) => dispatch(Actions.getInstrumentById(...args)),
  deleteInstrument: (...args) => dispatch(Actions.deleteInstrument(...args)),
  createInstrument: (...args) => dispatch(Actions.createInstrument(...args)),
  updateInstrument: (...args) => dispatch(Actions.updateInstrument(...args)),

  getMusicalFamilies: (...args) =>
    dispatch(Actions.getMusicalFamilies(...args)),
  getMusicalFamilyById: (...args) =>
    dispatch(Actions.getMusicalFamilyById(...args)),
  deleteMusicalFamily: (...args) =>
    dispatch(Actions.deleteMusicalFamily(...args)),
  createMusicalFamily: (...args) =>
    dispatch(Actions.createMusicalFamily(...args)),
  updateMusicalFamily: (...args) =>
    dispatch(Actions.updateMusicalFamily(...args)),

  getProgramTypes: (...args) => dispatch(Actions.getProgramTypes(...args)),
  getProgramTypeById: (...args) =>
    dispatch(Actions.getProgramTypeById(...args)),
  deleteProgramType: (...args) => dispatch(Actions.deleteProgramType(...args)),
  createProgramType: (...args) => dispatch(Actions.createProgramType(...args)),
  updateProgramType: (...args) => dispatch(Actions.updateProgramType(...args)),

  getProgramSubcategories: (...args) =>
    dispatch(Actions.getProgramSubcategories(...args)),
  getProgramSubcategoryById: (...args) =>
    dispatch(Actions.getProgramSubcategoryById(...args)),
  deleteProgramSubcategory: (...args) =>
    dispatch(Actions.deleteProgramSubcategory(...args)),
  createProgramSubcategory: (...args) =>
    dispatch(Actions.createProgramSubcategory(...args)),
  updateProgramSubcategory: (...args) =>
    dispatch(Actions.updateProgramSubcategory(...args)),

  getProgramSubcategories: (...args) =>
    dispatch(Actions.getProgramSubcategories(...args)),

  getAcademies: (...args) => dispatch(Actions.getAcademies(...args)),
});
class SettingsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    ages: PropTypes.array,
    getAges: PropTypes.func,
    getAgeById: PropTypes.func,
    deleteAge: PropTypes.func,
    createAge: PropTypes.func,
    updateAge: PropTypes.func,

    divisions: PropTypes.array,
    getDivisions: PropTypes.func,
    getDivisionById: PropTypes.func,
    deleteDivision: PropTypes.func,
    createDivision: PropTypes.func,
    updateDivision: PropTypes.func,

    grades: PropTypes.array,
    getGrades: PropTypes.func,
    getGradeById: PropTypes.func,
    deleteGrade: PropTypes.func,
    createGrade: PropTypes.func,
    updateGrade: PropTypes.func,

    institutions: PropTypes.array,
    getInstitutions: PropTypes.func,
    getInstitutionById: PropTypes.func,
    deleteInstitution: PropTypes.func,
    createInstitution: PropTypes.func,
    updateInstitution: PropTypes.func,

    levels: PropTypes.array,
    getLevels: PropTypes.func,
    getLevelById: PropTypes.func,
    deleteLevel: PropTypes.func,
    createLevel: PropTypes.func,
    updateLevel: PropTypes.func,

    masterTags: PropTypes.array,
    getMasterTags: PropTypes.func,
    getMasterTagById: PropTypes.func,
    deleteMasterTag: PropTypes.func,
    createMasterTag: PropTypes.func,
    updateMasterTag: PropTypes.func,

    musicalFamilies: PropTypes.array,
    getMusicalFamilies: PropTypes.func,
    getMusicalFamilyById: PropTypes.func,
    deleteMusicalFamily: PropTypes.func,
    createMusicalFamily: PropTypes.func,
    updateMusicalFamily: PropTypes.func,

    instruments: PropTypes.array,
    getInstruments: PropTypes.func,
    getInstrumentById: PropTypes.func,
    deleteInstrument: PropTypes.func,
    createInstrument: PropTypes.func,
    updateInstrument: PropTypes.func,

    programTypes: PropTypes.array,
    getProgramTypes: PropTypes.func,
    getProgramTypeById: PropTypes.func,
    deleteProgramType: PropTypes.func,
    createProgramType: PropTypes.func,
    updateProgramType: PropTypes.func,

    getProgramSubcategories: PropTypes.func,
    getProgramSubcategoryById: PropTypes.func,
    deleteProgramSubcategory: PropTypes.func,
    createProgramSubcategory: PropTypes.func,
    updateProgramSubcategory: PropTypes.func,

    getProgramSubcategories: PropTypes.func,
    programSubcategories: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: true,
    };
    this.refresh();
  }

  // componentDidMount() {
  //   this.refresh();
  // }

  async refresh() {
    const {
      getAges,
      getDivisions,
      getGrades,
      getLevels,
      getMusicalFamilies,
      getInstruments,
      getProgramTypes,
      getInstitutions,
      getProgramSubcategories,
      getMasterTags,
      getAcademies,
    } = this.props;

    await Promise.all([
      getAges(),
      getDivisions(),
      getGrades(),
      getLevels(),
      getMusicalFamilies(),
      getInstruments(),
      getProgramTypes(),
      getInstitutions(),
      getProgramSubcategories(),
      getMasterTags(),
      getAcademies(),
    ]);
    this.setState({ loading: false });
  }

  injectProgramSubcategories() {
    const { programTypes, programSubcategories } = this.props;
    const _programTypes = [];
    for (const key in programTypes) {
      if (Object.hasOwnProperty.call(programTypes, key)) {
        const programType = programTypes[key];
        programType.subcategories = programSubcategories.filter(
          (p) => p.programTypeID === programType.id
        );
        _programTypes.push(programType);
      }
    }

    return _programTypes;
  }

  render() {
    const {
      location,
      history,

      ages,
      getAges,
      getAgeById,
      deleteAge,
      createAge,
      updateAge,

      divisions,
      getDivisions,
      getDivisionById,
      deleteDivision,
      createDivision,
      updateDivision,

      institutions,
      getInstitutions,
      getInstitutionById,
      deleteInstitution,
      createInstitution,
      updateInstitution,

      grades,
      getGrades,
      getGradeById,
      deleteGrade,
      createGrade,
      updateGrade,

      levels,
      getLevels,
      getLevelById,
      deleteLevel,
      createLevel,
      updateLevel,

      masterTags,
      getMasterTags,
      getMasterTagById,
      deleteMasterTag,
      createMasterTag,
      updateMasterTag,

      musicalFamilies,
      getMusicalFamilies,
      getMusicalFamilyById,
      deleteMusicalFamily,
      createMusicalFamily,
      updateMusicalFamily,

      instruments,
      getInstruments,
      getInstrumentById,
      deleteInstrument,
      createInstrument,
      updateInstrument,

      programTypes,
      getProgramTypes,
      getProgramTypeById,
      deleteProgramType,
      createProgramType,
      updateProgramType,

      getProgramSubcategories,
      getProgramSubcategoryById,
      deleteProgramSubcategory,
      createProgramSubcategory,
      updateProgramSubcategory,

      programSubcategories,
    } = this.props;

    const { urlParams, loading } = this.state;

    const api = {
      getAges,
      getAgeById,
      deleteAge,
      createAge,
      updateAge,

      masterTags,
      getMasterTags,
      getMasterTagById,
      deleteMasterTag,
      createMasterTag,
      updateMasterTag,

      getDivisions,
      getDivisionById,
      deleteDivision,
      createDivision,
      updateDivision,

      getInstitutions,
      getInstitutionById,
      deleteInstitution,
      createInstitution,
      updateInstitution,

      getGrades,
      getGradeById,
      deleteGrade,
      createGrade,
      updateGrade,

      getLevels,
      getLevelById,
      deleteLevel,
      createLevel,
      updateLevel,

      getMusicalFamilies,
      getMusicalFamilyById,
      deleteMusicalFamily,
      createMusicalFamily,
      updateMusicalFamily,

      getInstruments,
      getInstrumentById,
      deleteInstrument,
      createInstrument,
      updateInstrument,

      getProgramTypes,
      getProgramTypeById,
      deleteProgramType,
      createProgramType,
      updateProgramType,

      getProgramSubcategories,
      getProgramSubcategoryById,
      deleteProgramSubcategory,
      createProgramSubcategory,
      updateProgramSubcategory,

      programSubcategories,
    };

    return (
      <WrapperSettingsPage
        history={history}
        location={location}
        institutions={institutions}
        api={api}
        urlParams={urlParams}
        loading={loading}
        ages={ages}
        divisions={divisions}
        levels={levels}
        instruments={instruments}
        programSubcategories={programSubcategories}
        programTypes={this.injectProgramSubcategories()}
        grades={grades}
        masterTags={masterTags}
        musicalFamilies={musicalFamilies}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
