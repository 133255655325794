import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// helpers
import { durationFormatter } from "helpers/date";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TimeAssetList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    timeAssets: PropTypes.array,
    eventTypes: PropTypes.array,
    events: PropTypes.array,
  };

  render() {
    const { classes, timeAssets, events, eventTypes } = this.props;

    const today = moment();

    const totalDuration =
      events &&
      events.reduce(
        (a, e) => (e.billableDuration ? Number(a + e.billableDuration) : a),
        0
      );
    const totalAssets =
      timeAssets &&
      timeAssets.reduce((a, e) => (e.duration ? Number(a + e.duration) : a), 0);
    const totalSchedule =
      events &&
      events.reduce((a, e) => {
        const dateFrom = moment(e.dateFromUTC);
        if (today.diff(dateFrom) <= 0) {
          return a + e.eventType.duration;
        }
        return a;
      }, 0);
    const remaining = totalAssets - totalDuration - totalSchedule;

    console.log(timeAssets, events);

    return (
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography display="block" variant="h6">
              Time Purchased
            </Typography>
            <div
              style={{
                width: "100%",
                height: 30,
                overflow: "hidden",
                borderRadius: 6,
                marginBottom: 20,
                position: "relative",
                display: "flex",
              }}
            >
              <div
                style={{
                  background: "#bdbdbd",
                  transition: "width 1s",
                  width: `${(totalDuration / totalAssets) * 100}%`,
                }}
              />
              <div
                style={{
                  background: "#42a5f5",
                  transition: "width 1s",
                  width: `${(totalSchedule / totalAssets) * 100}%`,
                }}
              />
              <div
                style={{
                  background: "#9ccc65",
                  transition: "width 1s",
                  width: `${(remaining / totalAssets) * 100}%`,
                }}
              />
            </div>
            <Grid container spacing={2}>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <div
                      style={{
                        background: "#bdbdbd",
                        height: 15,
                        width: 15,
                        borderRadius: 15,
                        border: "solid 3px white",
                        boxShadow: "0px 0px 8px rgba(0,0,0,0.4)",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="body2">
                      Consumed
                    </Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {durationFormatter(totalDuration)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <div
                      style={{
                        background: "#42a5f5",
                        height: 15,
                        width: 15,
                        borderRadius: 15,
                        border: "solid 3px white",
                        boxShadow: "0px 0px 8px rgba(0,0,0,0.4)",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="body2">
                      Scheduled
                    </Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {durationFormatter(totalSchedule)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <div
                      style={{
                        background: "#9ccc65",
                        height: 15,
                        width: 15,
                        borderRadius: 15,
                        border: "solid 3px white",
                        boxShadow: "0px 0px 8px rgba(0,0,0,0.4)",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="body2">
                      Remainig
                    </Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {durationFormatter(remaining)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography display="block" variant="h6">
              Purchase History
            </Typography>
            <div>
              <Divider />
              <div
                style={{
                  height: 120,
                  position: "relative",
                }}
              >
                {timeAssets && timeAssets.length > 3 ? (
                  <div className={classes.shadow} />
                ) : (
                  []
                )}
                <div className={classes.frame}>
                  {timeAssets &&
                    timeAssets.map((ta) => (
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item xs={2}>
                          <Typography display="block" variant="body2">
                            {durationFormatter(ta.duration)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography display="block" variant="caption">
                            {eventTypes?.find((e) => e.id === ta.eventTypeID)
                              ?.name ?? "Undefined"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography display="block" variant="body2">
                            {moment(ta.datePurchased).format("lll")}
                          </Typography>
                          <Typography
                            display="block"
                            variant="caption"
                            color="textSecondary"
                          >
                            {moment(ta.datePurchased).fromNow()}
                            <br />
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography display="block" variant="caption">
                            PaymentID: {ta.paymentID}
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                          <Chip
                            label="View Payment"
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              window.location.replace(
                                `${REACT_APP_BAZAAR}/payment?id=${ta.paymentID}`
                              );
                            }}
                          />
                        </Grid> */}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    ))}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(TimeAssetList);
